import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
// import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { CONF } from "../../config/config";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const StyledButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
        margin: 20px;
    }
`;

/* const ModalText = styled.span`
  text-align: center;
  width: 100%;
  display: block;
`; */

const StyledButton = styled.button`
    border-radius: 23px;
    width: 224px;
    background-color: ${(props) => (props.hollow ? "#ffffff" : "#dc1448")};
    border-color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    padding: 12px 0;
    display: flex;
    justify-content: center;

    :hover {
        background-color: ${(props) => (props.hollow ? "#e9e9e9" : "#BE1442")};
        border-color: #be1442;
    }
`;

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

/*
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
*/

//export default function ActivityModal(props) {
const ActivityModal = (props) => {
    const [open, setOpen] = React.useState(true);
    let timeOut;

    let currentPath;
    if (CONF.OPS_MODE === "internal") {
        currentPath = "/bayronnect";
    } else {
        currentPath = "/";
    }

    useEffect(() => {
        timeOut = setTimeout(function () {
            window.location.href = currentPath;
        }, 20000);
        console.debug(timeOut);

        return function cleanup() {
            clearTimeout(timeOut);
        };
    }, []);

    /* const handleClickOpen = () => {
    setOpen(true);
  }; */

    const handleClose = () => {
        setOpen(false);
        props.resetIdleTimer();
        clearTimeout(timeOut);
    };


    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Are you still here?
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <strong>
                            Hi, we noticed that you have been idle for some
                            time. Do you wish to stay in the lobby or go back to
                            the Homepage?
                        </strong>
                        <br />
                        If you click "Stay" you will stay in the lobby. If you
                        don't do anything you will be redirected back to the
                        Homepage in 20 seconds.
                    </Typography>
                    <StyledButtons>
                        <StyledButton
                            className={"acceptNews"}
                            onClick={async () => {
                                handleClose();
                            }}
                            width="180px"
                            hollow={false}
                        >
                            Stay
                        </StyledButton>
                    </StyledButtons>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default withRouter(ActivityModal);
