export function detectBrowser() {
    //console.info('Detecting browser');
    if (
        (navigator.userAgent.indexOf("Opera") ||
            navigator.userAgent.indexOf("OPR")) !== -1
    ) {
        return "Opera";
    } else if (navigator.userAgent.indexOf("Edg") !== -1) {
        // alert("Please use Firefox or Chrome.");
        // window.location.href = "https://bayron.intranet.cnb";
        return "Edge";
    } else if (navigator.userAgent.indexOf("edg/") !== -1) {
        // alert("Please use Firefox or Chrome.");
        // window.location.href = "https://bayron.intranet.cnb";
        return "IE";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
        return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
        // alert("Please use Firefox or Chrome.");
        // window.location.href = "https://bayron.intranet.cnb";
        return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        return "Firefox";
    } else if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        !!document.documentMode === true
    ) {
        // This is only for IE > 10.
        alert("Please use Firefox or Chrome.");
        window.location.href = "https://bayron.intranet.cnb";
        return "IE";
    } else {
        return "unknown browser";
    }
}



// If there is at least one word longer than maxWordLength, it returns true, false otherwise.
export function checkWordLength(myString, maxWordLength) {
    let returnValue = false;
    // let wordLengthsArray = [];
    // let wordLengthsArray = [];
    let words = myString.split(" ");
    for (let i = 0; i < words.length; i++) {
        if (words[i].length > maxWordLength) {
            returnValue = true;
        }
    }
    return returnValue;
}



// pattern from:
// https://stackoverflow.com/a/3809435/7657853
export function validateWebsiteUrl(websiteUrl) {
    // website is not required
    if (websiteUrl === "") {
        return true;
    }
    if (/\s/.test(String(websiteUrl))) {
        return false;
    }
    const urlRegEx =
        /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
    return urlRegEx.test(String(websiteUrl).toLowerCase());
}

