import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import QuitButton from "../stage/quitButton";
// import { colors } from "../components/general/styles";
// import breakpoint from "styled-components-breakpoint";
/* import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar"; */
import "react-circular-progressbar/dist/styles.css";
// import { easeLinear } from "d3-ease";
// import AnimatedProgressProvider from "../components/general/AnimatedProgressProvider";
import { useEffect } from "reactn";
import NewsModal from "../general/newsModal";
import { CONF } from "../../config/config";

const ConfirmPresence = styled.div`
    min-height: 600px;

    .permissionHolder {
        margin: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;

        @media screen and (max-width: 1024px) {
            margin: 0px;
        }
    }

    &.permissionWrap {
        align-items: center;
        margin: 0 auto;
        position: relative;
        //background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        height: 100%;
        margin: 0 15px;
        border-radius: 8px;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
            max-width: 500px !important;
            margin: 0 auto;
            min-height: 500px;
        }

        @media screen and (max-width: 800px) {
            margin-bottom: 40px;
        }
    }

    .permissionWrap {
        width: 100%;
        background-color: #11394f;
        display: flex;
        justify-content: center;
        //min-height: 98px;
        align-items: center;

        &:last-of-type {
            min-height: 98px;
            @media screen and (max-width: 800px) {
                min-height: auto;
            }
        }
    }

    .permissionHeader {
        max-width: 800px;
        display: flex;
        background-color: #11394f;
        padding: 15px 15px;
        justify-content: space-between;
        align-items: center;
        min-width: 90px;
        flex-direction: column;

        @media screen and (max-width: 800px) {
            padding: 30px 20px;
        }

        .headerText {
            color: #ffffff;
            font-size: 20px;
            font-weight: bold;
            margin-top: 0;

            @media screen and (max-width: 800px) {
                font-size: 15px;
                line-height: 15px;
            }
        }

        .headerCountdown {
            color: #dc1448;
            font-size: 30px;
            font-weight: bold;
            min-width: 90px;
            margin-top: 0;
            margin-bottom: 0;
        }

        .backToLobbyButton {
            margin: 0;

            @media screen and (max-width: 800px) {
                margin-left: 10px;
            }
        }
    }

    .confirmWrap {
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: contain;
        background-repeat: no-repeat;
        background-attachment: initial;
        background-position: center center;
        margin: 30px 0;
        flex-direction: column;
        margin: 0;

        &.notApprovedPermissionWrap {
            background-image: url("${(props) => props.initialBackImage}");
            // background-image: url("/permission-page/test.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &.approvedPermissionWrap {
            background-image: url("${(props) => props.approvedBackImage}");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
`;
const ConfirmCameraButton = styled.button`
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    text-align: center;
    border-radius: 50%;
    background-color: #dc1448;
    font-stretch: normal;
    font-style: bold;
    color: #ffffff;
    padding: 12px;
    border: 1px solid #dc1448;

    &.hide {
        display: none;
    }

    :hover {
        background-color: #be1442;
        border-color: #be1442;
    }

    @media screen and (max-width: 800px) {
        width: 100px;
        height: 100px;
        font-size: 15px;
        line-height: 18px;
    }
    @media screen and (max-height: 600px) {
        width: 100px;
        height: 100px;
        font-size: 15px;
        line-height: 18px;
    }
`;

const PermissionsComponent = (props) => {
    const socket = props.socket;
    //STREAMING VARIABLES AND ELEMENTS
    const videoId = localStorage.getItem("currentCameraId")
        ? { deviceId: localStorage.getItem("currentCameraId") }
        : false;
    const audioId = localStorage.getItem("currentAudioId")
        ? { deviceId: localStorage.getItem("currentAudioId") }
        : false;
    let videoStreamEl = useRef(null);
    // TODO +++ Alen please check.
    // eslint-disable-next-line no-unused-vars
    const [stream, setStream] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [modalContent, setModalContent] = useState(``);
    const [countDown, setCountDown] = useState(20);
    const [isClicked, setIsClicked] = useState(false);
    let count = 20;

    //FLAGS FOR USER PERMISSION
    let localPermFlag = useRef(false);
    let connectionTimeout = null;
    let countDownTimer = null;
    let step = 0;
    let remotePermFlag = useRef(false);
    let leaveSignalFired = false;

    //NEEDED INFO FOR MATCH AND FURTHER COMMUNICATION
    const meetup = props.matchData ? props.matchData.meetup : null;
    const nextStepUrl = meetup ? meetup.nextStepUrl : null;
    const endDate = meetup ? meetup.endDate : null;
    const matchIdDB = props.matchData ? props.matchData.matchIdDB : null;
    const socketRoomId = props.matchData
        ? props.matchData.matchRoomSocketId
        : null;
    const partnerInfo = props.matchData ? props.matchData.partnerInfo : null;
    const myInfo = props.matchData ? props.matchData.selfProfile : null;

    let matcher = useRef(false);
    let matchee = useRef(false);

    //CSS RULES
    const cssRules = {
        // localBackgroundImage: "url('" + imageUrl + "/" + myInfo.backgroundImage + "')",
        localBackgroundImage: "",
        showCameraNotice: "flex",
        userApprovedCamera: "none",
        showWaitPartner: "none",
        showReadyButton: "flex"
    };

    const [headerText, setHeaderText] = useState(
        "We found a match for you! Click before 0:00 to match."
    );
    const [confirmButtonClass, setConfirmButtonClass] = useState(
        "confirm-match-button"
    );
    let backgroundImageClass = isClicked
        ? "confirmWrap approvedPermissionWrap"
        : "confirmWrap notApprovedPermissionWrap";

    const [initialBackImage, setInitialBackImage] = useState(false);
    const [approvedBackImage, setApprovedBackImage] = useState(false);

    //SOCKET SIGNALING
    useEffect(() => {
        if (!socket) {
            console.log("NO SOCKET FOUND | permissionPage");
            window.location.href = window._env_.REACT_APP_BAYRONNECT_URL;
        }

        //Random background image
        // const imageIndex = Math.floor(Math.random() * (1 + 1));
        const imageIndex = 0;
        let initialImages = importAll(
            require.context(
                "../../../public/permission-page/initial",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        let approvedImages = importAll(
            require.context(
                "../../../public/permission-page/approved",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        let initialImage;
        let approvedImage;

        if (
            CONF.REACT_APP_NODE_ENV !== "development" &&
            CONF.OPS_MODE !== "external"
        ) {
            initialImage =
                "/bayronnect/permission-page/initial/" +
                initialImages[imageIndex];
        } else {
            initialImage =
                "/permission-page/initial/" + initialImages[imageIndex];
        }

        if (
            CONF.REACT_APP_NODE_ENV !== "development" &&
            CONF.OPS_MODE !== "external"
        ) {
            approvedImage =
                "/bayronnect/permission-page/approved/" +
                approvedImages[imageIndex];
        } else {
            approvedImage =
                "/permission-page/approved/" + approvedImages[imageIndex];
        }

        setInitialBackImage(initialImage);
        setApprovedBackImage(approvedImage);

        countDownTimer = setInterval(countDownChange, 1000);
        connectionTimeout = setInterval(permissionCountdown, 500);
        if (socket) {
            console.log("Mounted permissionPage component | permissionPage");
            socket.on("checkRemotePermission", (msg) => {
                console.log(
                    "RECEIVED checkRemotePermission signal | permissionPage"
                );
                console.log("checkRemotePermission data: ", msg);
                if (!localPermFlag.current) {
                    if (msg.matchee) {
                        matcher.current = true;
                        matchee.current = false;
                    } else {
                        matcher.current = false;
                        matchee.current = true;
                    }
                }
                remotePermFlag.current = true;
                console.log(
                    "BEFORE ON SIGNAL PERMISSION PAGE: ",
                    matcher.current
                );
            });

            socket.on("userLeft", (msg) => {
                console.log("RECEIVED userLeft signal | permissionPage");
                clearInterval(connectionTimeout);
                // if(!localPermFlag.current) {
                setModalContent(
                    `Your matching partner left the room! <br/> We'll guide you back to the matching area.`
                );
                setOpenAlert(true);

                setTimeout(function () {
                    handleRejectQuit(false);
                }, 5000);
                // }
            });

            return function cleanup() {
                clearInterval(connectionTimeout);
                clearInterval(countDownTimer);
                console.log(
                    "Cleaning socket events and mediaStream | permissionPage"
                );
                socket
                    .removeListener("checkRemotePermission")
                    .on("checkRemotePermission", () => {});
                socket.removeListener("userLeft").on("userLeft", () => {});

                if (
                    videoStreamEl.current !== null &&
                    videoStreamEl.current.srcObject != null
                ) {
                    console.log("Cleaning media stream | permissionPage");
                    videoStreamEl.current.srcObject
                        .getTracks()
                        .forEach(function (track) {
                            track.stop();
                        });
                }
            };
        }
    }, []);

    function countDownChange() {
        if (count === 0) {
            setCountDown("00");
            clearInterval(countDownTimer);
        } else {
            if (count < 11) {
                setCountDown((countDown) => "0" + (countDown - 1));
            } else {
                setCountDown((countDown) => countDown - 1);
            }
        }
        count--;
    }

    //COUNTDOWN FUNCTION
    function permissionCountdown() {
        if (step === 20.5) {
            //check if local/remote permissions are set
            if (!localPermFlag.current || !remotePermFlag.current) {
                console.log(
                    "ENTERED quit permissionPage function | permissionPage"
                );
                console.log(
                    "Local permission granted: ",
                    localPermFlag.current
                );
                console.log(
                    "Remote permission granted: ",
                    remotePermFlag.current
                );
                if (!localPermFlag.current) {
                    setModalContent(
                        `You didn't respond to the meeting request! <br/> You will be redirect back to homepage`
                    );
                    setOpenAlert(true);
                    setTimeout(function () {
                        clearInterval(connectionTimeout);
                        handleRejectQuit(true);
                    }, 5000);
                } else {
                    setModalContent(
                        `Your partner didn't respond to the meeting request! <br/> You will be redirect back to lobby`
                    );
                    setOpenAlert(true);
                    setTimeout(function () {
                        clearInterval(connectionTimeout);
                        handleRejectQuit(false);
                    }, 5000);
                }
                // sendLeaveMessage(localPermFlag.current);
            }
            step = 0;
        }
        //if permissions are granted start match
        if (localPermFlag.current && remotePermFlag.current) {
            console.log("ENTERED passed permissions | permissionPage");
            clearInterval(connectionTimeout);
            // console.log('MatchIdDB: ', matchIdDB);
            const state = {
                socketRoomId: socketRoomId,
                partnerInfo: partnerInfo,
                myInfo: myInfo,
                meetup: meetup,
                matchIdDB: matchIdDB,
                backgroundImage: myInfo.backgroundImage,
                init: matcher.current
            };
            console.log("BEFORE PERMISSION PAGE: ", matcher.current);
            console.log("moving to matchPage | permissionPage");

            props.setCurrentPage("match");
            props.setMatchData(state);
        }
        step = step + 0.5;
    }

    function importAll(r) {
        let images = [];
        r.keys().map((item, index) => {
            images.push(item.replace("./", ""));
            return images;
        });
        return images;
    }

    // CHILD COMPONENT USAGE
    function sendLeaveMessage(backToLobby) {
        console.log("FIRED sendLeaveMessage function | permissionPage");
        if (!leaveSignalFired) {
            leaveSignalFired = true;
            console.log("SENDING signal quitBeforeChat | permissionPage");
            clearInterval(connectionTimeout);
            socket.emit("quitBeforeChat", {
                matchIdDB: matchIdDB,
                partnerSocketId: partnerInfo.socketId
            });
            handleRejectQuit(backToLobby);
        }
    }

    //IF USER LEAVES PAGE
    function handleRejectQuit(quitButtonPressed) {
        console.log("FIRED handleRejectQuit function | permissionPage");
        console.log("User pressed quitButton: ", quitButtonPressed);

        console.log("SENDING signal quitBeforeChat | permissionPage");
        clearInterval(connectionTimeout);
        socket.emit("quitBeforeChat", {
            matchIdDB: matchIdDB,
            partnerSocketId: partnerInfo.socketId
        });
        //if user has pressed quit button
        if (quitButtonPressed) {
            //empty stream element if user has already accepted permissions
            if (stream !== null) {
                if (
                    videoStreamEl.current &&
                    videoStreamEl.current.srcObject != null
                ) {
                    videoStreamEl.current.srcObject
                        .getTracks()
                        .forEach(function (track) {
                            track.stop();
                        });
                }
            }

            window.location.href = window._env_.REACT_APP_BAYRONNECT_URL;
        } else {
            if (stream !== null && videoStreamEl.current.srcObject != null) {
                videoStreamEl.current.srcObject
                    .getTracks()
                    .forEach(function (track) {
                        track.stop();
                    });
            }
            backToLobby();
        }
    }

    //PAGE NAVIGATION
    function backToLobby() {
        // if (window.location.href.substring(window.location.href.lastIndexOf('/') + 1) !== "lobby") {
        console.log("FIRED backToLobby function | permissionPage");
        let currentTime = new Date();
        currentTime = currentTime.setHours(currentTime.getHours() - 2);
        let meetupEndDate = new Date(new Date(endDate));

        if (currentTime > meetupEndDate.getTime()) {
            props.history.push({
                pathname: "/next",
                state: {
                    nextStepUrl: nextStepUrl
                }
            });
        } else {
            // sessionStorage.setItem("comingFrom", "connect");
            // const state = {
            //   meetup: meetup
            // }
            // props.history.push({
            //   pathname: meetupId + "/lobby",
            //   state: state
            // });
            props.setCurrentPage("lobby");
        }
        // }
    }

    //STREAMING PREVIEW AND SETUP
    async function streamConfirmation() {
        setIsClicked(true);
        setConfirmButtonClass("confirm-match-button hide");
        setHeaderText("Waiting for your partner.");
        console.log("FIRED streamConfirmation function | permissionPage");
        console.log("CURRENT audio ID: " + audioId + " | permissionPage");
        console.log("CURRENT video ID: " + videoId + " | permissionPage");

        localPermFlag.current = true;
        if (!remotePermFlag.current) {
            if (
                localStorage.getItem("currentVideoId") !== "false" &&
                localStorage.getItem("currentVideoId") !== false
            ) {
                matchee.current = false;
                matcher.current = true;
            } else {
                matchee.current = true;
                matcher.current = false;
            }
        }
        console.log("MATCHEE: ", matchee.current);
        console.log("SENDING signal sendLocalPermission | permissionPage");
        socket.emit("sendLocalPermisison", {
            partnerSocketId: partnerInfo.socketId,
            matchee: matchee.current,
            userEmail:
                CONF.OPS_MODE === "internal"
                    ? localStorage.getItem("username") + "@bayer.com"
                    : localStorage.getItem("username"),
            partnerEmail: partnerInfo.email
        });
    }

    return (
        <ConfirmPresence
            className="permissionWrap"
            initialBackImage={initialBackImage}
            approvedBackImage={approvedBackImage}
        >
            {openAlert && <NewsModal alert content={modalContent} />}
            <div className="permissionHolder">
                <div className="permissionWrap">
                    <div className="permissionHeader">
                        <p className="headerText">{headerText}</p>
                        <p className="headerCountdown">00:{countDown}</p>
                    </div>
                </div>

                <div className={backgroundImageClass}>
                    <ConfirmCameraButton
                        onClick={streamConfirmation}
                        className={confirmButtonClass}
                    >
                        Click me!
                        <br />
                        I Am <br />
                        Ready
                    </ConfirmCameraButton>
                </div>

                <div className="permissionWrap">
                    <div className="permissionHeader">
                        <QuitButton
                            permissionPage
                            pressedButton
                            sendLeaveMessage={sendLeaveMessage}
                            width="100px"
                            title="Cancel"
                            tooltipText="Cancel this conversation. Match the next person."
                        />
                    </div>
                </div>
            </div>
        </ConfirmPresence>
    );
};

export default withRouter(PermissionsComponent);
