import React, { useState } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const StyledButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
        margin: 20px 20px 5px;
    }
`;

/* const ModalText = styled.span`
  text-align: center;
  width: 100%;
  display: block;
`; */

const StyledButton = styled.button`
    border-radius: 23px;
    width: 224px;
    background-color: ${(props) => (props.hollow ? "#ffffff" : "#dc1448")};
    border-color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    padding: 12px 0;
    display: flex;
    justify-content: center;
    border: none;

    :hover {
        background-color: ${(props) => (props.hollow ? "#e9e9e9" : "#BE1442")};
    }
`;

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const Alert = ({ visible, heading, message, button, onclick }) => {
    const [alertVisible, setAlertVisible] = useState(visible);
    const handleClick = () => {
        setAlertVisible(false);
        onclick();
    };

    useEffect(() => {
        setAlertVisible(visible);
    }, [visible]);

    return (
        <div>
            <Dialog
                onClose={handleClick}
                aria-labelledby="customized-dialog-title"
                open={alertVisible}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClick}>
                    {heading}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>{message}</Typography>
                    <StyledButtons>
                        <StyledButton
                            className={"acceptNews"}
                            onClick={handleClick}
                            width="180px"
                            hollow={false}
                        >
                            {button}
                        </StyledButton>
                    </StyledButtons>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Alert;
