import IconButton from "@material-ui/core/IconButton";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import VideocamOffOutlinedIcon from "@material-ui/icons/VideocamOffOutlined";
import MicNoneOutlinedIcon from "@material-ui/icons/MicNoneOutlined";
import MicOffOutlinedIcon from "@material-ui/icons/MicOffOutlined";
import { colors } from "./styles";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useState } from "reactn";
import SettingsIcon from "@material-ui/icons/Settings";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import ScreenShareTwoToneIcon from "@material-ui/icons/ScreenShareTwoTone";
import TooltipMui from "@material-ui/core/Tooltip";

import cameraOnIcon from "../../assets/icons/video/cameraOn.png";
import cameraOffIcon from "../../assets/icons/video/cameraOff.png";
import micOnIcon from "../../assets/icons/video/micOn.png";
import micOffIcon from "../../assets/icons/video/micOff.png";
import screenOnIcon from "../../assets/icons/video/screenOn.png";
import settingsIcon from "../../assets/icons/video/settings.png";

const InputContainer = styled.div`
    position: absolute;
    right: 10px;
    bottom: 15px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;

    .button {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        margin: 0 5px;
        opacity: 0.8;
        cursor: pointer;

        transition: all 0.1s linear;

        &:hover {
            opacity: 1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
`;

export const InputControls = (props) => {
    let micLevel = useRef(0);
    const [isVideo, setIsVideo] = useState(true);
    const [isMicrophone, setIsMicrophone] = useState(true);
    const currentStream = props.currentStream;
    let volumeInterval;

    useEffect(() => {
        clearInterval(volumeInterval);
        if (currentStream.getAudioTracks().length > 0) {
            startMicrophone();
        }

        return function cleanup() {
            clearInterval(volumeInterval);
        };
    }, [currentStream]);

    //https://stackoverflow.com/questions/33322681/checking-microphone-volume-in-javascript/50279260
    async function startMicrophone() {
        // TODO +++ Alen please check why are we not using the constraints here?
        // constraints is assigned a value but never used.
        // eslint-disable-next-line no-unused-vars
        const constraints = {
            audio: {
                deviceId: localStorage.getItem("currentAudioInputId")
            }
            // ,
            // video: {
            //   deviceId: localStorage.getItem("currentVideoId")
            // }
        };

        let audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
        let analyser = audioContext.createAnalyser();
        let microphone = audioContext.createMediaStreamSource(
            props.currentStream
        );

        analyser.fftSize = 1024;
        analyser.smoothingTimeConstant = 0.8;
        microphone.connect(analyser);

        const volumes = new Uint8Array(analyser.frequencyBinCount);
        const volumeCallback = () => {
            analyser.getByteFrequencyData(volumes);
            let volumeSum = 0;
            for (const volume of volumes) volumeSum += volume;
            const averageVolume = volumeSum / volumes.length;
            // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
            if (micLevel.current) {
                micLevel.current.style.backgroundImage =
                    "linear-gradient(to top, rgba(255,255,255,1) " +
                    averageVolume +
                    "%, " +
                    "rgba(255,255,255,0) 0%)";
            }
        };
        volumeInterval = setInterval(volumeCallback, 100);
        // },
        // function(err) {
        //   console.log("The following error occured: " + err.name);
        // }
        // );
    }
    function toggleVideo() {
        if (currentStream && currentStream) {
            currentStream.getTracks().forEach(function (track) {
                if (track.kind === "video") {
                    track.enabled = !track.enabled;
                    setIsVideo(track.enabled);
                }
            });
        }
    }
    function toggleMicrophone() {
        if (currentStream && currentStream) {
            currentStream.getTracks().forEach(function (track) {
                if (track.kind === "audio") {
                    track.enabled = !track.enabled;
                    setIsMicrophone(track.enabled);
                }
            });
        }
    }

    return (
        <InputContainer
            className={"input-controls"}
            isVideo={isVideo}
            isMicrophone={isMicrophone}
        >
            {!props.checkMedia && !props.sharingScreen && (
                <>
                    <TooltipMui title="Settings" placement="left-start" arrow>
                        <div
                            className="button"
                            onClick={() => props.setShowUserMedia(true)}
                        >
                            <img src={settingsIcon} alt="settings" />
                        </div>
                    </TooltipMui>
                    <TooltipMui
                        title="Toggle camera"
                        placement="left-start"
                        arrow
                    >
                        <div className="button" onClick={() => toggleVideo()}>
                            <img
                                src={isVideo ? cameraOnIcon : cameraOffIcon}
                                alt="toggle camera"
                            />
                        </div>
                    </TooltipMui>
                </>
            )}
            {props.activateScreenRecording && (
                <TooltipMui title="Share screen" placement="left-start" arrow>
                    <div
                        className="button"
                        onClick={() => props.activateScreenRecording()}
                    >
                        <img src={screenOnIcon} alt="share screen" />
                    </div>
                </TooltipMui>
            )}
            <TooltipMui title="Mute / unmute" placement="left-start" arrow>
                <div
                    className="button"
                    ref={micLevel}
                    onClick={() => toggleMicrophone()}
                >
                    <img
                        src={isMicrophone ? micOnIcon : micOffIcon}
                        alt="toggle microphone"
                    />
                </div>
            </TooltipMui>
        </InputContainer>
    );
};
