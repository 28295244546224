import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
// import NextButton from "../general/nextButton";
import Footer from "../general/footer";
import Tooltip from "@material-ui/core/Tooltip";
import breakpoint from "styled-components-breakpoint";
import { createEvent } from "../meetups/calendarInvite";
import NextButton from "../general/nextButton";
import { escapeHtml } from "../meetups/meetup";
import LinkIcon from "@material-ui/icons/Link";
import { CONF } from "../../config/config";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #fffdfb;
    width: 100%;
    flex-direction: column;
    padding: 0 30px;

    .capitalize {
        text-transform: capitalize;
    }

    a {
        cursor: pointer;
    }

    .columns {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }

    .column {
        flex: 50%;
    }

    h1 {
        font-size: 45px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        text-align: left !important;
        color: #fffdfb;
        margin-top: 0;
    }

    h3,
    .property-title {
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        margin: 0;
        margin-bottom: 10px;
    }

    p,
    a {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        margin: 0;
        margin-bottom: 30px;
    }

    .content-wrap {
        max-width: 1100px;
        width: 100%;
        display: flex;
        padding-left: 30px;
        padding-right: 30px;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;

        @media only screen and (max-width: 1024px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        &:first-of-type {
            border-bottom: 3px solid #d30f4b;
            padding-bottom: 30px;
            margin-bottom: 30px;
            padding-top: 60px;

            button {
                align-self: center;
            }

            p {
                margin-bottom: 0;
            }

            @media only screen and (max-width: 774px) {
                flex-direction: column;
                align-items: center;

                button {
                    margin-top: 30px;
                }
            }
        }

        &:nth-of-type(2) {
            @media only screen and (max-width: 774px) {
                flex-direction: column-reverse;
            }
        }

        .image-wrap {
            width: 100%;
            max-width: 50%;
            margin-left: 15px;

            ${breakpoint("mobile")`
        width: 100%;
        margin-bottom: 30px;
      `}
            ${breakpoint("tablet")`
        width: 60%;
        margin-bottom: 30px;
      `}
      ${breakpoint("desktop")`
        width: 100%;
        max-width: 50%;
        margin-bottom: 0;
      `}
      img {
                width: 100%;
            }
        }

        .info-wrap {
            width: 100%;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media only screen and (max-width: 774px) {
                max-width: 100% !important;
            }

            ${breakpoint("mobile")`
        width: 100%;
        margin-left: 0;
      `}

            ${breakpoint("tablet")`
        width: 100%;
        margin-left: 0;
      `}

      ${breakpoint("desktop")`
        width: 100%;
        max-width: 50%;
        margin-left: 15px;
      `}
        }
    }

    .back-button {
        margin-top: 50px;
        cursor: pointer;
        margin-bottom: 50px;

        button {
            display: flex;
            justify-content: center;
        }
    }

    .event-description {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    a {
        color: #fffdfb;
        font-weight: bold !important;
    }

    .text-wrap {
        white-space: pre-line;
    }

    .ml-0 {
        margin-left: 0 !important;
    }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .pt-15 {
        padding-top: 15px;
    }

    .pb-osm {
        padding-bottom: 15px;
    }

    @media only screen and (max-width: 1281px) {
        .desc-with-photo {
            max-width: 50% !important;
        }
    }

    @media only screen and (max-width: 774px) {
        .image-wrap {
            margin-left: 0 !important;
            max-width: 100% !important;
            img {
                max-height: 400px;
                object-fit: contain;
            }
        }

        .center-osm {
            text-align: center !important;
            align-items: center !important;
        }

        h1 {
            font-size: 35px !important;
        }

        h3,
        .property-title {
            font-size: 20px !important;
        }

        p,
        a {
            font-size: 15px !important;
        }

        .image-wrap,
        .info-wrap {
            //max-width: 80% !important;
        }

        .desc-with-photo {
            max-width: 80% !important;
        }
    }

    .event__link-holder {
        margin-left: 15px;
        display: inline-flex;
        align-items: flex-start;

        svg {
            margin-right: 5px;
        }

        @media only screen and (max-width: 774px) {
            justify-content: center;
            margin-left: 0;
        }
    }
    svg {
        cursor: pointer;
    }

    .event__date {
        display: flex;
        @media only screen and (max-width: 1024px) {
            flex-direction: column;
            .event__link-holder {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }

    .event__description {
        max-width: 1100px;
        padding: 0 30px 0px 45px;
        box-sizing: border-box;
        width: 100%;
        white-space: pre-wrap;
        word-wrap: break-word;

        @media only screen and (max-width: 1024px) {
            padding: 0 15px 0px 15px;
        }
    }
    .event__link {
        font-weight: normal !important;
        text-decoration: underline;
    }

    .event__reg-link-holder {
        margin-bottom: 30px;
        a {
            font-weight: normal !important;
            text-decoration: underline;
            margin-left: 5px;
        }
    }

    .event__reg-link {
        display: inline-block;

        svg {
            vertical-align: middle;
        }
    }

    .event__header {
        max-width: none !important;
        display: block !important;
        margin-right: 40px;
    }
`;

const Lobby = (props) => {
    const meetup = props.meetup;
    const path = CONF.OPS_MODE === "internal" ? "/bayronnect" : "";
    const photo = meetup.photo ? meetup.photo : null;

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(meetup.website);

    };

    const copyToClipBoardRegLink = () => {
        navigator.clipboard.writeText(meetup.registrationLink);

    };

    var fullAddress = "";
    if (meetup.eventType !== "online") {
        if (meetup.address) {
            fullAddress += meetup.address;
        }
        // if (meetup.city && meetup.city !== 'null') {
        //   fullAddress += meetup.address ? ', ' + meetup.city : meetup.city;
        // }
        // if (meetup.country && meetup.country !== 'null') {
        //   fullAddress += meetup.city ? ', ' + meetup.country : meetup.country;
        // }
    } else {
        fullAddress = "Online";
    }

    const createEventStringOutlookDate = () => {
        const newEvent = createEvent({
            id: meetup.id,
            name: meetup.name,
            startDate: meetup.startDate,
            endDate: meetup.endDate,
            type: meetup.eventType,
            website: meetup.website,
            address: meetup.address,
            // city: meetup.city,
            // country: meetup.country,
            description: meetup.description,
            meetupAll: meetup
        });
        const textArray = newEvent.split("\n").filter((f) => f.trim() !== "");
        const event = textArray.join("\n");
        return event;
    };

    const startDate = new Date(meetup.startDate);
    var startDateFormat = startDate.toLocaleString("de-AT", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: false
    });

    let startDateFirst = startDateFormat.split(",")[0];
    let startDateLast = startDateFormat.split(",")[1];

    const endDate = new Date(meetup.endDate);
    var endDateFormat = endDate.toLocaleString("de-AT", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: false
    });

    let endDateFirst = endDateFormat.split(",")[0];
    let endDateLast = endDateFormat.split(",")[1];

    function imageExist(url) {
        var img = new Image();
        img.src = url;
        return img.height != 0;
    }

    return (
        <React.Fragment>
            <Wrapper>
                {/*<div class="columns">
           <div class="column">
             test
            </div>
        <div class="column">
             test2
        </div>
        </div>*/}

                <div className="content-wrap">
                    <div className="info-wrap ml-0 center-osm event__header">
                        <h1 className="">
                            {!meetup.name ? "Event" : meetup.name}
                        </h1>
                        <p className="event__date">
                            {startDateFirst === endDateFirst ? (
                                <span>
                                    {startDateFormat} - {endDateLast}
                                    {fullAddress && ","} {fullAddress}
                                </span>
                            ) : (
                                <span>
                                    {startDateFormat} - {endDateFormat}
                                    {fullAddress && ","} {fullAddress}
                                </span>
                            )}
                        </p>
                    </div>

                    <NextButton
                        title="Download Outlook Invite"
                        tooltipText="Download Outlook Invite."
                        onClickEvent={() => {
                            const event = createEventStringOutlookDate();
                            window.open(
                                `data:text/calendar;charset=utf8,${escapeHtml(
                                    event
                                )}`
                            );
                        }}
                    />
                </div>

                <div className="content-wrap">
                    <div className="info-wrap">
                        {meetup.keywords ? (
                            <div className="keywords-wrap">
                                <p>
                                    <span className="property-title">
                                        Keywords:
                                    </span>{" "}
                                    {Array.isArray(meetup.keywords)
                                        ? meetup.keywords.join(", ")
                                        : meetup.keywords.replace(/,/g, ", ")}
                                </p>
                            </div>
                        ) : (
                            ""
                        )}

                        <div
                            className="description-wrap"
                            style={{
                                display: !meetup.language ? "none" : "block"
                            }}
                        >
                            <p>
                                <span className="property-title">
                                    Language:
                                </span>{" "}
                                {meetup.language ? meetup.language : "N/A"}
                            </p>
                        </div>

                        {meetup.website ? (
                            <div className="description-wrap">
                                <div className="event__reg-link-holder">
                                    <span className="property-title">Web:</span>{" "}
                                    <div className="event__reg-link">
                                        <Tooltip
                                            title={<div>Copy link</div>}
                                            arrow
                                        >
                                            <LinkIcon
                                                onClick={copyToClipBoard}
                                                className="linkIcon"
                                            />
                                        </Tooltip>
                                        <a
                                            target="_blank"
                                            href={meetup.website}
                                        >
                                            Link
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="description-wrap">
                            <p>
                                <span className="property-title">
                                    Registration required:
                                </span>{" "}
                                {meetup.registration ? "Yes" : "No"}
                                {meetup.registration &&
                                meetup.registrationLink ? (
                                    <div className="event__reg-link">
                                        ,{" "}
                                        <Tooltip
                                            title={
                                                <div>
                                                    Copy Registration link
                                                </div>
                                            }
                                            arrow
                                        >
                                            <LinkIcon
                                                onClick={copyToClipBoardRegLink}
                                                className="linkIcon"
                                            />
                                        </Tooltip>
                                        <a
                                            target="_blank"
                                            href={meetup.registrationLink}
                                        >
                                            Link
                                        </a>
                                    </div>
                                ) : null}
                            </p>
                        </div>

                        <div className="description-wrap">
                            <p>
                                <span className="property-title">Cost:</span>{" "}
                                {!meetup.cost || meetup.cost === "0"
                                    ? "Free"
                                    : meetup.cost}
                            </p>
                        </div>
                        <div className="description-wrap">
                            <p>
                                <span className="property-title capitalize">
                                    {!meetup.internality
                                        ? "N/A"
                                        : meetup.internality}
                                </span>
                                <span className="property-title "> event</span>
                            </p>
                        </div>
                    </div>
                    {photo && (
                        <div
                            className="image-wrap"
                            // style={{display: photo && imageExist(photo) ? "" : "none"}}
                        >
                            <img src={photo} alt="Back" />
                        </div>
                    )}
                </div>

                {meetup.description && (
                    <div className="event__description">
                        <p>{meetup.description}</p>
                    </div>
                )}

                <div className="back-button">
                    <Tooltip title={"Go back to homepage"} arrow>
                        <div
                            onClick={() => {
                                props.history.push("/");
                            }}
                        >
                            <img src={path + "/back.png"} alt="Back" />
                        </div>
                    </Tooltip>
                </div>
            </Wrapper>
            <div style={{ width: "100%" }}>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default withRouter(Lobby);
