import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const DropdownItem = (props) => {
    const [value, setValue] = useState(props.duration);

    return (
        <Select
            id="duration"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                props.setDuration(e.target.value);
            }}
            className="chatDurationSelect"
            inputProps={{
                name: "chatDuration"
            }}
        >
            {/* <MenuItem className="initialValue" hidden>{value === "1" ? `${value} minute` : `${value} minutes`}</MenuItem> */}
            <MenuItem value={1}>1 minute</MenuItem>
            <MenuItem value={2}>2 minutes</MenuItem>
            <MenuItem value={3}>3 minutes</MenuItem>
            <MenuItem value={4}>4 minutes</MenuItem>
            <MenuItem value={5}>5 minutes</MenuItem>
            <MenuItem value={7}>7 minutes</MenuItem>
            <MenuItem value={10}>10 minutes</MenuItem>
            <MenuItem value={15}>15 minutes</MenuItem>
            <MenuItem value={30}>30 minutes</MenuItem>
            <MenuItem value={60}>60 minutes</MenuItem>
        </Select>
    );
};
