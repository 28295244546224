import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../components/general/styles";
import NextButton from "../components/general/nextButton";

// Sample page for teasing bayronnect, was just important for a bachelor thesis.

const Wrapper = styled.div`
    width: 100%;
    //box-shadow: 0px 7px 23px 0px rgba(0, 0, 0, 0.15);
    max-height: 999px;
    transition: max-height 500ms ease-in-out;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    //position: absolute;
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);
    max-width: 600px;
    justify-self: center;
    align-self: center;
    margin: 30px auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    font-size: 1.5rem;
    padding: 50px;

    h1 {
        font-size: 1.5rem;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 3px solid ${colors.red};
    }
    p {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${colors.darkGray};
    }
    button {
        cursor: pointer;
    }
    .btnHolder {
        margin-top: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-of-type {
            margin-top: 20px;
        }
    }
`;

const ComingSoonPage = (props) => {
    // let { meetupName } = useParams();
    // const socket = props.socket;
    // const bayronUrl = window._env_.REACT_APP_BAYRON_URL
    //   ? window._env_.REACT_APP_BAYRON_URL
    //   : "https://127.0.0.1";
    // const baseUrl = `${bayronUrl}/bayronnect`; // window._env_.REACT_APP_BAYRON_URL ? `${window._env_.REACT_APP_BAYRON_URL}/bayronnect` : "https://127.0.0.1/bayronnect"

    // const languages = [
    //   "english",
    //   "german",
    //   "norwegian",
    //   "mandarin",
    //   "japanese",
    //   "spanish"
    // ];

    /*useEffect(() => {
      if(socket) {
        let languageFlag = false;
        let userLanguages = {};
        languages.forEach((item) => {
          if(localStorage.getItem(item)) {
            languageFlag = true;
            userLanguages[item] = true;
          }else {
         languageFlag = true;
            userLanguages[item] = true;
          }
        });
        if(languageFlag) {
          sessionStorage.setItem('comingFrom', 'preferences');
          console.log('login from meetup');
          props.login(meetupName, { english: true, german: true });
        }else {
          props.history.push({
            pathname: "/preferences"
          });
        }
      }
    }, [socket]);*/

    return (
        <Wrapper style={{ minHeight: "100%", minWidth: "100%" }}>
            <Container>
                <h1>Sorry this meetup does not yet exist</h1>

                <div className={"btnHolder"}>
                    <p>You can create your own meetup on ByronMeet!</p>
                    <NextButton
                        className="botton"
                        width="200px"
                        title="Go to ByronMeet"
                        tooltipText="Go to the Home page."
                        onClickEvent={() => (window.location.href = "/")}
                    />
                </div>
            </Container>
        </Wrapper>
    );
};

export default withRouter(ComingSoonPage);
