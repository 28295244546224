/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import NextButton from "../general/nextButton";
import Languages from "./languages";
import {
    sendLanguageToDB,
    sendUserPreferencesToDB
} from "../../hooks/socketOperations";
import CloseIcon from "@material-ui/icons/Close";
import TuneIcon from "@material-ui/icons/Tune";
import CityPreference from "./cityPreference";
import CommunitiesPreferences from "./communitiesPreferences";
import OrganisationsPreferences from "./organisationsPreferences";
import ProductsPreferences from "./productsPreferences";
import { useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import DepartmentsPreferences from "./departmentsPreferences";
import DivisionsPreferences from "./divisionsPreferences";
import SkillsPreferences from "./skillsPreferences";
import PeoplePreferences from "./peoplePreferences";
import { CONF } from "../../config/config";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 500px;

    .preferencescontainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .tabSelectorWrap {
        width: 100%;
        margin-top: 20px;

        @media only screen and (orientation: portrait) {
        }
    }
    .MuiTabs-flexContainer {
        display: flex;
        flex-wrap: wrap;
    }
    .tabSelector {
        flex: 1 0 21%; /* explanation below */
        background-color: rgb(244, 242, 242);
        margin: 5px 2px 0;
        border-radius: 5px;
        &.isFilter {
            border: 2px solid #dc1448;
        }
        @media only screen and (orientation: portrait), (max-width: 600px) {
            min-width: 150px;
        }
    }
    .MuiTab-wrapper {
        text-transform: none;
    }
    .prefTab {
        width: 100%;
    }
    .MuiTabs-indicator {
        display: none;
    }
    .Mui-selected {
        color: #11394f !important;
    }

    .tooltip {
        position: relative;
        bottom: 10px;
        margin-left: 5px;
        padding-left: 0.25rem;
    }
    .icon {
        font-size: 1rem;
        display: inline;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
    }

    .next-button {
        text-align: center;
    }

    @media only screen and (orientation: portrait), (max-width: 600px) {
        min-width: 200px;
    }
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && { children }}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

const PrefContainer = (props) => {
    const [userLanguages, setLanguages] = useState(props.userLanguages);

    const [allCities, setAllCities] = useState([]);
    const [userCityFilters, setUserCityFilters] = useState({
        meet_city: [],
        nomeet_city: []
    });
    const [userCityHardFilters, setUserCityHardFilters] = useState({
        meet_city: false,
        nomeet_city: false
    });

    const [userCommunitiesFilters, setUserCommunitiesFilters] = useState({
        meet_communities: [],
        nomeet_communities: []
    });
    const [userCommunitiesHardFilters, setUserCommunitiesHardFilters] =
        useState({
            meet_communities: false,
            nomeet_communities: false
        });

    const [userOrganisationsFilters, setUserOrganisationsFilters] = useState({
        meet_organisations: [],
        nomeet_organisations: []
    });
    const [userOrganisationsHardFilters, setUserOrganisationsHardFilters] =
        useState({ meet_organisations: false, nomeet_organisations: false });

    const [userProductsFilters, setUserProductsFilters] = useState({
        meet_products: [],
        nomeet_products: []
    });
    const [userProductsHardFilters, setUserProductsHardFilters] = useState({
        meet_products: false,
        nomeet_products: false
    });

    const [userDepartmentsFilters, setUserDepartmentsFilters] = useState({
        meet_departments: [],
        nomeet_departments: []
    });
    const [userDepartmentsHardFilters, setUserDepartmentsHardFilters] =
        useState({
            meet_departments: false,
            nomeet_departments: false
        });

    const [userDivisionsFilters, setUserDivisionsFilters] = useState({
        meet_divisions: [],
        nomeet_divisions: []
    });
    const [userDivisionsHardFilters, setUserDivisionsHardFilters] = useState({
        meet_divisions: false,
        nomeet_divisions: false
    });

    const [userSkillsFilters, setUserSkillsFilters] = useState({
        meet_skills: [],
        nomeet_skills: []
    });
    const [userSkillsHardFilters, setUserSkillsHardFilters] = useState({
        meet_skills: false,
        nomeet_skills: false
    });

    const [userPeopleFilters, setUserPeopleFilters] = useState({
        meet_people: [],
        nomeet_people: []
    });
    const [userPeopleHardFilters, setUserPeopleHardFilters] = useState({
        meet_people: false,
        nomeet_people: false
    });

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        async function fetchData() {
            await fetch(`${CONF.BAYRONNECT_API_URL}/api/getUserPreferences`, {
                method: "POST",
                body: JSON.stringify({
                    jwtToken: localStorage.getItem("jwtToken")
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("RESPONSE DATA", data);
                    if (
                        data.allCities &&
                        Object.keys(data.allCities).length > 0
                    ) {
                        setAllCities(data.allCities);
                    }

                    if (data.filters) {
                        if (
                            data.filters.city &&
                            Object.keys(data.filters.city).length > 0
                        )
                            setUserCityFilters(data.filters.city);
                        if (
                            data.filters.communities &&
                            Object.keys(data.filters.communities).length > 0
                        )
                            setUserCommunitiesFilters(data.filters.communities);
                        if (
                            data.filters.organisations &&
                            Object.keys(data.filters.organisations).length > 0
                        )
                            setUserOrganisationsFilters(
                                data.filters.organisations
                            );
                        if (
                            data.filters.products &&
                            Object.keys(data.filters.products).length > 0
                        )
                            setUserProductsFilters(data.filters.products);
                        if (
                            data.filters.departments &&
                            Object.keys(data.filters.departments).length > 0
                        )
                            setUserDepartmentsFilters(data.filters.departments);
                        if (
                            data.filters.divisions &&
                            Object.keys(data.filters.divisions).length > 0
                        )
                            setUserDivisionsFilters(data.filters.divisions);
                        if (
                            data.filters.skills &&
                            Object.keys(data.filters.skills).length > 0
                        )
                            setUserSkillsFilters(data.filters.skills);
                        if (
                            data.filters.people &&
                            Object.keys(data.filters.people).length > 0
                        )
                            setUserPeopleFilters(data.filters.people);
                    }

                    if (data.hardFilters) {
                        if (
                            data.hardFilters.city &&
                            Object.keys(data.hardFilters.city).length > 0
                        )
                            setUserCityHardFilters(data.hardFilters.city);
                        if (
                            data.hardFilters.communities &&
                            Object.keys(data.hardFilters.communities).length > 0
                        )
                            setUserCommunitiesHardFilters(
                                data.hardFilters.communities
                            );
                        if (
                            data.hardFilters.organisations &&
                            Object.keys(data.hardFilters.organisations).length >
                                0
                        )
                            setUserOrganisationsHardFilters(
                                data.hardFilters.organisations
                            );
                        if (
                            data.hardFilters.products &&
                            Object.keys(data.hardFilters.products).length > 0
                        )
                            setUserProductsHardFilters(
                                data.hardFilters.products
                            );
                        if (
                            data.hardFilters.departments &&
                            Object.keys(data.hardFilters.departments).length > 0
                        )
                            setUserDepartmentsHardFilters(
                                data.hardFilters.departments
                            );
                        if (
                            data.hardFilters.divisions &&
                            Object.keys(data.hardFilters.divisions).length > 0
                        )
                            setUserDivisionsHardFilters(
                                data.hardFilters.divisions
                            );
                        if (
                            data.hardFilters.skills &&
                            Object.keys(data.hardFilters.skills).length > 0
                        )
                            setUserSkillsHardFilters(data.hardFilters.skills);
                        if (
                            data.hardFilters.people &&
                            Object.keys(data.hardFilters.people).length > 0
                        )
                            setUserPeopleHardFilters(data.hardFilters.people);
                    }
                });
        }
        fetchData();
    }, []);

    const sendLanguage = async (languages) => {
        return await sendLanguageToDB(languages);
    };

    const saveUserPreferences = async () => {
        let userPreferences = {};
        userPreferences.filters = {
            ...userCityFilters,
            ...userCommunitiesFilters,
            ...userOrganisationsFilters,
            ...userProductsFilters,
            ...userDepartmentsFilters,
            ...userDivisionsFilters,
            ...userSkillsFilters,
            ...userPeopleFilters
        };
        userPreferences.hardFilters = {
            ...userCityHardFilters,
            ...userCommunitiesHardFilters,
            ...userOrganisationsHardFilters,
            ...userProductsHardFilters,
            ...userDepartmentsHardFilters,
            ...userDivisionsHardFilters,
            ...userSkillsHardFilters,
            ...userPeopleHardFilters
        };
        console.log("USER Pref on save", userPreferences);
        await sendUserPreferencesToDB(userPreferences);
    };

    return (
        <div>
            <div className="newMeetupHeader">
                <div className="modalHeaderTitle">
                    <TuneIcon className="preferencesIcon" />
                    <span>General preferences</span>
                </div>
                <div
                    className="exitModal"
                    onClick={() => {
                        props.closeModal(false);
                    }}
                >
                    <CloseIcon className="closeModalIcon" />
                </div>
            </div>
            <Container>
                <div className="tabSelectorWrap">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        scrollButtons="auto"
                        aria-label="full width tabs"
                    >
                        {CONF.OPS_MODE === "internal"
                            ? [
                                  <Tab
                                      key="languages"
                                      className="tabSelector"
                                      label="Languages"
                                      {...a11yProps(0)}
                                  />,
                                  <Tab
                                      key="cities"
                                      className={
                                          "tabSelector " +
                                          (userCityHardFilters.meet_city ||
                                          userCityHardFilters.nomeet_city
                                              ? "isFilter"
                                              : "")
                                      }
                                      label="Cities"
                                      {...a11yProps(1)}
                                  />,
                                  <Tab
                                      key="communities"
                                      className={
                                          "tabSelector " +
                                          (userCommunitiesHardFilters.meet_communities ||
                                          userCommunitiesHardFilters.nomeet_communities
                                              ? "isFilter"
                                              : "")
                                      }
                                      label="Communities"
                                      {...a11yProps(2)}
                                  />,
                                  <Tab
                                      key="organisations"
                                      className={
                                          "tabSelector " +
                                          (userOrganisationsHardFilters.meet_organisations ||
                                          userOrganisationsHardFilters.nomeet_organisations
                                              ? "isFilter"
                                              : "")
                                      }
                                      label="Organisations"
                                      {...a11yProps(3)}
                                  />,
                                  <Tab
                                      key="products"
                                      className={
                                          "tabSelector " +
                                          (userProductsHardFilters.meet_products ||
                                          userProductsHardFilters.nomeet_products
                                              ? "isFilter"
                                              : "")
                                      }
                                      label="Products"
                                      {...a11yProps(4)}
                                  />,
                                  <Tab
                                      key="departments"
                                      className={
                                          "tabSelector " +
                                          (userDepartmentsHardFilters.meet_departments ||
                                          userDepartmentsHardFilters.nomeet_departments
                                              ? "isFilter"
                                              : "")
                                      }
                                      label="Departments"
                                      {...a11yProps(5)}
                                  />,
                                  <Tab
                                      key="divisions"
                                      className={
                                          "tabSelector " +
                                          (userDivisionsHardFilters.meet_divisions ||
                                          userDivisionsHardFilters.nomeet_divisions
                                              ? "isFilter"
                                              : "")
                                      }
                                      label="Divisions"
                                      {...a11yProps(6)}
                                  />,
                                  <Tab
                                      key="skills"
                                      className={
                                          "tabSelector " +
                                          (userSkillsHardFilters.meet_skills ||
                                          userSkillsHardFilters.nomeet_skills
                                              ? "isFilter"
                                              : "")
                                      }
                                      label="Skills"
                                      {...a11yProps(7)}
                                  />,
                                  <Tab
                                      key="people"
                                      className={
                                          "tabSelector " +
                                          (userPeopleHardFilters.meet_people ||
                                          userPeopleHardFilters.nomeet_people
                                              ? "isFilter"
                                              : "")
                                      }
                                      label="People"
                                      {...a11yProps(8)}
                                  />
                              ]
                            : [
                                  <Tab
                                      key="languages"
                                      className="tabSelector"
                                      label="Languages"
                                      {...a11yProps(0)}
                                  />
                                  //   <Tab
                                  //       key="cities"
                                  //       className={
                                  //           "tabSelector " +
                                  //           (userCityHardFilters.meet_city ||
                                  //           userCityHardFilters.nomeet_city
                                  //               ? "isFilter"
                                  //               : "")
                                  //       }
                                  //       label="Cities"
                                  //       {...a11yProps(1)}
                                  //   />
                              ]}
                    </Tabs>
                </div>

                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 0}
                    id={`scrollable-auto-tabpanel-0`}
                    aria-labelledby={`scrollable-auto-tab-0`}
                >
                    <Languages
                        userLanguages={userLanguages}
                        setLanguages={setLanguages}
                    />
                </div>
                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 1}
                    id={`scrollable-auto-tabpanel-1`}
                    aria-labelledby={`scrollable-auto-tab-1`}
                >
                    <CityPreference
                        allCities={allCities}
                        userCityFilters={userCityFilters}
                        userCityHardFilters={userCityHardFilters}
                        setUserCityFilters={setUserCityFilters}
                        setUserCityHardFilters={setUserCityHardFilters}
                    />
                </div>
                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 2}
                    id={`scrollable-auto-tabpanel-2`}
                    aria-labelledby={`scrollable-auto-tab-2`}
                >
                    <CommunitiesPreferences
                        userCommunitiesFilters={userCommunitiesFilters}
                        userCommunitiesHardFilters={userCommunitiesHardFilters}
                        setUserCommunitiesFilters={setUserCommunitiesFilters}
                        setUserCommunitiesHardFilters={
                            setUserCommunitiesHardFilters
                        }
                    />
                </div>
                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 3}
                    id={`scrollable-auto-tabpanel-3`}
                    aria-labelledby={`scrollable-auto-tab-3`}
                >
                    <OrganisationsPreferences
                        userOrganisationsFilters={userOrganisationsFilters}
                        userOrganisationsHardFilters={
                            userOrganisationsHardFilters
                        }
                        setUserOrganisationsFilters={
                            setUserOrganisationsFilters
                        }
                        setUserOrganisationsHardFilters={
                            setUserOrganisationsHardFilters
                        }
                    />
                </div>
                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 4}
                    id={`scrollable-auto-tabpanel-4`}
                    aria-labelledby={`scrollable-auto-tab-4`}
                >
                    <ProductsPreferences
                        userProductsFilters={userProductsFilters}
                        userProductsHardFilters={userProductsHardFilters}
                        setUserProductsFilters={setUserProductsFilters}
                        setUserProductsHardFilters={setUserProductsHardFilters}
                    />
                </div>
                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 5}
                    id={`scrollable-auto-tabpanel-5`}
                    aria-labelledby={`scrollable-auto-tab-5`}
                >
                    <DepartmentsPreferences
                        userDepartmentsFilters={userDepartmentsFilters}
                        userDepartmentsHardFilters={userDepartmentsHardFilters}
                        setUserDepartmentsFilters={setUserDepartmentsFilters}
                        setUserDepartmentsHardFilters={
                            setUserDepartmentsHardFilters
                        }
                    />
                </div>
                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 6}
                    id={`scrollable-auto-tabpanel-6`}
                    aria-labelledby={`scrollable-auto-tab-6`}
                >
                    <DivisionsPreferences
                        userDivisionsFilters={userDivisionsFilters}
                        userDivisionsHardFilters={userDivisionsHardFilters}
                        setUserDivisionsFilters={setUserDivisionsFilters}
                        setUserDivisionsHardFilters={
                            setUserDivisionsHardFilters
                        }
                    />
                </div>
                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 7}
                    id={`scrollable-auto-tabpanel-7`}
                    aria-labelledby={`scrollable-auto-tab-7`}
                >
                    <SkillsPreferences
                        userSkillsFilters={userSkillsFilters}
                        userSkillsHardFilters={userSkillsHardFilters}
                        setUserSkillsFilters={setUserSkillsFilters}
                        setUserSkillsHardFilters={setUserSkillsHardFilters}
                    />
                </div>
                <div
                    role="tabpanel"
                    className="prefTab"
                    hidden={value !== 8}
                    id={`scrollable-auto-tabpanel-8`}
                    aria-labelledby={`scrollable-auto-tab-8`}
                >
                    <PeoplePreferences
                        userPeopleFilters={userPeopleFilters}
                        userPeopleHardFilters={userPeopleHardFilters}
                        setUserPeopleFilters={setUserPeopleFilters}
                        setUserPeopleHardFilters={setUserPeopleHardFilters}
                    />
                </div>
                <div>
                    <NextButton
                        className="next-button"
                        popup={true}
                        title="Confirm"
                        tooltipText="Confirm."
                        onClickEvent={async () => {
                            await saveUserPreferences();
                            if (userLanguages.length > 0) {
                                let response = await sendLanguage(
                                    userLanguages
                                );
                                if (response.status) {
                                    alert(
                                        "Could't save preferences, please try again later!"
                                    );
                                } else {
                                    props.setUserLanguages(userLanguages);
                                    props.closeModal(false);
                                }
                            } else {
                                alert("Please select at least one language!");
                            }
                        }}
                    />
                </div>
            </Container>
        </div>
    );
};

export default PrefContainer;
