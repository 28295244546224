import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../components/general/styles";
import NextButton from "../components/general/nextButton";
import CloseIcon from "@material-ui/icons/Close";

// Sample page for teasing ByronMeet, was just important for a bachelor thesis.

const Wrapper = styled.div`
    width: 100%;
    //box-shadow: 0px 7px 23px 0px rgba(0, 0, 0, 0.15);
    max-height: 999px;
    transition: max-height 500ms ease-in-out;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    justify-self: center;
    align-self: center;
    margin: 30px auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    font-size: 1.5rem;
    padding: 50px;

    .newMeetupHeader {
        position: absolute;
        top: 15px;
        right: 20px;
    }

    a {
        color: ${colors.red};
    }
    h1 {
        font-size: 1.5rem;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 3px solid ${colors.red};
    }
    p {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${colors.darkGray};
    }
    button {
        cursor: pointer;
    }
    .btnHolder {
        margin-top: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-of-type {
            margin-top: 20px;
        }
    }
`;

const MeetupFinishedPage = (props) => {
    // const socket = props.socket;
    const nextStepUrl = props.location.state.nextStepUrl
        ? props.location.state.nextStepUrl
        : null;
    // const bayronUrl = window._env_.REACT_APP_BAYRON_URL
    //   ? window._env_.REACT_APP_BAYRON_URL
    //   : "https://127.0.0.1";
    // const baseUrl = `${bayronUrl}/bayronnect`; // window._env_.REACT_APP_BAYRON_URL ? `${window._env_.REACT_APP_BAYRON_URL}/bayronnect` : "https://127.0.0.1/bayronnect"

    const closePopup = () => {
        window.location.href = `${window._env_.REACT_APP_BAYRONNECT_URL}/`;
    };

    return (
        <Wrapper style={{ minHeight: "100%", minWidth: "100%" }}>
            <Container>
                <div className="newMeetupHeader">
                    <div className="exitModal" onClick={closePopup}>
                        <CloseIcon className="closeModalIcon" />
                    </div>
                </div>
                {/* TODO +++ Add a Close icon here so the user can close the page */}
                {/* TODO +++ Think about displaying the nextStepUrl but only the first few characters if it is too long. */}
                <h1>
                    {props.state && props.state.noMeetup
                        ? "The meetup is over!"
                        : "The meetup is over or deleted!"}
                </h1>
                <p>
                    {" "}
                    Any additional feedback on your overall experience would be
                    highly appreciated.
                </p>
                <a href="mailto:byron@bayer.com?subject=ByronMeet%20Feedback">
                    Send email
                </a>
                <p>
                    {nextStepUrl
                        ? "Now enjoy the rest of your event!"
                        : "Now join another meetup or simply close the tab to finish."}
                </p>
                <div className={"btnHolder"}>
                    <NextButton
                        className="botton"
                        width="250px"
                        title={
                            nextStepUrl
                                ? "Move to the next agenda point"
                                : "Choose new meetup"
                        }
                        tooltipText={
                            nextStepUrl
                                ? "Click to follow the link set by the meetup Organizer."
                                : "Go to the Home page."
                        }
                        onClickEvent={() => {
                            if (nextStepUrl) {
                                window.location.href =
                                    nextStepUrl.slice(0, 4) === "http"
                                        ? nextStepUrl
                                        : `https://${nextStepUrl}`;
                            } else {
                                window.location.href =
                                    window._env_.REACT_APP_BAYRONNECT_URL;
                            }
                        }}
                    />
                </div>
            </Container>
        </Wrapper>
    );
};

export default withRouter(MeetupFinishedPage);
