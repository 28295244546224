import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
// import NextButton from "../general/nextButton";
import EnabledFilters from "./enabledFilters";
import ActivityModal from "../general/activityModal";
import Tooltip from "@material-ui/core/Tooltip";
import UsersInLobby from "./usersInLobby";
import NextButton from "../general/nextButton";
// Page for waiting for matching partner, corresponding components in ../components/lobby

const StyledMeetupButtons = styled.div`
    display: flex;
    margin-top: 30px;

    button {
        margin: 0 15px;
    }
`;

const Main = styled.main`
    @media screen and (max-width: 1024px) {
        height: auto !important;
        min-height: auto !important;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow: hidden;
    position: relative;
    //margin-bottom: 30px;
    max-width: 100%;
    border-radius: 8px;
    padding: 30px 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    h1 {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        text-align: center;
        color: #fffdfb;
        margin-top: 0px;
    }
`;

const Lobby = (props) => {
    const socket = props.socket;
    const meetup = props.meetup;
    const meetupId = meetup.id;
    const meetupName = meetup.name;
    const nextStepUrl = meetup.nextStepUrl;
    const [showActivityModal, setShowActivityModal] = useState(false);

    const [isCreator, setIsCreator] = useState(
        localStorage.getItem("userId") === props.meetup.creator
    );

    let timeOut;
    let meetupFinishedTimeOut;
    let meetupDuration = meetup.duration;

    var endDate = meetup.endDate;
    const endDate2 = meetup.meetupEndDate;

    const [confMeetupButton, setConfMeetupButton] = useState("Start Meetup");
    const [confButtonDisabled, setConfButtonDisabled] = useState(false);

    if (!endDate) {
        endDate = endDate2;
    }
    useEffect(() => {
        if (meetup.conference === 1) {
            setConfMeetupButton("Start meetup");
            setConfButtonDisabled(false);
        } else {
            setConfMeetupButton("Stop meetup");
            setConfButtonDisabled(true);
        }
    }, [props.meetup]);

    useEffect(() => {
        if (socket) {
            checkIfMeetupFinished();
            resetIdleTimer();
            socket.on("matchedWith", (msg) => {
                if (window.location.href.slice(-7) !== "connect") {
                    console.log(
                        "RECEIVED matchedWith signal | lobby component"
                    );
                    console.log("PARTNER email: ", msg.partnerProfile.email);
                    new Audio("https://i.cloudup.com/r4ZENSF0Hu.m4a").play();

                    props.setMatchData({
                        status: "match found",
                        partnerInfo: msg.partnerProfile,
                        selfProfile: msg.selfProfile,
                        meetup: msg.meetup,
                        matchRoomSocketId: msg.matchRoomSocketId,
                        matchIdDB: msg.matchIdDB
                    });
                    props.setCurrentPage("permission");
                }
            });
        } else {
            console.log("NO SOCKET LOBBY COMPONENT");
            window.location.href = window._env_.REACT_APP_BAYRONNECT_URL;
        }

        return function cleanup() {
            if (socket) {
                console.log("Cleaning socket events | lobby");
                clearTimeout(timeOut);
                socket
                    .removeListener("matchedWith")
                    .on("matchedWith", () => {});
                clearTimeout(meetupFinishedTimeOut);
            }
        };
    }, []);

    // Sets a timer to remove ghost users.
    // These are users that have been inactive for too long.
    // Because some simply leave the browser unattended,
    // therefore they would be matched and do not respond to the match.
    // Inactivity time is set to, in minutes, meetupDuration + 1 minute.
    function resetIdleTimer() {
        clearTimeout(timeOut);
        setShowActivityModal(false);

        // console.log(meetupDuration);

        timeOut = setTimeout(function () {
            setShowActivityModal(true);
        }, meetupDuration * 1000 * 60 + 60000);
    }

    // Sets a timer to execute at the moment the meetup finishes.
    // At that moment, the page goes to /next.
    function checkIfMeetupFinished() {
        let currentDateTime = new Date();
        // let currentTime = currentDateTime.setHours(currentDateTime.getHours());
        let meetupEndDate = new Date(endDate);
        let milliSecondsTillMeetupEnds = meetupEndDate - currentDateTime;
        // About timeOuts:
        // ...Browsers including Internet Explorer, Chrome, Safari, and Firefox store the delay
        // as a 32-bit signed integer internally. This causes an integer overflow
        // when using delays larger than 2,147,483,647 ms (about 24.8 days),
        // resulting in the timeout being executed immediately.
        // Taken from:
        // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout
        // Therefore, it milliSecondsTillMeetupEnds > 24 days, we do not set any timeOut.
        let milliSeconds24Days = 1000 * 60 * 60 * 24 * 24;
        /* console.log(
      "currentDateTime",
      currentDateTime,
      "endDate",
      endDate,
      "meetupId",
      meetupId,
      "meetupName",
      meetupName,
      "duration",
      meetupDuration,
      "milliSecondsTillMeetupEnds",
      milliSecondsTillMeetupEnds,
      "current > endDate",
      currentDateTime > meetupEndDate
    );
    */
        if (milliSecondsTillMeetupEnds < milliSeconds24Days) {
            meetupFinishedTimeOut = setTimeout(function (
                milliSecondsTillMeetupEnds
            ) {
                props.history.push({
                    pathname: "/next",
                    state: {
                        nextStepUrl: nextStepUrl
                    }
                });
            },
            milliSecondsTillMeetupEnds);
        }
    }

    return (
        <Main
            style={{
                display: "flex",
                flexGrow: "1",
                alignItem: "center",
                justifyContent: "center",
                flexDirection: "column",
                margin: "0 15px",
                position: "relative"
            }}
        >
            <EnabledFilters />
            <Wrapper>
                <h1>
                    {" "}
                    Hi, welcome to{" "}
                    {meetupName === "randd"
                        ? "the \n R&D Speed Networking"
                        : meetupName}{" "}
                    meetup!
                </h1>
                {/* <UsersInLobby users={users} /> */}
                <UsersInLobby
                    socket={socket}
                    meetupId={meetupId}
                    usersInLobby={props.usersInLobby}
                />
                {isCreator && meetup.conference !== 0 && (
                    <StyledMeetupButtons>
                        <NextButton
                            className="next-button"
                            title={confMeetupButton}
                            onClickEvent={() => {
                                socket.emit("startMeetup", {
                                    meetupId: meetup.id
                                });
                            }}
                            disabled={props.isConferenceStarted}
                        />
                        <NextButton
                            className="next-button"
                            title={"Start conference call"}
                            disabled={confButtonDisabled}
                            onClickEvent={() => {
                                socket.emit("/conference/start", {
                                    meetupId: meetup.id
                                });
                            }}
                        />
                    </StyledMeetupButtons>
                )}
            </Wrapper>
            {/*<div className="back-button">
          <Tooltip title={'Go back to homepage'} arrow>
            <div
              onClick={() => {
                socket.emit("backToHomePage", {
                  jwtToken: localStorage.getItem("jwtToken"),
                  meetupId: meetupId,
                  backToHomePage: true
                });
                props.closeSocket();
                props.history.push("/");
              }}
            >
              <img src={path + "/back.png"} alt="Back"/>
            </div>
          </Tooltip>
        </div>*/}

            {showActivityModal && (
                <ActivityModal
                    resetIdleTimer={resetIdleTimer}
                    nextStepUrl={nextStepUrl}
                    endDate={endDate}
                />
            )}
        </Main>
    );
};

export default withRouter(Lobby);
