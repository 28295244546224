import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import React from "react";

export const ExpandedInput = (props) => {
    return (
        <div className="input-wrap" onClick={props.onClick}>
            <div className="advanced-options">
                {props.expand ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                <label>
                    <strong>Further optimization</strong>
                </label>
            </div>
        </div>
    );
};
