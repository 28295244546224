import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components";

import { matchUser } from "../../hooks/socketOperations";
import { Redirect } from "react-router-dom";
import { CONF } from "../../config/config";

const StyledDialog = styled(Dialog)`
    @keyframes timer-slide-lt {
        0% {
            transform: rotate(135deg);
        }
        50% {
            transform: rotate(135deg);
        }
        100% {
            transform: rotate(315deg);
            display: none;
        }
    }
    @keyframes timer-slide-rt {
        0% {
            transform: rotate(-45deg);
        }
        50% {
            transform: rotate(135deg);
        }
        100% {
            transform: rotate(135deg);
            display: none;
        }
    }

    .timer {
        // width: 100%;
        // height: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        // overflow-y: hidden;

        @media screen and (max-height: 500px) {
            transform: scale(0.7);
        }

        .circle-timer {
            transform: scale(1.5);
            max-width: 200px;
            max-height: 200px;
            margin: 2rem auto;
            position: relative;
            cursor: pointer;
            // overflow: hidden;

            .timer-slot {
                position: relative;
                width: 100px;
                height: 200px;
                display: inline-block;
                overflow: hidden;
                float: left;

                .timer-lt,
                .timer-rt {
                    border-radius: 50%;
                    position: relative;
                    top: 50%;
                    left: -2px;
                    border: 10px solid white;
                    width: 180px;
                    height: 180px;
                    margin-left: -100px;
                    margin-top: -100px;
                    border-left-color: transparent;
                    border-top-color: transparent;
                    z-index: 5;
                    // overflow: hidden;
                }
                .timer-lt {
                    animation: 3s linear timer-slide-lt;
                    animation-fill-mode: forwards;

                    left: 100%;
                }
                .timer-rt {
                    animation: 3s linear timer-slide-rt;
                    animation-fill-mode: forwards;
                }
            }
            .count {
                position: absolute;
                width: 100%;
                height: 100%;
                font-size: 8rem;
                color: white;
                text-align: center;
                line-height: 200px;
                font-family: sans-serif;
                // overflow: hidden;
            }
            &.paused {
                .timer-lt,
                .timer-rt {
                    animation-play-state: paused;
                    transition: all 0.5s;
                }
            }
            &.paused,
            &:hover {
                .timer-lt,
                .timer-rt {
                    border: 10px solid rgba(white, 0.5);
                    border-left-color: transparent;
                    border-top-color: transparent;
                    transition: all 0.5s;
                }
            }
            &.paused {
                .count {
                    color: rgba(white, 0.5);
                    transition: all 0.5s;
                }
            }
        }
    }
    .MuiDialogContent-root {
        justify-content: center;
        align-items: center;
        display: flex;
        overflow: hidden;
    }
    .MuiDialog-paperWidthSm {
        height: 80%;
        width: 80%;
    }
`;

export default function AlertDialog(props) {
    const [count, setCount] = useState(3);
    const [showCountdown, setShowCountdown] = useState(false);

    const meetup = props.meetup;
    // TODO +++ Alen please check.
    // eslint-disable-next-line no-unused-vars
    const [noMeetup, setNoMeetup] = useState(false);

    useEffect(() => {
        if (!props.isSurveyModal) {
            let count2 = count;
            let alreadySent = false;

            async function timer() {
                if (count2 >= 1) {
                    count2 = count2 - 1;
                    setCount(count2);
                } else {
                    try {
                        // const meetupStatus = await getMeetupStatus();
                        if (!alreadySent) {
                            alreadySent = true;
                            await matchUser(
                                props.socket,
                                localStorage.getItem("jwtToken")
                            );
                        } else {
                            // setNoMeetup(true);
                            clearInterval(countDownInterval);
                        }
                    } catch {
                        return <Redirect to="/" />;
                    }
                    clearInterval(countDownInterval);
                }
            }
            let countDownInterval;
            //Part for conference meetup, check if meetup started or is it still in conference
            getMeetupStatus().then((meetupStatus) => {
                if (meetupStatus.conference === 1) {
                    if (props.socket) {
                        props.socket.on("startMeetup", () => {
                            setShowCountdown(true);
                            countDownInterval = setInterval(timer, 1000);
                        });
                    }
                } else if (
                    meetupStatus.conference === 2 ||
                    meetupStatus.conference === 0
                ) {
                    setShowCountdown(true);
                    countDownInterval = setInterval(timer, 1000);
                }
            });

            return function cleanup() {
                clearInterval(countDownInterval);
                getMeetupStatus().then((meetupStatus) => {
                    if (meetupStatus.conference && props.socket) {
                        props.socket
                            .removeListener("startMeetup")
                            .on("startMeetup", () => {});
                    }
                });
            };
        }
    }, [props.isSurveyModal]);

    async function getMeetupStatus() {
        return await fetch(`${CONF.BAYRONNECT_API_URL}/api/getMeetupStatus`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                meetupId: meetup.id
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data.meetup;
            });
    }

    return count > 0 ? (
        <div>
            <StyledDialog
                open={showCountdown}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={"countdown-modal"}
                container={() => document.getElementById("root")}
            >
                <DialogContent>
                    <div className="timer">
                        <div className="circle-timer">
                            <div className="timer-slot">
                                <div className="timer-lt" />
                            </div>
                            <div className="timer-slot">
                                <div className="timer-rt" />
                            </div>
                            <div className="count">{count}</div>
                        </div>
                    </div>
                </DialogContent>
            </StyledDialog>
        </div>
    ) : null;
}
