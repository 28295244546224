import TooltipMui from "@material-ui/core/Tooltip";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import styled from "styled-components";

const StyledP = styled.p`
    font-size: 14px;
    line-height: 24px;
    margin: 0;
`;

export const TextareaInput = (props) => {
    return (
        <div className="input-wrap">
            <label>
                {props.label}
                {props.tooltipText && (
                    <TooltipMui
                        arrow
                        placement="right-start"
                        title={
                            <React.Fragment>
                                <StyledP>{props.tooltipText}</StyledP>
                            </React.Fragment>
                        }
                    >
                        <HelpIcon className="help" />
                    </TooltipMui>
                )}
            </label>
            <textarea
                id={props.inputId}
                placeholder={props.placeholder}
                value={props.value}
                onPaste={props.onPaste}
                onChange={props.onChange}
                rows="3"
                maxLength={props.maxLength}
            />
        </div>
    );
};
