import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import QuitButton from "../stage/quitButton";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import KeyboardReturnRoundedIcon from "@material-ui/icons/KeyboardReturnRounded";
// Sample Profile, displayed underneath of video streams
import { CONF } from "../../config/config";

const InfoWrapper = styled.div`
    padding: 20px;
    background-color: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #4d4d4d;
    //height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    //height: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;

    .buttonWrap {
        @media screen and (max-width: 1120px) {
            display: block !important;
            width: auto !important;
        }
        @media screen and (max-width: 733px) {
            width: auto !important;
        }
    }

    ${breakpoint("tablet")`
    // flex-direction:column;
    // align-items: baseline;
  `}
    ${breakpoint("desktop")`
    // flex-direction:row;
    // align-items: baseline;
  `}
  @media only screen and (orientation: portrait), (max-width: 732px) {
        padding: 5px;
    }

    @media screen and (max-height: 800px) {
        padding: 10px;
    }
    @media screen and (max-width: 950px) {
        padding: 10px;
    }
`;

const UserName = styled.h2`
    margin: 0 auto 0 0;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #4d4d4d;

    @media screen and (max-height: 800px) {
        font-size: 12px;
    }
    @media screen and (max-width: 950px) {
        font-size: 12px;
    }
`;

const EMail = styled.p`
    margin: 0 auto 0 0;
    padding-top: 10px;
    display: flex;
    align-items: center;
    color: #4d4d4d;

    @media only screen and (orientation: portrait), (max-width: 732px) {
        display: none;
    }

    @media screen and (max-height: 800px) {
        font-size: 12px;
    }
    @media screen and (max-width: 950px) {
        font-size: 12px;
    }
`;

const Location = styled.p`
    margin: 0;
    margin-right: auto;

    display: flex;
    align-items: center;
    color: #4d4d4d;

    ${breakpoint("tablet")`
    padding-top: 16px;
    margin-right: auto;
    margin-left: 0;
  `}
    ${breakpoint("desktop")`
    padding-top: 0;
    margin-left: auto;
    margin-right: 0;
  `}

  @media screen and (max-width: 800px) {
        font-size: 12px;
    }
    @media screen and (min-width: 800px) and (max-width: 1090px) {
        font-size: 12px;
    }
    @media screen and (max-height: 800px) {
        font-size: 12px;
    }

    @media only screen and (orientation: portrait), (max-width: 732px) {
        display: none;
    }
`;

const Skill = styled.p`
    margin: 0;
    margin-right: auto;
    padding-top: 10px;
    display: block;
    text-align: right;
    align-items: center;
    color: #4d4d4d;

    svg {
        vertical-align: middle;
    }

    ${breakpoint("tablet")`
    margin-left: 0;
    margin-right: auto;
  `}
    ${breakpoint("desktop")`
    margin-left: auto;
    margin-right: 0;
  `}

  @media only screen and (orientation: portrait), (max-width: 732px) {
        display: none;
    }

    @media screen and (max-height: 800px) {
        font-size: 12px;
    }
    @media screen and (max-width: 950px) {
        font-size: 12px;
    }
`;

const DivWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;

    ${breakpoint("desktop")`
    width: 50%;
  `}

    @media screen and (max-height: 800px) {
        font-size: 12px;
    }
    @media screen and (max-width: 950px) {
        font-size: 12px;
    }
`;

const Profile = (props) => {
    return (
        <InfoWrapper>
            <DivWrap>
                <UserName>
                    <PersonIcon
                        style={{ paddingRight: "10px", color: "#C4C4C4" }}
                    />
                    {props.profile}
                </UserName>
                <EMail>
                    <MailOutlineIcon
                        style={{ paddingRight: "10px", color: "#C4C4C4" }}
                    />
                    <span
                        id={
                            props.myProfile ? "myEmailSpan" : "partnerEmailSpan"
                        }
                    >
                        {props.email}
                    </span>
                </EMail>
            </DivWrap>
            <DivWrap>
                {CONF.OPS_MODE === "internal" ? (
                    <React.Fragment>
                        <Location>
                            <MyLocationIcon
                                style={{
                                    paddingRight: "10px",
                                    color: "#C4C4C4"
                                }}
                            />
                            {props.location}
                        </Location>
                        <Skill>
                            <WorkIcon
                                style={{
                                    paddingRight: "10px",
                                    color: "#C4C4C4"
                                }}
                            />
                            {props.position}
                        </Skill>
                    </React.Fragment>
                ) : (
                    props.position && (
                        <Skill>
                            <WorkIcon
                                style={{
                                    paddingRight: "10px",
                                    color: "#C4C4C4"
                                }}
                            />
                            {props.position}
                        </Skill>
                    )
                )}
            </DivWrap>
            {/* <ProfilePicture src="" alt="" /> */}
        </InfoWrapper>
    );
};

export default Profile;
