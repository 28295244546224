/** @format */

import React, { useEffect, useRef, useState } from "react";
// import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NextButton from "./nextButton";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { InputControls } from "./inputControls";
import Dialog from "@material-ui/core/Dialog";
import { colors } from "./styles";
import TooltipMui from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import MediaPermissions from "../general/mediaPermissions";
import VideoCanvas from "./videoCanvas";

const ModalWrap = styled(Dialog)`
    .MuiDialog-paper {
        margin: 20px;
    }

    .permissions-img {
        margin: 10px auto 10px auto;
        border: 5px solid #dc1448;
        border-radius: 5px;
        max-width: 100%;
        box-sizing: border-box;
    }
    .modalWrap {
        .closeModalIcon {
            color: white;
        }
        display: flex;
        flex-direction: column;
        //width: 400px;
        //justify-content: center;
        padding: 0;
        position: relative;

        @media only screen and (orientation: portrait) {
            min-width: 200px;
            overflow-y: auto;
        }

        .enterLobbyButton {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
    .newMeetupHeader {
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 100;
        color: white;
    }
    .video-error {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px 0 0 0;
        svg {
            color: ${colors.pink};
            font-size: 50px;
        }
        p {
            display: flex;
            flex-direction: column;
            max-width: 400px;
            text-align: left;
            padding: 0 15px;
            margin-bottom: 0;
            span {
                margin-top: 5px;
            }
            a {
                display: inline-block;
            }
        }
    }
    .cameraPermContent {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h3 {
        text-align: center;
        margin: 0;
        position: absolute;
        top: 10px;
        z-index: 1000;
        left: 10px;
        color: white;
    }
    .videoPreview {
        width: 400px;
        height: 300px;
        background-color: rgba(0, 0, 0, 0.5);
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .videoContainer {
        position: relative;
        //margin-bottom: 20px;
        background: black;
        max-width: 100%;
        width: 400px;

        .virtualBackgroundWrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-left: auto;
            margin-right: auto;
            border-radius: 5px;
            background-color: rgba(15, 57, 79, 0.9);
            border-color: #ffffff;
            color: #fff;
            font-size: 15px;
            text-align: center;
            padding: 12px 10px;
            margin: 0 10px;
            width: max-content;
            max-width: 100%;
            cursor: pointer;
        }
    }
    .input-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        margin-bottom: 0;
        padding: 0 10px;
        max-width: 350px;
        width: 100%;
        box-sizing: border-box;
    }
    .language-wrap {
        display: flex;
        align-items: center;
    }
    .language-tooltip-wrap {
        display: flex;
    }
    .language-name {
        margin-left: 20px;
    }
    .tooltip-language {
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.4em;
        margin-top: 0.4em;
    }
    .guess-language {
        text-align: center;
        color: white;
        padding: 10px 23px;
        border-radius: 15px;
        background-color: #dc1448;
        cursor: pointer;
        transition: all 0.1s linear;
        width: fit-content;

        :hover {
            background-color: #be1442;
        }
    }
    .testyourmic-wrap {
        display: flex;
        align-items: center;
    }
    .testyourmic-tooltip-wrap {
        display: flex;
    }
    .toggle-mic {
        border-radius: 23px;
        background-color: #dc1448;
        border-color: #ffffff;
        color: #fff;
        font-size: 15px;
        text-align: center;
        padding: 12px 0;
        width: 150px;
    }
    .tooltip-testyourmic {
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.4em;
        margin-top: 0.4em;
    }
    .volume-visualizer {
        --volume: 0%;
        position: relative;
        width: 150px;
        height: 20px;
        margin: 50px;
        background-color: #ddd;
    }
    .volume-visualizer::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: var(--volume);
        background-color: green;
        transition: width 100ms linear;
    }
    .noticeText {
        margin: 0;
        margin-bottom: 20px;
        color: red;
    }
    .MuiInputBase-root {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
    }
    .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            text-align: center;
            color: white;
            padding: 10px 23px;
            border-radius: 15px;
            background-color: #dc1448;
            cursor: pointer;
            transition: all 0.1s linear;
            width: fit-content;

            :hover {
                background-color: #be1442;
            }
        }
    }
`;

const MyVideo = styled.video`
    display: none !important;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 400px;
    //height: auto;
    max-width: 100%;
    height: 300px;
    //position: absolute;

    //left: 99999px;
    //opacity: 0;

    @media only screen and (orientation: portrait) and (max-width: 500px) {
    }
`;

const WaitingForPartnerLoad = styled.div`
  margin-bottom: 20px;
  p {
    color: #FFFF;
    font-size: 20px;
    text-align: center;
    @keyframes blink {
      0% {
        opacity: .2;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: .2;
      }
    }
    span {
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
    }
    span:nth-child(2) {
      animation-delay: .2s;
    }
    span:nth-child(3) {
      animation-delay: .4s;
    }
  `;

const StyledP = styled.p`
    font-size: 14px;
    line-height: 24px;
    margin: 0;
`;

const UserMediaDevices = (props) => {
    const bayronUrl = window._env_.REACT_APP_BAYRON_URL
        ? window._env_.REACT_APP_BAYRON_URL
        : "https://127.0.0.1";
    const imageUrl = `${bayronUrl}/images`;
    const localVideo = useRef(null);
    const currentAudioInputId =
        localStorage.getItem("currentAudioInputId") &&
        localStorage.getItem("currentAudioInputId") !== "default"
            ? localStorage.getItem("currentAudioInputId")
            : false;
    const currentAudioOutputId =
        localStorage.getItem("currentAudioOutputId") &&
        localStorage.getItem("currentAudioOutputId") !== "default"
            ? localStorage.getItem("currentAudioOutputId")
            : false;
    const currentVideoId = localStorage.getItem("currentVideoId");
    const languages = importAll(
        require.context("../../assets/audioCheck", false, /\.(wav|m4a|mp3)$/)
    );

    const [isClicked, setIsClicked] = useState(false);
    const [showMediaPermissions, setShowMediaPermissions] = useState(false);
    const [requestedPermissions, setRequestedPermissions] = useState(false);
    const [showVideoCanvas, setShowVideoCanvas] = useState(false);
    const [currentStream, setCurrentStream] = useState(false);

    const [state, setState] = useState({
        possibleAudioInput: [],
        possibleAudioOutput: [],
        possibleVideoInput: [],
        hasActivatedMedia: false,
        currentAudioInputLabel: "",
        currentAudioOutputLabel: "",
        currentVideoLabel: "",
        profileBackgroundImage:
            "url('" +
            imageUrl +
            "/" +
            (localStorage.getItem("username") || "").split("@")[0] +
            "/profile') no-repeat center",
        constraints: { audio: false, video: false },
        currentLanguage: new Audio(
            languages[getRandomInt(languages.length)].default
        ),
        temporarySelectedKeys: [],
        languageName: "",
        currentStream: null,
        hasVideo: false
    });

    const [errorState, setErrorState] = useState({
        isError: false,
        videoDeviceUsed: false,
        audioDeviceUsed: false
    });

    const [alreadyClickedEnter, setAlreadyClickedEnter] = useState(false);

    const [playing, setPlaying] = useState(false);

    const checkDevices = () => {
        return navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                return devices.some(
                    (val) => val.kind === "audioinput" && val.label !== ""
                );
            })
            .then(function (result) {
                return result;
            });
    };

    useEffect(() => {
        if (props.showUserMedia) {
            checkDevices().then(function (result) {
                if (result || requestedPermissions) {
                    if (state.currentLanguage) {
                        state.currentLanguage.addEventListener(
                            "ended",
                            function () {
                                state.currentLanguage.currentTime = 0;
                                setPlaying(false);
                            }
                        );
                    }

                    getMediaDevices();

                    return function cleanup() {
                        if (
                            localVideo.current &&
                            localVideo.current.srcObject &&
                            !props.inMeetup
                        ) {
                            localVideo.current.srcObject
                                .getTracks()
                                .forEach(function (track) {
                                    track.stop();
                                });
                        }
                    };
                } else {
                    setShowMediaPermissions(true);
                }
            });
        }
    }, [showMediaPermissions]);

    const getMediaDevices = async () => {
        let possibleMediaDevices = {
            possibleAudioInput: [],
            possibleAudioOutput: [],
            possibleVideoInput: [],
            currentAudioInputLabel: "",
            currentAudioOutputLabel: "",
            currentVideoLabel: ""
        };
        let areDefault = {
            currentAudioInputId: false,
            currentAudioOutputId: false
        };
        let constraints = { audio: false, video: false };
        let errorState = {
            isError: false,
            videoDeviceUsed: false,
            audioDeviceUsed: false
        };
        let audioStream;
        let hasVideo = true;

        await navigator.mediaDevices.getUserMedia({ audio: true }).then(
            (stream) => {
                // if (!errorState.isError) {
                //   localVideo.current.srcObject = stream;
                // }
                audioStream = stream;
            },
            (e) => {
                errorState.isError = true;
                setErrorState(errorState);

                console.log(e.name);
                console.log("video error: ", e);
            }
        );

        // stopStream();

        await navigator.mediaDevices.getUserMedia({ video: true }).then(
            (stream) => {
                // if (!errorState.isError) {
                //   localVideo.current.srcObject = stream;
                // }
            },
            (e) => {
                errorState.videoDeviceUsed = true;
                setErrorState(errorState);
                hasVideo = false;

                console.log(e.name);
                console.log("video error: ", e);
            }
        );

        // stopStream();

        if (!errorState.isError) {
            //GET ALL POSSIBLE MEDIA DEVICES, SELECT DEFAULT/SELECTED ONE
            await navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                    devices.forEach((device) => {
                        if (device.deviceId === "communications") {
                            return;
                        }
                        if (device.kind === "audioinput") {
                            if (currentAudioInputId) {
                                if (currentAudioInputId === device.deviceId) {
                                    possibleMediaDevices.currentAudioInputLabel =
                                        device.label;
                                    constraints.audio = {
                                        deviceId: device.deviceId
                                    };
                                }
                            } else {
                                if (device.deviceId === "default") {
                                    // let deviceName = device.label.replace('Default - ', '');
                                    devices.forEach((firstDevice) => {
                                        if (
                                            firstDevice.groupId ===
                                                device.groupId &&
                                            device.kind === firstDevice.kind
                                        ) {
                                            possibleMediaDevices.currentAudioInputLabel =
                                                firstDevice.label;
                                            constraints.audio = {
                                                deviceId: device.deviceId
                                            };
                                            areDefault.currentAudioInputId =
                                                firstDevice.deviceId;
                                        }
                                        // if(deviceName === firstDevice.label && device.kind === firstDevice.kind) {
                                        // 	possibleMediaDevices.currentAudioInputLabel = firstDevice.label;
                                        // 	constraints.audio = {deviceId: device.deviceId};
                                        // 	areDefault.currentAudioInputId = firstDevice.deviceId;
                                        // }
                                    });
                                } else if (
                                    possibleMediaDevices.currentAudioInputLabel ===
                                    ""
                                ) {
                                    possibleMediaDevices.currentAudioInputLabel =
                                        device.label;
                                    constraints.audio = {
                                        deviceId: device.deviceId
                                    };
                                    areDefault.currentAudioInputId =
                                        device.deviceId;
                                }
                            }
                            if (
                                !device.label.includes("Default - ") &&
                                !device.label.includes("Standard - ")
                            ) {
                                possibleMediaDevices.possibleAudioInput.push({
                                    id: device.deviceId,
                                    label: device.label
                                });
                            }
                        } else if (device.kind === "videoinput") {
                            if (currentVideoId) {
                                if (currentVideoId === device.deviceId) {
                                    possibleMediaDevices.currentVideoLabel =
                                        device.label;
                                    constraints.video = {
                                        deviceId: device.deviceId
                                    };
                                }
                            } else {
                                if (
                                    possibleMediaDevices.currentVideoLabel ===
                                        "" &&
                                    device.deviceId !== ""
                                ) {
                                    possibleMediaDevices.currentVideoLabel =
                                        device.label;
                                    localStorage.setItem(
                                        "currentVideoId",
                                        device.deviceId
                                    );
                                    constraints.video = {
                                        deviceId: device.deviceId
                                    };
                                }
                            }

                            possibleMediaDevices.possibleVideoInput.push({
                                id: device.deviceId,
                                label: device.label
                            });
                        } else if (device.kind === "audiooutput") {
                            if (currentAudioOutputId) {
                                if (currentAudioOutputId === device.deviceId) {
                                    possibleMediaDevices.currentAudioOutputLabel =
                                        device.label;
                                }
                            } else {
                                if (device.deviceId === "default") {
                                    // let deviceName = device.label.replace('Default - ', '');
                                    devices.forEach((firstDevice) => {
                                        if (
                                            firstDevice.groupId ===
                                                device.groupId &&
                                            device.kind === firstDevice.kind
                                        ) {
                                            possibleMediaDevices.currentAudioOutputLabel =
                                                firstDevice.label;
                                            areDefault.currentAudioOutputId =
                                                firstDevice.deviceId;
                                        }
                                        // if(deviceName === firstDevice.label && device.kind === firstDevice.kind) {
                                        // 	possibleMediaDevices.currentAudioOutputLabel = firstDevice.label;
                                        // 	areDefault.currentAudioOutputId = firstDevice.deviceId;
                                        // }
                                    });
                                }
                                // else if(possibleMediaDevices.currentAudioOutputLabel === '') {
                                // 	possibleMediaDevices.currentAudioOutputLabel = device.label;
                                // 	areDefault.currentAudioOutputId = true;
                                // }
                            }
                            if (
                                !device.label.includes("Default - ") &&
                                !device.label.includes("Standard - ")
                            ) {
                                possibleMediaDevices.possibleAudioOutput.push({
                                    id: device.deviceId,
                                    label: device.label
                                });
                            }
                        }
                    });

                    //SET DEFAULT DEVICE IF DEVICE WAS REMOVED
                    if (
                        currentAudioInputId &&
                        possibleMediaDevices.currentAudioInputLabel === ""
                    ) {
                        devices.forEach((device) => {
                            if (
                                device.deviceId === "default" &&
                                device.kind === "audioinput"
                            ) {
                                let deviceName = device.label.replace(
                                    "Default - ",
                                    ""
                                );
                                devices.forEach((firstDevice) => {
                                    if (
                                        deviceName === firstDevice.label &&
                                        device.kind === firstDevice.kind
                                    ) {
                                        possibleMediaDevices.currentAudioInputLabel =
                                            firstDevice.label;
                                        constraints.audio = {
                                            deviceId: device.deviceId
                                        };
                                        areDefault.currentAudioInputId =
                                            firstDevice.deviceId;
                                    }
                                });
                            }
                        });
                    }
                    if (
                        currentAudioOutputId &&
                        possibleMediaDevices.currentAudioOutputLabel === ""
                    ) {
                        devices.forEach((device) => {
                            if (
                                device.deviceId === "default" &&
                                device.kind === "audiooutput"
                            ) {
                                // let deviceName = device.label.replace('Default - ', '');
                                devices.forEach((firstDevice) => {
                                    if (
                                        device.groupId ===
                                            firstDevice.groupId &&
                                        device.kind === firstDevice.kind
                                    ) {
                                        possibleMediaDevices.currentAudioOutputLabel =
                                            firstDevice.label;
                                        areDefault.currentAudioOutputId =
                                            firstDevice.deviceId;
                                    }
                                });
                            }
                        });
                    }
                    if (
                        currentVideoId &&
                        possibleMediaDevices.currentVideoLabel === ""
                    ) {
                        devices.forEach((device) => {
                            if (device.kind === "videoinput") {
                                devices.forEach((firstDevice) => {
                                    if (
                                        possibleMediaDevices.currentVideoLabel ===
                                            "" &&
                                        device.kind === firstDevice.kind
                                    ) {
                                        possibleMediaDevices.currentVideoLabel =
                                            firstDevice.label;
                                        constraints.video = {
                                            deviceId: device.deviceId
                                        };
                                        areDefault.currentVideoId =
                                            firstDevice.deviceId;
                                    }
                                });
                            }
                        });
                    }
                    for (const [key, value] of Object.entries(areDefault)) {
                        if (value === true) {
                            localStorage.setItem(key, "default");
                        } else if (value !== "" && value !== false) {
                            localStorage.setItem(key, value);
                        }
                    }
                    if (!hasVideo) {
                        localStorage.setItem("currentVideoId", false);
                        constraints.video = false;
                    }
                })
                .catch(function (err) {
                    console.log(err.name + ": " + err.message);
                });
            await navigator.mediaDevices.getUserMedia(constraints).then(
                (stream) => {
                    // localVideo.current.srcObject = stream;

                    let videoFlag = false;
                    stream.getTracks().forEach(function (track) {
                        if (track.kind === "video") {
                            setCurrentStream(stream);
                            videoFlag = true;
                        }
                    });
                    setState({
                        ...state,
                        ...possibleMediaDevices,
                        constraints: constraints,
                        hasActivatedMedia: true,
                        hasVideo: videoFlag
                    });
                },
                (e) => {
                    if (constraints.video) {
                        if (
                            e.name !== "NotFoundError" &&
                            e.name !== "NotReadableError"
                        ) {
                            errorState.videoDeviceUsed = true;
                            setErrorState(errorState);
                        }
                    } else {
                        if (e.name === "NotFoundError") {
                            errorState.isError = true;
                            setErrorState(errorState);
                        } else if (e.name === "NotReadableError") {
                            errorState.audioDeviceUsed = true;
                            setErrorState(errorState);
                        }
                    }
                    console.log(e);
                    console.log("video error: ", e);
                }
            );
        }
    };

    const videoInputChange = async (event) => {
        let constraints = { ...state.constraints };
        let currentStream;

        for (const videoDevice of state.possibleVideoInput) {
            if (videoDevice.label === event.target.value) {
                localStorage.setItem("currentVideoId", videoDevice.id);
                constraints.video = { deviceId: videoDevice.id };

                // stopStream();
                await navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then((stream) => {
                        // localVideo.current.srcObject = stream;
                        // currentStream = stream;
                        setState({
                            ...state,
                            currentVideoLabel: videoDevice.label,
                            constraints: constraints
                            // currentStream: currentStream
                        });
                        setCurrentStream(stream);
                    });
            }
        }
    };

    const audioInputChange = async (event) => {
        let constraints = { ...state.constraints };
        let currentStream;

        for (const audioDevice of state.possibleAudioInput) {
            if (audioDevice.label === event.target.value) {
                localStorage.setItem("currentAudioInputId", audioDevice.id);
                constraints.audio = { deviceId: audioDevice.id };

                // stopStream();
                await navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then((stream) => {
                        // localVideo.current.srcObject = stream;
                        // currentStream = stream;

                        setState({
                            ...state,
                            currentAudioInputLabel: audioDevice.label,
                            constraints: constraints,
                            currentStream: currentStream
                        });
                        setCurrentStream(stream);
                    });
            }
        }
    };

    const audioOutputChange = (event) => {
        let audioOutputId;

        for (let audioOutput of state.possibleAudioOutput) {
            if (audioOutput.label === event.target.value) {
                audioOutputId = audioOutput.id;
                localStorage.setItem("currentAudioOutputId", audioOutputId);
                setState({
                    ...state,
                    currentAudioOutputLabel: audioOutput.label
                });
            }
        }
        if (localVideo.current) {
            localVideo.current.setSinkId(audioOutputId);
        }
    };

    function checkSpeaker() {
        let tempSelectedKeys = [];
        if (!playing) {
            tempSelectedKeys = state.temporarySelectedKeys;
            let result = getNewItem(tempSelectedKeys);
            let sound = result["myObject"].default;
            let temporaryKey = result["temporaryKey"];
            tempSelectedKeys = tempSelectedKeys.concat(temporaryKey);
            const langName = getLanguageName(state.currentLanguage.src);
            setState({
                ...state,
                currentLanguage: new Audio(sound),
                temporarySelectedKeys: tempSelectedKeys,
                languageName: langName
            });
            state.currentLanguage.setSinkId(
                localStorage.getItem("currentAudioOutputId")
            );
            state.currentLanguage.play();
            state.currentLanguage.addEventListener("ended", function () {
                state.currentLanguage.currentTime = 0;
                setPlaying(false);
            });
        } else {
            state.currentLanguage.pause();
        }
        setPlaying(!playing);
    }

    // Select a new audio file.
    const getNewItem = (temporarySelectedKeys) => {
        let myObject;
        let temporaryKey;

        if (temporarySelectedKeys.length < languages.length) {
            // The user has not heard all data.
            do {
                temporaryKey = Math.floor(Math.random() * languages.length);
                myObject = languages[temporaryKey];
            } while (temporarySelectedKeys.indexOf(temporaryKey) > -1); // Repeat until we find an item the user has not seen yet.
        } else {
            // The user has heard all data. Just find a random item.
            temporaryKey = Math.floor(Math.random() * languages.length);
            myObject = languages[temporaryKey];
        }
        return {
            myObject: myObject,
            temporaryKey: temporaryKey
        };
    };

    function getLanguageName(fileName) {
        // First extract the file name.
        // From https://stackoverflow.com/questions/423376/how-to-get-the-file-name-from-a-full-path-using-javascript
        var file = fileName.split("\\").pop().split("/").pop();
        // Get everything before the last ".".
        var strictName = file.substr(0, file.indexOf("."));
        // Format is <phrase>_<language>_[dialect1]_[dialect2]_<creator>_number
        // Remove phrase.
        // From https://newbedev.com/javascript-javascript-get-string-before-and-after-character-code-example
        var noPhrase = strictName.substring(strictName.indexOf("_") + 1);
        // remove _<creator>_<number>
        var noPhraseNumber = noPhrase.slice(0, noPhrase.lastIndexOf("_"));
        var noPhraseNumberCreator = noPhraseNumber.slice(
            0,
            noPhraseNumber.lastIndexOf("_")
        );
        var finalLanguage = noPhraseNumberCreator.replace(/_/g, ", ");
        //var finalLanguage = noPhraseNumberCreator.replace("_", ", ");
        return finalLanguage;
    }

    function importAll(r) {
        return r.keys().map(r);
    }
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    const closeModal = async () => {
        // if(!props.inMeetup){
        //   console.log('in meetup');
        //   stopStream();
        // }
        props.setShowUserMedia(false);
        if (props.closePopup) {
            props.closePopup();
        }
    };

    // const stopStream = () => {
    //   console.log("ENTERED stopStream function | userMediaDevices");
    //   if (localVideo.current && localVideo.current.srcObject) {
    //     localVideo.current.srcObject.getTracks().forEach(function(track) {
    //       track.stop();
    //     });
    //     // localVideo.current.srcObject = null;
    //   }
    // };

    function enterMeetup() {
        if (props.meetup) {
            props.chooseMeetup({
                id: props.meetup.id,
                duration: props.meetup.duration,
                meetupName: props.meetup.name,
                nextStepUrl: props.meetup.nextStepUrl,
                endDate: props.meetup.endDate,
                isDebateMeetup: props.meetup.isDebateMeetup,
                isFriendsMeetup: props.meetup.isFriendsMeetup
            });
        } else {
            closeModal();
        }
    }

    return (
        <React.Fragment>
            <MediaPermissions
                showMediaPermissions={showMediaPermissions}
                setShowMediaPermissions={setShowMediaPermissions}
                requestedPermissions={requestedPermissions}
                setRequestedPermissions={setRequestedPermissions}
            />

            <ModalWrap
                open={props.showUserMedia && !showMediaPermissions}
                onClose={closeModal}
                aria-labelledby="form-dialog-title"
                // disableBackdropClick={true}
            >
                <div className="modalWrap">
                    <div className="newMeetupHeader">
                        <div
                            className="exitModal"
                            onClick={closeModal}
                            style={{
                                color: errorState.isError ? "black" : "white"
                            }}
                        >
                            <CloseIcon className="closeModalIcon" />
                        </div>
                    </div>
                    <h3
                        style={{
                            display: state.hasActivatedMedia ? "block" : "none"
                        }}
                    >
                        Device Permissions
                    </h3>

                    {errorState.isError ? (
                        <div className={"video-error"}>
                            <ErrorIcon /> <p> No media device found! </p>{" "}
                            <p>
                                <span>
                                    1. Maybe you did not give permission in your
                                    browser?
                                </span>
                                <img
                                    className="permissions-img"
                                    src="/bayronnect/permissions.png"
                                />
                                <span>
                                    2. If you are in a MS Teams or Zoom meeting,
                                    please leave it now.
                                </span>
                                <span>
                                    3. If nothing works. please close this
                                    browser and open it again.
                                </span>
                                <span>
                                    4. Are you sure that your camera or mic are
                                    working properly? Please contact{" "}
                                    <a href="mailto:byron@bayer.com">
                                        {" "}
                                        byron@bayer.com
                                    </a>
                                    .
                                </span>
                            </p>
                        </div>
                    ) : (
                        <div className="cameraPermContent">
                            <div
                                className="videoPreview"
                                style={{
                                    display: state.hasVideo ? "none" : "flex"
                                }}
                            >
                                <WaitingForPartnerLoad>
                                    <p>
                                        Waiting for media devices to start
                                        <span>.</span>
                                        <span>.</span>
                                        <span>.</span>
                                    </p>
                                </WaitingForPartnerLoad>
                            </div>
                            <div
                                className="videoContainer"
                                style={{
                                    display: state.hasVideo ? "block" : "none"
                                }}
                            >
                                {/* <MyVideo
                  webkit-playsInline
                  webkit-playsinline
                  playsInline
                  autoPlay
                  ref={localVideo}
                  muted
                  playsinline
                  style={{
                    display: state.hasActivatedMedia ? "flex" : "none",
                    background: !state.constraints.video
                      ? state.profileBackgroundImage
                      : "none"
                  }}
                /> */}
                                {currentStream && (
                                    <VideoCanvas
                                        inMeetup={props.inMeetup}
                                        isSmall={true}
                                        stream={currentStream}
                                    />
                                )}

                                {state.hasActivatedMedia && currentStream && (
                                    <InputControls
                                        currentStream={currentStream}
                                        localVideo={localVideo}
                                        checkMedia={true}
                                    />
                                )}
                                {state.hasActivatedMedia && (
                                    <div
                                        className="virtualBackgroundWrap"
                                        onClick={(e) => {
                                            setIsClicked(true);
                                        }}
                                        style={{
                                            display: isClicked ? "none" : "auto"
                                        }}
                                    >
                                        <p>
                                            1. Please leave TEAMS or ZOOM now{" "}
                                            <br />
                                            2. Virtual backgrounds are
                                            EXPERIMENTAL. <br /> Please send
                                            feedback. <br />
                                            (click to dismiss)
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="input-wrap">
                                <label>
                                    Audio input:
                                    <TooltipMui
                                        arrow
                                        placement="right-start"
                                        className="tooltip-testyourmic"
                                        title={
                                            <React.Fragment>
                                                <StyledP>
                                                    Test your mic, just speak.{" "}
                                                    <br />
                                                    If you don't see the slide
                                                    in the green Mic icon above
                                                    moving try changing your
                                                    Audio input.
                                                </StyledP>
                                            </React.Fragment>
                                        }
                                    >
                                        <HelpIcon className="help" />
                                    </TooltipMui>
                                </label>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="audioInputSelect"
                                    value={state.currentAudioInputLabel}
                                    onChange={(e) => {
                                        audioInputChange(e);
                                    }}
                                >
                                    {state.possibleAudioInput.length > 0 &&
                                        state.possibleAudioInput.map(
                                            (audioInputItem, index) => (
                                                <MenuItem
                                                    value={audioInputItem.label}
                                                    key={index}
                                                >
                                                    {audioInputItem.label}
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                                {errorState.audioDeviceUsed && (
                                    <p className="noticeText">
                                        You either are not giving permission to
                                        the microphone to be used, or your
                                        microphone is used by another
                                        application, so please close that other
                                        application.
                                    </p>
                                )}
                            </div>
                            {state.possibleAudioOutput.length > 0 && (
                                <div className="input-wrap">
                                    <label>Audio output:</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="audioOutputSelect"
                                        value={state.currentAudioOutputLabel}
                                        onChange={(e) => {
                                            audioOutputChange(e);
                                        }}
                                    >
                                        {state.possibleAudioOutput &&
                                            state.possibleAudioOutput.length >
                                                0 &&
                                            state.possibleAudioOutput.map(
                                                (audioOutputElement, index) => (
                                                    <MenuItem
                                                        value={
                                                            audioOutputElement.label
                                                        }
                                                        key={index}
                                                    >
                                                        {
                                                            audioOutputElement.label
                                                        }
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                    <div className="language-wrap">
                                        <div className="language-tooltip-wrap">
                                            <p
                                                className="guess-language"
                                                onClick={(e) => {
                                                    checkSpeaker();
                                                }}
                                            >
                                                Test your speaker
                                            </p>
                                            <TooltipMui
                                                arrow
                                                placement="right-start"
                                                className="tooltip-language"
                                                title={
                                                    <React.Fragment>
                                                        <StyledP>
                                                            If you don't hear
                                                            the sound try
                                                            changing your Audio
                                                            output. You could
                                                            contribute by
                                                            recording "Hello,
                                                            how are you?" in a
                                                            language you know in
                                                            your mobile phone
                                                            and sending the file
                                                            to us.
                                                        </StyledP>
                                                    </React.Fragment>
                                                }
                                            >
                                                <HelpIcon className="help" />
                                            </TooltipMui>
                                        </div>
                                        <p className="language-name">
                                            {" "}
                                            {state.temporarySelectedKeys
                                                .length > 0
                                                ? state.languageName
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="input-wrap">
                                <label>Video:</label>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="videoInputSelect"
                                    value={state.currentVideoLabel}
                                    onChange={(e) => {
                                        console.log(e);
                                        videoInputChange(e);
                                    }}
                                >
                                    {state.possibleVideoInput &&
                                        state.possibleVideoInput.length > 0 &&
                                        state.possibleVideoInput.map(
                                            (videoInputItem, index) => (
                                                <MenuItem
                                                    value={videoInputItem.label}
                                                    key={index}
                                                >
                                                    {videoInputItem.label}
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                                {errorState.videoDeviceUsed && (
                                    <p className="noticeText">
                                        You either are not giving permission to
                                        the camera to be used, or your camera is
                                        used by another application, so please
                                        close that other application.
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="confirmButton">
                        <NextButton
                            className="next-button"
                            disabled={
                                !state.hasActivatedMedia ||
                                errorState.audioDeviceUsed ||
                                alreadyClickedEnter
                            }
                            popup={true}
                            title="Confirm"
                            tooltipText="Confirm your settings."
                            onClickEvent={() => {
                                setAlreadyClickedEnter(true);
                                if (!errorState.isError) {
                                    enterMeetup();
                                    if (props.changeMediaDevices) {
                                        props.changeMediaDevices();
                                        console.log("changing devices");
                                    }
                                } else {
                                    closeModal();
                                }
                            }}
                        />
                    </div>
                </div>
            </ModalWrap>
        </React.Fragment>
    );
};

export default withRouter(UserMediaDevices);
