/* Requirements;

Total number of events	56				live		Skill_db	meetup
Total events in the past	45				live		Skill_db	meetup
Total planned events for the future	9				live		Skill_db	meetup

Events running now	2				live		Skill_db	meetup	list
<Name of event 1 running now>	<URL including ID of event 1>	<Total unique users in event 1>	<Number of current users in event 1>	<Total matches in event 1>	live
<Name of event 2 running now>	<URL including ID of event 2>	<Total unique users in event 2>	<Number of current users in event 2>	<Total matches in event 2>	live
Events that ran today	1				live		Skill_db	meetup	list
<Name of event 1 that ran today>	<URL including ID of event 1>	<Total unique users in event 1>	<Number of current users in event 1>	<Total matches in event 1>	live
Events that ran yesterday	3				cron job		Skill_db	meetup	list
<Name of event 1 that ran yesterday>	<URL including ID of event 1>	<Total unique users in event 1>	<Number of current users in event 1>	<Total matches in event 1>	cron job
<Name of event 2 that ran yesterday>	<URL including ID of event 2>	<Total unique users in event 2>	<Number of current users in event 2>	<Total matches in event 2>	cron job
<Name of event 3 that ran yesterday>	<URL including ID of event 3>	<Total unique users in event 3>	<Number of current users in event 3>	<Total matches in event 3>	cron job


Top 10 events by number of users

*/

import React from 'react';
import { DataTable } from './datatable';
import './styles.css';
import { CONF } from "../config/config";

export const dashboardUser = [
    "jesus.delvalle",
    "alen.mestrov.ext",
    "amestrov",
    "oliver.woischnik",
    "sandra.saliger",
    "jan-moritz.vonberg",
];

const username = (localStorage.getItem('username') || '').split('@')[0];

const url = CONF.BAYRONNECT_API_URL + '/api/statistics';
const statisticTypes = [
    { name: 'Overview', item: 'summary' },
    { name: 'Dev stats', item: 'dev-stats' },
    { name: 'Events completed', item: 'events' },
    { name: 'Events today', item: 'today' },
    { name: 'My Meetups', item: 'private' }
    //      {name: 'Data Info', item: 'info'}
];
const statisticForUser = [
    { name: 'My Meetups', item: 'private' },
    { name: 'Events completed', item: 'events' }
];

class StatisticPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Result: {
                userCount: 0,
                meetupCount: 0,
                matchCount: 0,
                meetupCountPast: 0,
                meetupCountFuture: 0,
                meetupsWithMatches: 0,
            },
            meetupsPast: [],
            meetupsFuture: [],
            meetupsToday: [],
            meetupsUser: [],
            devStats: {
                users: [],
                meetups: [],
                matches: [],
                userMatches: [],
                matchStats: {}
            }
        };

        if (dashboardUser.includes(username)) {
            this.state.statisticType = 'summary'
        } else {
            this.state.statisticType = 'private'
        }

    };

    componentDidMount() {
        this.getData(url, 'summary');
        this.getData(url, 'events');
        this.getData(url, 'private');
    }

    async getData(url, param) {
        const jwtToken = localStorage.getItem('jwtToken');
        const urlLong = url + '?statisticType=' + param + '&jwtToken=' + jwtToken;
        await fetch(urlLong)
            .then((response) => {
                return response.json();
            })
            .then(data => {
                if (data.StatisticType) {
                    if (param === 'events') {
                        this.setState({ Result: data.Result.numbers });
                        this.setState({ meetupsPast: data.Result.meetupsPast });
                    } else if (param === 'dev-stats') {
                        this.setState({ devStats: data.Result })
                    } else if (param === 'today') {
                        this.setState({ meetupsToday: data.Result.meetupsToday });
                    } else if (param === 'private') {
                        this.setState({ meetupsUser: data.Result.meetupsUser });
                    } else {
                        this.setState({ Result: data.Result.numbers });
                        this.setState({ meetupsFuture: data.Result.meetupsFuture });
                    }
                }
            })
    };

    handleNavEvent(e = '') {
        this.setState({ statisticType: e });
        if (e === 'today') {
            this.getData(url, e);
        }
        if (e === 'private') {
            this.getData(url, e);
        }
        if (e === 'dev-stats') {
            this.getData(url, e);
        }
    }

    render() {
        //  const myTitle = 'ByronMeet Statistics ';
        //  const myLogo = './Artboard.svg';
        //  const username = (localStorage.getItem('username') || '').split('@')[0];
        /*  if (!dashboardUser.includes(username)) {
            return  (
              <div id="top">
                <h1>Access is not allowed</h1>
              </div>
              );
          }
        */
        let navLinks;

        if (dashboardUser.includes(username)) {
            navLinks = statisticTypes.map(item => {
                return (
                    <li id="item" key={item.item} onClick={() => this.handleNavEvent(item.item)} >
                        {item.name}
                    </li>
                )
            });
        } else {
            navLinks = statisticForUser.map(item => {
                return (
                    <li id="item" key={item.item} onClick={() => this.handleNavEvent(item.item)} >
                        {item.name}
                    </li>
                )
            });
        }

        return (
            <div id="top">
                <nav style={this.props.style}>
                    <ul>
                        <li><p style={{ fontWeight: 'bold' }}>Menu</p></li>
                        {navLinks}
                    </ul>
                </nav>
                <DataTable statisticType={this.state.statisticType} statisticData={this.state} />
            </div>
        );
    }
}

export default StatisticPage;

