import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../components/general/styles";
import ChatRoundedIcon from "@material-ui/icons/ChatRounded";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";

const ChatContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 9999;

    @media only screen and (orientation: landscape) and (max-height: 500px) {
        top: 10px;
    }

    .MuiButtonBase-root {
        background: white !important;
        background-color: white !important;
    }
    * {
        box-sizing: border-box;
    }
    --background-color: #a5bfd4;
    --header-color: #6a96d8;
    --send-color: #e6edf6;
    --received-color: #f2f2f2;
    --blocked-color: #ffc1c1;
    --light-gray: #dfdfdf;
    --dark-gray: #858a91;

    .MuiButtonBase-root {
        background: white;
        margin-right: 20px;
        position: fixed;
        bottom: 20px;
        right: 10px;
    }

    .btn {
        opacity: 0.7;
        cursor: pointer;
        font-size: 18px;
        border: 1px solid ${colors.red};
        border-radius: 4px;
        // color: ${colors.red};
        padding: 5px 10px;
        text-transform: uppercase;
    }

    .btn:hover {
        opacity: 1;
    }

    .main-container {
        max-width: 400px;
        min-width: 400px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px;
        position: relative;
        z-index: 100;

        @media only screen and (orientation: portrait) and (max-width: 420px) {
            min-width: 250px;
        }

        @media only screen and (orientation: landscape) and (max-height: 500px) {
            border: 2px solid white;
        }
    }

    .head-container {
        width: 100%;
        padding: 10px 15px;
        box-sizing: border-box;
        background-color: ${colors.red};
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .head-container h1 {
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 1px;
    }

    .head-container img {
        width: 30px;
    }

    .head-container a {
        text-decoration: none;
    }

    .message-container {
        max-height: calc(100vh - 350px);
        min-height: calc(100vh - 350px);
        //display: flex;
        //flex-direction: column;
        overflow-y: auto;
        padding: 25px 25px 0 25px;
        justify-content: flex-end;

        @media only screen and (orientation: landscape) and (max-height: 500px) {
            max-height: calc(100vh - 250px);
            min-height: calc(100vh - 250px);
        }
    }

    .message-container h3 {
        color: var(--light-gray);
        border-bottom: 1px solid var(--light-gray);
        text-align: center;
        line-height: 0.1rem;
        margin: 10px 0 30px;
        text-transform: uppercase;
        font-size: 1rem;
    }

    .message-container h3 span {
        padding: 0 15px;
        background-color: #fff;
    }

    .message-container div.sent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .chat-message-wrap-received {
        width: 100%;
        display: flex;
        flex-direction: row !important;
        justify-content: flex-end !important;
        align-items: center !important;

        p {
            margin-right: 10px;
            word-wrap: break-word;
            word-break: break-word;
        }
    }
    .chat-message-wrap-sent {
        width: 100%;
        display: flex;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center !important;

        p {
            margin-left: 10px;
        }
    }

    .message-container div:not(.sent) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .message-container .hour {
        color: var(--dark-gray);
        margin: 0;
    }

    .message-container p {
        position: relative;
    }

    /* BUBBLE ARROW SENT */
    .sent p:before {
        content: "";
        position: absolute;
        top: 0;
        left: -6px;
        width: 0px;
        height: 0px;
        border-top: 0px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 10px solid var(--send-color);
    }

    /* BUBBLE ARROW RECEIVED */
    .received p:before {
        content: "";
        position: absolute;
        top: 0;
        right: -6px;
        width: 0px;
        height: 0px;
        border-top: 0px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 10px solid var(--received-color);
    }

    .sent-bubble {
        background-color: var(--send-color);
    }

    .received-bubble {
        background-color: var(--received-color);
    }

    .blocked-bubble {
        background-color: var(--blocked-color);
    }

    .sent-bubble,
    .received-bubble,
    .blocked-bubble {
        max-width: calc(100% - 40px);
        padding: 10px;
        // margin: 10px 0 20px;
        text-align: left;
    }

    .sent-bubble {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
    }

    .received-bubble {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .blocked-bubble {
        border-radius: 3px;
    }

    .input-container {
        border-top: 1px solid var(--light-gray);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;

        form {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    .input-container input {
        width: 100%;
        outline: none;
        border: none;
        padding-right: 15px;
        font-size: 1rem;
    }

    .input-container a {
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
        color: var(--header-color);
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-left: 20px;
    }

    .chatBtn {
        z-index: 10;
    }
`;

const ChatRoom = (props) => {
    const [message, setMessage] = useState("");
    const [chatMessage, setChatMessage] = useState([]);
    const [displayMessage, setDisplayMessage] = useState([]);

    const [open, setOpen] = useState(false);
    const messagesEndRef = React.useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const socket = props.socket;
    const meetupId = props.meetupId ? props.meetupId : false;
    const socketRoomId = props.socketRoomId;

    useEffect(() => {
        if (socket && meetupId !== false) {
            socket.on("meetupLobbyChat", (msg) => {
                let newMessage;
                const now = new Date();
                const time =
                    now.getHours() +
                    ":" +
                    (now.getMinutes() < 10 ? "0" : "") +
                    now.getMinutes();
                if (msg.type === "received") {
                    newMessage = {
                        type: "received",
                        message: msg.message,
                        time: time,
                        user: msg.user,
                        username: msg.userName
                    };
                } else {
                    newMessage = {
                        type: "sent",
                        message: msg.message,
                        time: time,
                        user: msg.user,
                        username: msg.userName
                    };
                }
                setOpen(true);
                setChatMessage((prevMessage) => [...prevMessage, newMessage]);
            });
            socket.emit("chatReady", {
                meetupId: meetupId,
                jwtToken: localStorage.getItem("jwtToken")
            });

            return function cleanup() {
                socket
                    .removeListener("meetupLobbyChat")
                    .on("meetupLobbyChat", () =>
                        console.log("removing meetupLobbyChat socket listener")
                    );
                socket
                    .removeListener("chatReady")
                    .on("chatReady", () =>
                        console.log("removing chatReady socket listener")
                    );
            };
        }
    }, [meetupId]);

    useEffect(() => {
        if (socket && socketRoomId !== false) {
            socket.on("matchChatMessage", (msg) => {
                let newMessage;
                const now = new Date();
                const time =
                    now.getHours() +
                    ":" +
                    (now.getMinutes() < 10 ? "0" : "") +
                    now.getMinutes();
                if (msg.type === "received") {
                    newMessage = {
                        type: "received",
                        message: msg.message,
                        time: time,
                        user: msg.user,
                        username: msg.userName
                    };
                } else {
                    newMessage = {
                        type: "sent",
                        message: msg.message,
                        time: time,
                        user: msg.user,
                        username: msg.userName
                    };
                }
                setOpen(true);
                setChatMessage((prevMessage) => [...prevMessage, newMessage]);
            });
        }
        return function cleanup() {
            if (socket) {
                socket
                    .removeListener("matchChatMessage")
                    .on("matchChatMessage", () =>
                        console.log("removing matchChatMessage socket listener")
                    );
            }
        };
    }, [socketRoomId]);

    useEffect(() => {
        setDisplayMessage(chatMessage);
    }, [chatMessage]);

    useEffect(() => {
        scrollToBottom();
    }, [displayMessage]);

    const sendMessage = (e) => {
        e.preventDefault();
        setMessage("");
        if (meetupId !== false) {
            socket.emit("meetupLobbyChat", {
                message: message,
                meetupId: meetupId,
                jwtToken: localStorage.getItem("jwtToken"),
                time: new Date()
            });
        } else {
            socket.emit("matchChatMessage", {
                message: message,
                jwtToken: localStorage.getItem("jwtToken"),
                socketRoomId: socketRoomId,
                time: new Date()
            });
        }
    };

    return (
        <ChatContainer>
            <div
                className="main-container"
                style={{ display: open ? "block" : "none" }}
            >
                <div className="head-container">
                    <h1>Chat room</h1>
                    <div className="btn" onClick={handleClose}>
                        <CloseIcon style={{ fontSize: "30px" }} />
                    </div>
                </div>

                <div className="message-container">
                    {displayMessage.map((value, index) => {
                        return value.type === "sent" ? (
                            <div className="received" key={index}>
                                <h5 className="hour">
                                    {value.time} <span>{value.user}</span>
                                </h5>
                                <div className="chat-message-wrap-received">
                                    <p className="received-bubble">
                                        {value.message}
                                    </p>
                                    <Avatar
                                        alt={value.user}
                                        src={`https://bayron.intranet.cnb/images/${value.username}/profile`}
                                        //src={value.username}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="sent" key={index}>
                                <h5 className="hour">
                                    {value.time} <span>{value.user}</span>
                                </h5>
                                <div className="chat-message-wrap-sent">
                                    <Avatar
                                        alt={value.user}
                                        src={`https://bayron.intranet.cnb/images/${value.username}/profile`}
                                        //src={value.username}
                                    />
                                    <p className="received-bubble">
                                        {value.message}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                    <div ref={messagesEndRef} />
                </div>

                <div className="input-container">
                    <form onSubmit={sendMessage}>
                        <input
                            type="text"
                            placeholder="Enter your message"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                        <button className="btn" type="submit">
                            Send
                        </button>
                    </form>
                </div>
            </div>
            <IconButton
                className={"chatBtn"}
                onClick={handleOpen}
                aria-label="chat"
            >
                <ChatRoundedIcon />
            </IconButton>
        </ChatContainer>
    );
};

export default ChatRoom;
