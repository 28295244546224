import { InfoTooltip } from "../infoTooltip";
import React from "react";
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutocompleteComp = styled(Autocomplete)`
    width: 100%;

    .MuiInputBase-root {
        border: none;

        :focus {
            border: none;
        }

        :hover {
            border: none !important;
        }
    }

    input:focus {
        border: none;
    }

    .MuiAutocomplete-tag {
        border-radius: 3px;
        background-color: #d8e7ef;
        padding-left: 8.5px;
        padding-top: 9px;
        padding-bottom: 9px;

        span {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #11394f;
        }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #11394f !important;
    }
`;

export const AutocompleteInput = (props) => {
    return (
        <div className="input-wrap">
            <label>
                {props.label}
                {props.tooltipText && (
                    <InfoTooltip tooltipText={props.tooltipText} />
                )}
            </label>
            <AutocompleteComp
                multiple
                options={props.autocompleteValues}
                defaultValue={props.defaultValue}
                onInputChange={props.onInputChange}
                onChange={props.onChange}
                renderInput={props.renderInput}
            />
        </div>
    );
};
