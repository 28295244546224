import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import TextField from "@material-ui/core/TextField";

import styled from "styled-components";
import NextButton from "../general/nextButton";
import { CONF } from "../../config/config";

const StyledDialog = styled(Dialog)`
    svg {
        font-size: 60px;
        fill: rgb(196, 196, 196);
        transition: all 0.3s;
    }

    .Mui-focused {
        color: #d30f4b !important;
        .MuiOutlinedInput-notchedOutline {
            border-color: #d30f4b !important;
        }
    }

    textarea {
        color: black !important;
    }

    .MuiInput-underline:after {
        border-bottom: 2px solid #d30f4b;
    }

    .survey-btns {
        display: flex;
        padding-top: 20px;

        button {
            margin: 10px;
        }
    }

    .MuiDialogContent-root {
        padding-bottom: 20px;
    }

    .MuiTypography-root {
        display: flex;
        justify-content: center;
    }

    .active {
        svg {
            fill: #d30f4b;
        }
    }
`;

const StyledTextField = styled(TextField)`
    width: 100%;
`;

export default function SurveyModal(props) {
    const [surveyRating, setSurveyRating] = useState("");
    const [surveyDescription, setSurveyDescription] = useState("");
    const [isSurveyOver, setIsSurveyOver] = useState(false);

    useEffect(() => {
        return () => {
            console.log("surveymodal unmount");
        };
    }, []);

    const handleSubmit = () => {
        fetch(`${CONF.BAYRONNECT_API_URL}/api/setSurveyRating`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                surveyRating: surveyRating,
                surveyDescription: surveyDescription,
                meetupId: props.meetup.id,
                meetupName: props.meetup.name,
                matchId: props.matchData.matchIdDB,
                partnerEmail: props.matchData.partnerInfo.email
            }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        // .then(response => {
        //   return response.json();
        // })
        // .then(data => {
        //   console.log(data);

        //   if (data.status) {
        //     console.log(data);
        //     if (data.status === "1") {
        //       setIsSurveyOver(true);
        //       handleClose()
        //     }
        //   }
        // });
        setIsSurveyOver(true);
        handleClose();
    };

    const handleClose = () => {
        localStorage.removeItem("surveyData");
        props.setIsSurveyModal(false);
        // setSurveyRating("");
        // setSurveyDescription("");
        // setIsSurveyOver(false);
        // console.log(props);
    };
    const handleChange = (event) => {
        setSurveyDescription(event.target.value);
    };
    return (
        <StyledDialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!isSurveyOver ? (
                <>
                    <DialogTitle id="alert-dialog-title">
                        {"How was your meetup experience?"}
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <IconButton
                                onClick={() => setSurveyRating("sad")}
                                className={surveyRating === "sad" ? "sad" : ""}
                            >
                                <SentimentVeryDissatisfiedIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => setSurveyRating("neutral")}
                                className={
                                    surveyRating === "neutral" ? "neutral" : ""
                                }
                            >
                                <SentimentDissatisfiedIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => setSurveyRating("happy")}
                                className={
                                    surveyRating === "happy" ? "active" : ""
                                }
                            >
                                <SentimentSatisfiedAltIcon />
                            </IconButton>
                        </DialogContentText>

                        {surveyRating !== "" && (
                            <StyledTextField
                                id="standard-multiline-static"
                                label="Describe your experience"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={surveyDescription}
                                onChange={handleChange}
                            />
                        )}
                        <div className="survey-btns">
                            <NextButton
                                className="next-button"
                                title="Close"
                                onClickEvent={() => handleClose()}
                            />
                            <NextButton
                                className="next-button"
                                title="Submit"
                                onClickEvent={() => handleSubmit()}
                                disabled={surveyRating === ""}
                                tooltipText="Please select a rating before submiting"
                            />
                        </div>
                    </DialogContent>
                </>
            ) : (
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        {"Thank you for your response :)"}
                    </DialogTitle>
                </DialogContent>
            )}
        </StyledDialog>
    );
}
