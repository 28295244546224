import { InfoTooltip } from "../infoTooltip";
import { EventTypeDropdown } from "../eventTypeDropdown";
import { DropdownItem } from "../dropdownItem";
import React from "react";
import { InternalityDropdown } from "../internalityDropdown";

export const DropdownInput = (props) => {
    return (
        <div className="input-wrap">
            <label>
                {props.label}
                {props.tooltipText && (
                    <InfoTooltip tooltipText={props.tooltipText} />
                )}
            </label>
            {props.dropdownType === "event" && (
                <EventTypeDropdown
                    setEventType={props.setEventType}
                    eventType={props.eventType}
                    setExpand={props.expand}
                />
            )}
            {props.dropdownType === "duration" && (
                <DropdownItem
                    setDuration={props.setDuration}
                    duration={props.duration}
                />
            )}
            {props.dropdownType === "internality" && (
                <InternalityDropdown
                    setInternality={props.setInternality}
                    internality={props.internality}
                />
            )}
        </div>
    );
};
