import React, { useRef, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import Lobby from "../components/lobby/lobby";
import PermissionComponent from "../components/lobby/permissionComponent";
import Match from "../components/lobby/match";

import ChatRoom from "../components/lobby/chatRoom";
import LocalStream from "../components/lobby/localStream";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import CountdownModal from "../components/lobby/countdownModal";
import DebateOrFriendsMeetupModal from "../components/meetups/debateOrFriendsMeetup";
import Footer from "../components/general/footer";
import { useEffect } from "react";
import UserMediaDevices from "../components/general/userMediaDevices";
// import MeetupFinishedPage from "../pages/meetupFinished";
import Conference from "../components/lobby/conference";
import SurveyModal from "../components/lobby/surveyModal";

const LobbyContainerStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 15px 0 15px;
    box-sizing: border-box;
    margin: auto 0;
    align-items: center;
    padding: 60px 0 100px 0;
    position: relative;

    @media screen and (max-width: 1024px) {
        margin-top: 80px;
    }

    .back-button {
        margin-top: 30px;
        cursor: pointer;
        position: absolute;
        left: -15px;
        right: 0;
        width: 100vw;
        bottom: -90px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        button {
            display: flex;
            justify-content: center;
        }
    }

    & > * {
        width: 50% !important;
        /* width: 100% !important; */
        height: 100%;
        margin: 0 15px;
        max-width: 1000px;

        @media screen and (max-width: 1024px) {
            height: 100%;
            width: 100% !important;
            max-width: 100% !important;
            min-height: 0px;
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0 15px 60px 15px;
        margin-top: 80px;
    }
`;

const StyledFooter = styled(Footer)`
    margin-top: 30px;
`;

const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexGrow: "1"
};

const LobbyPage = (props) => {
    let { meetupId } = useParams();
    const tempVideoRef = useRef(null);

    const [videoId, setVideoId] = useState(
        localStorage.getItem("currentVideoId") !== "false"
            ? { deviceId: localStorage.getItem("currentVideoId") }
            : false
    );
    const audioId = localStorage.getItem("currentAudioInputId")
        ? { deviceId: localStorage.getItem("currentAudioInputId") }
        : false;
    const [stream, setStream] = useState(null);

    const [showUserMedia, setShowUserMedia] = useState(false);
    const location = props.location;
    const [displayComponent, setDisplayComponent] = useState({
        lobby: false,
        permission: false,
        match: false,
        conference: false
    });
    const [matchData, setMatchData] = useState({});
    const [currentPage, setCurrentPage] = useState("");

    const [showLocalStream, setShowLocalStream] = useState(false);
    const [changeVideo, setChangeVideo] = useState(false);
    const [quitButtonClicked, setQuitButtonClicked] = useState(false);

    const [tempStream, setTempStream] = useState(null);

    const [sharingScreen, setSharingScreen] = useState(false);
    const [tempCaptureStream, setTempCaptureStream] = useState(false);

    const [roomId, setRoomId] = useState(
        props.location.state.meetup.id + "conference"
    );
    const [isCreator, setIsCreator] = useState(
        localStorage.getItem("userId") === props.location.state.meetup.creator
    );

    const [isSurveyModal, setIsSurveyModal] = useState(false);

    if (!props.location.state || !props.location.state.meetup) {
        window.location.href =
            window._env_.REACT_APP_BAYRONNECT_URL + "/" + meetupId;
    }

    const socket = props.socket;
    const [meetup, setMeetup] = useState(props.location.state.meetup);
    const debateOrFriendsMeetup =
        meetup.isDebateMeetup || meetup.isFriendsMeetup ? true : false;

    useEffect(() => {
        getMediaDevices();

        if (!socket) {
            window.location.href = window._env_.REACT_APP_BAYRONNECT_URL;
        }
    }, []);

    useEffect(() => {
        setCurrentPage("lobby");
        if (socket) {
            if (meetup.conferenceStarted) {
                setCurrentPage("conference");
            }
            socket.on("conferenceStarted", async () => {
                setCurrentPage("conference");
            });
            socket.on("conferenceEnded", async () => {
                setCurrentPage("lobby");
            });

            //used to singal if meetup has changed from normal to conference and vice versa (at least for now)
            socket.on("updateMeetupStatus", async (msg) => {
                console.log("UPDATE MEETUP STATUS: ", msg.meetup);
                setMeetup(msg.meetup);
            });
        }

        return () => {
            if (socket) {
                socket
                    .removeListener("conferenceStarted")
                    .on("conferenceStarted", () => {});
                socket
                    .removeListener("conferenceJoin")
                    .on("conferenceJoin", () => {});
                socket
                    .removeListener("updateMeetupStatus")
                    .on("updateMeetupStatus", () => {});
            }
        };
    }, []);

    useEffect(() => {
        if (matchData.socketRoomId) {
            socket.emit("screenSharing", {
                screenSharing: sharingScreen,
                socketRoomId: matchData.socketRoomId
            });
        }
    }, [sharingScreen]);

    useEffect(() => {
        setDisplayComponent({
            lobby: currentPage === "lobby",
            permission: currentPage === "permission",
            match: currentPage === "match",
            conference: currentPage === "conference"
        });
    }, [currentPage]);

    useEffect(() => {
        if (stream) {
            setShowLocalStream(true);
            let flag = false;
            stream.getTracks().forEach(function (track) {
                if (track.kind === "video") {
                    flag = true;
                }
            });
            if (!flag) {
                setTempStream(stream);
            }
        }
    }, [stream]);

    //SELECT PREFERRED MEDIA DEVICES (IF ANY) AND START STREAM
    async function getMediaDevices() {
        let constraints = {
            audio: audioId,
            video: {
                width: { max: 1024, ideal: 640, min: 640 },
                height: { max: 768, ideal: 480, min: 480 },
                ...videoId
            }
        };

        if (localStorage.getItem("currentVideoId") === "false") {
            constraints = {
                audio: audioId,
                video: false
            };
        }

        await navigator.mediaDevices.getUserMedia(constraints).then(
            (media) => {
                setStream(media);
            },
            (e) => {
                console.log("FIRED onVideoError function | matchPage");
                console.log("videoError error: " + e.name + +" | matchPage");
                if (
                    e.name === "NotAllowedError" ||
                    e.name === "NotReadableError"
                ) {
                    const answer = window.confirm(
                        'We noticed issues with your audio or video input. \n\n If you want to continue anyway, e.g. by messaging, click "OK". \n\n Otherwise click "Cancel" to fix your audio and video inputs.'
                    );
                    console.log("videoError confirm screen answer: ", answer);
                    if (!answer) {
                        document.cookie =
                            "inputPermissions=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
                    }
                }
            }
        );
    }

    async function activateScreenRecording() {
        let captureStream = null;

        if (sharingScreen) {
            tempCaptureStream.getTracks().forEach(function (track) {
                track.stop();
            });
            getMediaDevices();
            setChangeVideo(!changeVideo);
            setSharingScreen(false);
            return;
        }

        try {
            captureStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true
            });
            setTempCaptureStream(captureStream);
            setSharingScreen(true);

            let tracks = stream.getTracks();

            tracks.forEach((track) => {
                if (track.kind === "video") {
                    stream.removeTrack(track);
                    stream.addTrack(captureStream.getVideoTracks()[0]);
                }
            });
            setStream(stream);
            setChangeVideo(!changeVideo);
            console.log(captureStream);
        } catch (err) {
            console.error("Error on screen recording: " + err);
        }
    }

    async function changeMediaDevices() {
        let constraints = {
            audio: { deviceId: localStorage.getItem("currentAudioInputId") },
            video: {
                width: { max: 1024, ideal: 640, min: 640 },
                height: { max: 768, ideal: 480, min: 480 },
                deviceId: localStorage.getItem("currentVideoId")
            }
        };

        if (localStorage.getItem("currentVideoId") === "false") {
            constraints = {
                audio: {
                    deviceId: localStorage.getItem("currentAudioInputId")
                },
                video: false
            };
        }

        if (stream) {
            stream.getTracks().forEach(function (track) {
                track.stop();
            });
        }

        await navigator.mediaDevices.getUserMedia(constraints).then((media) => {
            console.log("CHANGE MEDIA STREAM LOBBY: ", media);
            setStream(media);
        });
    }

    return (
        <React.Fragment>
            {isSurveyModal && (
                <SurveyModal
                    history={props.history}
                    matchData={matchData}
                    meetup={meetup}
                    isSurveyModal={isSurveyModal}
                    setIsSurveyModal={setIsSurveyModal}
                />
            )}
            <div style={pageStyle}>
                {debateOrFriendsMeetup && (
                    <DebateOrFriendsMeetupModal
                        isSurveyModal={isSurveyModal}
                        meetup={meetup}
                        socket={socket}
                    />
                )}
                {displayComponent.lobby && !debateOrFriendsMeetup && (
                    <CountdownModal
                        isSurveyModal={isSurveyModal}
                        socket={socket}
                        meetup={meetup}
                    />
                )}

                <LobbyContainerStyled>
                    {displayComponent.lobby && (
                        <Lobby
                            socket={socket}
                            history={props.history}
                            closeSocket={props.closeSocket}
                            meetup={meetup}
                            setMatchData={setMatchData}
                            setCurrentPage={setCurrentPage}
                            usersInLobby={
                                props.location.state.currentUsersInLobby
                            }
                        />
                    )}

                    {displayComponent.conference && (
                        <Conference
                            localStream={tempStream}
                            meetup={meetup}
                            socket={socket}
                        />
                    )}

                    {displayComponent.permission && (
                        <PermissionComponent
                            socket={socket}
                            location={location}
                            matchData={matchData}
                            setMatchData={setMatchData}
                            setCurrentPage={setCurrentPage}
                        />
                    )}

                    {displayComponent.match && (
                        <Match
                            socket={socket}
                            location={location}
                            changeVideo={changeVideo}
                            matchData={matchData}
                            setCurrentPage={setCurrentPage}
                            stream={tempStream}
                            quitButtonClicked={quitButtonClicked}
                            setQuitButtonClicked={setQuitButtonClicked}
                            setIsSurveyModal={setIsSurveyModal}
                            setMatchData={setMatchData}
                        />
                    )}

                    {showLocalStream && (
                        <LocalStream
                            socket={socket}
                            matchData={matchData}
                            meetup={meetup}
                            history={props.history}
                            tempStream={tempStream}
                            setTempStream={setTempStream}
                            location={location}
                            stream={stream}
                            currentPage={currentPage}
                            setShowUserMedia={setShowUserMedia}
                            sharingScreen={sharingScreen}
                            displayComponent={displayComponent}
                            setQuitButtonClicked={setQuitButtonClicked}
                            activateScreenRecording={activateScreenRecording}
                        />
                    )}

                    {showUserMedia && (
                        <UserMediaDevices
                            inMeetup={true}
                            changeMediaDevices={changeMediaDevices}
                            showUserMedia={showUserMedia}
                            setShowUserMedia={setShowUserMedia}
                        />
                    )}
                </LobbyContainerStyled>

                <StyledFooter />
                {!displayComponent.match && (
                    <ChatRoom
                        socket={socket}
                        history={props.history}
                        meetupId={meetup.id}
                        socketRoomId={false}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default withRouter(LobbyPage);
