import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Meetup from "../components/meetups/meetup";
import PrefContainer from "../components/preferences/prefContainer";
import Dialog from "@material-ui/core/Dialog";
import TuneIcon from "@material-ui/icons/Tune";
import MeetupForm from "../components/meetups/meetupForm";
import CloseIcon from "@material-ui/icons/Close";
import Footer from "../components/general/footer";
import NewsModal from "../components/general/newsModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { colors } from "../components/general/styles";
// import Cookies from 'universal-cookie';
//import Welcome from "../components/general/welcome";
import AdBanner from "../components/general/adBanner";
import SurveyModal from "../components/lobby/surveyModal";
// import { isLoggedIn, useAuth } from '../../auth/auth';
import Alert from "../components/general/alert";
import { CONF } from "../config/config";

const PageWrap = styled.div`
    width: 100%;
    height: 100%;
    min-width: 275px;

    .contentWrap {
        transition: max-height 500ms ease-in-out;
        width: 80%;
        margin: 0 auto 80px auto;

        .homePageHeader {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 20px;
            align-items: center;

            @media only screen and (orientation: portrait), (max-width: 768px) {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
            }

            @media only screen and (max-width: 1024px) {
                position: relative;
                margin-bottom: 20px;
                margin-top: 60px;
            }

            .btnDisabled {
                pointer-events: none;
                opacity: 0.6;
            }

            .filterWrap {
                display: flex;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;

                @media only screen and (max-width: 1024px) {
                    flex-wrap: wrap;
                }

                > .filter {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                    background-color: #dc1448;

                    padding: 12px 18px 12px 18px;
                    border-radius: 15px;
                    margin-top: 20px;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
                    font-weight: 400;
                    position: relative;
                    transition: all 0.1s linear;

                    & > .tooltip {
                        opacity: 0;
                        position: absolute;
                        bottom: calc(-5px);
                        left: 50%;
                        transform: translate(-50%, 100%) scale(0.9);
                        padding: 14px 20px;
                        width: 100%;
                        background-color: rgba(17, 57, 79, 1);
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
                        color: white;
                        border-radius: 8px;
                        font-size: 14px;
                        pointer-events: none;
                        transition: all 0.1s linear;
                        z-index: 1;
                    }

                    :hover {
                        background-color: #be1442;
                        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);

                        & > .tooltip {
                            opacity: 1;
                            transform: translate(-50%, 100%) scale(1);
                        }
                    }

                    @media only screen and (max-width: 560px) {
                        width: 100%;
                        justify-content: space-between;
                        font-size: 15px;
                        margin-right: 0;
                    }

                    @media only screen and (max-width: 1024px) {
                    }
                    input {
                        content: "";
                        width: 20px;
                        height: 20px;
                        margin-left: 10px;
                    }
                    input[type="checkbox"] {
                        accent-color: #123950;
                    }
                }
            }

            .meetup-options {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
            }

            .createMeetupContainer {
                width: fit-content;

                @media only screen and (max-width: 560px) {
                    width: 100%;
                    justify-content: space-between;
                    font-size: 15px;
                    margin-right: 0;
                }

                .createMeetup {
                    font-size: 18px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;
                    margin-right: 20px;
                    padding: 12px 18px;
                    border-radius: 15px;
                    box-sizing: border-box;
                    background-color: #dc1448;
                    border: none #dc1448;
                    cursor: pointer;

                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
                    font-weight: 400;
                    position: relative;
                    transition: all 0.1s linear;

                    :hover {
                        background-color: ${(props) =>
                            props.hollow ? "#e9e9e9" : "#BE1442"};
                        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
                    }

                    @media only screen and (max-width: 560px) {
                        width: 100%;
                        font-size: 15px;
                    }
                }

                @media only screen and (orientation: portrait),
                    (max-width: 732px) {
                    width: 100%;
                }
            }

            .meetUpText {
                display: flex;
                text-align: left;
                font-size: 32px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;
            }

            .generalPreferences {
                display: flex;
                margin-left: auto;
                margin-bottom: 20px;
                align-items: center;
                cursor: pointer;

                @media only screen and (orientation: portrait),
                    (max-width: 732px) {
                    align-items: right;
                    margin-top: 20px;
                }

                @media only screen and (orientation: portrait),
                    (max-width: 891px) {
                    //position: absolute;
                    //top: 0;
                    //right: 0;
                    //margin-top: 17px;
                }

                @media only screen and (max-width: 560px) {
                    position: initial;
                }

                .generalPreferencesText {
                    display: normal;
                }

                .preferencesIcon {
                    color: white;
                }

                span {
                    margin-left: 21px;
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;
                }
            }
        }

        .runningMeetupsContainer {
            margin-top: 75px;
            border-radius: 4px;
            display: flex;
            flex-wrap: wrap;
            margin-left: -12px;
            margin-right: -12px;

            @media only screen and (orientation: portrait), (max-width: 732px) {
                margin-top: 30px;
            }
        }

        .showMoreMeetups {
            margin-top: 56px;
            margin-bottom: 60px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            cursor: pointer;

            @media only screen and (orientation: portrait),
                (maxmax-width: 732px) {
                margin-top: 10px;
            }
        }

        @media only screen and (orientation: portrait), (max-width: 732px) {
            width: 90%;
        }
    }
`;
const HomePage = (props) => {
    const socket = props.socket;
    const [meetups, setMeetups] = useState([]);
    const [openNewMeetup, setOpenNewMeetup] = useState(false);
    const [openPreferences, setOpenPreferences] = useState(false);
    const [userLanguages, setUserLanguages] = useState(false);
    // let cookies = new Cookies();
    const [currentMeetupIndex, setCurrentMeetupIndex] = useState(8);
    const [hideShowMoreButton, setHideShowMoreButton] = useState(false);
    const [currentMeetupRow, setMeetupRow] = useState([]);
    const [showNews, setShowNews] = useState(false);
    const [isSurveyModal, setIsSurveyModal] = useState(false);
    const [filters, setFilters] = useState({
        bayronnect: true,
        face2face: true,
        online: true,
        hybrid: true
    });
    const [myCityFilter, setMyCityFilter] = useState(false);
    // const [textFilter, setTextFilter] = useState("");
    const [userCity, setUserCity] = useState(false);
    const [surveyData, setSurveyData] = useState(
        JSON.parse(localStorage.getItem("surveyData"))
    );
    const [tokenExpired, setTokenExpired] = useState(false);

    //const myInfo =  props.location.state.myInfo;

    // const [loggedIn, setLoggedIn] = useState(isLoggedIn);

    // Cleanup from previous meetups.
    // 29.08.2021: Safari on the Mac apparently does not set empty keys, so
    // localStorage.setItem('temporaryPreferences', ''); would essentially remove the key.
    // TODO +++ Should we better set
    // localStorage.setItem('temporaryPreferences', '');
    // inside of one of the useEffect() functions?
    // What happens if the user has not registered to Byron and lands directly in Bayronnect?

    useEffect(() => {
        localStorage.removeItem("temporaryPreferences");
        if (socket) {
            fetch(`${CONF.BAYRONNECT_API_URL}/api/initialize`, {
                method: "POST",
                body: JSON.stringify({
                    jwtToken: localStorage.getItem("jwtToken")
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.status) {
                        setTokenExpired(true);
                    } else {
                        let availableMeetups = [];
                        data.meetups.forEach((value) => {
                            // filters[value.eventType]
                            // if(value.participants.length > 0) {
                            //   if(value.participants.includes(localStorage.getItem('username'))) {
                            //     availableMeetups.push(value);
                            //   }
                            // }else {

                            // filter by user city
                            if (myCityFilter && userCity) {
                                if (
                                    !value.city ||
                                    value.city.toLowerCase() !==
                                        userCity.toLowerCase()
                                ) {
                                    return;
                                }
                            }

                            // filter by event type and name
                            if (!value.eventType || filters[value.eventType]) {
                                availableMeetups.push(value);
                                // if(!textFilter) {
                                //   availableMeetups.push(value);
                                // } else if (searchText(textFilter, value.name)) { // filter by event name
                                //   availableMeetups.push(value);
                                // }
                            }
                            // }
                        });
                        setMeetups(availableMeetups);
                        localStorage.setItem("userId", data.userId);
                        localStorage.setItem(
                            "myInfo",
                            JSON.stringify(data.myInfo)
                        );

                        setUserLanguages(data.userLanguages);
                        setUserCity(data.userCity);
                        // if (data.meetups.length <= currentMeetupIndex) {
                        //   setHideShowMoreButton(true);
                        // }
                    }
                });
        }
    }, [socket]);

    useEffect(() => {
        if (meetups.length) {
            setMeetupRow(meetups.slice(0, currentMeetupIndex));
            // setCurrentMeetupIndex(4);
        }
    }, [meetups]);

    //TODO CHECK STEP 2 (removed at the end userId as checker)
    useEffect(() => {
        if (socket) {
            socket.on("updateMeetups", (newMeetups) => {
                if (newMeetups) {
                    let availableMeetups = [];
                    let username;
                    if (CONF.OPS_MODE === "internal") {
                        username =
                            localStorage.getItem("username") + "@bayer.com";
                    } else {
                        username = localStorage.getItem("username");
                    }
                    newMeetups.meetups.forEach((value) => {
                        // filter by user city
                        if (myCityFilter && userCity) {
                            if (
                                !value.city ||
                                value.city.toLowerCase() !==
                                    userCity.toLowerCase()
                            ) {
                                return;
                            }
                        }

                        if (
                            value.eventType &&
                            !filters[
                                value.eventType
                            ] /*|| (textFilter && !searchText(textFilter, value.name))*/
                        ) {
                            return; // skip meetups that don't pass filter
                        }

                        if (value.participants.length > 0) {
                            if (value.participants.includes(username)) {
                                availableMeetups.push(value);
                            }
                        } else {
                            availableMeetups.push(value);
                        }
                    });
                    setMeetups(availableMeetups);
                    setMeetupRow(availableMeetups.slice(0, currentMeetupIndex));
                }
            });

            socket.on("updateConferenceStarted", (msg) => {
                let updatedMeetups = meetups.map((item) => {
                    console.log(meetups);
                    if (item.id === msg.meetup.id) {
                        return {
                            ...item,
                            conferenceStarted: msg.meetup.conferenceStarted
                        };
                    }
                    return item;
                });

                console.log(
                    updatedMeetups,
                    updatedMeetups.slice(0, currentMeetupIndex)
                );
                setMeetups(updatedMeetups);
                setMeetupRow(updatedMeetups.slice(0, currentMeetupIndex));
            });
        }
        return function cleanup() {
            if (socket) {
                socket
                    .removeListener("updateMeetups")
                    .on("updateMeetups", () => {});
                socket
                    .removeListener("updateConferenceStarted")
                    .on("updateConferenceStarted", () => {});
            }
        };
    }, [socket, meetups]);

    useEffect(() => {
        if (!localStorage.getItem("news")) {
            setShowNews(true);
        }

        if (surveyData) {
            setIsSurveyModal(true);
        }
    }, []);

    const openMeetupModal = () => {
        setOpenNewMeetup(true);
    };
    const closeMeetupModal = () => {
        setOpenNewMeetup(false);
    };

    const openPreferencesModal = () => {
        setOpenPreferences(true);
    };
    const closePreferencesModal = () => {
        setOpenPreferences(false);
    };

    const showMeetups = () => {
        setMeetupRow(meetups.slice(0, currentMeetupIndex + 4));
        setCurrentMeetupIndex(currentMeetupIndex + 4);
    };

    const filterEvents = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.checked
        });
    };

    // const textFilterEvents = (e) => {
    //     setTextFilter(e.target.value)
    // }

    // fuzzy text search
    // from: https://stackoverflow.com/a/16908326/7657853
    // const searchText = (search, text) => {
    //   search = search.toUpperCase();
    //   text = text.toUpperCase();

    //   var j = -1; // remembers position of last found character

    //   // consider each search character one at a time
    //   for (var i = 0; i < search.length; i++) {
    //       var l = search[i];
    //       if (l === ' ') continue;     // ignore spaces

    //       j = text.indexOf(l, j+1);     // search for character & update position
    //       if (j === -1) return false;  // if it's not found, exclude this item
    //   }
    //   return true;
    // }

    return (
        <div className="mainWrap">
            <PageWrap>
                <div className="contentWrap">
                    <div className="homePageHeader">
                        <div
                            className="generalPreferences"
                            onClick={openPreferencesModal}
                        >
                            <TuneIcon className="preferencesIcon" />
                            <span className="generalPreferencesText">
                                General preferences
                            </span>
                        </div>
                        <div className="meetup-options">
                            <div className="createMeetupContainer">
                                <div
                                    className={`createMeetup ${
                                        !currentMeetupRow
                                            ? "btnDisabled-not"
                                            : ""
                                    }`}
                                    onClick={openMeetupModal}
                                >
                                    + Create new event
                                </div>
                            </div>
                            {/* <h1 className="meetUpText">Meetups</h1> */}

                            {!CONF.IS_STANDALONE && (
                                <div className="filterWrap">
                                    <div className="filter">
                                        <span>Online Speed Networking</span>
                                        <input
                                            name="bayronnect"
                                            type="checkbox"
                                            checked={filters.bayronnect}
                                            onChange={filterEvents}
                                        />
                                        <div className="tooltip">
                                            This event is networking made quick
                                            and simple! With this event, you can
                                            connect different Byron users
                                            through quick chats. You can
                                            customize it further by adding a
                                            theme or question – making it a
                                            debate!
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <span>Face-to-face</span>
                                        <input
                                            name="face2face"
                                            type="checkbox"
                                            checked={filters.face2face}
                                            onChange={filterEvents}
                                        />
                                        <div className="tooltip">
                                            These are all your in person
                                            meetings and events. Add futher
                                            descriptions and details about your
                                            meeting to help everyone get there
                                            prepared!
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <span>Online</span>
                                        <input
                                            name="online"
                                            type="checkbox"
                                            checked={filters.online}
                                            onChange={filterEvents}
                                        />
                                        <div className="tooltip">
                                            You can't meet in person? Keep track
                                            of all your online meetings through
                                            all other platforms and help your
                                            colleagues prepare.
                                        </div>
                                    </div>
                                    <div
                                        className="filter"
                                        style={{
                                            display: userCity ? "flex" : "none"
                                        }}
                                    >
                                        <span>Hybrid</span>
                                        <input
                                            name="hybrid"
                                            type="checkbox"
                                            checked={filters.hybrid}
                                            onChange={filterEvents}
                                        />
                                        <div className="tooltip">
                                            Part of you are in the office and
                                            part of you online? No problem. With
                                            a hybrid meeting, you can all sync
                                            up and get the job done. Specify the
                                            theme and keywords and you've got
                                            yourself a meeting!
                                        </div>
                                    </div>

                                    {/* <div>
                <span>Online + Face 2 Face</span>
                <input
                  name="onlineface2face"
                  type="checkbox"
                  checked={filters.onlineface2face}
                  onChange={filterEvents} />
              </div> */}
                                    {/* <div>
                <span>Filter for keyword:</span>
                <input type="text" name="text-filter" value={textFilter} onChange={textFilterEvents} />
              </div> */}
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        {(CONF.OPS_MODE === "internal" ||
                            CONF.REACT_APP_NODE_ENV === "development") && (
                            <AdBanner className="AdContainer" />
                        )}
                    </div>

                    {!currentMeetupRow ? (
                        <CircularProgress
                            size={70}
                            style={{
                                color: colors.pink,
                                margin: "150px auto",
                                display: "block"
                            }}
                        />
                    ) : (
                        <div className="runningMeetupsContainer">
                            {currentMeetupRow.length > 0 &&
                                currentMeetupRow.map(
                                    (meetup, index) =>
                                        filters[meetup.eventType] && (
                                            <Meetup
                                                socket={socket}
                                                meetup={meetup}
                                                meetups={meetups}
                                                key={index}
                                                userLanguages={userLanguages}
                                                openPreferencesModal={
                                                    setOpenPreferences
                                                }
                                            />
                                        )
                                )}
                        </div>
                    )}
                    {!hideShowMoreButton &&
                    meetups.length > currentMeetupIndex ? (
                        <div className="showMoreMeetups" onClick={showMeetups}>
                            Show more
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <Dialog
                    open={openNewMeetup}
                    onClose={closeMeetupModal}
                    aria-labelledby="form-dialog-title"
                >
                    <div className="modalWrap">
                        <div className="newMeetupHeader">
                            <h3>Create event</h3>
                            <div
                                className="exitModal"
                                onClick={closeMeetupModal}
                            >
                                <CloseIcon className="closeModalIcon" />
                            </div>
                        </div>
                        <MeetupForm
                            meetups={meetups}
                            closeMeetupModal={closeMeetupModal}
                            socket={socket}
                        />
                    </div>
                </Dialog>
                <Dialog
                    open={openPreferences}
                    onClose={closePreferencesModal}
                    aria-labelledby="form-dialog-title"
                >
                    <div className="modalWrap">
                        <PrefContainer
                            socket={socket}
                            userLanguages={userLanguages}
                            setUserLanguages={setUserLanguages}
                            closeModal={closePreferencesModal}
                        />
                    </div>
                </Dialog>

                {/* the newsletter functionality does not work. hence.. disable for now. */}
                {/* {showNews && CONF.OPS_MODE === "external" ? (
                    <NewsModal news />
                ) : (
                    ""
                )} */}

                <Alert
                    visible={tokenExpired}
                    heading="Session expired!"
                    message="Please log in again."
                    button="OK"
                    onclick={() => {
                        localStorage.removeItem("jwtToken");
                        window.location.reload();
                    }}
                />
            </PageWrap>
            <Footer />
            {isSurveyModal && (
                <SurveyModal
                    history={props.history}
                    isSurveyModal={isSurveyModal}
                    setIsSurveyModal={setIsSurveyModal}
                    matchData={surveyData.matchData}
                    meetup={surveyData.meetup}
                />
            )}
        </div>
    );
};

export default withRouter(HomePage);
