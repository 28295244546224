import React from "react";
import styled from "styled-components";
import NextButton from "../general/nextButton";
// import { sendTopic } from "../../hooks/socketOperations";
import { colors } from "../general/styles";

/*
const StyledButton = styled.button`
  position: absolute;
  width: auto;
  padding: 12px 20px;
  bottom: 50%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: ${colors.red};
  color: white;
  z-index: 1500;
  border: none;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
`;
*/

const PopupComponent = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.7);

    .copyBtn {
        margin-bottom: 20px;

        svg {
            font-size: 30px;
        }
    }

    .inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        font-size: 1.5rem;
        padding: 50px;
        p {
            font-size: 1.1rem;
            font-weight: 500;
            margin: 10px;
            color: ${colors.darkGray};
        }
        .link {
            color: ${colors.pink};
            margin-bottom: 10px;
        }
        .icon {
            position: absolute;
            top: 15px;
            right: 15px;
            color: ${colors.darkGray};
        }
    }
`;

const ReconnectionPopup = (props) => {
    return (
        <PopupComponent>
            <div className="inner">
                <span className="icon">
                    <i
                        className="fa fa-times-circle"
                        onClick={() => {
                            props.closeModal();
                        }}
                    />
                </span>
                <p>Current connection state: {props.currentConnectionState}</p>
                <br />
                <p>
                    Some message depending on connection state (it can be go to
                    homepage exit VPN, refresh the page and try again for
                    example)
                </p>
                <NextButton
                    title="I got it!"
                    onClickEvent={() => {
                        props.closeModal();
                    }}
                />
            </div>
        </PopupComponent>
    );
};

export default ReconnectionPopup;
