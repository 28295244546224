import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import { CONF } from "../../config/config";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute!important",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        overflow: "hidden"
    },
    paper: {
        // backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3)
    }
}));
const StyledDiv = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
`;
const SkillsInner = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 100px 0px 15px;
    overflow-y: scroll;
    color: white;
`;

const StyledChip = styled(Chip)`
    margin-right: 10px;
    margin-bottom: 10px;
    background: white;
`;
const StyledParagraph = styled.p`
    font-size: 18px;
    font-weight: 500;
`;
const ModalSection = styled.div`
    &:last-of-type {
        margin-bottom: 15px;
    }
`;

const SkillsOverlay = ({ userId }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState([]);

    useEffect(() => {
        getUserSkills();
    }, []);

    const getUserSkills = async () => {
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getBayronTags`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                userId: userId
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setUserInfo(data);
            });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <StyledDiv>
            <IconButton onClick={handleOpen} aria-label="open info">
                <InfoIcon
                    style={{
                        color: "white",
                        fontSize: 40,
                        borderRadius: "50%",
                        background: "#D30F4B"
                    }}
                />
            </IconButton>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                container={() => document.getElementById("foreignVideoBox")}
            >
                <Fade in={open}>
                    <SkillsInner>
                        <IconButton
                            className={"closeInfoBtn"}
                            onClick={handleClose}
                            aria-label="cancel"
                        >
                            <CloseIcon
                                style={{
                                    color: "white",
                                    background: "#D30F4B",
                                    borderRadius: "50%",
                                    padding: "5px",
                                    fontSize: 30
                                }}
                            />
                        </IconButton>
                        {userInfo.skills && (
                            <ModalSection>
                                <StyledParagraph>Skills</StyledParagraph>
                                {userInfo.skills &&
                                    userInfo.skills
                                        .filter((t) => t.level !== 0)
                                        .map((skill) => (
                                            <StyledChip
                                                label={skill.skill}
                                                key={skill.skill}
                                            />
                                        ))}
                            </ModalSection>
                        )}
                        {userInfo.communities && (
                            <ModalSection>
                                <StyledParagraph>Communities</StyledParagraph>
                                {userInfo.communities &&
                                    userInfo.communities.map((community) => (
                                        <StyledChip
                                            label={community.community}
                                        />
                                    ))}
                            </ModalSection>
                        )}
                        {userInfo.organisations && (
                            <ModalSection>
                                <StyledParagraph>Organisations</StyledParagraph>
                                {userInfo.organisations &&
                                    userInfo.organisations.map((community) => (
                                        <StyledChip
                                            label={community.organisation}
                                        />
                                    ))}
                            </ModalSection>
                        )}
                        {userInfo.products && (
                            <ModalSection>
                                <StyledParagraph>Products</StyledParagraph>
                                {userInfo.products &&
                                    userInfo.products.map((community) => (
                                        <StyledChip label={community.product} />
                                    ))}
                            </ModalSection>
                        )}
                    </SkillsInner>
                </Fade>
            </Modal>
        </StyledDiv>
    );
};
export default SkillsOverlay;
