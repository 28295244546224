/** @format */

import React, { useState, useRef, useEffect } from "react";
// import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NextButton from "./nextButton";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { InputControls } from "./inputControls";
import { colors } from "./styles";
import TooltipMui from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const PermissionDialog = styled(Dialog)`
    .MuiDialogTitle-root {
        button {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .enterLobbyButton {
        margin: 30px auto 10px auto;
    }

    .MuiDialogContent-root {
        border-top: none;
    }

    .permission__title {
        font-weight: bold;
        font-size: 20px;
    }

    .permission__subtitle {
        margin-bottom: 50px;
    }
`;

const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const mediaPermissions = (props) => {
    const handleClickOpen = () => {
        props.setShowMediaPermissions(true);
    };
    const handleClose = () => {
        props.setShowMediaPermissions(false);
    };

    return (
        <PermissionDialog
            // onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.showMediaPermissions}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={handleClickOpen}
            />
            <DialogContent dividers>
                <Typography gutterBottom>
                    <span className="permission__title">
                        Allow ByronMeet to use your camera and microphone.
                    </span>
                    <span className="permission__subtitle">
                        {" "}
                        ByronMeet need access to your camera and microphone so
                        that other participants can see and hear you.
                    </span>
                </Typography>

                <NextButton
                    className="next-button"
                    title="Request permissions"
                    tooltipText="Request permissions"
                    onClickEvent={() => {
                        props.setShowMediaPermissions(false);
                        props.setRequestedPermissions(true);
                    }}
                />
            </DialogContent>
        </PermissionDialog>
    );
};

export default mediaPermissions;
