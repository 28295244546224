import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../general/styles";
import NextButton from "../general/nextButton";
import md5 from "md5";
import {
    addMeetupToDB,
    checkCurrentUsersInMeetup,
    updateMeetupInDB
} from "../../hooks/socketOperations";
import { createEvent } from "./calendarInvite";
import { dashboardUser } from "../../pages/statisticPage";
import Compressor from "compressorjs";
import TextField from "@material-ui/core/TextField";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import IconButton from "@material-ui/core/IconButton";
import { escapeHtml } from "../meetups/meetup";
import MsgReader from "@kenjiuno/msgreader";
import { InfoTooltip } from "./infoTooltip";
import { TextInput } from "./meetup-inputs/textInput";
import { TextareaInput } from "./meetup-inputs/textareaInput";
import { SwitchInput } from "./meetup-inputs/switchInput";
import { DropdownInput } from "./meetup-inputs/dropdownInput";
import { ExpandedInput } from "./meetup-inputs/expandedInput";
import { TimeInput } from "./meetup-inputs/timeInput";
import { FileDropInput } from "./meetup-inputs/fileDropInput";
import { AutocompleteInput } from "./meetup-inputs/autocompleteInput";
import { CalendarInput } from "./meetup-inputs/calendarInput";
import { AddressInput } from "./meetup-inputs/addressInput";
import { CONF } from "../../config/config";
import { max } from "date-fns";

import { checkWordLength, validateWebsiteUrl } from "../general/functions";

const ical = require("ical");

const InputComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .share-meeting {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        align-items: center;

        button {
            margin-left: 30px;
        }

        .link {
            color: ${colors.pink};
            margin-bottom: 20px;
        }
    }

    .meetup-btns {
        display: flex;
        margin-top: 30px;
        margin-bottom: 15px;

        button {
            margin: 0 15px;

            @media only screen and (orientation: portrait) {
                margin: 10px 15px;
            }
        }

        @media only screen and (orientation: portrait) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .photo-input {
        padding: 10px 0px !important;
    }

    .input-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: flex-start;
        margin-top: 24px;

        &.canRematch-wrap {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .advanced-options {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .meetupDateTime {
            border: none;
            box-sizing: border-box;
            margin-top: 16px;
            border-radius: 8px;
            background-color: #f4f2f2;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${colors.darkGray};
            padding-left: 15.5px;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 100%;

            ::before {
                border-bottom: 0;
            }
        }

        label {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4d4d4d;
        }

        input {
            padding: 0;
        }

        .meetupInput {
            border: none;
            box-sizing: border-box;
            margin-top: 16px;
            border-radius: 8px;
            background-color: #f4f2f2;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${colors.darkGray};
            padding-left: 15.5px;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 100%;
        }

        .meetupDateWrap {
            display: flex;
            width: 100%;
            @media screen and (max-width: 1024px) {
                flex-direction: column;
            }

            .meetupEndDate {
                padding-left: 20px;
            }

            .meetupStartDate,
            .meetupEndDate {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 15px;
                }
            }
        }

        textarea {
            resize: none;
            border: none;
            box-sizing: border-box;
            margin-top: 16px;
            border-radius: 8px;
            background-color: #f4f2f2;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${colors.darkGray};
            padding-left: 15.5px;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 100%;
        }
    }

    .createMeetup {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;

        padding: 15px 71px;
        border-radius: 23px;
        background-color: #11394f;
        border: none;
    }

    .help {
        font-size: 16px;
        margin-left: 4px;
    }

    .geosuggest {
        width: 100%;
    }

    .geosuggest__suggests--hidden {
        max-height: 0;
        overflow: hidden;
        border-width: 0;
        display: none;
    }

    .geosuggest__suggests {
        background: #f4f2f2;
        color: #4d4d4d;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 23px;
    }

    .geosuggest__item--active {
        font-weight: bold;
    }

    .geosuggest__item {
        :hover {
            font-weight: bold;
        }
    }
`;

const TextFieldComp = styled(TextField)`
    color: red;
    border-color: white;

    > div {
        border-radius: 8px;
        background-color: #f4f2f2;
    }
`;

const PopupComponent = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.7);

    .copyBtn {
        margin-bottom: 20px;

        svg {
            font-size: 30px;
        }
    }

    .inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        font-size: 1.5rem;
        padding: 50px;
        box-sizing: border-box;
        max-width: 100%;

        p {
            font-size: 1.1rem;
            font-weight: 500;
            margin: 10px;
            color: ${colors.darkGray};
        }

        .link {
            color: ${colors.pink};
            margin-bottom: 10px;
        }

        .icon {
            position: absolute;
            top: 15px;
            right: 15px;
            color: ${colors.darkGray};
        }
    }
`;

const MeetupForm = (props) => {
    const meetup = props.meetup;
    let groupPeople = "";

    const username = localStorage.getItem("username");
    const isAdmin = username
        ? dashboardUser.includes(username.split("@")[0])
        : false;
    // get offset of client timezone
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const [tempMeetup, setTempMeetup] = useState(null);
    const [name, setName] = useState("");
    const [meetupParticipants, setMeetupParticipants] = useState([]);
    const [meetupId, setMeetupId] = useState("");
    const [description, setDescription] = useState(
        !meetup || !meetup.description ? "" : meetup.description
    );
    const [nextStepUrl, setNextStepUrl] = useState(
        !meetup || !meetup.nextStepUrl ? "" : meetup.nextStepUrl
    );
    const [debateOrFriendsQuestion, setDebateOrFriendsQuestion] = useState(
        !meetup || !meetup.debateOrFriendsQuestion
            ? ""
            : meetup.debateOrFriendsQuestion
    );
    const [debateOrFriendsAnswers, setDebateOrFriendsAnswers] = useState(
        !meetup || !meetup.debateOrFriendsAnswers
            ? ""
            : meetup.debateOrFriendsAnswers
    );

    const userId = localStorage.getItem("userId");

    const [topics, setTopics] = useState(
        !meetup || !meetup.topics ? "" : meetup.topics
    );
    const [closedParticipants, setClosedParticipants] = useState(groupPeople);
    // current dates normalized by browser timezone
    const [duration, setDuration] = useState(
        !meetup || !meetup.duration ? 5 : meetup.duration
    );
    const [city, setCity] = useState(
        !meetup || !meetup.city ? "" : meetup.city
    );
    // const [country, setCountry] = useState(!meetup || !meetup.country ? "" : meetup.country);
    const [website, setWebsite] = useState(
        !meetup || !meetup.website ? "" : meetup.website
    );
    const [registrationLink, setRegistrationLink] = useState(
        !meetup || !meetup.registrationLink ? "" : meetup.registrationLink
    );
    const [language, setLanguage] = useState(
        !meetup || !meetup.language ? [] : meetup.language.split(",")
    );
    const [registration, setRegistration] = useState(
        !meetup || !meetup.registration ? false : meetup.registration
    );
    const [isFree, setIsFree] = useState(
        !meetup || !meetup.cost ? true : false
    );
    const [cost, setCost] = useState(
        !meetup || meetup.isFree ? "" : meetup.cost
    );
    const [internality, setInternality] = useState(
        !meetup || !meetup.internality ? "Internal" : meetup.internality
    );
    const [keywords, setKeywords] = useState(
        !meetup || !meetup.keywords ? [] : meetup.keywords.split(",")
    );
    const [autocompleteValues, setAutocompleteValues] = useState({
        // cities: [],
        // countries: [],
        languages: [],
        keywords: [],
        requested: false
    });
    const [address, setAddress] = useState(
        !meetup || !meetup.address ? "" : meetup.address
    );
    const [photo, setPhoto] = useState(
        !meetup || !meetup.photo ? "" : meetup.photo
    );
    const [eventType, setEventType] = useState(
        !meetup || !meetup.eventType ? "bayronnect" : meetup.eventType
    );
    const [expand, setExpand] = useState(false);
    const [isBreakRoom, setIsBreakRoom] = useState(
        !meetup || !meetup.isBreakRoom ? false : meetup.isBreakRoom
    );
    const [isDebateMeetup, setIsDebateMeetup] = useState(
        !meetup || !meetup.isDebateMeetup ? false : meetup.isDebateMeetup
    );
    const [isFriendsMeetup, setIsFriendsMeetup] = useState(
        !meetup || !meetup.isFriendsMeetup ? false : meetup.isFriendsMeetup
    );
    // const [id, setId] = useState("");
    // status to show success of meetup creation
    const [status, setStatus] = useState("");
    const [selectedStartDate, setSelectedStartDate] = useState(
        `${new Date(Date.now() - tzoffset).toISOString().substring(0, 14)}00`
    );

    const [canRematch, setCanRematch] = useState(true);
    const [preventSameSite, setPreventSameSite] = useState(false);
    const [conference, setconference] = useState(false);

    const [selectedEndDate, setSelectedEndDate] = useState(
        `${new Date(Date.now() - tzoffset + 3600000)
            .toISOString()
            .substring(0, 14)}00`
    );

    const [outlookStartDate, setOutlookStartDate] = useState(
        `${new Date(Date.now() - tzoffset).toISOString().substring(0, 14)}00`
    );
    const [outlookEndDate, setOutlookEndDate] = useState(
        `${new Date(Date.now() - tzoffset).toISOString().substring(0, 14)}00`
    );

    // To know if we are cloning a meetup or not.
    const action = props.action;

    var maxNameLength = 50; // Max number of characters allowed as meetup name.

    var maxWordLength = 14; // Max number of characters allowed in any single word of the meetup name.

    var namePlaceholder = // Placeholder string for the meetup form.
        "Think of a name for your event that's less than " +
        maxNameLength +
        " characters.";

    var maxDescriptionLength = 10000; // Event description placeholder and limit

    var descriptionPlaceholder =
        "What’s your event about? Think of a description that's less than " +
        maxDescriptionLength +
        " characters. You'll say everything else at the meetup!";

    var errorMessagesArray = {
        wrongDebateOrFriendsQuestion:
            "Don’t forget to add a question for the discussion!",
        wrongNumberOfDebateOrFriendsAnswers:
            "Don’t forget to add at least 2 or a maximum of 20 answers for a debate or friends meetup!",
        nameAlreadyExists:
            "The name of the meetup already exists. Please choose a different name.",
        startDateAfterEndDate:
            "The End Date/Time is sooner than or the same as the Start Date/Time. Please select other Dates/Times.",
        emptyName: "Don’t forget to add the name for your meetup!",
        incomplete: "You may need to change the time on BOTH dates again.",
        wordTooLong:
            "The max length of a word in the title is 14 characters. Emojis count as 2 characters.",
        nameTooLong:
            "The meetup name cannot be longer than 50 characters. Emojis count as 2 characters.",
        descriptionLimit:
            "The event description cannot be longer than " +
            maxDescriptionLength +
            " characters. Emojis count as 2 characters.",
        invalidWebsiteUrl: "Please enter event website in valid format.",
        invalidRegistrationLink:
            "Please enter event registration link in valid format."
    };

    // get autocomplete options from the postgres DB
    const getAutocompleteValues = async () => {
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getAutocompleteValues`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken")
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data) {
                    setAutocompleteValues(data);
                }
            });
    };

    const getAutocompleteKeywords = async (input = "") => {
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getAutocompleteKeywords`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                input: input
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data) {
                    console.log(`data: ${data}`);
                    setAutocompleteValues({
                        ...autocompleteValues,
                        keywords: data.keywords
                    });
                }
            });
    };

    const getPhotoSrc = (input) => {
        if (!input) {
            return "";
        } else if (input instanceof File || input instanceof Blob) {
            return window.URL.createObjectURL(input);
        } else {
            return input;
        }
    };

    useEffect(() => {
        if (meetup) {
            let startDate = new Date(meetup.startDate);
            startDate = new Date(startDate.setHours(startDate.getHours()));
            let endDate = new Date(meetup.endDate);
            endDate = new Date(endDate.setHours(endDate.getHours()));
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
            setCanRematch(meetup.canRematch);
            setIsDebateMeetup(meetup.isDebateMeetup);
            setIsFriendsMeetup(meetup.isFriendsMeetup);
            setPreventSameSite(meetup.preventSameSite);
            if (meetup.conference === 1) {
                setconference(true);
            } else {
                setconference(false);
            }

            if (action === "clone") {
                setName(props.meetupCloneName);
            } else {
                setName(meetup.name);
            }
            meetup.nextStepUrl && setNextStepUrl(meetup.nextStepUrl);
            meetup.debateOrFriendsQuestion &&
                setDebateOrFriendsQuestion(meetup.debateOrFriendsQuestion);
            meetup.debateOrFriendsAnswers &&
                setDebateOrFriendsAnswers(meetup.debateOrFriendsAnswers);

            if (
                meetup.groups !== "null" &&
                meetup.groups &&
                !meetup.isDebateMeetup &&
                !meetup.isFriendsMeetup
            ) {
                let groups = JSON.parse(meetup.groups);

                groups.forEach((value, index) => {
                    if (value.users.length > 1) {
                        value.users.forEach((email, index) => {
                            groupPeople =
                                groupPeople + email + ":" + value.id + ",";
                        });
                    } else {
                        groupPeople =
                            groupPeople + value.users[0] + ":" + value.id + ",";
                    }
                });
                groupPeople = groupPeople.substring(0, groupPeople.length - 1);
                setClosedParticipants(groupPeople);
                console.log(groupPeople);
            } else {
                groupPeople = !meetup ? "" : meetup.closedParticipants;
                setClosedParticipants(groupPeople);
            }
        }

        if (!autocompleteValues.requested) {
            getAutocompleteValues();
        }
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const createClickEvent = () => {
        const topicsOriginalArray = topics
            .split(",")
            .map((topic) => topic.trim());
        const topicsTemporaryArray = topicsOriginalArray.filter(function (el) {
            return el;
        });
        // JavaScript Set removes duplicate elements.
        const topicsSet = new Set(topicsTemporaryArray);
        const topicsArray = Array.from(topicsSet);

        // Sanitize debateOrFriendsAnswers.
        // Trim elements.
        var debateOrFriendsAnswersArray = [];
        if (debateOrFriendsAnswers && debateOrFriendsAnswers !== null) {
            const debateOrFriendsAnswersArrayOriginal = debateOrFriendsAnswers
                .split(",")
                .map((debateOrFriendsAnswer) => debateOrFriendsAnswer.trim());
            //  Remove empty answers.
            const debateOrFriendsAnswersTempArray =
                debateOrFriendsAnswersArrayOriginal.filter(function (el) {
                    return el;
                });
            // JavaScript Set removes duplicate elements.
            const debateOrFriendsAnswersSet = new Set(
                debateOrFriendsAnswersTempArray
            );
            debateOrFriendsAnswersArray = Array.from(debateOrFriendsAnswersSet);
        }

        let closedParticipantsArray;

        if (closedParticipants.indexOf("\n") > -1) {
            console.log("HAS LINES");
            closedParticipantsArray = closedParticipants.split("\n");

            closedParticipantsArray.forEach((value, index) => {
                let val = value.trim();
                if (val === "") {
                    delete closedParticipantsArray[index];
                }
            });
            closedParticipantsArray = closedParticipantsArray.filter(function (
                e
            ) {
                return e;
            });
            if (closedParticipantsArray.length > 1) {
                closedParticipantsArray.forEach((value, index) => {
                    if (value.indexOf("\t") > -1) {
                        closedParticipantsArray[index] = value
                            .replace("\t", ":")
                            .toLowerCase();
                    }
                });
            } else {
                closedParticipantsArray = closedParticipantsArray[0]
                    .split(",")
                    .map((participant) => participant.trim().toLowerCase());
            }
        } else {
            closedParticipantsArray = closedParticipants
                .split(",")
                .map((participant) => participant.trim().toLowerCase());
        }
        // console.log(closedParticipantsArray);
        let groups = [];
        let groupFlag = false;
        let userFlag = false;
        let groupHolder = [];
        let defaultGroupName;
        let username;

        if (CONF.OPS_MODE === "internal") {
            username = localStorage.getItem("username") + "@bayer.com";
        } else {
            username = localStorage.getItem("username");
        }

        closedParticipantsArray.forEach((value, index) => {
            const part = value.split(":");
            if (part.length === 2) {
                groupFlag = true;
                groupHolder.push(part[1].trim().toLowerCase());
            }
            if (part[0].toLowerCase() === username) {
                userFlag = true;
            }
        });
        if (groupHolder.length > 0) {
            if (groupHolder.indexOf("AwesomePeople") > -1) {
                defaultGroupName = "TheAvengers";
            } else {
                defaultGroupName = "AwesomePeople";
            }
        }
        if (!userFlag && closedParticipantsArray[0] !== "") {
            closedParticipantsArray.push(username);
            alert("You did not add yourself to the meetup. We added you.");
        }
        if (groupFlag) {
            closedParticipantsArray.forEach((value, index) => {
                const part = value.split(":");
                if (part.length < 2) {
                    alert(
                        "The user " +
                            part[0] +
                            " did not have a group. We added this user to " +
                            defaultGroupName +
                            " group."
                    );
                    let obj = groups.find((x) => x.id === defaultGroupName);
                    if (!obj) {
                        groups.push({ id: defaultGroupName, users: [part[0]] });
                    } else {
                        let index = groups.indexOf(obj);
                        groups.fill(
                            obj.users.push(part[0].toLowerCase()),
                            index,
                            index++
                        );
                    }
                } else {
                    closedParticipantsArray[index] = part[0].toLowerCase();
                    let obj = groups.find((x) => x.id === part[1].trim());
                    if (!obj) {
                        groups.push({ id: part[1].trim(), users: [part[0]] });
                    } else {
                        let index = groups.indexOf(obj);
                        groups.fill(obj.users.push(part[0]), index, index++);
                    }
                }
            });
        } else {
            groups = null;
            closedParticipantsArray.forEach((value, index) => {
                closedParticipantsArray[index] = value.toLowerCase();
            });
        }

        // Temporary check if the dates are selected correctly. If the user is cloning or updating a meetup,
        // and only ONE new datetime is selected, the format of the just selected date is different from the
        // other one, so BOTH must be brought to the same format to be able to be compared
        // startDate < endDate.
        let temporaryStartDate = new Date(
            (new Date(selectedStartDate).getTime() / 1000) * 1000 -
                new Date().getTimezoneOffset() * 60000
        )
            .toISOString()
            .substring(0, 16);
        let temporaryEndDate = new Date(
            (new Date(selectedEndDate).getTime() / 1000) * 1000 -
                new Date().getTimezoneOffset() * 60000
        )
            .toISOString()
            .substring(0, 16);

        if (
            name.trim().length > 0 &&
            selectedStartDate &&
            selectedEndDate &&
            temporaryStartDate < temporaryEndDate &&
            ((!isDebateMeetup && !isFriendsMeetup) ||
                ((isDebateMeetup || isFriendsMeetup) &&
                    debateOrFriendsQuestion.trim().length > 0 &&
                    debateOrFriendsAnswersArray.length > 1 &&
                    debateOrFriendsAnswersArray.length <= 20))
        ) {
            // Using RegExp.
            // This works: if (/(^| )\w{18,}( |$)/.test(name)) {
            // This also works: if ((new RegExp (/(^| )\w{18,}( |$)/)).test(name)) {
            // This also works. Note the escaped \ character.:
            // if ((new RegExp ('(^| )\\w{18,}( |$)')).test(name)) {

            // The next expression returns true if length of every single word is less than
            // maxWordLength + 1. We stopped using that RegExp below
            // because it had issues with the character &.
            // let checkMaxWordLength = maxWordLength + 1;
            // if ((new RegExp ('(^| )\\w{' + checkMaxWordLength + ',}( |$)')).test(name)) {

            if (checkWordLength(name, maxWordLength)) {
                setStatus("wordTooLong");
            } else if (name.length > maxNameLength) {
                setStatus("nameTooLong");
            } else if (description.length > maxDescriptionLength) {
                setStatus("descriptionLimit");
            } else if (!validateWebsiteUrl(website)) {
                setStatus("invalidWebsiteUrl");
            } else if (!validateWebsiteUrl(registrationLink)) {
                setStatus("invalidRegistrationLink");
            } else if (!props.meetups.some((meetup) => meetup.name === name)) {
                let randomMeetupNamePart = Math.random()
                    .toString(36)
                    .substring(7);
                const id = md5(name + randomMeetupNamePart);
                // setId(id);

                // if start date beginning has already happened
                if (
                    new Date(selectedStartDate).toISOString() <
                    new Date(Date.now()).toISOString()
                ) {
                    addMeetup(
                        {
                            id,
                            name,
                            description,
                            city,
                            // country,
                            address,
                            website,
                            registrationLink,
                            internality,
                            language,
                            registration,
                            cost: isFree ? "" : cost,
                            keywords,
                            eventType,
                            nextStepUrl,
                            debateOrFriendsQuestion,
                            debateOrFriendsAnswers: debateOrFriendsAnswersArray,
                            startDate: new Date(
                                (new Date(selectedStartDate).getTime() / 1000) *
                                    1000 -
                                    new Date().getTimezoneOffset() * 60000
                            )
                                .toISOString()
                                .substring(0, 16),
                            endDate: new Date(
                                (new Date(selectedEndDate).getTime() / 1000) *
                                    1000 -
                                    new Date().getTimezoneOffset() * 60000
                            )
                                .toISOString()
                                .substring(0, 16),
                            creator: userId,
                            duration,
                            topics: topicsArray,
                            closedParticipants:
                                closedParticipantsArray.join(","),
                            groups: JSON.stringify(groups),
                            canRematch: canRematch,
                            preventSameSite: preventSameSite,
                            conference: conference,
                            isBreakRoom: isBreakRoom,
                            isDebateMeetup: isDebateMeetup,
                            isFriendsMeetup: isFriendsMeetup,
                            photo
                        },
                        { live: true, toServer: true }
                    );
                    setStatus("live");
                } else {
                    addMeetup(
                        {
                            id,
                            name,
                            description,
                            city,
                            // country,
                            address,
                            website,
                            registrationLink,
                            internality,
                            language,
                            registration,
                            cost: isFree ? "" : cost,
                            keywords,
                            eventType,
                            nextStepUrl,
                            debateOrFriendsQuestion,
                            debateOrFriendsAnswers: debateOrFriendsAnswersArray,
                            startDate: new Date(
                                (new Date(selectedStartDate).getTime() / 1000) *
                                    1000 -
                                    new Date().getTimezoneOffset() * 60000
                            )
                                .toISOString()
                                .substring(0, 16),
                            endDate: new Date(
                                (new Date(selectedEndDate).getTime() / 1000) *
                                    1000 -
                                    new Date().getTimezoneOffset() * 60000
                            )
                                .toISOString()
                                .substring(0, 16),
                            creator: userId,
                            duration,
                            topics: topicsArray,
                            closedParticipants:
                                closedParticipantsArray.join(","),
                            canRematch: canRematch,
                            groups: JSON.stringify(groups),
                            preventSameSite: preventSameSite,
                            conference: conference,
                            isBreakRoom: isBreakRoom,
                            isDebateMeetup: isDebateMeetup,
                            isFriendsMeetup: isFriendsMeetup,
                            photo
                        },
                        { live: false, toServer: true }
                    );
                    setStatus("later");
                }
                setSelectedStartDate(
                    `${new Date(Date.now() - tzoffset)
                        .toISOString()
                        .substring(0, 14)}00`
                );
                setSelectedEndDate(
                    `${new Date(Date.now() - tzoffset + 3600000)
                        .toISOString()
                        .substring(0, 14)}00`
                );
            } else {
                setStatus("nameAlreadyExists");
            }
        } else if (
            (isDebateMeetup || isFriendsMeetup) &&
            !debateOrFriendsQuestion
        ) {
            setStatus("wrongDebateOrFriendsQuestion");
        } else if (
            (isDebateMeetup || isFriendsMeetup) &&
            (debateOrFriendsAnswersArray.length < 2 ||
                debateOrFriendsAnswersArray.length > 20)
        ) {
            setStatus("wrongNumberOfDebateOrFriendsAnswers");
            console.log("pinga");
        } else if (selectedEndDate <= selectedStartDate) {
            setStatus("startDateAfterEndDate");
        } else if (name.trim().length <= 0) {
            setStatus("emptyName");
        } else {
            setStatus("incomplete");
        }
    };

    const createEventStringOutlookDate = () => {
        const newEvent = createEvent({
            name: name,
            startDate: outlookStartDate,
            endDate: outlookEndDate,
            type: eventType,
            website: website,
            address: address,
            city: city,
            // country: country,
            description: description,
            attendees: meetupParticipants,
            id: meetupId,
            meetupAll: tempMeetup
        });
        const textArray = newEvent.split("\n").filter((f) => f.trim() !== "");
        const event = textArray.join("\n");
        return event;
    };

    const popupMessage = () => {
        if (status === "live" || status === "later") {
            return (
                <PopupComponent>
                    <div className="inner">
                        <span className="icon">
                            <i
                                className="fa fa-times-circle"
                                onClick={() => {
                                    props.closeMeetupModal();

                                    setStatus("");
                                    (status === "live" || status === "later") &&
                                        setName("");
                                    (status === "live" || status === "later") &&
                                        setTopics("");
                                }}
                            />
                        </span>
                        <p>Yay! You created a meetup!</p>
                        <br />
                        <p>Share this link for people to access the meetup:</p>
                        {/*<p className="link">*/}
                        {/*    {window.location.href + meetupId}*/}
                        {/*</p>*/}
                        <IconButton
                            aria-label="copy"
                            className={"copyBtn"}
                            onClick={copyToClipboard(
                                window.location.href + meetupId
                            )}
                        >
                            <FileCopyOutlinedIcon />
                        </IconButton>

                        <NextButton
                            title="Download Outlook Invite"
                            tooltipText="Download Outlook Invite."
                            onClickEvent={() => {
                                const event = createEventStringOutlookDate();
                                window.open(
                                    `data:text/calendar;charset=utf8,${escapeHtml(
                                        event
                                    )}`
                                );
                                props.closeMeetupModal();
                                setStatus("");
                            }}
                        />
                    </div>
                </PopupComponent>
            );
        } else if (status === "updated") {
            // props.closeMeetupModal();
        } else {
            return (
                <PopupComponent>
                    <div className="inner">
                        <span className="icon">
                            <i
                                className="fa fa-times-circle"
                                onClick={() => {
                                    setStatus("");
                                    (status === "live" || status === "later") &&
                                        setName("");
                                    (status === "live" || status === "later") &&
                                        setTopics("");
                                }}
                            />
                        </span>
                        <p>{errorMessagesArray[status]}</p>
                        <br />
                        <NextButton
                            title="Understood"
                            tooltipText="Understood."
                            onClickEvent={() => setStatus("")}
                        />
                    </div>
                </PopupComponent>
            );
        }
    };

    const updateMeetup = () => {
        const topicsOriginalArray = topics
            .split(",")
            .map((topic) => topic.trim());
        const topicsTemporaryArray = topicsOriginalArray.filter(function (el) {
            return el;
        });
        // JavaScript Set removes duplicate elements.
        const topicsSet = new Set(topicsTemporaryArray);
        const topicsArray = Array.from(topicsSet);

        // Sanitize debateOrFriendsAnswers.
        // Trim elements.
        const debateOrFriendsAnswersArrayOriginal = debateOrFriendsAnswers
            .split(",")
            .map((debateOrFriendsAnswer) => debateOrFriendsAnswer.trim());
        //  Remove empty answers.
        const debateOrFriendsAnswersTempArray =
            debateOrFriendsAnswersArrayOriginal.filter(function (el) {
                return el;
            });
        // JavaScript Set removes duplicate elements.
        const debateOrFriendsAnswersSet = new Set(
            debateOrFriendsAnswersTempArray
        );
        const debateOrFriendsAnswersArray = Array.from(
            debateOrFriendsAnswersSet
        );

        let closedParticipantsArray;

        if (closedParticipants.indexOf("\n") > -1) {
            console.log("HAS LINES");
            closedParticipantsArray = closedParticipants.split("\n");

            closedParticipantsArray.forEach((value, index) => {
                let val = value.trim();
                if (val === "") {
                    delete closedParticipantsArray[index];
                }
            });
            closedParticipantsArray = closedParticipantsArray.filter(function (
                e
            ) {
                return e;
            });
            if (closedParticipantsArray.length > 1) {
                closedParticipantsArray.forEach((value, index) => {
                    if (value.indexOf("\t") > -1) {
                        closedParticipantsArray[index] = value
                            .replace("\t", ":")
                            .toLowerCase();
                    }
                });
            } else {
                closedParticipantsArray = closedParticipantsArray[0]
                    .split(",")
                    .map((participant) => participant.trim().toLowerCase());
            }
        } else {
            closedParticipantsArray = closedParticipants
                .split(",")
                .map((participant) => participant.trim().toLowerCase());
        }
        console.log(closedParticipantsArray);
        let groups = [];
        let groupFlag = false;
        let userFlag = false;
        let groupHolder = [];
        let defaultGroupName;
        let username;

        if (CONF.OPS_MODE === "internal") {
            username = localStorage.getItem("username") + "@bayer.com";
        } else {
            username = localStorage.getItem("username");
        }
        closedParticipantsArray.forEach((value, index) => {
            const part = value.split(":");
            if (part.length === 2) {
                groupFlag = true;
                groupHolder.push(part[1].trim().toLowerCase());
            }
            if (part[0] === username) {
                userFlag = true;
            }
        });
        if (groupHolder.length > 0) {
            if (groupHolder.indexOf("AwesomePeople") > -1) {
                defaultGroupName = "TheAvengers";
            } else {
                defaultGroupName = "AwesomePeople";
            }
        }
        if (!userFlag && closedParticipantsArray[0] !== "") {
            closedParticipantsArray.push(username);
            alert("You did not add yourself to the meetup. We added you.");
        }
        if (groupFlag) {
            closedParticipantsArray.forEach((value, index) => {
                const part = value.split(":");
                if (part.length < 2) {
                    alert(
                        "The user " +
                            part[0] +
                            " did not have a group. We added this user to " +
                            defaultGroupName +
                            " group."
                    );
                    let obj = groups.find((x) => x.id === defaultGroupName);
                    if (!obj) {
                        groups.push({ id: defaultGroupName, users: [part[0]] });
                    } else {
                        let index = groups.indexOf(obj);
                        groups.fill(obj.users.push(part[0]), index, index++);
                    }
                } else {
                    closedParticipantsArray[index] = part[0];
                    let obj = groups.find((x) => x.id === part[1].trim());
                    if (!obj) {
                        groups.push({ id: part[1].trim(), users: [part[0]] });
                    } else {
                        let index = groups.indexOf(obj);
                        groups.fill(obj.users.push(part[0]), index, index++);
                    }
                }
            });
        } else {
            groups = null;
            closedParticipantsArray.forEach((value, index) => {
                closedParticipantsArray[index] = value;
            });
        }
        // console.log(groups);
        let temporaryStartDate = new Date(
            (new Date(selectedStartDate).getTime() / 1000) * 1000 -
                new Date().getTimezoneOffset() * 60000
        )
            .toISOString()
            .substring(0, 16);
        let temporaryEndDate = new Date(
            (new Date(selectedEndDate).getTime() / 1000) * 1000 -
                new Date().getTimezoneOffset() * 60000
        )
            .toISOString()
            .substring(0, 16);

        if (
            name.trim().length > 0 &&
            selectedStartDate &&
            selectedEndDate &&
            temporaryStartDate < temporaryEndDate &&
            description.length < maxDescriptionLength &&
            validateWebsiteUrl(website) &&
            validateWebsiteUrl(registrationLink) &&
            ((!isDebateMeetup && !isFriendsMeetup) ||
                ((isDebateMeetup || isFriendsMeetup) &&
                    debateOrFriendsQuestion.trim().length > 0 &&
                    debateOrFriendsAnswersArray.length > 1 &&
                    debateOrFriendsAnswersArray.length < 21))
        ) {
            // if (name && selectedStartDate && selectedEndDate) {
            if (
                (name !== meetup.name &&
                    !props.meetups.some((meetup) => meetup.name === name)) ||
                meetup.name === name
            ) {
                const id = meetup.id;

                let newMeetup = {
                    id,
                    name,
                    description,
                    address,
                    city,
                    // country,
                    website,
                    registrationLink,
                    internality,
                    language,
                    registration,
                    cost: isFree ? "" : cost,
                    keywords,
                    eventType,
                    nextStepUrl,
                    debateOrFriendsQuestion,
                    debateOrFriendsAnswers: debateOrFriendsAnswersArray,
                    startDate: new Date(
                        (new Date(selectedStartDate).getTime() / 1000) * 1000 -
                            new Date().getTimezoneOffset() * 60000
                    )
                        .toISOString()
                        .substring(0, 16),
                    endDate: new Date(
                        (new Date(selectedEndDate).getTime() / 1000) * 1000 -
                            new Date().getTimezoneOffset() * 60000
                    )
                        .toISOString()
                        .substring(0, 16),
                    creator: userId,
                    duration,
                    topics: topicsArray,
                    closedParticipants: closedParticipantsArray.join(","),
                    groups: JSON.stringify(groups),
                    canRematch: canRematch,
                    preventSameSite: preventSameSite,
                    conference: conference,
                    isBreakRoom: isBreakRoom,
                    isDebateMeetup: isDebateMeetup,
                    isFriendsMeetup: isFriendsMeetup,
                    photo
                };

                // if start date beginning has already happened

                if (
                    new Date(selectedStartDate).toISOString() <
                    new Date(Date.now()).toISOString()
                ) {
                    updateMeetupDB(
                        newMeetup,
                        { live: true, toServer: true },
                        props.socket
                    );
                    setStatus("updated");
                } else {
                    updateMeetupDB(
                        newMeetup,
                        { live: false, toServer: true },
                        props.socket
                    );
                    setStatus("updated");
                }
                setSelectedStartDate(
                    `${new Date(Date.now() - tzoffset)
                        .toISOString()
                        .substring(0, 14)}00`
                );
                setSelectedEndDate(
                    `${new Date(Date.now() - tzoffset + 3600000)
                        .toISOString()
                        .substring(0, 14)}00`
                );
            } else {
                setStatus("nameAlreadyExists");
            }
        } else if (!validateWebsiteUrl(website)) {
            setStatus("invalidWebsiteUrl");
        } else if (!validateWebsiteUrl(registrationLink)) {
            setStatus("invalidRegistrationLink");
        } else if (
            (isDebateMeetup || isFriendsMeetup) &&
            !debateOrFriendsQuestion
        ) {
            setStatus("wrongDebateOrFriendsQuestion");
        } else if (
            (isDebateMeetup || isFriendsMeetup) &&
            (debateOrFriendsAnswersArray.length < 2 ||
                debateOrFriendsAnswersArray.length > 20)
        ) {
            setStatus("wrongNumberOfDebateOrFriendsAnswers");
        } else if (selectedEndDate <= selectedStartDate) {
            setStatus("startDateAfterEndDate");
        } else if (name.trim().length <= 0) {
            setStatus("emptyName");
        } else if (description.length > maxDescriptionLength) {
            setStatus("descriptionLimit");
        } else {
            setStatus("incomplete");
        }
    };

    const selectAddress = (suggestion) => {
        if (suggestion && suggestion.label) {
            setAddress(suggestion.label); // string value
        }
        setCity("");
        // extract city from address
        if (
            suggestion &&
            suggestion.gmaps &&
            suggestion.gmaps.address_components
        ) {
            suggestion.gmaps.address_components.forEach((part) => {
                if (part.types[0] == "locality") {
                    // console.log('city', part.long_name);
                    setCity(part.long_name);
                }
            });
        }
    };

    const handlePhoto = (event) => {
        const image = event.target.files[0];
        if (image.type === "image/gif") {
            setPhoto(image);
        } else {
            new Compressor(image, {
                quality: 0.8,
                success: (compressedResult) => {
                    setPhoto(compressedResult);
                }
            });
        }
    };

    const handleDragAndDrop = (event) => {
        const file = event.target.files[0];

        const filename = file.name.split(".").pop();

        if (filename == "msg") {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;

                const testMsg = new MsgReader(text);
                const testMsgInfo = testMsg.getFileData();

                console.log(testMsgInfo);
                setName(testMsgInfo.subject);
                setDescription(testMsgInfo.body);
                setSelectedStartDate(new Date(testMsgInfo.apptStartWhole));
                setSelectedEndDate(new Date(testMsgInfo.apptEndWhole));
            };
            reader.readAsArrayBuffer(file);
        } else {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;

                const data = ical.parseICS(text);
                let attendees;
                for (let k in data) {
                    if (data.hasOwnProperty(k)) {
                        var ev = data[k];
                        if (data[k].type == "VEVENT") {
                            setDescription(ev.description);
                            if (ev.summary.val) {
                                setName(ev.summary.val);
                            } else {
                                setName(ev.summary);
                            }
                            if (ev.start) {
                                setSelectedStartDate(
                                    new Date(ev.start.getTime())
                                );
                            }
                            if (ev.end) {
                                setSelectedEndDate(new Date(ev.end.getTime()));
                            }
                            if (ev.duration) {
                                let durationMinutes = ev.duration.replace(
                                    /\D/g,
                                    ""
                                );
                                let newDateObj = new Date(
                                    ev.start.getTime() + durationMinutes * 60000
                                );
                                setSelectedEndDate(new Date(newDateObj));
                            }
                            setAddress(ev.location);

                            console.log(ev);
                        }
                    }
                }
                console.log(data);
            };
            reader.readAsText(file);
        }
    };

    const handleIsFree = (event) => {
        setIsFree(event.target.checked);
    };

    const handleBreakRoomMeetup = (event) => {
        setIsBreakRoom(event.target.checked);
    };

    const handleIsDebateMeetupToggle = (event) => {
        setIsDebateMeetup(event.target.checked);
        if (isFriendsMeetup) {
            setIsFriendsMeetup(false);
        }
    };

    const handleIsFriendsMeetupToggle = (event) => {
        setIsFriendsMeetup(event.target.checked);
        if (isDebateMeetup) {
            setIsDebateMeetup(false);
        }
    };

    const handleCanRematchToggle = (event) => {
        setCanRematch(event.target.checked);
    };

    const handleSameSiteToggle = (event) => {
        setPreventSameSite(event.target.checked);
    };

    const handleconference = (event) => {
        console.log("conference", conference);
        setconference(event.target.checked);
    };

    const updateMeetupDB = (meetup, info) => {
        const newMeetup = {
            id: meetup.id,
            name: meetup.name,
            description: meetup.description,
            city: meetup.city,
            // country: meetup.country,
            address: meetup.address,
            website: meetup.website,
            registrationLink: meetup.registrationLink,
            internality: meetup.internality,
            language: meetup.language,
            registration: meetup.registration,
            cost: meetup.cost,
            keywords: meetup.keywords,
            eventType: meetup.eventType,
            creator: meetup.creator,
            startDate: meetup.startDate,
            endDate: meetup.endDate,
            duration: meetup.duration,
            nextStepUrl: meetup.nextStepUrl,
            isDebateMeetup: meetup.isDebateMeetup,
            isFriendsMeetup: meetup.isFriendsMeetup,
            debateOrFriendsQuestion: meetup.debateOrFriendsQuestion,
            debateOrFriendsAnswers: meetup.debateOrFriendsAnswers,
            topics: meetup.topics,
            closedParticipants: meetup.closedParticipants,
            canRematch: meetup.canRematch,
            groups: meetup.groups,
            preventSameSite: meetup.preventSameSite,
            conference: meetup.conference ? 1 : 0,
            isBreakRoom: meetup.isBreakRoom,
            photo: meetup.photo
        };
        // (meetup.nextStepUrl) && (newMeetup.nextStepUrl = meetup.nextStepUrl);

        if (info.toServer === true) {
            newMeetup.startDate = new Date(new Date(meetup.startDate).getTime())
                .toISOString()
                .substring(0, 16);
            newMeetup.endDate = new Date(new Date(meetup.endDate).getTime())
                .toISOString()
                .substring(0, 16);
            updateMeetupInDB(
                { meetup: newMeetup, live: info.live },
                props.socket
            );
            props.closeMeetupModal();
        }
    };

    const addMeetup = (meetup, info) => {
        let newMeetup = {
            id: meetup.id,
            name: meetup.name,
            description: meetup.description,
            address: meetup.address,
            city: meetup.city,
            // country: meetup.country,
            website: meetup.website,
            registrationLink: meetup.registrationLink,
            internality: meetup.internality,
            language: meetup.language,
            registration: meetup.registration,
            cost: meetup.cost,
            keywords: meetup.keywords,
            eventType: meetup.eventType,
            creator: meetup.creator,
            startDate: meetup.startDate,
            endDate: meetup.endDate,
            duration: meetup.duration,
            nextStepUrl: meetup.nextStepUrl,
            isDebateMeetup: meetup.isDebateMeetup,
            isFriendsMeetup: meetup.isFriendsMeetup,
            debateOrFriendsQuestion: meetup.debateOrFriendsQuestion,
            debateOrFriendsAnswers: meetup.debateOrFriendsAnswers,
            topics: meetup.topics,
            closedParticipants: meetup.closedParticipants,
            canRematch: meetup.canRematch,
            groups: meetup.groups,
            preventSameSite: meetup.preventSameSite,
            conference: meetup.conference ? 1 : 0,
            isBreakRoom: meetup.isBreakRoom,
            photo: meetup.photo
        };
        setTempMeetup(newMeetup);
        setOutlookStartDate(meetup.startDate);
        setOutlookEndDate(meetup.endDate);
        setMeetupId(meetup.id);

        let participantsArray = [];
        let closedParticipantsArray;
        if (meetup.closedParticipants.length > 0) {
            closedParticipantsArray = meetup.closedParticipants.split(",");
            closedParticipantsArray.forEach((participant) =>
                participantsArray.push({ email: participant })
            );
        }

        setMeetupParticipants(participantsArray);

        // (meetup.nextStepUrl) && (newMeetup.nextStepUrl = meetup.nextStepUrl);

        // console.log("meetup added", newMeetup);

        if (info.toServer === true) {
            newMeetup.startDate = new Date(new Date(meetup.startDate).getTime())
                .toISOString()
                .substring(0, 16);
            newMeetup.endDate = new Date(new Date(meetup.endDate).getTime())
                .toISOString()
                .substring(0, 16);
            addMeetupToDB({ meetup: newMeetup, live: info.live }, props.socket);
        }
    };

    const handlePaste = (e) => {
        let clipboardText = e.clipboardData.getData("text");

        function extractEmails(text) {
            let firstMatch = text.match(/(?:\<).+?(?=\>)/g);
            return firstMatch.map((str) => str.substring(1));
        }

        if (clipboardText.includes("<") || clipboardText.includes(">")) {
            e.preventDefault();
            setClosedParticipants(
                closedParticipants.concat(
                    extractEmails(clipboardText).join(", ")
                )
            );
        }
    };

    const handlePasteTextArea = (e) => {
        let clipboardText = e.clipboardData.getData("text");
        const shouldSetValue = clipboardText < maxDescriptionLength;

        if (shouldSetValue) {
            setDescription(clipboardText);
        }
    };

    return (
        <div>
            <InputComponent>
                <DropdownInput
                    setEventType={setEventType}
                    eventType={eventType}
                    expand={setExpand}
                    dropdownType="event"
                    label="Event type:"
                />
                {eventType && eventType !== "bayronnect" ? (
                    <CalendarInput onChange={handleDragAndDrop} />
                ) : null}
                <TextInput
                    label="Event name: "
                    placeholder={namePlaceholder}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    inputId="meetup-name"
                />
                {eventType && eventType !== "bayronnect" ? (
                    <TextareaInput
                        label="Description:"
                        value={description ? description : ""}
                        onChange={(e) => setDescription(e.target.value)}
                        inputId="meetup-description"
                        placeholder={descriptionPlaceholder}
                        onPaste={handlePasteTextArea}
                        maxLength={maxDescriptionLength}
                    />
                ) : null}
                <TimeInput
                    value={selectedStartDate}
                    onChange={(e) => {
                        try {
                            const endTime = e.getTime() + 3600000 - tzoffset;
                            setSelectedEndDate(
                                new Date(endTime).toISOString().substring(0, 16)
                            );
                        } catch (e) {
                            console.log("setting endtime failed");
                        }
                        setSelectedStartDate(e);
                    }}
                    onError={(e) => {
                        // console.log(e);
                    }}
                    selectedEndDate={selectedEndDate}
                    setSelectedEndDate={(e) => {
                        setSelectedEndDate(e);
                    }}
                />
                {eventType && eventType !== "bayronnect" ? (
                    <FileDropInput
                        onChange={handlePhoto}
                        photoSrc={getPhotoSrc(photo)}
                        onClick={() => setPhoto("")}
                    />
                ) : null}
                {eventType && eventType !== "bayronnect" ? (
                    <AutocompleteInput
                        autocompleteValues={autocompleteValues.keywords}
                        defaultValue={keywords}
                        onInputChange={(event, value) => {
                            getAutocompleteKeywords(value);
                        }}
                        onChange={(event, value) => {
                            setKeywords(value);
                        }}
                        renderInput={(params) => (
                            <TextFieldComp
                                {...params}
                                placeholder="Keywords connected with your event"
                                margin="normal"
                                variant="outlined"
                            />
                        )}
                        label="Keywords:"
                    />
                ) : null}

                {eventType === "face2face" || eventType === "hybird" ? (
                    <AddressInput
                        placeholder="Where will you be meeting? Enter the full address."
                        label="Address:"
                        initialValue={address}
                        onSuggestSelect={selectAddress}
                    />
                ) : null}
                {eventType && eventType !== "bayronnect" ? (
                    <TextInput
                        label="Link:"
                        placeholder="Enter the URL of your event."
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        inputId="meetup-website"
                    />
                ) : null}
                {eventType && eventType !== "bayronnect" ? (
                    <AutocompleteInput
                        autocompleteValues={autocompleteValues.languages}
                        defaultValue={language}
                        onChange={(event, value) => {
                            setLanguage(value);
                        }}
                        renderInput={(params) => (
                            <TextFieldComp
                                {...params}
                                placeholder="What language will the event be held in?"
                                margin="normal"
                                variant="outlined"
                            />
                        )}
                        label="Language:"
                    />
                ) : null}
                {eventType && eventType !== "bayronnect" ? (
                    <SwitchInput
                        checked={registration}
                        onChange={(e) => setRegistration(e.target.checked)}
                        label="Registration required:"
                        tooltipText="Select this if the participants need to register for this event and add a registration link."
                        name="registration"
                    />
                ) : null}
                {registration && (
                    <TextInput
                        label="Registration link:"
                        placeholder="Registration link"
                        value={registrationLink}
                        onChange={(e) => setRegistrationLink(e.target.value)}
                        inputId="registration-link"
                    />
                )}
                {eventType && eventType !== "bayronnect" ? (
                    <SwitchInput
                        checked={isFree}
                        onChange={handleIsFree}
                        label="Participation is free:"
                        tooltipText="Select this if the participation is free. If not, enter the cost below."
                        name="isFree"
                    />
                ) : null}
                {eventType && eventType !== "bayronnect" && !isFree ? (
                    <TextInput
                        label="Cost:"
                        placeholder="Event cost"
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                        inputId="meetup-cost"
                    />
                ) : null}
                {eventType && eventType !== "bayronnect" ? (
                    <DropdownInput
                        setEventType={setEventType}
                        eventType={eventType}
                        expand={setExpand}
                        setDuration={setDuration}
                        duration={duration}
                        setInternality={setInternality}
                        internality={internality}
                        dropdownType="internality"
                        label="Internal or external event:"
                        tooltipText="Are you meeting with your colleagues or with clients or collaborators?"
                    />
                ) : null}
                {!eventType || eventType === "bayronnect" ? (
                    <DropdownInput
                        setEventType={setEventType}
                        eventType={eventType}
                        expand={setExpand}
                        setDuration={setDuration}
                        duration={duration}
                        dropdownType="duration"
                        label="Duration of the chat:"
                        tooltipText="Set the length of each individual chat within the event. Do you want to have a brief introduction or hear their life story?"
                    />
                ) : null}
                {!eventType || eventType === "bayronnect" ? (
                    <ExpandedInput
                        onClick={() => setExpand(!expand)}
                        expand={expand}
                    />
                ) : null}
                {expand === true && (
                    <>
                        {isAdmin ? (
                            <SwitchInput
                                checked={isBreakRoom}
                                onChange={handleBreakRoomMeetup}
                                label="Chit Chat meetup:"
                                tooltipText="Is a Chit Chat meetup. This meetup would rematch
                          people after 1 week, therefore ignoring the normal
                          rule of matching only once. Usually, the meetup would
                          be created to stay alive for a long period of time,
                          e.g. months. It is meant as an informal chat like
                          those random encounters at the coffee machine."
                                name="canRematchOption"
                            />
                        ) : null}
                        {!CONF.IS_STANDALONE && (
                            <SwitchInput
                                checked={isDebateMeetup}
                                onChange={handleIsDebateMeetupToggle}
                                label="Debate Meetup:"
                                tooltipText="There's nothing wrong with a healthy discussion!
                        Here's an opportunity to talk to someone with a different
                        point of view. After creating a question as the theme of
                        your event, Byron will connect users that selected
                        different answers. Click to see your options."
                                name="isDebateMeetup"
                            />
                        )}
                        <SwitchInput
                            checked={isFriendsMeetup}
                            onChange={handleIsFriendsMeetupToggle}
                            label="Friends Meetup:"
                            tooltipText="Is there a topic you're passionate about? Discuss it
                        with like-minded people and broaden your horizons! After
                        creating a question that's the theme of your event, Byron
                        will connect users that selected the same answer. Click
                        to see your options."
                            name="isFriendsMeetup"
                        />
                        {isDebateMeetup === true || isFriendsMeetup === true ? (
                            <>
                                <TextInput
                                    label="Question or Topic to debate:"
                                    tooltipText="Question. You can also use emojis or non-latin
                          characters."
                                    placeholder="Enter a Question or Topic you want to discuss about"
                                    value={debateOrFriendsQuestion}
                                    onChange={(e) =>
                                        setDebateOrFriendsQuestion(
                                            e.target.value
                                        )
                                    }
                                    inputId="meetup-debateQuestion"
                                    onBlur={(e) => {
                                        setDebateOrFriendsQuestion(
                                            e.target.value.trim()
                                        );
                                    }}
                                />

                                <TextInput
                                    label="Comma-separated Answers, min 2, max 20:"
                                    tooltipText="Answers. You can also use emojis or non-latin
                          characters. Duplicates will be automatically removed."
                                    placeholder="Enter the Comma-separated Answers, min 2, max 20"
                                    value={debateOrFriendsAnswers}
                                    onChange={(e) =>
                                        setDebateOrFriendsAnswers(
                                            e.target.value
                                        )
                                    }
                                    inputId="meetup-debateAnswers"
                                />
                            </>
                        ) : null}

                        <SwitchInput
                            checked={canRematch}
                            onChange={handleCanRematchToggle}
                            label="Ignore prior matches:"
                            tooltipText="Select this option if you want to avoid connecting
                        people that were previously matched to add more diversity."
                            name="canRematchOption"
                        />

                        <SwitchInput
                            checked={preventSameSite}
                            onChange={handleSameSiteToggle}
                            label="Prevent people from the same city to meet:"
                            tooltipText="Want to see different faces? By clicking here, you can
                        only see people that are in different cities and offices."
                            name="sameSiteOption"
                        />

                        {!CONF.IS_STANDALONE && (
                            <SwitchInput
                                checked={conference}
                                onChange={handleconference}
                                label="Conference:"
                                tooltipText="Select this will be a conference call."
                                name="conferenceOption"
                            />
                        )}

                        <TextInput
                            label="Post-meetup link:"
                            tooltipText="Want to have a wrap-up meeting to talk about how things
                        went? Add a post-meetup link."
                            placeholder="Enter the link for a Zoom or Teams meeting."
                            value={nextStepUrl}
                            onChange={(e) => setNextStepUrl(e.target.value)}
                            inputId="meetup-nextStepUrl"
                        />

                        <TextareaInput
                            label="Meetup topics:"
                            tooltipText="Want to further specify your Debate, or Friends meetup?
                        Add extra topics."
                            value={topics}
                            onChange={(e) => setTopics(e.target.value)}
                            inputId="meetup-topics"
                            placeholder="Optional: Enter comma-separated topics for your meetup,
                        for example: What do you do?, Where do you work?, What is the
                        meaning of life?"
                        />

                        <TextareaInput
                            label="Secret Meetup:"
                            tooltipText="Don’t want everyone to access this meetup? You can choose
                        who can see it or sort everyone into groups that can interact
                        with each other."
                            value={closedParticipants}
                            onChange={(e) =>
                                setClosedParticipants(e.target.value)
                            }
                            inputId="meetup-topics"
                            onPaste={handlePaste}
                            placeholder="Enter comma-separated emails of people who can join the
                        meetup, you can also copy a list of e-mails from an Excel
                        sheet or word document, line by line. If you want to sort
                        people by groups, separate the emails by „:”, tab, or space."
                        />
                    </>
                )}
                <div className={"meetup-btns"}>
                    {!meetup ? (
                        <button
                            className="createMeetup"
                            onClick={createClickEvent}
                        >
                            Create
                        </button>
                    ) : action === "clone" ? (
                        <button
                            className="createMeetup"
                            onClick={createClickEvent}
                        >
                            Clone
                        </button>
                    ) : (
                        <button className="createMeetup" onClick={updateMeetup}>
                            Update
                        </button>
                    )}
                </div>
            </InputComponent>
            {status !== "" && popupMessage()}
        </div>
    );
};

export default withRouter(MeetupForm);
