import React from "react";
import styled from "styled-components";
import RatingsTable from "../components/statistics/ratingsTable";

const StyledDiv = styled.div`
  overflow-x: auto;
  background: white;

  .sad-column {
    cursor: pointer;
  }
`;

export class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeMeetup: null, ratingsModal: false };
    }

    setActiveMeetup = meetup => {
        if ((meetup.ratingSad + meetup.ratingHappy + meetup.ratingNeutral) > 0) {
            this.setState({
                activeMeetup: meetup
            });
            this.setState({
                ratingsModal: true
            });
        }
    };

    closeRatingsModal = () => {
        this.setState({
            ratingsModal: false
        });
    };

    render() {
        const statisticType = this.props.statisticType;

        const tableHead = (
            <tr>
                <th />
                <th>No.</th>
                <th>Name</th>
                <th className="tableValueCenter">Day</th>
                <th className="tableValueCenter">Time</th>
                <th />
                <th />
                <th className="tableValueRight">Users</th>
                <th className="tableValueRight">Matches</th>
                <th className="tableValueRight">Failed</th>
                <th className="tableValueRight">Rejected</th>
                <th>Sad</th>
                <th>Neutral</th>
                <th>Happy</th>

                <th>Created by</th>

                <th />
            </tr>
        );

        const tableHeadSummary = (
            <tr>
                <th />
                <th>No.</th>
                <th>Name</th>
                <th className="tableValueCenter">Day</th>
                <th className="tableValueCenter">Time</th>
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                <th>Created by</th>
                <th />
            </tr>
        );

        let returnData;
        switch (statisticType) {
            case "events":
                returnData = (
                    <StyledDiv id="data">
                        <p />
                        <table>
                            <thead>
                                <MyTableRow
                                    myStyle={{ width: "20em", fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="Events completed:  "
                                    myVal=""
                                />
                                {tableHead}
                            </thead>
                            <tbody>
                                <EventLine
                                    setActiveMeetup={this.setActiveMeetup}
                                    meetups={this.props.statisticData.meetupsPast}
                                />
                            </tbody>
                        </table>
                        {this.state.activeMeetup && (
                            <RatingsTable closeRatingsModal={this.closeRatingsModal} ratingsModal={this.state.ratingsModal} activeMeetup={this.state.activeMeetup} />
                        )}
                    </StyledDiv>
                );
                break;
            case 'dev-stats':
                returnData = (
                    <div style={{ padding: "20px" }} id="data">

                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td colSpan="3" style={{ fontWeight: 'bold' }}>Total users:</td>
                                    <td> {this.props.statisticData.devStats.users ? this.props.statisticData.devStats.users.length : 0}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colSpan="3" style={{ fontWeight: 'bold' }}>Total meetups:</td>
                                    <td> {this.props.statisticData.devStats.meetups ? this.props.statisticData.devStats.meetups.length : 0}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colSpan="3" style={{ fontWeight: 'bold' }}>Meetup stats:</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(this.props.statisticData.devStats.matchStats).map((meetupCount, usrs) =>
                                    <tr key={meetupCount}>
                                        <td></td>
                                        <td>{this.props.statisticData.devStats.matchStats[meetupCount]} users</td>
                                        <td> joined</td>
                                        <td>{meetupCount} meetups</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                );
                break;
            case "today":
                returnData = (
                    <StyledDiv id="data">
                        <p />
                        <table>
                            <thead>
                                <MyTableRow
                                    myStyle={{ width: "16em", fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="Events today:  "
                                    myVal=""
                                />
                                {tableHead}
                            </thead>
                            <tbody>
                                <EventLine
                                    setActiveMeetup={this.setActiveMeetup}
                                    meetups={this.props.statisticData.meetupsToday}
                                />
                            </tbody>
                        </table>
                        {this.state.activeMeetup && (
                            <RatingsTable closeRatingsModal={this.closeRatingsModal} ratingsModal={this.state.ratingsModal} activeMeetup={this.state.activeMeetup} />
                        )}
                    </StyledDiv>
                );
                break;
            case "private":
                returnData = (
                    <StyledDiv id="data">
                        <p />
                        <table>
                            <thead>
                                <MyTableRow
                                    myStyle={{ width: "16em", fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="My Meetups:  "
                                    myVal=""
                                />
                                {tableHead}
                            </thead>
                            <tbody>
                                <EventLine
                                    setActiveMeetup={this.setActiveMeetup}
                                    meetups={this.props.statisticData.meetupsUser}
                                />
                            </tbody>
                        </table>
                        {this.state.activeMeetup && (
                            <RatingsTable closeRatingsModal={this.closeRatingsModal} ratingsModal={this.state.ratingsModal} activeMeetup={this.state.activeMeetup} />
                        )}
                    </StyledDiv>
                );
                break;
            case "info":
            case "summary":
            default:
                returnData = (
                    <StyledDiv id="data">
                        <p />
                        <table>
                            <tbody>
                                <MyTableRow
                                    myStyle={{ width: "18em", fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="Total number of events in database:"
                                    myVal={this.props.statisticData.Result.meetupCount}
                                />
                                <MyTableRow
                                    myStyle={{ width: "18em", fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="Events already finished (with matches):"
                                    myVal={this.props.statisticData.Result.meetupCountPast}
                                />
                                <MyTableRow
                                    myStyle={{ width: "18em", fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="Events planned or ongoing:"
                                    myVal={this.props.statisticData.Result.meetupCountFuture}
                                />
                                <MyTableRow
                                    myStyle={{ width: "18em", fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="Total number of users in ByronMeet:"
                                    myVal={this.props.statisticData.Result.userCount}
                                />
                                <MyTableRow
                                    myStyle={{ width: "18em", fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="Total number of matches:"
                                    myVal={this.props.statisticData.Result.matchCount}
                                />
                                <MyTableRow myHead="" myDesc="" />
                                <MyTableRow
                                    myStyle={{ fontWeight: "bold" }}
                                    myHead=""
                                    myDesc="Events onging or planned:"
                                    myVal=""
                                />
                                {tableHeadSummary}
                                <EventLine
                                    setActiveMeetup={this.setActiveMeetup}
                                    meetups={this.props.statisticData.meetupsFuture}
                                />
                            </tbody>
                        </table>
                    </StyledDiv>
                );
        }

        return returnData;
    }
}

function EventLine(props) {
    if (!props.meetups) {
        return (
            <tr>
                <td />
                <td> -- no Meetups found --</td>
            </tr>
        );
    } else {
        return (
            <>
                {props.meetups.map(item => {
                    //    const startDate = new Date(item.meetupStartDate).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit',  year: 'numeric'});
                    let startDate = new Date(item.meetupStartDate).toLocaleDateString(
                        [],
                        {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric"
                        }
                    );
                    let endDate = new Date(item.meetupEndDate).toLocaleDateString([], {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    });
                    if (startDate === endDate) endDate = "";
                    //    const startTime = new Date(item.meetupStartDate).toLocaleTimeString('de-DE', {hour: 'numeric', minute : 'numeric'});
                    const startTime = new Date(item.meetupStartDate).toLocaleTimeString(
                        [],
                        { timeStyle: "short" }
                    );
                    const endTime = new Date(item.meetupEndDate).toLocaleTimeString([], {
                        timeStyle: "short"
                    });
                    return (
                        <tr key={item.meetupId}>
                            <td />
                            <td>{item.count + 1}.</td>
                            <td>{item.meetupName}</td>
                            <td className="tableDateTime">
                                {startDate}
                                <br />
                                {endDate}
                            </td>
                            <td className="tableDateTime">
                                {startTime}
                                <br />
                                {endTime}
                            </td>
                            <td />
                            <td />
                            <td className="tableValueRight">{item.userCnt}</td>
                            <td className="tableValueRight">{item.matches}</td>
                            <td className="tableValueRight">{item.matchesFailed}</td>
                            <td className="tableValueRight">{item.matchesRejected}</td>

                            <td
                                className={"tableValueRight " + ((item.ratingNeutral + item.ratingSad + item.ratingHappy > 0) ? 'sad-column' : '')}
                                onClick={() => props.setActiveMeetup(item)}
                            >
                                {item.ratingSad}
                            </td>
                            <td
                                className={"tableValueRight " + ((item.ratingNeutral + item.ratingSad + item.ratingHappy > 0) ? 'sad-column' : '')}
                                onClick={() => props.setActiveMeetup(item)}
                            >{item.ratingNeutral}</td>
                            <td
                                className={"tableValueRight " + ((item.ratingNeutral + item.ratingSad + item.ratingHappy > 0) ? 'sad-column' : '')}
                                onClick={() => props.setActiveMeetup(item)}
                            >{item.ratingHappy}
                            </td>

                            <td>{item.meetupCreatedBy}</td>
                            <td />
                        </tr>
                    );
                })}
            </>
        );
    }
}

class MyTableRow extends React.Component {
    render() {
        return (
            <tr>
                <td>{this.props.myHead} </td>
                <td style={this.props.myStyle} colSpan="3">
                    {this.props.myDesc}
                </td>
                <td className="tableValueRight"> {this.props.myVal} </td>
                <td />
            </tr>
        );
    }
}
