import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Countdown from "react-countdown-now";
import { colors } from "../general/styles";

const PositionAndForm = styled.div`
  display: flex;
  background-color: white;
  /* max-width: 200px; */
  /* max-height: 200px; */
  border-radius: 8px;
  text-align: center;
  position: absolute;
  top: -90px;
  z-index: 10001;
  flex-direction: row;
  width: auto;
  padding: 7px 20px;
  align-items: center;

  right: 0;
  left: 0;
  width: auto;
  max-width: 250px;
  display: flex;
  margin: 0 auto;
  justify-content: center;

  @media screen and (max-height: 1024px) {
   top: -70px;
  }

}

  h1 {
    color: ${colors.red};
  }

  @media only screen and (orientation: portrait), (max-width: 732px) {
    //background-color: rgba(255, 255, 255, 0.3);
    //padding: 4px 20px;
    //margin-left: 10px;
  }
`;

const HeaderCounter = styled.h1`
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #11394f;
    margin: 0 15px;
`;
const HeaderCounterTimer = styled.h1`
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #11394f;
    margin: 0;
`;

const TimerImage = styled.div`
    width: 40px;
    height: 40px;
    object-fit: contain;
`;

const FinalNotice = styled.div`
    div {
        display: flex;
        opacity: 0;
        pointer-events: none;
        z-index: 100000;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.8);

        p {
            text-align: center;
            font-size: 30px;
            color: white;
        }
    }

    .fade-in {
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
        animation: fadein 2s;
        animation-fill-mode: forwards;
    }
    .fade-out {
        -webkit-animation: fadeout 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadeout 2s; /* Firefox < 16 */
        -ms-animation: fadeout 2s; /* Internet Explorer */
        -o-animation: fadeout 2s; /* Opera < 12.1 */
        animation: fadeout 2s;
        animation-fill-mode: forwards;
    }
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fadeout {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadeout {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadeout {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    /* Internet Explorer */
    @-ms-keyframes fadeout {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadeout {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

const SessionTimer = React.memo((props) => {
    const time = Date.now() + props.minutes * 60000;
    // const time = Date.now() + 15000;
    const [countdown, setCountdown] = useState(false);
    const [complete, setComplete] = useState(false);
    const [countdownTimer] = useState("10");

    let halfTimeFlag = -1;

    useEffect(() => {
        if (countdown && props.connected) {
            startTimer();
        }
    }, [props.connected, countdown]);

    const renderer = ({ days, hours, minutes, seconds }) => {
        if (halfTimeFlag > 0) {
            halfTimeFlag--;
        } else if (halfTimeFlag === 0) {
            const element = document.querySelector(".showHalfTimeNotice");
            element.classList.remove("fade-in");
            element.classList.add("fade-out");
        }

        if (halfTimeFlag < 0) {
            if ((props.minutes * 60) / 2 === minutes * 60 + seconds) {
                console.log("TESTING");
                const element = document.querySelector(".showHalfTimeNotice");
                element.classList.add("fade-in");
                halfTimeFlag = 3;
            }
        }

        if (minutes > 0) {
            return complete
                ? "Your session is over!"
                : `${minutes < 10 ? "0" + minutes : minutes}:${
                      seconds < 10 ? "0" + seconds : seconds
                  }`;
        } else {
            if (seconds === 10) {
                const element = document.querySelector(".showFinalNotice");
                element.classList.add("fade-in");
            } else if (seconds === 7) {
                const element = document.querySelector(".showFinalNotice");
                element.classList.remove("fade-in");
                element.classList.add("fade-out");
            }
            return complete ? "Your session is over!" : `${seconds} seconds`;
        }
    };

    const handleComplete = (e) => {
        // console.log("Timer ended!");
        setComplete(true);
        props.handleQuit();
    };

    const startTimer = () => {
        setComplete(false);
        countdown.getApi().start();
    };

    const handleTimerStart = (event) => {
        // console.log("Event", event);
    };

    return (
        <PositionAndForm>
            <TimerImage>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                >
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill="#D30F4B"
                            d="M20 .5C8.954.5 0 9.454 0 20.5s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20"
                        />
                        <path
                            fill="#FFF"
                            d="M31.034 12.046c0-1.045-.847-1.787-1.892-1.787-2.026 0-3.281 2.355-2.6 6.328.66-1.979 1.767-2.543 2.6-2.543.896 0 1.892-.567 1.892-1.998M22.258 15.211c0-.638.517-1.091 1.155-1.091 1.238 0 2.005 1.438 1.587 3.865-.402-1.21-1.079-1.553-1.587-1.553-.547 0-1.155-.347-1.155-1.22M25.507 19.012c-7.253 0-8.491 7.048-13.523 4.738-1.067-.49-1.57-1.09-1.83-1.627a2.319 2.319 0 0 1-.265-.955c-.05-.971 1.537-3.882.937-5.467-.605-1.597-2.773 2.053-3.715 2.557-.941.503-5.3-.068-4.418 1.395.876 1.452 4.187 1.86 4.877 2.643.114.13.241.368.383.674 1.564 2.926 3.37 8.057 11.588 8.188-.114.137-.228.276-.328.391-.203.235-.362.42-.45.57a.438.438 0 0 0 .002.435c.072.123.234.271.602.271h.006c.67-.016 2.234-.408 3.578-1.396l.276-.202c1.67-.003 3.83-.087 6.583-.721 1.561-.36 2.834-1.09 2.984-1.748 1.878-8.26-2.245-9.745-7.287-9.745zm1.857 8.142a1.335 1.335 0 0 1-1.333-1.333c0-.735.598-1.334 1.333-1.334.734 0 1.333.599 1.333 1.334 0 .734-.599 1.333-1.333 1.333z"
                        />
                    </g>
                </svg>
            </TimerImage>
            <HeaderCounter>Time left:</HeaderCounter>
            <HeaderCounterTimer>
                <Countdown
                    date={new Date(time)}
                    autoStart={false}
                    ref={setCountdown}
                    renderer={renderer}
                    onComplete={handleComplete}
                    onTick={handleTimerStart}
                />
            </HeaderCounterTimer>
            <FinalNotice>
                <div className="showFinalNotice">
                    <p>{countdownTimer} seconds left! Say goodbye!</p>
                </div>
                <div className="showHalfTimeNotice">
                    <p>Half time!</p>
                </div>
            </FinalNotice>
        </PositionAndForm>
    );
});

export default SessionTimer;
