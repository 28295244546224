import { InfoTooltip } from "../infoTooltip";
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";

export const TimeInput = (props) => {
    return (
        <div className="input-wrap">
            <div className="meetupDateWrap">
                <div className={"meetupStartDate"}>
                    <label>
                        Start time:{" "}
                        {props.startTooltipText && (
                            <InfoTooltip tooltipText={props.startTooltipText} />
                        )}
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            ampm={false}
                            value={props.value}
                            onChange={props.onChange}
                            InputProps={{ className: "meetupDateTime" }}
                            onError={props.onError}
                            autoOk={true}
                            disablePast
                            format="dd.MM.yyyy HH:mm"
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className="meetupEndDate">
                    <label>
                        End time:{" "}
                        {props.endTooltipText && (
                            <InfoTooltip tooltipText={props.endTooltipText} />
                        )}
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            ampm={false}
                            value={props.selectedEndDate}
                            onChange={props.setSelectedEndDate}
                            InputProps={{ className: "meetupDateTime" }}
                            onError={props.onError}
                            autoOk={true}
                            disablePast
                            minDate={props.value}
                            format="dd.MM.yyyy HH:mm"
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
        </div>
    );
};
