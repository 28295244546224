/** @format */

import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PlayCircle from "@material-ui/icons/PlayCircleFilled";
import PauseCircle from "@material-ui/icons/PauseCircleFilled";
import { colors } from "./styles";
import IconButton from "@material-ui/core/IconButton";
import ProfilePictureLink from "./profilepicturelink";
import { useLocation } from "react-router-dom";
import { matchUser } from "../../hooks/socketOperations";
import FaqModal from "../general/faqModal";
import UserMediaDevices from "./userMediaDevices";
import UserProfileModal from "./userProfileModal";
import { dashboardUser } from "../../pages/statisticPage";
import Klick from "../../components/conversationalAI/klick.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import { logout } from "../auth/auth-client";
import ChatIcon from "@material-ui/icons/Chat";
import { Icon } from "@material-ui/core";
import BookIcon from "../../assets/icons/book.png";
import CameraIcon from "../../assets/icons/camera.png";
import LogoutIcon from "../../assets/icons/logout.png";
import ProfileIcon from "../../assets/icons/profile.png";
import { CONF } from "../../config/config";

const Navbar = styled.nav`
  background: ${colors.gray};
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  min-width: 275px;

  .navSection {
    min-width: 275px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon {
      width: 25px;
      height: 25px;
      margin: 15px;
      cursor: pointer;
      transition: all 0.1s linear;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .hoverUnderline {
      display: flex;
      align-items: center;
      margin-left: 15px;

      svg {
        transition: all 0.3s;
      }
      &:hover {
        svg {
          transform: scale(1.1);
        }
      }
    }

    @media only screen and (orientation: portrait), (max-width: 570px) {
      position: absolute;
      width: 100%;
      right: 0px;
      top: 60px;
      z-index: 6;
      height: fit-content;
      background-color: ${colors.gray};
      display: flex;
      justify-content: space-around;
      align-items: center;
      transform: translateY(-200%);
      transition: transform 0.3s ease;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  .navSection-active {
    transform: translateY(0%);
  }

  .profileAvatar {
    display: flex;
    margin-left: 15px;
    margin-bottom: 5px;
    a {
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
      p {
        margin: 0 0 0 10px;
      }
    }
  }

  .dashboard {
    display: flex;
    margin-left: 15px;
    margin-bottom: 5px;
    a {
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
      font-weight: bold;
      &:hover,
      &:focus {
         &:after,
          &:before {
            width: 100%;
            height: 2px;
            background: white;
          }
        }

        position: relative;
        z-index: 2;
        &:after,
        &:before {
          position: absolute;
          content: "";
          width: 2px;
          height: 2px;
          bottom: -5px;
          left: 0;
          right: 0;
          margin: 0 auto;
          z-index: -1;
          transition: all 0.3s;
        }
      }

    }
  }

  .hoverUnderline {
    &:hover,
    &:focus {
      p {
        &:after,
        &:before {
          width: 100%;
          height: 2px;
          background: white;
        }
      }
    }

    p {
      position: relative;
      font-weight: 500;
      display: inline-block;
      z-index: 2;

      &:after,
      &:before {
        position: absolute;
        content: "";
        width: 2px;
        height: 2px;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
        transition: all 0.3s;
      }

      @media only screen and (orientation: portrait), (max-width: 570px) {
        display: none;
      }
    }
  }

  @media only screen and (orientation: portrait), (max-width: 570px) {
    .burger {
      cursor: pointer;
      z-index: 1;
      margin-right: -5%;
      .line {
        width: 30px;
        height: 4px;
        background-color: white;
        margin: 5px;
        border-radius: 2px;
      }
    }
  }
`;

const Container = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    color: white;
    font-size: 34px;
    z-index: 1;

    a {
        color: white;
        margin-right: 30px;

        &:last-of-type {
            display: flex;
            align-items: center;

            img {
                width: 150px;
                height: auto;
                padding-top: 5px;
            }

            @media only screen and (orientation: portrait), (max-width: 768px) {
                display: none;
            }
        }
    }
`;

const isBtnDisabled = false;

const NavbarBayronnect = (props) => {
    const socket = props.socket;
    let location = useLocation();
    // variable to determine whether to set play to false on next url change
    // const [resetPlay, setResetPlay] = useState(false);
    const [play, setPlay] = useState(false);
    // @ts-ignore
    const path = CONF.OPS_MODE === "internal" ? "/bayronnect" : "/";

    let localVideo = useRef(null);
    const [showUserMedia, setShowUserMedia] = useState(false);
    const [showUserProfile, setShowUserProfile] = useState(false);
    const [isPermissionPage, setIsPermissionPage] = useState(false);
    const [showConversationalAi, setShowConversationalAi] = useState(false);

    const username = localStorage.getItem("username");
    const isAdmin = username
        ? dashboardUser.includes(username.split("@")[0])
        : false;

    let currentPath;
    // @ts-ignore
    if (CONF.OPS_MODE === "internal") {
        currentPath = "bayronnect";
    } else {
        currentPath = "/";
    }

    useEffect(() => {
        return function cleanup() {
            // @ts-ignore
            if (localVideo.current && localVideo.current.srcObject) {
                console.debug("CLEANING Preference STREAM");
                // @ts-ignore
                localVideo.current.srcObject
                    .getTracks()
                    .forEach(function (track) {
                        track.stop();
                    });
            }
        };
    }, []);

    useEffect(() => {
        if (location.pathname === "/permission-page") {
            setIsPermissionPage(true);
        }
        if (
            location.pathname.indexOf("/lobby") >= 0 ||
            location.pathname === currentPath ||
            location.pathname === "/match"
        ) {
            setIsPermissionPage(false);
        }
        if (
            !["permission-page", "lobby", "match"].some((term) =>
                location.pathname.includes(term)
            )
        ) {
            setPlay(false);
        }
        if (location.pathname === "/match") {
            setShowConversationalAi(false);
        }
    }, [location]);

    const navSlide = () => {
        // Jesus cleaning warnings.
        // const burger = document.querySelector('.burger');
        const nav = document.querySelector(".navSection");
        // @ts-ignore
        nav.classList.toggle("navSection-active");
    };

    const togglePlay = () => {
        let alreadySent = false;
        fetch(`${CONF.BAYRONNECT_API_URL}/api/setUserPause`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                pause: !play
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            // @ts-ignore
            .then((data) => {
                if (
                    location.pathname !== "/connect" &&
                    location.pathname !== "/match" &&
                    play &&
                    !alreadySent
                ) {
                    alreadySent = true;
                    matchUser(socket, localStorage.getItem("jwtToken"));
                }
                setPlay(!play);
            });
    };
    if (!isPermissionPage) {
        return (
            <Navbar>
                <Container>
                    <Logo>
                        <a href={path}>ByronMeet</a>
                        {
                            // @ts-ignore
                            CONF.OPS_MODE === "internal" && (
                                <a href={path}>
                                    <img
                                        // @ts-ignore
                                        src={require("../../assets/images/byron_logo.png")}
                                        alt="Byron"
                                    />
                                </a>
                            )
                        }
                    </Logo>
                    <div className={"burger"} onClick={navSlide}>
                        <div className={"line"}></div>
                        <div className={"line"}></div>
                        <div className={"line"}></div>
                    </div>
                    <div className={"navSection"}>
                        {/* {isAdmin && (
                            <// @ts-ignore
                            IconButton
                                className={"hoverUnderline"}
                                onClick={() => {
                                    props.history.push("/chatbot");
                                }}
                            >
                                <// @ts-ignore
                                Tooltip
                                    title={<div>Chatbot</div>}
                                    arrow
                                >
                                    <// @ts-ignore
                                    ChatIcon
                                        style={{
                                            // @ts-ignore
                                            color: isBtnDisabled
                                                ? null
                                                : "white",
                                            fontSize: "30px"
                                        // }}
                                    />
                                </Tooltip>
                            </IconButton>
                        )} */}
                        <// @ts-ignore
                        Tooltip
                            title={<div>Learn</div>}
                            arrow
                            onClick={() => {
                                setShowConversationalAi(true);
                            }}
                        >
                            <// @ts-ignore
                            Icon
                                className="icon"
                            >
                                <img src={BookIcon} />
                            </Icon>
                        </Tooltip>
                        {location.pathname === "/" && (
                            <// @ts-ignore
                            Tooltip
                                title="Settings"
                                arrow
                                onClick={() => {
                                    setShowUserMedia(true);
                                }}
                            >
                                <// @ts-ignore
                                Icon
                                    className="icon"
                                >
                                    <img src={CameraIcon} />
                                </Icon>
                            </Tooltip>
                        )}
                        {location.pathname !== "/" && (
                            <// @ts-ignore
                            Tooltip
                                title={
                                    play
                                        ? "Click to get new matches."
                                        : "Click for a temporary break. You will not get new matches."
                                }
                                arrow
                                className="icon"
                                onClick={() => {
                                    !isBtnDisabled && togglePlay();
                                }}
                            >
                                {play ? (
                                    // @ts-ignore
                                    <PlayCircle
                                        style={{
                                            // @ts-ignore
                                            color: isBtnDisabled
                                                ? null
                                                : "white",
                                            fontSize: "34px"
                                        }}
                                    />
                                ) : (
                                    // @ts-ignore
                                    <PauseCircle
                                        style={{
                                            // @ts-ignore
                                            color: isBtnDisabled
                                                ? null
                                                : "white",
                                            fontSize: "34px"
                                        }}
                                    />
                                )}
                            </Tooltip>
                        )}
                        <// @ts-ignore
                        FaqModal
                        />
                        {
                            // @ts-ignore
                            CONF.OPS_MODE === "internal" ? (
                                <div className="profileAvatar">
                                    <a
                                        href={`${CONF.BAYRON_URL}/me`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={"hoverUnderline"}
                                    >
                                        <// @ts-ignore
                                        ProfilePictureLink
                                            homePageHeader={true}
                                            username={
                                                (
                                                    localStorage.getItem(
                                                        "username"
                                                    ) || ""
                                                ).split("@")[0]
                                            }
                                            size="32px"
                                        />
                                    </a>
                                </div>
                            ) : (
                                <// @ts-ignore
                                React.Fragment
                                >
                                    {location.pathname === "/" && (
                                        <// @ts-ignore
                                        Tooltip
                                            title="Profile"
                                            arrow
                                            onClick={() => {
                                                setShowUserProfile(true);
                                            }}
                                        >
                                            <// @ts-ignore
                                            Icon
                                                className="icon"
                                            >
                                                <img src={ProfileIcon} />
                                            </Icon>
                                        </Tooltip>
                                    )}
                                    <// @ts-ignore
                                    Tooltip
                                        title="Logout"
                                        arrow
                                        onClick={() => {
                                            // localStorage.clear();
                                            localStorage.removeItem("jwtToken");
                                            localStorage.removeItem(
                                                "bayronToken"
                                            );
                                            localStorage.removeItem("token");
                                            localStorage.removeItem("username");
                                            localStorage.removeItem(
                                                "logged-in"
                                            );
                                            localStorage.removeItem(
                                                "currentAudioInputId"
                                            );
                                            localStorage.removeItem("userId");
                                            localStorage.removeItem(
                                                "currentAudioOutputId"
                                            );
                                            localStorage.removeItem(
                                                "currentVideoId"
                                            );
                                            localStorage.removeItem("myInfo");
                                            localStorage.removeItem("news");
                                            localStorage.removeItem(
                                                "activeEffect"
                                            );

                                            // @ts-ignore
                                            if (
                                                // window._env_.REACT_APP_OPS_MODE === "internal"
                                                CONF.OPS_MODE === "internal"
                                            ) {
                                                logout();
                                            }

                                            window.location.reload();
                                        }}
                                    >
                                        <// @ts-ignore
                                        Icon
                                            className="icon"
                                        >
                                            <img src={LogoutIcon} />
                                        </Icon>
                                    </Tooltip>
                                </React.Fragment>
                            )
                        }
                        {
                            // @ts-ignore
                            CONF.OPS_MODE === "internal" ? (
                                // isAdmin &&
                                <div className="dashboard">
                                    <a href={`/bayronnect/dashboard`}>
                                        Dashboard
                                    </a>
                                </div>
                            ) : (
                                isAdmin && (
                                    <div className="dashboard">
                                        <a href={`/dashboard`}>Dashboard</a>
                                    </div>
                                )
                            )
                        }
                    </div>
                </Container>
                {showUserMedia ? (
                    <UserMediaDevices
                        showUserMedia={showUserMedia}
                        setShowUserMedia={setShowUserMedia}
                    />
                ) : (
                    ""
                )}
                {showConversationalAi ? (
                    <Klick
                        showConversationalAi={showConversationalAi}
                        setShowConversationalAi={setShowConversationalAi}
                    />
                ) : (
                    ""
                )}
                {showUserProfile &&
                // @ts-ignore
                CONF.OPS_MODE === "external" ? (
                    // @ts-ignore
                    <UserProfileModal
                        showUserProfile={showUserProfile}
                        setShowUserProfile={setShowUserProfile}
                    />
                ) : (
                    ""
                )}
            </Navbar>
        );
    } else {
        return "";
    }
};

export default withRouter(NavbarBayronnect);
