/** @format */
import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useGlobal } from "reactn";
import Error from "./pages/errorPage";
import LobbyPage from "./pages/lobby";
import EventPage from "./pages/event";
import useSocket from "./hooks/useSocket";
import DirectionConnection from "./pages/directionConnection";
import ComingSoonPage2 from "./pages/comingSoon2";
import Faq from "./pages/faq";
import NavbarBayronnect from "./components/general/navbar";
import HomePage from "./pages/homePage";
import "./index.css";
import meetupFinished from "./pages/meetupFinished";
import { detectBrowser } from "./components/general/functions";
import Start from "./pages/start";
import StatisticPage from "./pages/statisticPage";
import LandingPage from "./pages/landingPage";

//import LoginModalExternal from "./components/auth/loginModalExternal";
import LoginModal from "./components/auth/loginModal";
import Chatbot from "./components/chatbot/chatbot";
import { CONF } from "./config/config";

console.info("CONF", CONF);

require("dotenv").config();

const Main = () => {
    const [reload, setReload] = useState(false);
    const [, setSocket] = useGlobal("socket");
    const socket = useSocket(reload);
    const browser = detectBrowser();

    const closeSocket = () => {
        console.debug("Closing socket");
        setSocket(false);
        setReload(!reload);
    };

    const [jwtToken, setJWTToken] = useState(localStorage.getItem("jwtToken"));

    let basedir = "";

    if (CONF.OPS_MODE === "internal") {
        basedir = "bayronnect";
        document.body.classList.remove("external");
    } else {
        document.body.classList.add("external");
    }

    if (browser === "IE" || browser === "Firefox") {
        document.body.classList.add("browser-check");
        return (
            <div>
                <h1>
                    Byron does not support Firefox, Internet Explorer nor the
                    Legacy Edge.
                </h1>
                <br />
                <h1>Supported platforms and browsers:</h1>
                <p>
                    Windows 10: <strong>Chrome</strong>, new Edge, Opera, Brave,
                    Vivaldi, Avast Secure Browser.
                </p>
                <p>
                    Mac: Safari, Chrome, new Edge, Opera, Brave, Vivaldi, Avast
                    Secure Browser.
                </p>
                <p>iPad: Safari, Chrome, new Edge.</p>
                <p>iPhone: Safari, Chrome, new Edge.</p>
                <p>Android: Chrome.</p>
                <p>Chromebook: Chrome.</p>
                <p>Amazon Tablet: Silk.</p>
                <br />
                <p>
                    Your browser will ask you for permission to access your
                    camera and microphone. Maybe your firewall also will ask.
                </p>
                <p>
                    Some browsers may also present a checkbox asking if it
                    should remember that decision.
                </p>
                <br />
                <p>
                    Byron does not support the Tor Browser, nor any browser from
                    the Xbox (360, One, X) or PlayStation (4, 5).
                </p>
                <br />
                <p>With love, the Byron Team</p>
                {/* <pre>
                                      '..'.,',..'
                                         ';.'  ,'
                                          ;;
                                          ;'
                            :._   _.------------.___
                    __      :__:-'                  '--.
             __   ,' .'    .'             ______________'.
           /__ '.-  _\___.'          0  .' .'  .'  _.-_.'
              '._                     .-': .' _.' _.'_.'
                 '----'._____________.'_'._:_:_.-'--'
        </pre>
        <p>Credits to <a href="https://ascii.co.uk/art/whale">ascii.co.uk</a></p>
          */}
            </div>
        );
    }

    if (!jwtToken) {
        console.info("no jwtToken");
        if (CONF.OPS_MODE === "external") {
            console.info("LoginModalExternal");
            // comment out first return for dev purposes
            return <LandingPage setJWTToken={setJWTToken} />;
            //return <LoginModalExternal setJWTToken={setJWTToken} />;
        } else {
            return <LoginModal setJWTToken={setJWTToken} />;
        }
    } else {
        return (
            <BrowserRouter basename={basedir}>
                <Switch>
                    <Route exact path="/start" component={Start} />
                    <Route>
                        <NavbarBayronnect socket={socket} />
                        <Switch>
                            <Route
                                exact
                                path="/:meetupId/lobby"
                                render={() => (
                                    <LobbyPage
                                        socket={socket}
                                        closeSocket={closeSocket}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/event/:meetupId"
                                render={() => <EventPage />}
                            />
                            <Route
                                exact
                                path="/"
                                render={() => <HomePage socket={socket} />}
                            />
                            <Route
                                exact
                                path="/coming-soon"
                                component={ComingSoonPage2}
                            />
                            <Route
                                exact
                                path="/next"
                                component={meetupFinished}
                            />
                            <Route exact path="/faq" component={Faq} />
                            <Route
                                exact
                                path="/dashboard"
                                component={StatisticPage}
                            />

                            {/* <Route path="/chatbot" component={Chatbot} /> */}

                            <Route
                                exact
                                path="/:meetupId"
                                render={() => (
                                    <DirectionConnection socket={socket} />
                                )}
                            />

                            <Route component={Error} />
                        </Switch>
                    </Route>
                </Switch>
            </BrowserRouter>
        );
    }
};

export default Main;
