import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import JourneyTimer from "../components/general/journeytimer";
import styled from "styled-components";
import { colors } from "../components/general/styles";
import NextButton from "../components/general/nextButton";
import UserMediaDevices from "../components/general/userMediaDevices";
import { chooseMeetupOnDB } from "../hooks/socketOperations";
import { CONF } from "../config/config";

// Sample page for teasing bayronnect, was just important for a bachelor thesis.

const Wrapper = styled.div`
    width: 100%;
    max-height: 999px;
    transition: max-height 500ms ease-in-out;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    max-width: 600px;
    justify-self: center;
    align-self: center;
    margin: 30px auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    font-size: 1.5rem;
    padding: 50px;

    h1 {
        font-size: 1.5rem;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 3px solid ${colors.red};
    }
    p {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${colors.darkGray};
    }
    button {
        cursor: pointer;
    }
    .btnHolder {
        margin-top: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-of-type {
            margin-top: 20px;
        }
    }
`;

const DirectionConnection = (props) => {
    // const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

    const meetupId = props.match.params.meetupId;
    const socket = props.socket;
    const [meetupDoesntExistFlag, setMeetupDoesntExistFlag] = useState(false);
    const [meetupInFutureFlag, setMeetupInFutureFlag] = useState(false);
    const [meetupStartTime, setMeetupStartTime] = useState(false);
    // TODO +++ Alen are we not using the variable from localStorage loggedIn anymore?
    // const [loggedIn, setLoggedIn] = useState(!!(localStorage.getItem("jwtToken") && localStorage.getItem("jwtToken").length > 20));

    console.log("DIRECT CONNECTION");

    const [showUserMedia, setShowUserMedia] = useState(false);
    const [meetup, setMeetup] = useState(false);
    const [userLanguages, setUserLanguages] = useState(false);
    let userId;

    const bayronUrl = window._env_.REACT_APP_BAYRON_URL
        ? window._env_.REACT_APP_BAYRON_URL
        : "https://127.0.0.1";
    const baseUrl = `${bayronUrl}/bayronnect`; // window._env_.REACT_APP_BAYRON_URL ? `${window._env_.REACT_APP_BAYRON_URL}/bayronnect` : "https://127.0.0.1/bayronnect"

    useEffect(() => {
        if (socket) {
            if (
                !localStorage.getItem("jwtToken") ||
                localStorage.getItem("jwtToken").length < 20
            ) {
                localStorage.setItem("redirect", meetupId);
                props.history.push("/");
                // alert("Please first login to Bayron and then come back here.");
                // window.location.href = "https://bayron.intranet.cnb";
            } else {
                sessionStorage.setItem("loggedIn", true);
                checkStatus();
            }
        }
    }, [socket]);

    useEffect(() => {
        const url = window.location.href;
        if (url.substr(url.length - 1) === "/") {
            window.location.href = url.replace(/\/$/, "");
        }
    }, []);

    const checkStatus = async () => {
        let response = await fetch(
            `${CONF.BAYRONNECT_API_URL}/api/directConnection`,
            {
                method: "POST",
                body: JSON.stringify({
                    jwtToken: localStorage.getItem("jwtToken"),
                    meetupId: meetupId
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
        if (response.status === 404) {
            response = await response.json();
            console.log(response);
            if (response.error === 1) {
                setMeetupDoesntExistFlag(true);
            } else {
                alert(
                    "Sorry, you need to enter ByronMeet from your Byron Profile."
                );
                window.location.href = baseUrl;
            }
        } else {
            response = await response.json();
            console.log("DIRECT CONNECTION RES: ", response);
            const meetup = response.meetup;
            const currentTime = new Date(new Date().getTime());
            const meetupTime = new Date(new Date(meetup.startDate).getTime());
            const meetupEndTime = new Date(new Date(meetup.endDate).getTime());
            userId = response.userId;

            setUserLanguages(response.userLanguages);

            if (currentTime < meetupTime) {
                setMeetupStartTime(meetupTime);
                setMeetupInFutureFlag(true);
            } else if (currentTime > meetupEndTime) {
                props.history.push({
                    pathname: "/next",
                    state: {
                        nextStepUrl: response.nextStepUrl
                    }
                });
            } else {
                setMeetup(meetup);
                setShowUserMedia(true);
            }
        }
    };

    const chooseMeetup = async () => {
        if (userLanguages.length === 0) {
            alert("Please choose at least one language before continuing");
            props.openPreferencesModal(true);
        } else {
            sessionStorage.setItem("duration", meetup.duration.toString());
            const res = await chooseMeetupOnDB(meetupId, userId, socket.id);
            if (res) {
                props.history.push({
                    pathname: encodeURI(meetup.id) + "/lobby",
                    state: {
                        meetup: meetup
                    }
                });
            } else {
                alert("Something went wrong, please try again");
                window.location.reload();
            }
        }
    };

    const closePopup = () => {
        props.history.push("/");
    };

    return (
        <div>
            {meetupInFutureFlag ? (
                <div>
                    <Wrapper style={{ minHeight: "100%", minWidth: "100%" }}>
                        <Container>
                            <h1>Are you ready for your next meetup?</h1>
                            <p>The meetup starts in</p>
                            <JourneyTimer time={meetupStartTime} />
                            <div className={"btnHolder"}>
                                <p>
                                    Check your luggage: Do you already have a
                                    Byron profile?
                                </p>
                                <NextButton
                                    className="botton"
                                    width="200px"
                                    title="Register on Byron"
                                    tooltipText="Register, approve informed consent, add skills, ..."
                                    onClickEvent={() =>
                                        (window.location.href =
                                            window._env_.REACT_APP_BAYRON_URL)
                                    }
                                />
                            </div>
                            <div className={"btnHolder"}>
                                <p>Or participate in another meetup!</p>
                                <NextButton
                                    className="botton"
                                    width="200px"
                                    title="Choose meetup"
                                    tooltipText="Choose meetup."
                                    onClickEvent={() =>
                                        (window.location.href =
                                            window._env_.REACT_APP_BAYRONNECT_URL)
                                    }
                                />
                            </div>
                        </Container>
                    </Wrapper>
                </div>
            ) : (
                ""
            )}
            {showUserMedia ? (
                <UserMediaDevices
                    closePopup={closePopup}
                    showUserMedia={showUserMedia}
                    setShowUserMedia={setShowUserMedia}
                    chooseMeetup={chooseMeetup}
                    meetup={meetup}
                />
            ) : (
                ""
            )}
            <div>
                {meetupDoesntExistFlag ? (
                    <Wrapper style={{ minHeight: "100%", minWidth: "100%" }}>
                        <Container>
                            <h1>Sorry this meetup does not yet exist</h1>

                            <div className={"btnHolder"}>
                                <p>
                                    You can create your own meetup on ByronMeet!
                                </p>
                                <NextButton
                                    className="botton"
                                    width="200px"
                                    title="Go to ByronMeet"
                                    tooltipText="Go to the Home page."
                                    onClickEvent={() =>
                                        (window.location.href = "/")
                                    }
                                />
                            </div>
                        </Container>
                    </Wrapper>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default withRouter(DirectionConnection);
