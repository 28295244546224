import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Tooltip from "@material-ui/core/Tooltip";
import HomeIcon from "@material-ui/icons/Home";
import WeekendSharpIcon from "@material-ui/icons/WeekendSharp";

const StyledButton = styled.button`
    border-radius: 23px;
    background-color: ${(props) => (props.hollow ? "#ffffff" : "#dc1448")};
    border-color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    padding: 12px 0;
    width: ${(props) => props.width};
    margin-left: ${(props) => (props.popup ? "30px" : "0px")};
    margin-right: ${(props) => (props.popup ? "30px" : "0px")};
    margin-top: ${(props) => (props.popup ? "30px" : "0px")};
    margin-bottom: 15px;
    border: 1px solid #dc1448;
    display: flex;
    justify-content: center;

    &.button-small {
        height: 54px;
        width: 54px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 10px;
    }

    :hover {
        background-color: ${(props) => (props.hollow ? "#e9e9e9" : "#BE1442")};
        border-color: #be1442;
    }

    ${breakpoint("tablet")`
    margin-bottom: 0;
  `} @media only screen and(orientation: portrait),(max-width: 732 px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 15px;
        width: ${(props) => (props.img ? "fit-content" : props.width)};
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const QuitButton = (props) => {
    //alert (bayronUrl);
    const bayronUrl = window._env_.REACT_APP_BAYRONNECT_URL
        ? `${window._env_.REACT_APP_BAYRONNECT_URL}/`
        : "https://127.0.0.1:3000";
    // alert ("bayronUrl: " + bayronUrl + "\r\n" +
    //        "window._env_.REACT_APP_BAYRON_URL: "+ window._env_.REACT_APP_BAYRON_URL);
    // const orientation = useScreenOrientation();
    const small = props.small && "button-small";

    return (
        <Tooltip title={props.tooltipText} arrow>
            <StyledButton
                onClick={() => {
                    if (props.toBayron) {
                        console.log("MATCH DATA ON QUIT: ", props.matchData);
                        if (props.currentPage === "match") {
                            localStorage.setItem(
                                "surveyData",
                                JSON.stringify({
                                    meetup: props.meetup,
                                    matchData: props.matchData
                                })
                            );
                            props.socket.emit("quitBeforeChat", {
                                matchIdDB: props.matchData.matchIdDB,
                                partnerSocketId:
                                    props.matchData.partnerInfo.socketId
                            });
                        }

                        window.location.href =
                            window._env_.REACT_APP_BAYRONNECT_URL;
                    } else {
                        if (props.permissionPage) {
                            console.log(
                                "user clicked Cancel Button | permissionPage"
                            );
                            props.sendLeaveMessage(false);
                            window.location.href =
                                window._env_.REACT_APP_BAYRONNECT_URL;
                        } else {
                            console.log(
                                "user clicked Leave Button | matchPage"
                            );
                            props.setQuitButtonClicked(true);
                        }
                    }
                }}
                width={props.width ? props.width : "224px"}
                hollow={props.hollow}
                popup={props.popup}
                img={props.img}
                className={`
          ${
              props.toBayron && !props.permissionPage ? "" : "backToLobbyButton"
          } ${small}
        `}
            >
                {props.title}

                {props.backToBayron && <HomeIcon />}
                {props.backToLobby && <WeekendSharpIcon />}
            </StyledButton>
        </Tooltip>
    );
};

export default QuitButton;
