import React from "react";
import styled from "styled-components";
// import { colors } from "../components/general/styles";

const Container = styled.div``;

const Start = (props) => {
    return <Container>test</Container>;
};

export default Start;
