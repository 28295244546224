import TooltipMui from "@material-ui/core/Tooltip";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import { IOSSwitch } from "../../general/IOSwitch";
import styled from "styled-components";

const StyledP = styled.p`
    font-size: 14px;
    line-height: 24px;
    margin: 0;
`;

export const SwitchInput = (props) => {
    return (
        <div className="input-wrap canRematch-wrap">
            <label className="inputLabel">
                {props.label}
                <TooltipMui
                    arrow
                    placement="right-start"
                    title={
                        <React.Fragment>
                            <StyledP>{props.tooltipText}</StyledP>
                        </React.Fragment>
                    }
                >
                    <HelpIcon className="help" />
                </TooltipMui>
            </label>
            <IOSSwitch
                checked={props.checked}
                onChange={props.onChange}
                name={props.name}
                inputProps={{ "aria-labelledby": "switch-list-label-topics" }}
            />
        </div>
    );
};
