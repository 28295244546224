/** @format */

import { Client } from 'micro-graphql-react';
import { UserAgentApplication } from 'msal';
import { CONF } from "../../config/config";



const msalInstance = new UserAgentApplication({
    auth: {
        clientId: CONF.ENTRA_ID_CLIENT,
        authority: 'https://login.microsoftonline.com/' + CONF.ENTRA_ID_TENANT,
        redirectUri: CONF.ENTRA_ID_TOKEN_DEST
    }
});

/**
 * retrieve a valid access token via msal
 * if no scopes are provided an idToken will be provided
 */
export async function getAccessToken(scopes) {
    let realScopes = scopes;
    if (!realScopes || !realScopes.length) {
        realScopes = [CONF.ENTRA_ID_CLIENT];
    }
    if ((CONF.REACT_APP_NODE_ENV || CONF.NODE_ENV) === 'development') {
        console.info("DEV ENV");
        const devJWToken = "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6ImhmaDhtbzY2LWNhZzAtMm5sYy1oOWxkLWcwMjIxM2NoMSJ9.nMz4E1Ta02lB_rgqkIjrkJxa_dve0zfwhbL7MxT3j1Q";
        localStorage.setItem("jwtToken", devJWToken);
        return true;
    }
    try {
        const token = await msalInstance.acquireTokenSilent({
            scopes: realScopes
        });
        return token;
    } catch (e) {
        if (
            e.errorCode !== 'consent_required' &&
            e.errorCode !== 'interaction_required' &&
            e.errorCode !== 'login_required'
        ) {
            console.error('Error getting silent token', e);
            return null;
        }
    }

    try {
        const token = await msalInstance.acquireTokenPopup({
            scopes: realScopes
        });
        return token;
    } catch (e) {
        console.error('Error getting interactive token', e);
        return null;
    }
}

export async function getUserId() {
    const token = await getAccessToken();
    if (!token) {
        return null;
    }
    return token.idToken.oid || token.idToken.objectId;
}

export function getUserName() {
    const account = msalInstance.getAccount();
    if (!account) {
        return null;
    }
    return account.userName.split('@')[0];
}

export async function login() {
    return msalInstance.loginPopup({ scopes: ['user.read'] });
}

export function logout() {
    msalInstance.logout();
}

/*
 * GraphQL-Client that will automatically refresh tokens and add them to the header
 */
export class AuthClient extends Client {
    /**
     * @param {*} options default options for the GraphQL-Client
     * @param {Boolean} accessToken if true will retrieve an access token (to be used for MS Graph Queries in the backend) instead of an ID token
     */
    constructor(options, accessToken) {
        super(options);
        this.accessToken = !!accessToken;
    }

    async prepareOptions() {
        const token = await getAccessToken(this.accessToken ? ['user.read'] : null);
        if (token) {
            this.fetchOptions = {
                headers: {
                    'X-USER': token.idToken.objectId,
                    'ID-TOKEN': token.idToken.rawIdToken,
                    'B-GRAPH-ACCESS-TOKEN': token.accessToken,
                    'x-apollo-operation-name': 'init',
                    'Apollo-Require-Preflight': 'true'
                }
            };
        }
    }

    async runQuery(query, variables) {
        await this.prepareOptions();
        return super.runQuery(query, variables);
    }

    async runMutation(mutation, variables) {
        await this.prepareOptions();
        return super.runMutation(mutation, variables);
    }
}
