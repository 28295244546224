import { InfoTooltip } from "../infoTooltip";
import Geosuggest from "react-geosuggest";
import React from "react";

export const AddressInput = (props) => {
    return (
        <div className="input-wrap">
            <label>
                {props.label}{" "}
                {props.tooltipText && (
                    <InfoTooltip tooltipText={props.tooltipText} />
                )}
            </label>
            <Geosuggest
                initialValue={props.initialValue}
                // types={['(cities)']}
                placeholder={props.placeholder}
                inputClassName="meetupInput"
                onSuggestSelect={props.onSuggestSelect}
            />
        </div>
    );
};
