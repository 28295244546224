/** @format */

export const colors = {
    red: '#D30F4B',
    blue: '#00BCFF',
    green: '#66B512',
    grey: '#11394f',
    gray: '#11394f',
    redAlpha03: '#f2b9cb',
    redAlpha07: '#e57295',
    darkGray: '#4d4d4d',
    pink: '#dc1448'
};
