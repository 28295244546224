import styled from "styled-components";
import TooltipMui from "@material-ui/core/Tooltip";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";

const StyledP = styled.p`
    font-size: 14px;
    line-height: 24px;
    margin: 0;
`;
export const InfoTooltip = (props) => {
    let text = props.tooltipText;
    if (!text) {
        return;
    }
    return (
        <TooltipMui
            arrow
            placement="right-start"
            title={
                <React.Fragment>
                    <StyledP>{text}</StyledP>
                </React.Fragment>
            }
        >
            <HelpIcon className="help" />
        </TooltipMui>
    );
};
