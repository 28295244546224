import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
// Material-UI renamed ExpansionPanel to Accordion.
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import styled from "styled-components";
import { colors } from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Icon } from "@material-ui/core";
import QuestionIcon from "../../assets/icons/question.png";
import { CONF } from "../../config/config";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const StyledDialog = styled(Dialog)`
    top: initial !important;
    left: initial !important;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    .MuiBackdrop-root {
        display: none;
    }
    .MuiDialog-paper {
        margin: 0 !important;
        max-width: 400px !important;
        //width: 400px !important;
    }
    .MuiDialogContent-root {
        padding: 0px !important;
    }
    .MuiDialogTitle-root {
        flex: 0 0 auto;
        margin: 0;
        padding: 0 24px 16px 24px;
    }
    .MuiTypography-h6 {
        text-align: center;
        border-bottom: 3px solid ${colors.red};
    }
    .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 50px !important;
    }
    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 14px 0 !important;
    }
    .MuiIconButton-label {
        justify-content: flex-end !important;
    }
    .MuiIconButton-root {
        padding: 10px 10px 0 0 !important;
        width: 40px;
        margin-left: auto;
    }
`;

/*
const FaqContainer = styled.div`
  .MuiIconButton-root:hover {
    background-color: initial !important;
  }
`;
*/

export default function FormDialog() {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const toggleOpen = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Tooltip title={<div>FAQ</div>} arrow onClick={toggleOpen}>
                <Icon className="icon faqBtn">
                    <img src={QuestionIcon} />
                </Icon>
            </Tooltip>

            <StyledDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                elevation={3}
                className={"faqModal"}
            >
                <IconButton className={"hoverUnderline"} onClick={handleClose}>
                    <CancelIcon
                        style={{ color: colors.red, fontSize: "30px" }}
                    />
                </IconButton>
                <DialogTitle id="form-dialog-title">
                    Frequently Asked Questions
                </DialogTitle>
                <DialogContent>
                    <div className={classes.root}>
                        {!CONF.IS_STANDALONE && (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>
                                        Do we have a guide?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Yes. Please{" "}
                                        <a
                                            href={require("../../assets/ByronMeet_End_User_Guide.pdf")}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            click here to get it.
                                        </a>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>
                                    I'm not being matched
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    It may help if you click the pause button so
                                    that it turns into a play button. Then press
                                    the play button again.
                                    <br />
                                    If this doesn't help click 'Go back' and
                                    reenter the meetup. <br />
                                    Also make sure that you don't have any
                                    preferences set that prevent you from being
                                    matched. To do that, go to the startpage and
                                    click on "General Preferences" and check if
                                    any of the tabs have a hard filter in place.
                                    <br />
                                    If you're still experiencing issues, contact{" "}
                                    <a href="mailto:byron@bayer.com">
                                        byron@bayer.com
                                    </a>{" "}
                                    or type your question into the chat in the
                                    lobby.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography className={classes.heading}>
                                    I couldn't connect to my partner
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    The major cause for a failed connection is
                                    when your partner did not click on "Ready"
                                    and thus you'll be guided back to the lobby.{" "}
                                    <br />
                                    Sometimes, however, due to slow internet it
                                    may happen that when both partners accept to
                                    very different times, e.g. one partner at
                                    the very beginning of the countdown clock
                                    and one at the very end of it, the
                                    connection cannot be established. <br />
                                    A general advice is to always click on the
                                    "Ready" button as soon as possible. <br />
                                    If the issue does persist, please contact{" "}
                                    <a href="mailto:byron@bayer.com">
                                        byron@bayer.com
                                    </a>{" "}
                                    or ask in the chat of the lobby (visible to
                                    all meetup members).
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4a-content"
                                id="panel4ia-header"
                            >
                                <Typography className={classes.heading}>
                                    What is the play button for?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Next to the FAQ question mark you can see a
                                    little pause/play button. If it shows a
                                    pause button, it means that you are actively
                                    participating in the meetup. If you click
                                    it, it will put you on "pause", so that you
                                    won't be matched again. If you click it
                                    again, you'll be back to active status.
                                    <br /> You can also click the pause button
                                    during a videochat. It doesn't have an
                                    effect on your current meeting.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5a-content"
                                id="panel5a-header"
                            >
                                <Typography className={classes.heading}>
                                    My camera does not work
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    First and foremost, do you have any further
                                    applications running that require access to
                                    your camera, e.g. Zoom or Teams? If so,
                                    please close these apps and reload
                                    ByronMeet. <br />
                                    Additionally, you may click on the camera
                                    button on the top right of the screen inside
                                    the red bar. After a few seconds, you should
                                    be able to see the contents from your
                                    camera. If that is not the case, chose your
                                    respective camera device from the dropdown.
                                    Depending on whether you've granted
                                    ByronMeet permission to use the camera or
                                    not, you'll be asked to grant permission via
                                    a small browser popup at the top of the
                                    page. In this case, please check "Remember
                                    permission" so that you don't have to do
                                    this again and click "OK". Finally click
                                    "Confirm" to confirm your camera and audio
                                    choice.
                                    <br />
                                    If the issue does persist, please contact{" "}
                                    <a href="mailto:byron@bayer.com">
                                        byron@bayer.com
                                    </a>{" "}
                                    or ask in the chat of the lobby (visible to
                                    all meetup members).
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5a-content"
                                id="panel5a-header"
                            >
                                <Typography className={classes.heading}>
                                    The Login button does not work
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Use an 'incognito' or 'private' window in
                                    your browser and now go again to the{" "}
                                    <a href={`${window.location.origin}`}>
                                        Home
                                    </a>{" "}
                                    page.
                                    <br />
                                    If the issue does persist, please contact{" "}
                                    <a href="mailto:byron@bayer.com">
                                        byron@bayer.com
                                    </a>
                                    .
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </DialogContent>
            </StyledDialog>
        </React.Fragment>
    );
}
