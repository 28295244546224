import React, { useState } from "react";
import { useRef, useEffect } from "react";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "universal-cookie";
//import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, Navigation, Zoom } from "swiper";

import ErrorModalExternal from "../components/auth/errorModalExternal";
import { CONF } from "../config/config";

const Navbar = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 135px;
    position: relative;
    background-color: #fff;
    @media screen and (max-width: 1023px) {
        padding: 30px;
    }

    @media screen and (max-width: 767px) {
        padding: 35px 0;
    }

    .byronmeet-logo-wrap {
        flex-shrink: 1;
        transition: all 0.3s ease;

        @media screen and (max-width: 767px) {
            margin-left: 10px;
            max-width: 90%;
        }
        @media screen and (max-width: 400px) {
            margin-left: 5px;
            max-width: 80%;
        }
        @media screen and (max-width: 360px) {
            max-width: 75%;
        }

        @media screen and (max-width: 330px) {
            max-width: 70%;
        }
        img {
            width: 100%;
            max-width: 300px;
        }
    }

    .menu-wrap {
        display: flex;
        justify-content: flex-end;
        flex-shrink: 0;

        @media screen and (max-width: 767px) {
            display: none;
        }

        button {
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #11394f;
            padding-right: 45px;
            border: none;
            background-color: #fff;
        }
    }

    .hamburger-lines {
        height: 20px;
        width: 25px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: 767px) {
            display: none;
        }
        @media screen and (max-width: 360px) {
            right: 10px;
            width: 20px;
        }
        @media screen and (max-width: 330px) {
            right: 5px;
            width: 20px;
        }
    }

    .hamburger-lines .line {
        display: block;
        height: 3px;
        width: 100%;
        border-radius: 10px;
        background: #0e2431;
    }

    .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }

    .menu-items {
        display: flex;
    }

    li {
        list-style: none;
        margin-bottom: 30px;
    }

    .checkbox {
        position: absolute;
        display: block;
        height: 32px;
        width: 32px;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto 0;
        z-index: 5;
        opacity: 0;
        cursor: pointer;

        @media screen and (max-width: 360px) {
            right: 10px;
            width: 27px;
        }

        @media screen and (max-width: 330px) {
            right: 5px;
        }
    }

    .menu-items {
        background-color: #fff;
        width: 100%;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        transition: transform 0.5s ease-in-out;
        text-align: center;
        position: absolute;
        top: 97px;
        padding-top: 30px;

        button {
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #11394f;
            border: none;
            background-color: #fff;
            margin: 0 auto;
        }
    }

    input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
    }

    input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
        transform: rotate(45deg);
    }

    input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
        transform: scaleY(0);
    }

    input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
        transform: rotate(-45deg);
    }
`;

const Hero = styled.div`
    display: flex;
    padding-top: 135px;
    background-color: #11394f;

    @media screen and (max-width: 1023px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
        padding-top: 50px;
        flex-direction: column;
    }

    .text-wrap {
        padding-top: 55px;
        width: 50%;

        @media screen and (max-width: 1023px) {
            padding-top: 15px;
            padding-left: 30px;
        }

        @media screen and (max-width: 767px) {
            padding-top: 0px;
            padding-bottom: 32px;
            padding-left: 0;
            width: 100%;
        }

        .inner-text-wrap {
            max-width: 500px;
            margin: 0 auto;

            @media screen and (min-width: 2400px) {
                max-width: 760px;
                margin-top: 150px;
            }

            @media screen and (max-width: 767px) {
                padding-left: 50px;
                padding-right: 50px;
            }
        }

        h1 {
            font-size: 32px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: #fff;
            text-align: left !important;
            margin: 0;
            margin-bottom: 40px;

            @media screen and (min-width: 2400px) {
                font-size: 80px;
            }

            @media screen and (max-width: 1023px) {
                font-size: 25px;
                margin-bottom: 30px;
            }

            @media screen and (max-width: 767px) {
                font-size: 29px;
                text-align: center !important;
            }
        }

        h2 {
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: #fff;
            margin: 0;
            margin-bottom: 55px;

            @media screen and (max-width: 1023px) {
                font-size: 20px;
                margin-bottom: 30px;
            }

            @media screen and (max-width: 767px) {
                font-size: 20px;
                font-weight: 300;
                text-shadow: 0 5px 15px rgba(0, 0, 0, 0.16);
                text-align: center !important;
                margin-bottom: 26px;
            }
        }
    }

    .button-wrap {
        display: flex;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }

        button {
            color: #ffffff;
            padding: 20px 78px;
            border-radius: 33px;
            background-color: #dc1448;
            border: none;
            font-size: 22px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;

            :hover {
                background-color: #be1442;
            }

            @media screen and (max-width: 1023px) {
                padding: 15px 50px;
                font-size: 18px;
            }

            @media screen and (max-width: 767px) {
                font-size: 21px;
                text-align: center !important;
                padding: 13px 52px;
                display: flex;
                justify-content: center;
            }
        }

        .login-button {
            margin-right: 25px;

            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        p {
            display: none;
            text-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #fff;

            @media screen and (max-width: 767px) {
                display: block;
            }

            a {
                text-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
                font-size: 16px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                text-decoration: underline;
            }
        }
    }

    .image-wrap {
        width: 50%;
        max-width: 1400px;
        margin-left: auto;

        @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 33px;
        }

        img {
            width: 100%;
        }
    }
`;

const Product = styled.div`
    padding-top: 135px;
    background-color: #1c4257;
    padding-bottom: 95px;

    .facts-section {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        text-align: center;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            .fact {
                padding-bottom: 20px;
            }
            .fact-title {
                margin-bottom: 15px;
            }
        }
    }

    .fact-subtitle {
        color: #b0b0b0;
        font-size: 1em;
        margin: 0;
    }

    @media screen and (max-width: 1023px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
        padding: 27px;
    }

    &.about-us {
        background-color: #1c4257;

        .subtitle {
            margin-bottom: 125px;

            @media screen and (max-width: 1023px) {
                margin-bottom: 30px;
            }

            @media screen and (max-width: 767px) {
                margin-bottom: 27px;
            }

            h3 {
                margin-bottom: 40px;

                @media screen and (max-width: 767px) {
                    margin-bottom: 30px;
                }
            }
            p {
                font-size: 22px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.82;
                letter-spacing: normal;
                text-align: center;
                color: #f2f2f3;
                max-width: 730px;
                margin: 0 auto;

                @media screen and (min-width: 2400px) {
                    max-width: none;
                }

                @media screen and (min-width: 2400px) {
                    font-size: 40px;
                }

                @media screen and (max-width: 1023px) {
                    font-size: 20px;
                }
            }
        }
    }

    &.byron {
        background-color: #11394f;

        .about {
            p {
                max-width: 925px !important;
            }
        }
    }

    h3 {
        font-size: 40px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin: 0;

        @media screen and (min-width: 2400px) {
            font-size: 60px;
        }

        @media screen and (max-width: 1023px) {
            font-size: 25px;
        }

        @media screen and (max-width: 767px) {
            font-size: 29px;
            margin-bottom: 27px;
        }
    }

    .sub-content {
        opacity: 0.18;
        font-size: 140px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #f2f2f3;
        margin: 0;
        margin-bottom: 82px;

        @media screen and (min-width: 2400px) {
            font-size: 180px;
        }

        @media screen and (max-width: 1023px) {
            font-size: 70px;
            margin-bottom: 30px;
        }

        @media screen and (max-width: 767px) {
            font-size: 60px;
            margin-bottom: 0;
        }
    }

    img {
        width: 100%;
    }

    .swiper-initialized {
        @media screen and (max-width: 767px) {
            margin: 0 -27px;
        }
    }

    &.about-us {
        .swiper-slide {
            transform: none;

            &.swiper-slide-active {
                transform: none;
            }
        }
    }
    .swiper-wrapper {
        padding: 50px 0;
    }

    .swiper-slide {
        @media screen and (max-width: 767px) {
            transform: scale(0.9) !important;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #123950;
            opacity: 0.4;
        }

        &.swiper-slide-active {
            transform: scale(1.2) !important;

            &:before {
                display: none;
            }

            @media screen and (max-width: 767px) {
                transform: scale(1) !important;
            }
        }
    }

    /* Custom styles for Swiper navigation buttons */
    .swiper-button-next,
    .swiper-button-prev {
        color: white;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-size: 40px;
    }

    /* Apply blur to all slides */
    .swiper-slide {
        filter: blur(2px);
        transition: filter 0.3s ease; /* Smooth transition */
    }

    /* Remove blur from the active slide */
    .swiper-slide-active {
        filter: blur(0);
    }
    .about {
        margin-top: 70px;

        @media screen and (max-width: 1023px) {
            margin-top: 45px;
        }

        @media screen and (max-width: 767px) {
            margin-top: 0;
        }

        p {
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.82;
            letter-spacing: normal;
            text-align: center;
            color: #f2f2f3;
            max-width: 730px;
            margin: 0 auto;
            margin-top: 42px;

            @media screen and (max-width: 1023px) {
                margin-top: 30px;
                padding: 0 30px;
                font-size: 20px;
            }

            @media screen and (max-width: 767px) {
                margin-top: 0;
            }
        }
    }

    &.about-us {
        padding-bottom: 0;

        @media screen and (max-width: 1023px) {
            padding-bottom: 30px;
        }
    }
`;

const Contact = styled.div`
    background-color: #11394f;
    padding: 95px 100px 95px 100px;

    @media screen and (max-width: 1023px) {
        padding: 50px 70px;
    }

    @media screen and (max-width: 767px) {
        padding: 27px 25px;
    }

    .inner-wrapper {
        background-color: #1c4257;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 64px 132px 54px 132px;

        @media screen and (min-width: 2400px) {
            max-width: 1300px;
            margin: 0 auto;
        }

        @media screen and (max-width: 1023px) {
            padding: 30px 70px;
        }

        @media screen and (max-width: 767px) {
            padding: 27px 30px 35px 30px;
        }

        h3 {
            font-size: 40px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            margin: 0;

            @media screen and (max-width: 1023px) {
                margin-bottom: 30px;
            }

            @media screen and (max-width: 767px) {
                font-size: 29px;
                margin-bottom: 32px;
            }
        }

        a {
            color: #f2f2f3;
            font-weight: 500;
        }

        p {
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: center;
            color: #f2f2f3;
            margin: 0;

            @media screen and (max-width: 1023px) {
                margin-bottom: 0;
            }

            @media screen and (max-width: 767px) {
                font-size: 20px;
                font-weight: 300;
            }
        }

        .text-wrap {
            margin-bottom: 40px;

            @media screen and (max-width: 767px) {
                margin-bottom: 28px;
            }
        }

        .form-wrap {
            width: 100%;

            form {
                display: flex;
                flex-direction: column;
            }

            .input-wrap {
                margin-bottom: 40px;
                display: flex;

                @media screen and (max-width: 1023px) {
                    flex-direction: column;
                    margin-bottom: 20px;
                }

                @media screen and (max-width: 767px) {
                    flex-direction: column;
                    margin-bottom: 20px;
                }
            }

            input {
                flex: 50%;
                padding: 27px 0 27px 25px;
                border-radius: 7px;
                background-color: #0e3043;
                border: none;
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #c4c4c4;
                text-align: left;

                ::placeholder {
                    color: #c4c4c4;
                    opacity: 1;

                    @media screen and (max-width: 767px) {
                        color: #fff;
                        font-weight: 300;
                    }
                }

                @media screen and (max-width: 767px) {
                    font-size: 17px;
                    font-weight: 300;
                    color: #fff;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
                }

                &.name {
                    margin-right: 25px;

                    @media screen and (max-width: 1023px) {
                        margin-right: 0;
                        margin-bottom: 21px;
                    }

                    @media screen and (max-width: 767px) {
                        margin-right: 0;
                        margin-bottom: 21px;
                    }
                }
            }

            textarea {
                padding: 0;
                padding-left: 25px;
                padding-top: 25px;
                border-radius: 7px;
                background-color: #0e3043;
                border: none;
                font-size: 20px;
                text-align: center;
                text-align: left;
                color: #c4c4c4;

                ::placeholder {
                    color: #c4c4c4;
                    opacity: 1;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;

                    @media screen and (max-width: 767px) {
                        color: #fff;
                        font-weight: 300;
                    }
                }
            }

            .submit-button {
                display: block;
                max-width: 375px;
                padding: 22.5px 111px;
                border-radius: 36px;
                background-color: #d31548;
                border: none;
                font-size: 22px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                margin: 0 auto;
                margin-top: 55px;

                @media screen and (max-width: 767px) {
                    padding: 13px 55px 13px 52px;
                    font-size: 20px;
                    font-weight: normal;
                    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24);
                }
            }
        }
    }
`;

const Footer = styled.div`
    padding: 47px 135px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;

    @media screen and (max-width: 1023px) {
        padding: 30px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 20px 70px;
    }

    .logo-wrap {
        display: flex;
        align-items: center;

        img {
            width: 75px;

            @media screen and (max-width: 767px) {
                width: 35px;
            }
        }

        p {
            margin-left: 24px;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: #11394f;

            @media screen and (max-width: 1023px) {
                margin-left: 10px;
            }

            @media screen and (max-width: 767px) {
                font-size: 11px;
                margin-left: 11px;
            }
        }
    }

    .footer-link-wrap {
        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        a {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #11394f;
            margin-right: 60px;

            @media screen and (max-width: 1023px) {
                margin-right: 10px;
            }

            @media screen and (max-width: 767px) {
                font-size: 11px;
                margin-right: 0;
                margin-top: 14px;
            }
        }
    }

    .bayer-logo-wrap {
        img {
            width: 75px;

            @media screen and (max-width: 767px) {
                width: 60px;
                margin-top: 17px;
            }
        }
    }

    .fact {
        flex: 1;
        margin: 0 10px;
        text-align: center;
    }

    .fact-title {
        color: white;
        font-size: 1.5em;
        margin: 0;
    }

    .fact-subtitle {
        color: #b0b0b0;
        font-size: 1em;
        margin: 0;
    }
`;

// Install modules
SwiperCore.use([Autoplay, Navigation, Zoom]);

const LandingPage = (props) => {
    const [modalInfo, setModalInfo] = useState({ open: false, msg: "" });
    const path = CONF.OPS_MODE === "internal" ? "/bayronnect" : "";
    console.debug(`DEBUG> landing page: path set to: "${path}"`);
    const productsSection = useRef();
    const aboutSection = useRef();
    const contactSection = useRef();

    const cookies = new Cookies();

    const scroll = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const authenticateSetCookies = async (data) => {
            await fetch(`${CONF.BAYRONNECT_API_URL}/api/authenticate`, {
                method: "POST",
                body: JSON.stringify({
                    profile: data.profile,
                    id: data.UID,
                    data: data.data
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    console.log("RESPONSE DATA", response);
                    if (response.jwtToken) {
                        localStorage.setItem("jwtToken", response.jwtToken);
                        localStorage.setItem(
                            "username",
                            data.profile.email.toLowerCase()
                        );
                        cookies.set("newLogin", "true");
                        props.setJWTToken(true);
                    } else {
                        setModalInfo({ open: true, msg: response.msg });
                    }
                });
        };

        window.gigya.socialize.addEventHandlers({
            onLogin: function () {
                window.gigya.fidm.saml.continueSSO();
            }
        });
        window.gigya.accounts.addEventHandlers({ onLogin: firstLoginHandler });

        function firstLoginHandler(eventObj) {
            var application = window.location.hostname;
            var date = new Date();
            var apps = eventObj.data.bc_accessApplications;
            var json_obj;
            var timestamp = date.toISOString();
            console.log("EVENT OBJECT: ", eventObj);
            if (!apps) {
                // if application list empty, create new json object
                json_obj = {
                    hostname: application,
                    firstLogin: timestamp,
                    lastLogin: timestamp
                };
                apps = [JSON.stringify(json_obj)];
            } else {
                var isNewApp = true;
                var appIndex;
                // is app new or already in the apps list?
                for (var i = 0; i < apps.length; i++) {
                    // search for app in the array
                    var host = JSON.parse(apps[i]).hostname;
                    if (host == application) {
                        isNewApp = false;
                        appIndex = i;
                        break;
                    }
                }

                if (isNewApp) {
                    // app is new -> new entry in array
                    json_obj = {
                        hostname: application,
                        firstLogin: timestamp,
                        lastLogin: timestamp
                    };
                    apps.push(JSON.stringify(json_obj));
                } else {
                    // app is already in array, actualize timestamp in lastLogin
                    json_obj = JSON.parse(apps[appIndex]);
                    json_obj.lastLogin = timestamp;
                    apps[appIndex] = JSON.stringify(json_obj);
                }
            }

            window.gigya.accounts.setAccountInfo({
                data: { bc_accessApplications: apps }
            }); // update apps list on server
            authenticateSetCookies(eventObj);
        }
    }, []);

    function gigyaLogin() {
        window.gigya.accounts.showScreenSet({
            screenSet: "bayer-RegistrationLogin",
            sessionExpiration: "20000"
        });
    }

    function gigyaRegistration() {
        window.gigya.accounts.showScreenSet({
            screenSet: "bayer-RegistrationLogin",
            startScreen: "bayer-register-nosc"
        });
    }

    return (
        <React.Fragment>
            <Navbar>
                <div className="logo-container">
                    <div className="byronmeet-logo-wrap">
                        <img
                            src={path + "/byronmeet-logo.svg"}
                            alt="ByronMeetLogo"
                        />
                    </div>
                </div>
                <div className="menu-wrap">
                    {/* <button onClick={() => scroll(productsSection)}>
                        About
                    </button> */}
                    <button onClick={() => scroll(aboutSection)}>
                        About us
                    </button>
                    <button onClick={() => scroll(contactSection)}>
                        Contact us
                    </button>
                </div>
                <input className="checkbox" type="checkbox" name="" id="" />
                <div className="hamburger-lines">
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
                <div className="menu-items">
                    {/* <li>
                        <button onClick={() => scroll(productsSection)}>
                            Products
                        </button>
                    </li> */}
                    <li>
                        <button onClick={() => scroll(aboutSection)}>
                            About us
                        </button>
                    </li>
                    <li>
                        <button onClick={() => scroll(contactSection)}>
                            Contact us
                        </button>
                    </li>
                </div>
            </Navbar>
            <Hero>
                <div className="text-wrap">
                    <div className="inner-text-wrap">
                        <h1>Discover a new way to connect.</h1>
                        <h2>
                            Engage in brief, dynamic video chats with various
                            colleagues & experts, offering a fresh and exciting
                            approach to networking.
                        </h2>
                        <div className="button-wrap">
                            <button
                                className="login-button"
                                onClick={() => gigyaLogin()}
                            >
                                Login
                            </button>
                            <button onClick={() => gigyaRegistration()}>
                                Register
                            </button>
                            <p>
                                Already a member?{" "}
                                <a href="#" onClick={() => gigyaLogin()}>
                                    Log in
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="image-wrap">
                    <img src={path + "/laptop.png"} alt="Logo" />
                </div>
            </Hero>
            <Product ref={productsSection}>
                <div className="subtitle">
                    {/* <h3>Products</h3> */}
                    <p className="sub-content">ByronMeet</p>
                </div>
                <div className="facts-section">
                    <div className="fact">
                        <h3 className="fact-title">12718+ users</h3>
                        <p className="fact-subtitle">
                            from 85 countries in 329 cities
                        </p>
                    </div>
                    <div className="fact">
                        <h3 className="fact-title">31244+</h3>
                        <p className="fact-subtitle">
                            1:1 Speed network/random meetings
                        </p>
                    </div>
                    <div className="fact">
                        <h3 className="fact-title">Live Since 2020</h3>
                        <p className="fact-subtitle">Continuously improving</p>
                    </div>
                </div>
                <div className="about">
                    <h3>Connecting People. Made Simple.</h3>
                    <p>
                        ByronMeet is a speed networking tool that allows you to
                        connect and interact with the apposite experts in a
                        dynamic, low barrier fashion.
                        <br /> <br />{" "}
                    </p>
                    <p>
                        ByronMeet is part of the Byron networking suite. <br />{" "}
                    </p>
                </div>
                <Swiper
                    spaceBetween={100}
                    slidesPerView={2.5}
                    centeredSlides={true}
                    initialSlide={1}
                    loop={true}
                    roundLengths={true}
                    zoom={{
                        maxRatio: 2,
                        minRatio: 1
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true
                    }}
                    navigation
                    breakpoints={{
                        360: {
                            slidesPerView: 1.2,
                            spaceBetween: 5
                        },
                        768: {
                            slidesPerView: 1.5,
                            spaceBetween: 70
                        },
                        1024: {
                            slidesPerView: 1.9,
                            spaceBetween: 70
                        },
                        1280: {
                            slidesPerView: 2.5,
                            spaceBetween: 100
                        },
                        2400: {
                            slidesPerView: 3.1,
                            spaceBetween: 100
                        }
                    }}
                >
                    <SwiperSlide>
                        <img src={path + "/byronMeet.png"} alt="byronmeet" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={path + "/createMeetup.png"}
                            alt="createMeetup"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={path + "/friendsdebateExplained.png"}
                            alt="friendsdebateExplained"
                        />
                    </SwiperSlide>
                </Swiper>
            </Product>
            <Product className="byron">
                <div className="subtitle">
                    {/* <p className="sub-content">what people say...</p> */}
                    <h3>What's the buzz?</h3>
                </div>

                <div className="about">
                    {/* <h3>Transparency, Made simple.</h3> */}
                    {/* <p>
                        Connect with colleagues and experts from every corner of
                        your company. Share and spark ideas with a diverse mix
                        of people and perspectives.
                    </p> */}
                </div>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={2.5}
                    centeredSlides={true}
                    initialSlide={1}
                    loop={true}
                    roundLengths={true}
                    zoom={{
                        maxRatio: 2,
                        minRatio: 1
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true
                    }}
                    navigation
                    breakpoints={{
                        360: {
                            slidesPerView: 1.2,
                            spaceBetween: 5
                        },
                        768: {
                            slidesPerView: 1.5,
                            spaceBetween: 70
                        },
                        1024: {
                            slidesPerView: 1.9,
                            spaceBetween: 70
                        },
                        1280: {
                            slidesPerView: 2.5,
                            spaceBetween: 100
                        },
                        2400: {
                            slidesPerView: 3.1,
                            spaceBetween: 200
                        }
                    }}
                >
                    <SwiperSlide>
                        <img
                            src={path + "/Jesus-del-Valle-Rosales.png"}
                            alt="Jesus del Valle Rosales"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={path + "/Jan-Moritz-von-Berg.png"}
                            alt="Jan-Moritz von Berg"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={path + "/Louis-Casper-Venter.png"}
                            alt="Louis Casper Venter"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={path + "/Lena-Schermer.png"}
                            alt="Lena Schermer"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={path + "/Oliver-Woischnik.png"}
                            alt="Oliver Woischnik"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={path + "/Christopher-Blaesing.png"}
                            alt="Christopher Blaesing"
                        />
                    </SwiperSlide>
                </Swiper>
            </Product>
            <Product ref={aboutSection} className="about-us">
                <div className="subtitle">
                    <h3>About us</h3>

                    <p>
                        We are a group of tec enthusiasts with the mission to
                        make knowledge findable and to immediately connect you
                        with the expert you are looking for. <br />
                        We want you to find out what your company knows: in its
                        entirety.
                    </p>
                </div>
            </Product>
            <Contact ref={contactSection}>
                <div className="inner-wrapper">
                    <div className="text-wrap">
                        <h3>Contact us</h3>
                        <p>You want to know more?</p>
                        <p>
                            Send us an{" "}
                            <a href="mailto:byron@bayer.com">email</a>
                        </p>
                    </div>
                    {/* <div className="form-wrap">
                        <form>
                            <div className="input-wrap">
                                <input
                                    type="text"
                                    name="name"
                                    className="name"
                                    placeholder="Name/Surname"
                                />
                                <input
                                    type="text"
                                    name="email"
                                    className="email"
                                    placeholder="Email"
                                />
                            </div>
                            <textarea
                                name="message"
                                rows="7"
                                cols="50"
                                className="message"
                                placeholder="Message"
                            ></textarea>
                            <button className="submit-button">
                                Send Message
                            </button>
                        </form>
                    </div> */}
                </div>
            </Contact>
            <Footer>
                <div className="logo-wrap">
                    <img
                        src={path + "/byron-logo-footer-landing.svg"}
                        alt="Powered by"
                    />
                    <p>powered by Bayer Pharma IT</p>
                </div>
                <div className="footer-link-wrap">
                    <a
                        href="https://www.bayer.com/en/conditions-of-use"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms and Conditions
                    </a>
                    <a
                        href="https://www.bayer.com/en/privacy-statement"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Statement
                    </a>
                    <a
                        href="https://www.bayer.com/en/imprint"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Imprint
                    </a>
                </div>
                <div className="bayer-logo-wrap">
                    <a
                        href="https://www.bayer.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={path + "/bayer-logo-footer.png"}
                            alt="Footer logo"
                        />
                    </a>
                </div>
            </Footer>
            {modalInfo.open && (
                <ErrorModalExternal
                    msg={modalInfo.msg}
                    setModalInfo={setModalInfo}
                />
            )}
        </React.Fragment>
    );
};

export default LandingPage;
