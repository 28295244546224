/*
 *  The variables in the "window._env_" object are taken from
 *   from env-config.js (which is created during container start by the
 *    env.sh script.). They can have **any** name (do not need to start with
 *    REACT_APP_*).
 *
 *
 *  The variables in the "process.env." object must start with REACT_APP_*.
 *  see: https://create-react-app.dev/docs/adding-custom-environment-variables/
 *
 *
 * General logic:
 *    the windows._env_.REACT_APP_* values
 *   supersede process.env.REACT_APP* values
 *
 *
 */

export const CONF = {

    BAYRON_URL:
        window._env_.REACT_APP_BAYRON_URL !== undefined
            ? window._env_.REACT_APP_BAYRON_URL
            : process.env.REACT_APP_BAYRON_URL || "",

    BAYRONNECT_API_URL:
        window._env_.REACT_APP_BAYRONNECT_API_URL !== undefined
            ? window._env_.REACT_APP_BAYRONNECT_API_URL
            : process.env.REACT_APP_BAYRONNECT_API_URL || "",

    OPS_MODE:
        (window._env_.REACT_APP_OPS_MODE !== undefined && window._env_.REACT_APP_OPS_MODE !== "")
            ? window._env_.REACT_APP_OPS_MODE
            : process.env.REACT_APP_OPS_MODE || "",

    NODE_ENV:
        window._env_.NODE_ENV !== undefined
            ? window._env_.NODE_ENV
            : process.env.NODE_ENV || "",

    REACT_APP_NODE_ENV:
        window._env_.REACT_APP_NODE_ENV !== undefined
            ? window._env_.REACT_APP_NODE_ENV
            : process.env.REACT_APP_NODE_ENV || "",


    ENTRA_ID_TOKEN_DEST:
        (window._env_.REACT_APP_ENTRA_ID_TOKEN_DEST !== undefined && window._env_.REACT_APP_ENTRA_ID_TOKEN_DEST !== "")
            ? window._env_.REACT_APP_ENTRA_ID_TOKEN_DEST
            : process.env.REACT_APP_ENTRA_ID_TOKEN_DEST || window.location.origin,

    ENTRA_ID_TENANT: "fcb2b37b-5da0-466b-9b83-0014b67a7c78", // Bayer
    ENTRA_ID_CLIENT: "4866ff70-57c5-49ae-9c82-7e6c6a37cf7f", // Bayron

    IMAGES_URL:
        window._env_.REACT_APP_IMAGES_URL !== undefined
            ? window._env_.REACT_APP_IMAGES_URL
            : process.env.REACT_APP_IMAGES_URL || "",

    APIGW_URL:
        (window._env_.REACT_APP_APIGW_URL !== undefined && window._env_.REACT_APP_APIGW_URL !== "")
            ? window._env_.REACT_APP_APIGW_URL
            : process.env.REACT_APP_APIGW_URL || "",

};

CONF.IS_STANDALONE = (() => {
    switch (CONF.OPS_MODE) {
        case "internal": return false;
        case "external": return true;
        default: return false;
    }
})();