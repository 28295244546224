import { InfoTooltip } from "../infoTooltip";
import React from "react";

export const FileDropInput = (props) => {
    return (
        <div className="input-wrap">
            <label>
                Photo:{" "}
                <InfoTooltip tooltipText="Choose a picture that best represents your meetup." />
            </label>
            <input
                type="file"
                accept="image/*"
                onChange={props.onChange}
                className="photo-input"
            />
            <img width={"200px"} src={props.photoSrc} onClick={props.onClick} />
        </div>
    );
};
