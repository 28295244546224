/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../general/styles";
import { withRouter } from "react-router-dom";
import { chooseMeetupOnDB, deleteMeetup } from "../../hooks/socketOperations";
import breakpoint from "styled-components-breakpoint";
import DownloadIcon from "@material-ui/icons/GetApp";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import DeleteIcon from "@material-ui/icons/Delete";
// import LinkIcon from "@material-ui/icons/Link";
// import ProfilePictureLink from "../general/profilepicturelink";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Dialog from "@material-ui/core/Dialog";
import MeetupForm from "../meetups/meetupForm";
import CloseIcon from "@material-ui/icons/Close";
import NewsModal from "../general/newsModal";
import UserMediaDevices from "../general/userMediaDevices";

import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import { createEvent } from "./calendarInvite";

// Jesus Debate Meetup.
// import PrefContainer from "../preferences/prefContainer";
// import UserProfileModal from "../general/userProfileModal";
import DebateOrFriendsMeetup from "./debateOrFriendsMeetup";

export const escapeHtml = (text) => {
    let map = {
        "<": "&lt;",
        ">": "&gt;"
    };

    return text.replace(/[<>]/g, function (m) {
        return map[m];
    });
};

const MeetupWrap = styled.div`
    width: calc(100% * (1 / 4) - 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    margin-bottom: 20px;
    border-radius: 0;
    // flex: 1 0 21%;
    margin-left: 12px;
    margin-right: 12px;

    .meetupHeader {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: ${colors.grey};
        padding: 8px 14px;
        box-sizing: border-box;

        .meetupOptionsWrap {
            display: flex;
            width: 33%;
        }
        .meetupOwner {
            margin: 0;
            padding: 0;
            font-size: 12px;
            font-weight: 500;
            color: #11394f;
            width: 33%;
            text-align: center;
        }
        .meetupActionsWrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .deleteIcon {
            margin: 0 5px;
            font-size: 20px;
            color: white;
            cursor: pointer;
            transition: all 0.1s linear;
            &:hover {
                transform: scale(1.2);
            }
        }
        .closeMeetupButton {
            display: flex;
        }
    }

    .meetupBodyWrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 40px;
        height: 100%;

        font-weight: normal;
        font-stretch: normal;
        font-style: normal;

        h3 {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000000;
            margin: 10px 0;
            min-height: 65px;
        }

        p {
            font-size: 14px;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #dc1448;
            margin: 5px 0;
        }

        .button {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: ${(props) => (props.meetupToday ? "white" : "#dc1448")};
            padding: 10px 23px;
            border-radius: 15px;
            background-color: ${(props) =>
                props.meetupToday ? "#dc1448" : "white"};
            border-color: ${(props) =>
                props.meetupToday ? "initial" : colors.pink};
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: all 0.1s linear;
            margin: 15px 0 5px;

            :hover {
                background-color: ${(props) =>
                    props.meetupToday ? "#BE1442" : "#e9e9e9"};
                box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
            }
        }

        @media only screen and (orientation: portrait) and (max-width: 624px) {
            display: flex;
            width: 100%;
            align-items: center;

            .meetupTitleTimeWrap {
                width: 100%;
                margin: 0px 10px 0px 0px;
            }
        }
    }

    @media only screen and (orientation: portrait), (max-width: 1000px) {
        width: calc(85% * (1 / 2) - 60px);
    }

    @media only screen and (orientation: portrait) and (max-width: 624px) {
        width: 100%;
        padding: 20px 20px;
    }

    &.meetupActionsWrap {
        cursor: pointer;
        flex-wrap: wrap;
        min-height: 100px;
        justify-content: flex-start;
    }
    .message-wrap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .alert-box {
        padding: 15px;
        border: 1px solid transparent;
        border-radius: 4px;
        margin-top: 0.5em;
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .success {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
        // display: none;
        opacity: 0;
    }
    .alert-hidden {
        opacity: 0;
        transition: all 250ms linear 1s;
    }
    .alert-shown {
        opacity: 1;
    }
`;

const Meetup = (props) => {
    const meetup = props.meetup;
    //console.log("meetup", meetup); GMT - 2 (!)
    const meetups = props.meetups;
    const [meetupPeopleCount, setMeetupPeopleCount] = useState(
        meetup.userCount ? meetup.userCount : 0
    );
    const [openLink, setOpenLink] = useState(false);
    const userId = localStorage.getItem("userId");
    const editable = userId === meetup.creator;

    const startDate = new Date(new Date(meetup.startDate).getTime());
    const endDate = new Date(new Date(meetup.endDate).getTime());
    const [showUserMedia, setShowUserMedia] = useState(false);

    const socket = props.socket;
    const [showDebateOrFriendsMeetup, setShowDebateOrFriendsMeetup] =
        useState(false);
    // const [userLanguages, setUserLanguages] = useState(false);

    // For Copy Link to meetup to Clipboard.
    // TODO +++ Alen please check this. isShowingAlert is assigned a value but never used.
    // eslint-disable-next-line no-unused-vars
    const [isShowingAlert, setIsShowingAlert] = useState(false);

    // tzoffset is the offset from the client's time zone to UTC in milliseconds
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

    useEffect(() => {
        socket.on("updateUserCount", (meetupData) => {
            console.log("updateUserCount", meetupData);
            if (meetupData.meetup[0].id === meetup.id) {
                setMeetupPeopleCount(meetupData.meetup[0].userCount);
            }
        });
        return function cleanup() {
            if (socket) {
                socket
                    .removeListener("updateUserCount")
                    .on("updateUserCount", () => {});
            }
        };
    }, [socket]);

    const todayDate = new Date();

    const meetupDate =
        startDate.getDate() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getFullYear();

    const meetupTimeString =
        startDate.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        }) +
        " - " +
        endDate.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        });

    const meetupHour = startDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
    });

    const meetupEndHour = endDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
    });

    const meetupToday =
        startDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0);
    const meetupGoing =
        startDate.setHours(0, 0, 0, 0) < todayDate.setHours(0, 0, 0, 0) &&
        endDate.setHours(0, 0, 0, 0) >= todayDate.setHours(0, 0, 0, 0);

    const [meetupEditModal, setMeetupEditModal] = useState(false);

    const removeMeetup = (meetupId) => {
        deleteMeetup(meetupId, socket);
    };

    const openMeetupEditModal = () => {
        setMeetupEditModal(true);
    };
    const closeMeetupEditModal = () => {
        setMeetupEditModal(false);
    };

    const [meetupCloneModal, setMeetupCloneModal] = useState(false);

    const openMeetupCloneModal = () => {
        setMeetupCloneModal(true);
    };
    const closeMeetupCloneModal = () => {
        setMeetupCloneModal(false);
    };

    const chooseMeetup = async (meetupObj) => {
        if (props.userLanguages.length === 0) {
            alert("Please choose at least one language before continuing");
            props.openPreferencesModal(true);
        } else {
            sessionStorage.setItem("duration", meetupObj.duration.toString());
            const currentUsersInLobby = await chooseMeetupOnDB(
                meetupObj.id,
                userId,
                socket.id
            );
            props.history.push({
                pathname: encodeURI(meetupObj.id) + "/lobby",
                state: {
                    meetup: meetup,
                    currentUsersInLobby: currentUsersInLobby
                }
            });
        }
    };

    function shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    // Jesus cleaning warnings.
    // TODO +++ Alen please check this. I removed the sanitizedParticipants variable.
    // Original:
    // let sanitizedParticipants = [];
    /*
  if (meetup.users) {
    sanitizedParticipants = shuffle(meetup.users.slice(0, 5));
  }
  */
    // Jesus below.
    if (meetup.users) {
        shuffle(meetup.users.slice(0, 5));
    }

    const createEventStringOutlookDate = () => {
        let participantsArray = [];

        if (meetup.closedParticipants.length > 0) {
            let closedParticipantsArray = meetup.closedParticipants.split(",");
            closedParticipantsArray.forEach((participant) =>
                participantsArray.push({ email: participant })
            );
        }

        const newEvent = createEvent({
            id: meetup.id,
            name: meetup.name,
            startDate: meetup.startDate,
            endDate: meetup.endDate,
            attendees: participantsArray,
            type: meetup.eventType,
            website: meetup.website,
            address: meetup.address,
            // city: meetup.city,
            // country: meetup.country,
            description: meetup.description,
            meetupAll: meetup
        });
        const textArray = newEvent.split("\n").filter((f) => f.trim() !== "");
        const event = textArray.join("\n");
        console.log("conf3", meetup);

        return event;
    };

    const copyToClipBoard = () => {
        if (meetup.eventType == "face2face" || meetup.eventType === "hybird") {
            navigator.clipboard.writeText(
                window.location.href + "event/" + meetup.id
            );
        } else {
            navigator.clipboard.writeText(window.location.href + meetup.id);
        }

        setIsShowingAlert(true);
        setTimeout(() => {
            setIsShowingAlert(false);
        }, 1000);
    };

    return (
        <MeetupWrap meetupToday={meetupToday || meetupGoing}>
            <div className="meetupHeader">
                <div
                    className="meetupActionsWrap"
                    onClick={() => copyToClipBoard()}
                >
                    <Tooltip
                        onClick={() => {
                            const event = createEventStringOutlookDate();
                            window.open(
                                `data:text/calendar;charset=utf8,${escapeHtml(
                                    event
                                )}`
                            );
                        }}
                        title={<div>Download Outlook Invite</div>}
                        arrow
                    >
                        <DownloadIcon className="deleteIcon" />
                    </Tooltip>
                    <Tooltip
                        title={
                            <div>
                                Copy Link <br /> to Meetup
                            </div>
                        }
                        arrow
                    >
                        <LinkIcon className="deleteIcon" />
                    </Tooltip>
                </div>
                {/* <p className="meetupOwner">{editable ? "OWNER" : "GUEST"}</p>*/}
                <div className="meetupActionsWrap">
                    {/*<div
            className=""
            onClick={() => {
              setOpenLink(true);
            }}
          >
            <LinkIcon className="deleteIcon"/>
          </div>{" "}*/}
                    <Tooltip
                        title={<div>Clone Meetup</div>}
                        arrow
                        onClick={() => {
                            openMeetupCloneModal();
                        }}
                    >
                        <FileCopyIcon
                            className="deleteIcon"
                            style={{ fontSize: "16" }}
                        />
                    </Tooltip>
                    {editable && (
                        <>
                            <Tooltip
                                title={<div>Edit Meetup</div>}
                                arrow
                                onClick={() => {
                                    openMeetupEditModal();
                                }}
                            >
                                <EditIcon
                                    className="deleteIcon"
                                    style={{ fontSize: "16" }}
                                />
                            </Tooltip>
                            <Tooltip
                                title={<div>Delete Meetup</div>}
                                arrow
                                onClick={() => {
                                    removeMeetup(meetup.id);
                                }}
                            >
                                <DeleteIcon
                                    className="deleteIcon"
                                    style={{ fontSize: "16" }}
                                />
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>
            {/* TODO +++ Alen: Display alert floating over the component for a few seconds
      <div className={`alert-box success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}>Meetup Link copied to Clipboard.</div>
      */}
            <div className="meetupBodyWrap">
                {meetupToday ? (
                    <>
                        <p>Today</p>
                        <p>
                            {meetupHour} - {meetupEndHour}
                        </p>
                    </>
                ) : (
                    <>
                        <p>{meetupDate} </p>
                        <p>{meetupTimeString}</p>
                    </>
                )}
                <h3>{meetup.name}</h3>
                <p>
                    {meetupPeopleCount > 0
                        ? `${meetupPeopleCount} active ${
                              meetupPeopleCount === 1 ? "user" : "users"
                          }`
                        : "No active users"}
                </p>
                <div
                    className="button"
                    onClick={async () => {
                        // if (!localStorage.getItem('currentAudioId')) {
                        if (
                            !meetup.eventType ||
                            meetup.eventType === "bayronnect"
                        ) {
                            setShowUserMedia(true);
                        } else {
                            // transfer to event page
                            props.history.push({
                                pathname: "/event/" + encodeURI(meetup.id),
                                state: {
                                    meetup: meetup
                                }
                            });
                        }
                    }}
                >
                    ENTER
                </div>
            </div>
            {showDebateOrFriendsMeetup ? (
                <DebateOrFriendsMeetup
                    meetup={meetup}
                    showDebateOrFriendsMeetup={showDebateOrFriendsMeetup}
                    setShowDebateOrFriendsMeetup={setShowDebateOrFriendsMeetup}
                />
            ) : (
                ""
            )}
            {showUserMedia ? (
                <UserMediaDevices
                    showUserMedia={showUserMedia}
                    setShowUserMedia={setShowUserMedia}
                    chooseMeetup={chooseMeetup}
                    meetup={meetup}
                />
            ) : (
                ""
            )}
            {openLink && (
                <NewsModal link={meetup.name} setModal={setOpenLink} />
            )}

            <Dialog
                open={meetupEditModal}
                onClose={closeMeetupEditModal}
                aria-labelledby="form-dialog-title"
            >
                <div className="modalWrap">
                    <div className="newMeetupHeader">
                        <h3>Edit meetup</h3>
                        <div
                            className="exitModal"
                            onClick={closeMeetupEditModal}
                        >
                            <CloseIcon className="closeModalIcon" />
                        </div>
                    </div>
                    <MeetupForm
                        meetups={meetups}
                        closeMeetupModal={closeMeetupEditModal}
                        meetup={meetup}
                        action="update"
                        socket={socket}
                    />
                </div>
            </Dialog>

            <Dialog
                open={meetupCloneModal}
                onClose={closeMeetupCloneModal}
                aria-labelledby="form-dialog-title"
            >
                <div className="modalWrap">
                    <div className="newMeetupHeader">
                        <h3>Clone meetup</h3>
                        <div
                            className="exitModal"
                            onClick={closeMeetupCloneModal}
                        >
                            <CloseIcon className="closeModalIcon" />
                        </div>
                    </div>
                    <MeetupForm
                        meetups={meetups}
                        closeMeetupModal={closeMeetupCloneModal}
                        meetup={meetup}
                        meetupCloneName={"Clone - " + meetup.name}
                        action="clone"
                        socket={socket}
                    />
                </div>
            </Dialog>
        </MeetupWrap>
    );
};

export default withRouter(Meetup);
