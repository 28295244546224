import { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import { CONF } from "../config/config";
// Hook to open Socket with Signaling Server (see bayronnect-server)

const useSocket = () => {
    // const keycloak = useKeycloak();
    const [value, setValue] = useState(false);
    const [soc, setSoc] = useGlobal("socket");
    const io = require("socket.io-client");

    const initConnection = () => {
        // Enter signaling URL here
        var soc = io.connect(
            CONF.BAYRONNECT_API_URL,
            {
                path: "/sockets",
                transports: ["websocket"],
                upgrade: false,
                closeOnBeforeunload: false,
                //'sync disconnect on unload': true
            },
            {
                "force new connection": true,
            }
        );

        setSoc(soc);
        setValue(soc);
        // return soc.disconnect;
    };

    useEffect(() => {
        if (soc) {
            setValue(soc);
        } else {
            initConnection();
        }
    }, []);
    return value;
};

export default useSocket;
