import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CONF } from "../../config/config";

export const EventTypeDropdown = (props) => {
    const [value, setValue] = useState(props.eventType);

    return (
        <Select
            id="eventType"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                props.setEventType(e.target.value);
                if (e.target.value !== "bayronnect") {
                    props.setExpand(false);
                }
            }}
            className="chatDurationSelect"
            inputProps={{
                name: "eventType"
            }}
            label="Choose the type of event you want to create."
        >
            <MenuItem value={"bayronnect"}>Online Speed Networking</MenuItem>
            {!CONF.IS_STANDALONE && (
                <MenuItem value={"face2face"}>Face 2 Face</MenuItem>
            )}
            {!CONF.IS_STANDALONE && (
                <MenuItem value={"online"}>Online</MenuItem>
            )}
            {!CONF.IS_STANDALONE && (
                <MenuItem value={"hybrid"}>Hybrid</MenuItem>
            )}
        </Select>
    );
};
