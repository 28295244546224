import React, { useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { InputControls } from "../general/inputControls";
import Profile from "../stage/profile";
import breakpoint from "styled-components-breakpoint";
import QuitButton from "../stage/quitButton";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import KeyboardReturnRoundedIcon from "@material-ui/icons/KeyboardReturnRounded";
import VideoCanvas from "../general/videoCanvas";

const LocalVideoStyled = styled.div`

  display: flex;

  @media screen  and (max-width: 1024px) {
    justify-content: center;
  }

  ${breakpoint("tablet")`
       margin: 0px 15px 0 15px;
    `}
  .videoWrap {
    max-width: 100%;
    height: 100%;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    ${breakpoint("tablet")`
       margin: 0px;
    `}


    ${breakpoint("desktop")`
    `} @media screen and(max-height: 400 px) {
  }

    @media screen and (min-width: 800px) and (max-width: 1090px) {


    }

    @media only screen and (orientation: portrait), (max-width: 732px) {
      margin: 0px;
      margin-top: 0px;
      margin: 0 auto;
    }
    @media screen  and (max-width: 1024px) {
      // margin-top: 80px !important;
      margin-bottom: 80px !important;
      max-width: none !important;


    }

  }


  .VideoBox {
    height: 100%;
    width: 100%;
    //max-height: 480px;
    position: relative;
    padding-right: 0 !important;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-height: 400px) {
      height: auto;
      margin-top: 40px;
    }

    @media screen and (max-width: 800px) {
      height: auto;
      margin-top: 30px;

    }
    @media screen and (min-width: 800px) and (max-width: 1024px) {
      height: auto;
      margin-top: 40px;
    }

    @media screen and (max-width: 1024px) {
      height: 100%;
      justify-content: flex-end;
      min-height: 250px;
    }

    @media screen and (max-width: 768px) {
     flex-direction: column;
      align-items: initial;

      .button-container{
        max-width: 100% !important;
      }

      .input-controls{
        position: initial!important;
        padding: 10px 0;
      }

    }

    .MuiBackdrop-root {
      position: absolute !important;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.8) !important;
    }

    .closeInfoBtn {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .output-video{
    position: absolute;
  }

  .myVideo {
    //height: 100%;
    width: 100%;
    z-index: 2;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: contain;
    flex-grow: 1;
    //object-fit: cover;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    display: none !important;

    //position: absolute;
    max-height: 660px;

    ${breakpoint("desktop")`
        width: 100%;
        // height: 390px;
        display: block;
    `};

    @media screen and (max-height: 400px) {
      width: 100%;
      //height: 290px;
      display: block;
      // max-height: 400px;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
      display: block;

    }
    @media screen and (min-width: 800px) and (max-width: 1090px) {
      width: 100%;
      //height: 290px;
      display: block;
      // max-height: 400px;
    }

    background-image: ${(props) => props.localBackgroundImage};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: black;
  }

  .loaderInfoText {
    font-size: 22px;
    text-align: center;
    color: white;
  }

  .localVideoLoader {
    max-height: 480px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
    z-index: 2;
    display: none;
    //todo uncomment when implemented
      // display: ${(props) => (props.showLocalVideo ? "none" : "flex")}
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: ${(props) => props.localBackgroundImage}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ${breakpoint("desktop")`
        width: 100%;
        height: 100%;
        // display: block;
    `};
    @media screen and (max-height: 400px) {
      width: 100%;
      height: 100%;
      // display: block;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
      // display: block;
      max-height: 300px;
    }
    @media screen and (min-width: 800px) and (max-width: 1090px) {
      width: 100%;
      height: 100%;
      // display: block;
    }
  }

  .showLocalVideo {
    display: flex;
    //todo uncomment when implemented
      // display: ${(props) => (props.showLocalVideo ? "flex" : "none")};
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    @media screen and (max-height: 1024px) {
      margin: 0 auto;

    }

  }

  .buttonWrap {
    display: flex;
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 1;

    @media screen and (max-width: 1024px) {
     top: 40px;
    }
  }

`;

const LocalStream = (props) => {
    let localVideo = useRef(null);
    const myInfo = JSON.parse(localStorage.getItem("myInfo"));
    let localBackgroundImage = props.location.state.backgroundImage
        ? props.location.state.backgroundImage
        : "none";

    const [showCanvasVideo, setShowCanvasVideo] = useState(false);

    useEffect(() => {
        // fetchCurrentUser();
        if (props.stream) {
            props.stream.getTracks().forEach(function (track) {
                if (track.kind === "video") {
                    setShowCanvasVideo(true);
                }
            });
        }

        return function cleanup() {};
    }, []);

    return (
        <LocalVideoStyled
            showLocalVideo={props.showLocalVideo}
            localBackgroundImage={localBackgroundImage}
        >
            <div className="videoWrap">
                <div className="buttonWrap">
                    <QuitButton
                        currentPage={props.currentPage}
                        matchData={props.matchData}
                        meetup={props.meetup}
                        socket={props.socket}
                        history={props.history}
                        small={true}
                        backToBayron={true}
                        toBayron={true}
                        setQuitButtonClicked={props.setQuitButtonClicked}
                        hollow={true}
                        popup={true}
                        width="240px"
                        tooltipText="Leave this meeting and go back to the Home page."
                        img={<HomeRoundedIcon />}
                    />
                    {props.displayComponent.match && (
                        <QuitButton
                            small={true}
                            backToLobby={true}
                            setQuitButtonClicked={props.setQuitButtonClicked}
                            style={{
                                display:
                                    props.currentPage != "match" ? "none" : ""
                            }}
                            popup={true}
                            width="240px"
                            tooltipText="Leave this conversation and go back to the Lobby to match somebody else."
                            img={<KeyboardReturnRoundedIcon />}
                        />
                    )}
                </div>

                <div className="localVideoLoader">
                    <svg
                        version="1.1"
                        id="loader-1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="250px"
                        height="250px"
                        viewBox="0 0 40 40"
                        enableBackground="new 0 0 40 40"
                    >
                        <path
                            opacity="0.2"
                            fill="#000"
                            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                  C22.32,8.481,24.301,9.057,26.013,10.047z"
                        >
                            <animateTransform
                                attributeType="xml"
                                attributeName="transform"
                                type="rotate"
                                from="0 20 20"
                                to="360 20 20"
                                dur="1.3s"
                                repeatCount="indefinite"
                            />
                        </path>
                    </svg>
                    <p className="loaderInfoText">Waiting for initialization</p>
                </div>
                <div className="showLocalVideo">
                    <div className="VideoBox" id={"myVideoBox"}>
                        {showCanvasVideo && (
                            <VideoCanvas
                                currentPage={props.currentPage}
                                tempStream={props.tempStream}
                                setTempStream={props.setTempStream}
                                stream={props.stream}
                                sharingScreen={props.sharingScreen}
                            />
                        )}

                        {props.stream && (
                            <InputControls
                                sharingScreen={props.sharingScreen}
                                currentStream={props.stream}
                                localVideo={localVideo}
                                setShowUserMedia={props.setShowUserMedia}
                                activateScreenRecording={
                                    props.activateScreenRecording
                                }
                            />
                        )}
                    </div>
                    <Profile
                        myProfile={true}
                        profile={myInfo.profile}
                        location={myInfo.location}
                        position={myInfo.position}
                        email={myInfo.email}
                        currentPage={props.currentPage}
                        displayComponent={props.displayComponent}
                    />
                </div>
            </div>
        </LocalVideoStyled>
    );
};

export default withRouter(LocalStream);
