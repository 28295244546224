import React, { useState, useCallback, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import SessionTimer from "../stage/sessiontimer";
import breakpoint from "styled-components-breakpoint";
import Profile from "../stage/profile";
import TopicDisplay from "../stage/topicdisplay";
import ReconnectionPopup from "../stage/reconnectionPopup";
import SkillsOverlay from "../stage/skillsOverlay";
import ChatRoom from "../lobby/chatRoom";
import NewsModal from "../general/newsModal";
import IconButton from "@material-ui/core/IconButton";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import { CONF } from "../../config/config";

// import UserMediaDevices from "../general/userMediaDevices";
// import { set } from "date-fns";

//support for FF
// import adapter from 'webrtc-adapter';
// import meetup from "../meetups/meetup";

// TODO +++ Alen please check this. RTCSessionDescription is assigned a value but never used.
// eslint-disable-next-line no-unused-vars
const { RTCPeerConnection, RTCSessionDescription } = window;
// Actual StagePage with WebRTC implementation
const VidGen = styled.div`
  //padding-bottom: 50px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (orientation: portrait), (max-width: 732px) {
    justify-content: flex-start;
    padding-bottom: 15px;
    flex-direction: column-reverse;
  }

  .video-expand{
    flex: 0 0 auto;
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    background: #66B512!important;
    position: absolute;
    right: 15px;
    bottom: 15px;
    height: 54px;
    width: 54px;
    color: white;
  }

  .next-button {
    text-align: center;
  }

  .videoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .divWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    ${breakpoint("tablet")`
      max-width: 1400px;
      width: 100%;
      justify-content: center;
      flex-direction: row;
    `}
  }

  .videoWrap {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;




    @media only screen and (orientation: portrait), (max-width: 732px) {
      margin: 0px;
      margin-top: 0px;
      margin: 0 auto;
    }
  }
  .videoLoader {
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 2;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: ${(props) => (props.showRemoteVideo ? "none" : "flex")};

    > p {
      color: white;
    }
  }
  .VideoBox {
    height: 100%;
    width: 100%;
    height: initial!important;
    position: relative;
    padding-right: 0 !important;



    @media screen and (max-width: 800px) {
      height: auto;
    }
    @media screen and (min-width: 800px) and (max-width: 1090px) {
      height: auto;
      // margin-top: 40px;
    }

    .MuiBackdrop-root {
      position: absolute !important;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.8) !important;
    }
    .closeInfoBtn {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .foreignVideo {
    //height: 100%;
    height: initial !important;
    width: 100%;
    z-index: 2;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex-grow: 1;
    object-fit: contain;
    aspect-ratio: 4/3;
    background: black!important;

    // background-image: ${(props) => props.remoteBackgroundImage};
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    //background-color: transparent;

    ${breakpoint("desktop")`
        width: 100%;
        height: 100%;
        display: block;
    `};

    &.dontMirror {
      transform: scale(-1, 1);
    }

    @media screen and (max-height: 800px) {
      width: 100%;  display: block;
      // max-height: 400px;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
      display: block;
    }
    @media screen and (min-width: 800px) and (max-width: 1090px) {
      width: 100%;
      display: block;
      // max-height: 400px;
    }

    background-image: ${(props) => props.remoteBackgroundImage};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

  }

  &.expanded{

    .showRemoteVideo{
      div:not(.VideoBox){
        opacity: 0;
      }
    }
    #foreignVideoBox{
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 95%;
      height: 95% !important;
      margin: auto;
      z-index: 99999;
    }

    .foreignVideo{
      height: 100%!important;
      border-radius: 8px;
    }
  }

  .myVideo {
    height: 480px;
    width: 100%;
    height: 100%;
    background-color: blue;
    z-index: 2;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: fill;
    flex-grow: 1;
    object-fit: cover;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    ${breakpoint("desktop")`
        width: 100%;
        height: 480px;
        display: block;
    `};

    @media screen and (max-height: 800px) {
      width: 100%;
      display: block;
      // max-height: 400px;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
      display: block;
    }
    @media screen and (min-width: 800px) and (max-width: 1090px) {
      width: 100%;
      display: block;
      // max-height: 400px;
    }

    background-image: ${(props) => props.localBackgroundImage};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .remoteVideoLoader {
    width: 100%;
    height: 100%;
    max-height: 480px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
    z-index: 2;
    display: ${(props) => (props.showRemoteVideo ? "none" : "flex")};
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: ${(props) => props.remoteBackgroundImage}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ${breakpoint("desktop")`
        width: 100%;
        height: 100%;
        // display: block;
    `};
    @media screen and (max-height: 800px) {
      width: 100%;
      height: 100%;
      // display: block;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
      // display: block;
    }
    @media screen and (min-width: 800px) and (max-width: 1090px) {
      width: 100%;
      height: 100%;
      // display: block;
    }
  }

  .loaderInfoText {
    font-size: 22px;
    text-align: center;
    color: white;
  }

  .showRemoteVideo {
    display: ${(props) => (props.showRemoteVideo ? "flex" : "none")};
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-image: ${(props) =>
        props.remoteBackgroundImage ? props.remoteBackgroundImage : "none"}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .localVideoLoader {
    max-height: 480px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
    z-index: 2;
    display: ${(props) => (props.showLocalVideo ? "none" : "flex")}
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: ${(props) => props.localBackgroundImage}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ${breakpoint("desktop")`
        width: 100%;
        height: 100%;
        // display: block;
    `};
    @media screen and (max-height: 800px) {
      width: 100%;
      height: 100%;
      // display: block;
    }


    @media screen and (min-width: 800px) and (max-width: 1090px) {
      width: 100%;
      height: 100%;
      // display: block;
    }
  }
  .showLocalVideo {
    display: ${(props) => (props.showLocalVideo ? "flex" : "none")};
    flex-direction: column;
    height: 100%;
    width: 100%;

    // @media screen (max-width: 1000px) {
    //   margin-top: 30px;
    // }
  }

  .buttonWrap {
    display: flex;
    max-width: 100%;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    justify-content: center;

    @media only screen and (orientation: portrait), (max-width: 732px) {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      bottom: -20px;
    }


  }

`;

/* Adapt the colors based on primary prop */
/* const Button = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;
*/

// Stage-Component

const Match = (props) => {
    // if(!props.location.state) {
    //   window.location.href = `${window._env_.REACT_APP_BAYRONNECT_URL}/`;
    // }

    //STREAMING VARIABLES AND ELEMENTS
    const [videoId, setVideoId] = useState(
        localStorage.getItem("currentVideoId") !== "false"
            ? { deviceId: localStorage.getItem("currentVideoId") }
            : false
    );
    // Jesus cleaning warnings.
    // const [audioId, setAudioId] = useState(localStorage.getItem("currentAudioInputId") ? {deviceId: localStorage.getItem("currentAudioInputId")} : false);
    const audioId = localStorage.getItem("currentAudioInputId")
        ? { deviceId: localStorage.getItem("currentAudioInputId") }
        : false;
    const [stream, setStream] = useState(props.stream);
    let localVideo = useRef(null);
    let remoteVideo = useRef(null);
    // let videoStream = null;
    const [leaveMessage, setLeaveMessage] = useState(
        "Your matching partner left the room! <br/> We'll guide you back to the matching area."
    );

    //MATCH INFO VARIABLES
    const socket = props.socket;
    const myInfo = props.matchData.myInfo ? props.matchData.myInfo : {};
    const partnerInfo = props.matchData.partnerInfo
        ? props.matchData.partnerInfo
        : {};
    const socketRoomId = props.matchData.socketRoomId + "match";
    const matchIdDB = props.matchData.matchIdDB;
    const meetupInfo = props.matchData.meetup;
    const topics = props.matchData.meetup
        ? props.matchData.meetup.topics
        : null;
    const init = props.matchData.init ? props.matchData.init : false;
    const [openAlert, setOpenAlert] = useState(false);
    const [audioTrack, setAudioTrack] = useState(false);
    const [videoTrack, setVideoTrack] = useState(false);

    let audioTrackNormal;
    let videoTrackNormal;

    const [localDescription, setLocalDescription] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [isUserSharingScreen, setIsUserSharingScreen] = useState(false);

    const bayronUrl = window._env_.REACT_APP_BAYRONNECT_URL
        ? `${window._env_.REACT_APP_BAYRONNECT_URL}/`
        : "https://127.0.0.1:3000";

    //CSS VARIABLES
    //todo uncomment when implemented
    /*  let remoteBackgroundImage = props.location.state.partnerInfo.backgroundImage
    ? props.location.state.partnerInfo.backgroundImage
    : "none";
  let localBackgroundImage = props.location.state.backgroundImage
    ? props.location.state.backgroundImage
    : "none";*/
    let remoteBackgroundImage = "none";
    let localBackgroundImage = "none";
    const [showRemoteVideo, setShowRemoteVideo] = useState(false);
    const [showLocalVideo, setShowLocalVideo] = useState(false);
    //const orientation = useScreenOrientation();
    const [funcAlreadyFired, setFuncAlreadyFired] = useState(false);

    //RTC PEER CONNECTION VARIABLES
    const [peerConnection, setPeerConnection] = useState(
        new RTCPeerConnection({
            iceServers: [
                { urls: "stun:turnserver.bayron.int.bayer.com:443" },
                {
                    urls: [
                        "turns:turnserver.bayron.int.bayer.com:443?transport=tcp",
                        "turns:turnserver.bayron.int.bayer.com:5349"
                    ],
                    credential: "lRlP32eM",
                    username: "yeysus"
                }
            ]
        })
    );
    // let peerConnection = new RTCPeerConnection(
    //     {
    //       iceServers: [
    //         { urls: "stun:turnserver.bayron.int.bayer.com:443" },
    //         { urls:
    //           [
    //             "turns:turnserver.bayron.int.bayer.com:443?transport=tcp",
    //             "turns:turnserver.bayron.int.bayer.com:5349",
    //           ],
    //           credential: "lRlP32eM",
    //           username: "yeysus"
    //         }
    //       ]
    //     }
    //   )
    const [showReconnectionPopup, setShowReconnectionPopup] = useState(false);
    const [currentConnectionState, setCurrentConnectionState] = useState("new");

    // let isAlreadyCalling = false;
    // const [showUserMedia, setShowUserMedia] = useState(false);
    const [startSessionTimer, setStartSessionTimer] = useState(false);
    let intervalId;

    //FLAG FOR ALREADY EXITED SIGNAL
    let alreadyExited = false;

    //GET AND START MEDIA DEVICES
    useEffect(() => {
        console.log("USEEFFECT []");
        if (socket) {
            peerConnectionListeners(peerConnection);
            peerConnectionSocketListeners(peerConnection);

            socket.on("userReady", () => {
                setStartSessionTimer(true);
                if (init) {
                    console.log("CALLING getMediaDevices userReady");
                    getMediaDevices();
                }
            });

            socket.on("screenSharing", (msg) => {
                setIsUserSharingScreen(msg.screenSharing);
            });

            socket.on("userQuitMatch", (data) => {
                console.log(
                    "Partner have media device problems: " +
                        data.mediaDeviceError +
                        " | matchPage"
                );
                if (data.mediaDeviceError) {
                    setLeaveMessage(
                        "Your partner has media device problems and left the room to fix it. \n\n We will guide you back to the lobby."
                    );
                } else {
                    setOpenAlert(true);
                }

                setFuncAlreadyFired(true);
                const connectionData = { ...data.statistics };

                // sendMatchData(true, connectionData);

                setTimeout(function () {
                    handleQuit(true);
                }, 5000);
            });

            // checkIfEnoughTimeForConversation();
            socket.emit("userReady", {
                socketRoomId: socketRoomId,
                meetupId: meetupInfo.id
            });
        } else {
            // window.location.href = window._env_.REACT_APP_BAYRONNECT_URL;
        }

        return function cleanup() {
            if (localVideo.current && localVideo.current.srcObject) {
                localVideo.current.srcObject
                    .getTracks()
                    .forEach(function (track) {
                        try {
                            track.stop();
                        } catch (e) {
                            console.log("FAILED TO CLEANUP LOCAL STREAM: ", e);
                        }
                    });
            }
            if (remoteVideo.current && remoteVideo.current.srcObject) {
                remoteVideo.current.srcObject
                    .getTracks()
                    .forEach(function (track) {
                        try {
                            track.stop();
                        } catch (e) {
                            console.log("FAILED TO CLEANUP REMOTE STREAM: ", e);
                        }
                    });
            }
            clearInterval(intervalId);
            socket
                .removeListener("sendPeerConnectionInfo")
                .on("sendPeerConnectionInfo", () => {});
            socket
                .removeListener("sendPeerConnectionAnswer")
                .on("sendPeerConnectionAnswer", () => {});
            socket.removeListener("userReady").on("userReady", () => {});
            socket
                .removeListener("userQuitMatch")
                .on("userQuitMatch", () => {});
            // window.removeEventListener("beforeunload", sendStats);
        };
    }, []);

    useEffect(() => {
        if (stream && peerConnection) {
            console.log("USEEFFECT [props.changeVideo, props.stream]");
            if (
                localStorage.getItem("currentAudioOutputId") &&
                localStorage.getItem("currentAudioOutputId") !== "default" &&
                remoteVideo.current
            ) {
                remoteVideo.current
                    .setSinkId(localStorage.getItem("currentAudioOutputId"))
                    .then(() => {
                        console.log(
                            `Success, audio output device attached to foreign video: ${localStorage.getItem(
                                "currentAudioOutputId"
                            )}`
                        );
                    })
                    .catch((error) => {
                        let errorMessage = error;
                        if (error.name === "SecurityError") {
                            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                        }
                        console.error(errorMessage);
                    });
            }

            let screenVideoTrack = props.stream.getVideoTracks()[0];
            var sender = peerConnection.getSenders().find(function (s) {
                return s.track.kind == screenVideoTrack.kind;
            });
            if (sender) {
                sender.replaceTrack(screenVideoTrack);
            }

            let screenAudioTrack = props.stream.getAudioTracks()[0];
            var sender = peerConnection.getSenders().find(function (s) {
                return s.track.kind == screenAudioTrack.kind;
            });
            if (sender) {
                sender.replaceTrack(screenAudioTrack);
            }
        }
    }, [props.changeVideo, props.stream]);

    useEffect(() => {
        console.log("USEEFFECT [localDescription]");
        if (localDescription) {
            console.log("changed localDescription + INIT:", init);
            if (init && socket) {
                socket.emit("sendPeerConnectionInfo", {
                    socketRoomId: socketRoomId,
                    meetupId: meetupInfo.id,
                    localDescription: localDescription
                });
            }
        }
    }, [localDescription]);

    useEffect(() => {
        console.log("USEEFFECT [props.quitButtonClicked]");
        if (props.quitButtonClicked) {
            console.log("QUIT BUTTON CLICKED: ", props.quitButtonClicked);
            // props.setMatchData({});
            sendQuitMessage();
            props.setQuitButtonClicked(false);
        }
    }, [props.quitButtonClicked]);

    useEffect(() => {
        console.log("USEEFFECT [peerConnection]");

        // if(currentConnectionState === 'failed') {
        //   console.log('peerConnection failed, trying again');
        //   socket.removeListener('sendPeerConnectionInfo').on('sendPeerConnectionInfo', () => {});
        //   socket.removeListener('sendPeerConnectionAnswer').on('sendPeerConnectionAnswer', () => {});

        //   peerConnectionSocketListeners(peerConnection);
        //   peerConnectionListeners(peerConnection);

        //   if(init) {
        //     console.log('CALLING getMediaDevices init');
        //     getMediaDevices();
        //   }
        // }

        return function cleanup() {
            console.log("CLOSING PEERCONNECTION (peerconnection useEffect)");
            if (peerConnection) {
                console.log("PEERCONNECTION OPENED, now closing");
                // if(stream) {
                // stream.getTracks().forEach(track => {
                //   console.log('SETTING TRACKS');
                //   if(track.kind === 'audio') {
                //     console.log('AUDIO TRACK useState:' , audioTrack);
                //     console.log('AUDIO TRACK normal:' , audioTrackNormal);
                //     try {
                //       console.log('REMOVE AUDIO TRACK getMediaDevices: ' , audioTrackNormal);
                //       peerConnection.removeTrack(audioTrackNormal);
                //     }catch(e) {
                //       console.log('REMOVE AUDIO TRACK ERROR: ', e);
                //     }
                //   }else {
                //     console.log('VIDEO TRACK useState:' , videoTrack);
                //     console.log('VIDEO TRACK normal:' , videoTrackNormal);
                //     try {
                //       console.log('REMOVE VIDEO TRACK getMediaDevices: ' , videoTrack);
                //       peerConnection.removeTrack(videoTrackNormal);
                //     }catch(e) {
                //       console.log('REMOVE AUDIO TRACK ERROR: ', e);
                //     }
                //   }
                // });
                // }
                peerConnection.close();
                peerConnection.onicecandidate = null;
                peerConnection.ontrack = null;
                peerConnection.onicecandidate = null;
                peerConnection.onnegotiationneeded = null;
                peerConnection.onconnectionstatechange = null;
            }

            socket
                .removeListener("sendPeerConnectionInfo")
                .on("sendPeerConnectionInfo", () => {});
            socket
                .removeListener("sendPeerConnectionAnswer")
                .on("sendPeerConnectionAnswer", () => {});
        };
    }, [peerConnection]);

    async function sendMatchData(sendToServer = false, connectionData = {}) {
        return peerConnection
            .getStats(null)
            .then((stats) => {
                stats.forEach((report) => {
                    let statObjectKey = "";
                    if (init) {
                        statObjectKey = "matcher";
                    } else {
                        statObjectKey = "matchee";
                    }

                    if (report.type === "outbound-rtp") {
                        if (report.kind === "audio") {
                            if (report.packetsSent !== undefined) {
                                if (report.bytesSent !== undefined) {
                                    // console.log(" ("+ (report.bytesSent/1024000).toFixed(2) +" MB)");
                                    connectionData[
                                        statObjectKey + "AudioSent"
                                    ] = report.bytesSent;
                                } else {
                                    connectionData[
                                        statObjectKey + "AudioSent"
                                    ] = -1;
                                }
                            }
                            if (report.packetsLost !== undefined) {
                                console.log(" Lost: " + report.packetsLost);
                            }
                        } else if (report.kind === "video") {
                            console.log("OUTBOUND-RTP VIDEO: ");
                            if (report.packetsSent !== undefined) {
                                if (report.bytesSent !== undefined) {
                                    // console.log(" ("+ (report.bytesSent/1024000).toFixed(2) +" MB)");
                                    connectionData[
                                        statObjectKey + "VideoSent"
                                    ] = report.bytesSent;
                                } else {
                                    connectionData[
                                        statObjectKey + "VideoSent"
                                    ] = -1;
                                }
                                if (report.packetsLost !== undefined) {
                                    console.log(" Lost: " + report.packetsLost);
                                }
                            }
                        }
                    }

                    if (report.type === "inbound-rtp") {
                        if (report.kind === "audio") {
                            if (report.packetsReceived !== undefined) {
                                if (report.bytesReceived !== undefined) {
                                    // console.log(" ("+ (report.bytesReceived/1024000).toFixed(2) +" MB)");
                                    connectionData[
                                        statObjectKey + "AudioReceived"
                                    ] = report.bytesReceived;
                                } else {
                                    connectionData[
                                        statObjectKey + "AudioReceived"
                                    ] = -1;
                                }
                                if (report.packetsLost !== undefined) {
                                    connectionData[
                                        statObjectKey + "AudioPacketsLost"
                                    ] = report.packetsLost;
                                    console.log(" Lost: " + report.packetsLost);
                                } else {
                                    connectionData[
                                        statObjectKey + "AudioPacketsLost"
                                    ] = 0;
                                }
                            }
                        } else if (report.kind === "video") {
                            if (report.packetsReceived !== undefined) {
                                if (report.bytesReceived !== undefined) {
                                    // console.log(" ("+ (report.bytesReceived/1024000).toFixed(2) +" MB)");
                                    connectionData[
                                        statObjectKey + "VideoReceived"
                                    ] = report.bytesReceived;
                                } else {
                                    connectionData[
                                        statObjectKey + "VideoReceived"
                                    ] = -1;
                                }

                                if (report.packetsLost !== undefined) {
                                    connectionData[
                                        statObjectKey + "VideoPacketsLost"
                                    ] = report.packetsLost;
                                    console.log(" Lost: " + report.packetsLost);
                                } else {
                                    connectionData.videoPacketsLost = 0;
                                    connectionData[
                                        statObjectKey + "VideoPacketsLost"
                                    ] = 0;
                                }
                            }
                        }
                    }
                });
            })
            .then(() => {
                if (sendToServer) {
                    connectionData.matchId = matchIdDB;
                    fetch(
                        `${CONF.BAYRONNECT_API_URL}/api/saveMatchStatistics`,
                        {
                            method: "POST",
                            body: JSON.stringify({
                                jwtToken: localStorage.getItem("jwtToken"),
                                statistics: connectionData,
                                matchIdDB: matchIdDB
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        }
                    )
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            console.log(data);
                            return;
                        });
                } else {
                    return connectionData;
                }
            })
            .catch((e) => {
                console.log("ERROR WITH GETTING/SENDING MATCH STATISTICS ", e);
            });
    }

    function peerConnectionSocketListeners(peerConnection) {
        //RECEIVE PEER MEDIA OFFER FROM INIT MATCHER
        socket.on("sendPeerConnectionInfo", async (data) => {
            console.log("RECEIVING sendPeerConnectionInfo", data);
            if (data.localDescription) {
                try {
                    const localDescription = data.localDescription;
                    if (localDescription.type === "offer") {
                        try {
                            console.log(
                                "RECEIVED OFFER, SETTING REMOTE AND LOCAL DESCRIPTION"
                            );
                            await peerConnection.setRemoteDescription(
                                localDescription
                            );
                        } catch (e) {
                            console.log("setRemoteDescription error: ", e);
                        }
                        if (!init) {
                            console.log("CALLING getMediaDevices init");
                            await getMediaDevices();
                            try {
                                await peerConnection.setLocalDescription(
                                    await peerConnection.createAnswer()
                                );
                                console.log("FINAL: ", peerConnection);
                                socket.emit("sendPeerConnectionAnswer", {
                                    socketRoomId: data.socketRoomId,
                                    localDescription:
                                        peerConnection.localDescription
                                });
                            } catch (e) {
                                console.log("setLocalDescription error: ", e);
                            }
                        }
                    }
                } catch (e) {
                    console.log("error on localDescription: ", e);
                }
            } else if (data.candidate) {
                console.log("ADDING CANDIDATE");
                try {
                    await peerConnection.addIceCandidate(data.candidate);
                } catch (e) {
                    console.log("COULDNT ADD ICE CANDIDATE:", e);
                }
            }
        });

        //RECEIVE ANSWER FROM NOT INIT MATCHER
        socket.on("sendPeerConnectionAnswer", async (data) => {
            try {
                const remoteDescription = data.localDescription;
                console.log(
                    "RECEIVED sendPeerConnectionAnswer signal | matchPage",
                    remoteDescription
                );

                await peerConnection.setRemoteDescription(remoteDescription);
            } catch (e) {
                console.log("error sendPeerConnectionAnswer: ", e);
            }
            console.log("FINAL: ", peerConnection);
        });
    }

    //EVENT LISTENERS FOR PEER CONNECTION
    function peerConnectionListeners(peerConnection) {
        //FIRED AFTER GETTING MEDIA TRACK FROM PARTNER
        peerConnection.ontrack = function ({ streams: [mediaStreams] }) {
            console.log("FIRED peerConnection.ontrack | matchPage");
            remoteVideo.current.srcObject = mediaStreams;
            setShowRemoteVideo(true);
        };

        //FIRED AFTER LINE 794 & 796
        peerConnection.onnegotiationneeded = async () => {
            if (init) {
                try {
                    await peerConnection.setLocalDescription(
                        await peerConnection.createOffer()
                    );
                    setLocalDescription(peerConnection.localDescription);
                } catch (err) {
                    console.error(err);
                }
            }
        };

        //GATHER AND SEND ICE CANDIDATES TO PARTNER
        peerConnection.onicecandidate = ({ candidate }) => {
            socket.emit("sendPeerConnectionInfo", {
                socketRoomId: socketRoomId,
                candidate: candidate
            });
        };

        //CHECK CURRENT CONNECTION STATE
        peerConnection.onconnectionstatechange = async function (event) {
            switch (peerConnection.connectionState) {
                case "connected":
                    if (init) {
                        // socket.emit("storeMatch", {
                        //   myId: myInfo.id,
                        //   partnerId: partnerInfo.id,
                        //   matchIdDB: matchIdDB
                        // });
                    }
                    console.log("PeerConnection: connected.");
                    break;
                case "disconnected":
                    setCurrentConnectionState("disconnected");
                    console.log("PeerConnection: disconnected.");
                    break;
                case "failed":
                    const timer = setTimeout(() => {
                        setShowReconnectionPopup(true);
                        setCurrentConnectionState("failed");

                        peerConnection.restartIce();
                        peerConnection.close();
                        setPeerConnection(
                            new RTCPeerConnection({
                                iceServers: [
                                    {
                                        urls: "stun:turnserver.bayron.int.bayer.com:5349"
                                    },
                                    {
                                        urls: [
                                            "turns:turnserver.bayron.int.bayer.com:5349",
                                            "turns:turnserver.bayron.int.bayer.com:443?transport=tcp"
                                        ],
                                        credential: "lRlP32eM",
                                        username: "yeysus"
                                    }
                                ]
                            })
                        );

                        clearTimeout(timer);
                    }, 5000);
                    console.log("PeerConnection: failed.");
                    break;
                case "closed":
                    setShowReconnectionPopup(false);
                    setCurrentConnectionState("closed");
                    console.log("PeerConnection: closed.");
                    break;
                default:
                    console.log(peerConnection.connectionState);
                    console.log(
                        "PeerConnection: default. Other than connected, disconnected, failed, closed."
                    );
            }
        };
    }

    //SELECT PREFERRED MEDIA DEVICES (IF ANY) AND START STREAM
    async function getMediaDevices() {
        console.log("CALLING getMediaDevices function");
        stream.getTracks().forEach((track) => {
            console.log("SETTING TRACKS");
            if (track.kind === "audio") {
                console.log("AUDIO TRACK useState:", audioTrack);
                console.log("AUDIO TRACK normal:", audioTrackNormal);
                try {
                    if (audioTrackNormal) {
                        console.log(
                            "REMOVE AUDIO TRACK getMediaDevices: audioTrackNormal ",
                            audioTrackNormal
                        );
                        peerConnection.removeTrack(audioTrackNormal);
                    }
                    // if(audioTrack) {
                    //   console.log('REMOVE AUDIO TRACK getMediaDevices: audioTrack' , audioTrack);
                    //   peerConnection.removeTrack(audioTrack);
                    // }
                } catch (e) {
                    console.log("REMOVE AUDIO TRACK ERROR: ", e);
                }
                try {
                    audioTrackNormal = peerConnection.addTrack(track, stream);
                    setAudioTrack(audioTrackNormal);
                } catch (e) {
                    console.log("ERRRON ON ADDING AUDIO TRACK: ", e);
                }
            } else {
                console.log("VIDEO TRACK useState:", videoTrack);
                console.log("VIDEO TRACK normal:", videoTrackNormal);
                try {
                    if (videoTrackNormal) {
                        console.log(
                            "REMOVE VIDEO TRACK getMediaDevices: ",
                            videoTrackNormal
                        );
                        peerConnection.removeTrack(videoTrackNormal);
                    }
                    // if(videoTrack) {
                    //   console.log('REMOVE AUDIO TRACK getMediaDevices: audioTrack' , audioTrack);
                    //   peerConnection.removeTrack(audioTrack);
                    // }
                } catch (e) {
                    console.log("REMOVE AUDIO TRACK ERROR: ", e);
                }
                try {
                    videoTrackNormal = peerConnection.addTrack(track, stream);
                    setVideoTrack(videoTrackNormal);
                } catch (e) {
                    console.log("ERRRON ON ADDING VIDEO TRACK: ", e);
                }
            }
        });
    }

    //STOP ALL RUNNING MEDIA DEVICES, NOTIFY USER OF LEAVING, TAKE USER BACK TO LOBBY
    const handleQuit = useCallback((disconnectedUser = false) => {
        // props.setMatchData({});
        console.log("Partner already left: ", disconnectedUser);
        if (remoteVideo.current && remoteVideo.current.srcObject) {
            remoteVideo.current.srcObject.getTracks().forEach(function (track) {
                track.stop();
            });
        }

        if (disconnectedUser) {
            setOpenAlert(true);
            backToLobby();
        } else {
            backToLobby();
        }
    }, []);

    //BACK TO LOBBY
    function backToLobby() {
        console.log("FIRED backToLobby function | matchPage");
        let currentTime = new Date();
        currentTime = currentTime.setHours(currentTime.getHours() - 2);
        let meetupEndDate = new Date(new Date(meetupInfo.endDate));

        if (currentTime > meetupEndDate.getTime()) {
            props.history.push({
                pathname: "/next",
                state: {
                    nextStepUrl: meetupInfo.nextStepUrl
                }
            });
        } else if (!alreadyExited) {
            alreadyExited = true;
            const state = {
                meetup: meetupInfo
            };
        }

        props.setCurrentPage("lobby");
        props.setIsSurveyModal(true);
    }

    //SEND QUIT MESSAGE, NEEDED FOR CHILD COMPONENTS
    function sendQuitMessage(mediaDeviceError = false) {
        console.log("SENDING userQuitMatch signal | matchPage");
        // sendMatchData().then((connectionData) => {
        socket.emit("userQuitMatch", {
            userId: myInfo.id,
            socketRoomId: socketRoomId,
            mediaDeviceError: mediaDeviceError,
            matchStatistics: {}
        });
        // });

        backToLobby();
    }

    // Check if meetup is about to end.
    // People starting their meetings in endDateTime - currentDateTime < meetupDuration
    // will get a reduced meetupDuration so they can end their conversation at
    // the endDateTime. So it can be guaranteed that they could join the next agenda point.

    // TODO +++ This sets duration individually for each user in their sessionStorage,
    // and this is not good.
    // Because they will have at least some milliseconds difference in the duration.
    // Why we need this, and we don't simply stop matching: Because some people
    // may want to use the last minutes "to match a new colleague"
    // (we see it all the time)
    // and we should allow this.

    // Also, TODO +++ we should stop users from matching
    // if the time to endDate is less than 1 minute.

    function checkIfEnoughTimeForConversation() {
        let currentTime = new Date();
        currentTime = currentTime.setHours(currentTime.getHours());
        let meetupEndDate = new Date(new Date(meetupInfo.endDate));
        let milliSecondsTillMeetupEnds = meetupEndDate.getTime() - currentTime;
        // meetupInfo.duration is in minutes.
        let durationAsInt = parseInt(meetupInfo.duration);
        if (durationAsInt * 60 * 1000 >= milliSecondsTillMeetupEnds) {
            sessionStorage.setItem(
                "duration",
                (milliSecondsTillMeetupEnds / 60000).toString()
            );
            return milliSecondsTillMeetupEnds / 60000;
        } else {
            return meetupInfo.duration;
        }
    }

    function closeModal() {
        setShowReconnectionPopup(false);
    }

    return (
        <VidGen
            showRemoteVideo={showRemoteVideo}
            remoteBackgroundImage={remoteBackgroundImage}
            showLocalVideo={showLocalVideo}
            localBackgroundImage={localBackgroundImage}
            className={expanded && "expanded"}
        >
            {openAlert && <NewsModal alert content={leaveMessage} />}
            <SessionTimer
                connected={startSessionTimer}
                handleQuit={handleQuit}
                minutes={
                    sessionStorage.getItem("duration")
                        ? parseInt(sessionStorage.getItem("duration"))
                        : 5
                }
            />
            <TopicDisplay
                socket={socket}
                topics={topics}
                socketRoomId={socketRoomId}
            />
            <div className="videoContainer">
                <div className="divWrapper">
                    <div className="videoWrap">
                        <div className="videoLoader">
                            <div className="remoteVideoLoader">
                                <svg
                                    version="1.1"
                                    id="loader-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="250px"
                                    height="250px"
                                    viewBox="0 0 40 40"
                                    enableBackground="new 0 0 40 40"
                                >
                                    <path
                                        opacity="0.2"
                                        fill="#000"
                                        d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                                    />
                                    <path
                                        fill="#FFFFFF"
                                        d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                C22.32,8.481,24.301,9.057,26.013,10.047z"
                                    >
                                        <animateTransform
                                            attributeType="xml"
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 20 20"
                                            to="360 20 20"
                                            dur="1.3s"
                                            repeatCount="indefinite"
                                        />
                                    </path>
                                </svg>
                                <p className="loaderInfoText">
                                    Waiting for initialization
                                </p>
                            </div>
                        </div>
                        <div className="showRemoteVideo">
                            <div className="VideoBox" id={"foreignVideoBox"}>
                                {CONF.OPS_MODE === "internal" ? (
                                    <React.Fragment>
                                        <SkillsOverlay
                                            userId={partnerInfo.id}
                                        />
                                    </React.Fragment>
                                ) : (
                                    ""
                                )}
                                <video
                                    className={`foreignVideo ${
                                        isUserSharingScreen && "dontMirror"
                                    }`}
                                    autoPlay
                                    ref={remoteVideo}
                                    playsInline
                                />

                                <IconButton
                                    className="video-expand"
                                    onClick={() => setExpanded(!expanded)}
                                >
                                    <AspectRatioIcon />
                                </IconButton>
                            </div>
                            <Profile
                                myProfile={false}
                                profile={partnerInfo.profile}
                                location={partnerInfo.location}
                                position={partnerInfo.position}
                                email={partnerInfo.email}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ChatRoom
                socket={socket}
                history={props.history}
                meetupName={false}
                socketRoomId={socketRoomId}
            />
            {/* )} */}

            {showReconnectionPopup ? (
                <ReconnectionPopup
                    currentConnectionState={currentConnectionState}
                    closeModal={closeModal}
                />
            ) : (
                ""
            )}
        </VidGen>
    );
};

export default withRouter(Match);
