import React, { useState, useEffect } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
//import fakeUser from 'public/skynet_round.png';

import { CONF } from "../../config/config";

const InfoWrapper = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background: #d30f4b;
    color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    ${breakpoint("tablet")`
    flex-direction:column;
    align-items: baseline;
  `}
    ${breakpoint("desktop")`
    flex-direction:row;
    align-items: baseline;
    margin-top: 50px;
  `}

  ${breakpoint("mobile")`
    padding: 20px !important;
  `}

  @media only screen and (orientation: portrait), (max-width: 732px) {
        padding: 5px;
    }

    .adWraper {
        display: flex;
        margin-left: 20px;
    }

    .profileImage {
        border-radius: 50%;

        ${breakpoint("mobile")`
      display: none
    `}

        ${breakpoint("desktop")`
      display: flex
    `}
    }
`;

const DivWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${breakpoint("mobile")`
    display: block;
  `}

    ${breakpoint("desktop")`
    display: flex;
  `}

  h2 {
        margin: 0;
    }
    .publisherWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        h3 {
            margin: 0;
        }
        p {
            margin: 0;
            margin-top: 10px;
        }
    }
    // .sectionWrap {
    //   display: flex;
    //   width: 70%;

    .EMail {
        margin-left: 20px;

        ${breakpoint("mobile")`
        margin-left: 0;
        text-align: center;
      `}

        ${breakpoint("desktop")`
        margin-left: 20px;
        text-align: left;
      `}
    }

    .sectionContentWrap {
        cursor: pointer;
        display: flex;
        margin-left: 20px;
        margin-right: auto;

        ${breakpoint("mobile")`
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}

        ${breakpoint("desktop")`
        flex-direction: row;
        margin-left: 20px;
        justify-content: flex-start;
        align-items: flex-start;
      `}

      .picture {
            min-width: 150px !important;
        }
    }

    .text {
        margin-left: 20px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: pre-line;

        ${breakpoint("mobile")`
        margin: 10px;
        margin-bottom: 0;
      `}
        ${breakpoint("desktop")`
        margin: 0;
        margin-left: 20px;
        justify-content: flex-start;
      `}
    }
    // }
`;

const AdBanner = (props) => {
    const [randomAd, setRandomAd] = useState(false);

    var allAds;
    if (CONF.OPS_MODE === "internal") {
        allAds = [
            {
                adId: "7d960ac5-c738-438e-9df2-1bb5293e38a4",
                adCreatedAt: "2021-08-30 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/heiko.schomberg/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/heiko.schomberg",
                adEditorsEmails: "",
                adType: "",
                adSubType: "",
                adTitle: "Be you. Be Bayer.",
                adText: "Embracing the power of inclusion and diversity, Bayer launches its employer branding “Be you. Be Bayer”.",
                adPicturePath: "/bayronnect/ads_mock/ads_BeBayer.jpg",
                adContact: "hr@bayer.com",
                adLink: "https://bayernet.int.bayer.com/en/bayer/brand/employer%20branding?forceurllang=true",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },
            {
                adId: "ede4341d-7657-4693-8cd5-53d883d11e5a",
                adCreatedAt: "2021-08-30 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/heiko.schomberg/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/heiko.schomberg",
                adEditorsEmails: "",
                adType: "",
                adSubType: "",
                adTitle: "Be you. Be Bayer.",
                adText: "Bayer führt seine Arbeitgebermarke „Be you. Be Bayer.“ ein und setzt dabei ganz auf die Kraft von Inklusion und Vielfalt.",
                adPicturePath: "/bayronnect/ads_mock/ads_BeBayer.jpg",
                adContact: "hr@bayer.com",
                adLink: "https://bayernet.int.bayer.com/de-de/bayer/brand/employer%20branding?forceurllang=true",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },
            {
                adId: "242ee431-8d68-4e1d-8b42-6dbbfddf2190",
                adCreatedAt: "2021-08-30 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/jesus.delvalle/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/jesus.delvalle",
                adEditorsEmails: "",
                adType: "job",
                adSubType: "Short Term Assignment",
                adTitle:
                    "Join the Pharma DT&IT Digital Disruption Team as STA!",
                adText: "Help us pushing the Byron project forward. If you are a software developer, or are learning JavaScript, React, AWS, Elasticsearch, JOIN US. #softwaredevelopment #yes #justdoit",
                adPicturePath: "/bayronnect/ads_mock/ads_code.png",
                adContact: "ads@bayron.com",
                adLink: "mailto:byron@bayer.com?subject=I%20want%20to%20develop%20software",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },
            {
                adId: "025f6bc1-4b66-4abe-91e9-e65c9d47aa48",
                adCreatedAt: "2021-08-30 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/vanessa.judas/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/vanessa.judas",
                adEditorsEmails: "",
                adType: "",
                adSubType: "",
                adTitle:
                    "Advertise your campaigns, share your projects, gain visibility.",
                adText: "Send us images or videos and we will display them on our large 84-inch monitor at the front window of the Byron Bar in Berlin.",
                adPicturePath: "/bayronnect/ads_mock/image2.png",
                adContact: "byron@bayer.com",
                adLink: "mailto:byron@bayer.com",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },

            {
                adId: "f11b9528-05b3-4223-b390-9313d562374c",
                adCreatedAt: "2021-08-30 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/jesus.delvalle/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/jesus.delvalle",
                adEditorsEmails: "",
                adType: "",
                adSubType: "",
                adTitle: "BAYER DE MEXICO",
                adText: "Bayer products have been available in Mexico for more than 100 years; we have our own subsidiary there since 1921. With 8 production sites, 3 administration sites, and around 4,500 colleagues, Bayer de México is one of our most important subsidiaries.",
                adPicturePath: "/bayronnect/ads_mock/Bayer_Mexico.png",
                adContact: "ads@bayron.com",
                adLink: "https://www.bayer.com/es/mx/mexico-home",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },
            {
                adId: "adfe7e30-3318-49f2-805a-295ffb6ab367",
                adCreatedAt: "2021-08-30 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/jesus.delvalle/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/jesus.delvalle",
                adEditorsEmails: "",
                adType: "",
                adSubType: "",
                adTitle: "BAYER UK AND IRELAND",
                adText: "Bayer has traded in the UK and Ireland since Friedrich Bayer first sold his dyestuffs here in 1871. Early business was handled through the offices of engineering company JM Steel, then through Bayer's own operations based in Manchester, then Surrey. Today Bayer’s UK and Ireland region is headquartered in Reading, with locations in Cambridge and Dublin.",
                adPicturePath: "/bayronnect/ads_mock/Bayer_UK_and_Ireland.png",
                adContact: "ads@bayron.com",
                adLink: "https://www.bayer.co.uk/en/",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },
            {
                adId: "a640e903-a1ca-4978-be61-0d2a4f46ffd2",
                adCreatedAt: "2021-08-30 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/jesus.delvalle/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/jesus.delvalle",
                adEditorsEmails: "",
                adType: "",
                adSubType: "",
                adTitle: "BAYER CANADA",
                adText: "We have over 1,400 employees at Bayer Canada. Our main location is in Mississauga, ON.",
                adPicturePath: "/bayronnect/ads_mock/Bayer_Canada.png",
                adContact: "ads@bayron.com",
                adLink: "https://www.bayer.com/en/ca/canada-home",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },
            {
                adId: "8439641a-a4c1-4635-b3fd-b5e2974833b9",
                adCreatedAt: "2021-08-30 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/jesus.delvalle/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/jesus.delvalle",
                adEditorsEmails: "",
                adType: "",
                adSubType: "",
                adTitle: "Which Languages do you speak?",
                adText: "The Tower of Babel is an origin myth meant to explain why the world's peoples speak different languages. Estimates of the number of human dialects vary between 5,000 and 7,000. Maybe you want to meet people that speak your mother tongue but are based in another country? Enter the languages you speak as skill in Byron. Maybe somebody wants to connect with you!",
                adPicturePath:
                    "/bayronnect/ads_mock/Tower_of_Babel_Wikipedia.png",
                adContact: "ads@bayron.com",
                adLink: "https://bayron.intranet.cnb/me",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },
            {
                adId: "209380de-42c5-48ad-b071-4a3f06cfd5d4",
                adCreatedAt: "2022-01-01 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/jesus.delvalle/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/jesus.delvalle",
                adEditorsEmails: "",
                adType: "job",
                adSubType: "Short Term Assignment",
                adTitle: "Interested in a Short Term Assignment?",
                adText: "Do you have Managerial courage, Will to succeed, are Results-oriented, have an Unwavering focus, and are Unafraid of confrontation, then we want to know you. We are looking for colleagues interested in a STA in the Pharma IT Digital Disruption Team. Contact us NOW!",
                adPicturePath: "/bayronnect/ads_mock/Ad_STA.png",
                adContact: "ads@bayron.com",
                adLink: "mailto:jesus.delvalle@bayer.com?subject=STA at the Digital Disruption Team",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            },
            {
                adId: "f1d3a926-6345-42cd-8a11-390138f146fa",
                adCreatedAt: "2022-01-01 12:59:23.208801",
                adModifiedAt: "",
                adAuthorEmail: "jesus.delvalle@bayer.com",
                adAuthorImage:
                    "https://bayron.intranet.cnb/images/jesus.delvalle/profile",
                adAuthorUrl:
                    "https://bayron.intranet.cnb/profile/jesus.delvalle",
                adEditorsEmails: "",
                adType: "job",
                adSubType: "Short Term Assignment",
                adTitle: "Opportunities present themselves in strange ways",
                adText: "And here is one: If you are interested into know many people and departments across Bayer, or want to improve your programming skills, or work in interior architecture and design, or in Digital Innovation, you could consider making a STA at the Pharma IT Digital Disruption Team. Please talk to us. And Btw, if you do not know what is the picture shown here, you do not know one of the most interesting unsolved mysteries of human history. Just contact somebody of our Team.",
                adPicturePath: "/bayronnect/ads_mock/Voynich_Manuscript.png",
                adContact: "ads@bayron.com",
                adLink: "mailto:jesus.delvalle@bayer.com?subject=STA at the Digital Disruption Team",
                adStartDate: "",
                adExpiryDate: "",
                adStatus: "",
                adGroup: "",
                adPlacement: "",
                adLabels: ""
            }
        ];
    } else {
        // external version of Byron for testing.
        allAds = [];
    }

    var currentDate = new Date();

    // The Ad to be shown has either empty labels, meaning, it can be shown
    // to everybody, or, if it has labels, it can only be shown to
    // users with the same skills, products, etc.

    // Currently the Ads are in an array.
    // TODO +++ Alen they should be moved to the database.

    // 1. Extract Ads only with valid expiryDates.
    // 2. Of these, extract all Ads without labels or Ads with appropriate labels.
    var allAdsPossible = [];

    useEffect(() => {
        start();
    }, []);

    const clickEvent = () => {
        window.open(randomAd.adLink);
    };

    // Taken from skillsOverlay.jsx.
    const getUserSkills = async () => {
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getBayronTags`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken")
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const userInfo = data;
                // This userInfo array holds the user expertises and parameters:
                // skills, communities, organisations, products, projects, tools, department, division, city, country.
                // They can be accessed using e.g. userInfo.current.skills,
                // userInfo.current.communities, etc.



                createArrayOfDataToBeShown(userInfo);
            });
    };

    var start = () => {
        if (CONF.OPS_MODE !== "external") {
            // getUserSkills calls
            //   createArrayOfDataToBeShown,
            // which is the function that sets the randomAd to be shown.
            // getUserSkills contains a fetch function / async await.
            // There are probably much better ways to do this.

            getUserSkills();
        } else {
            // IF YOU ARE DEVELOPING, CHANGE THE EMAIL TO YOUR EMAIL IN THE DEVELOPMENT BROWSER.
            // Use some default values for testing in our own development laptops.
            // Always test if empty also works.
            // city, country, department, division are single strings for a user
            //   but they are an array of strings for the Ads.
            // Notice that skills are objects with "skill" and "level".
            let userExpertises = {
                department: "BAG-PH-RD-OIDT-OI-ST",
                division: "PH",
                city: "Boston, MA",
                country: "Germany",
                skills: [
                    { skill: "Latin music", level: 1 },
                    { skill: "Biology", level: 1 },
                    { skill: "Radeberger", level: 2 },
                    { skill: "Beer", level: 3 }
                ],
                projects: ["APAC Purpose Project"],
                tools: ["R", "JavaScript"],
                communities: ["Grow"],
                products: ["Aspirin"],
                organisations: ["Bayer"],
                email: "iron00000@yeysus.com"
            };


            createArrayOfDataToBeShown(userExpertises);
        }
    };

    // If adDepartment === userDepartment, return true.
    // If adDepartment ends in *, and userDepartment starts with (adDepartment - *), return true.
    // Else return false.
    var checkDepartment = (adDepartment, userDepartment) => {
        // If adDepartment is empty we do not need to check anymore.
        // console.log("In checkDepartment. adDepartment: " + adDepartment + ". userDepartment: " + userDepartment);
        if (!adDepartment) {
            return true;
        }
        // If adDepartment exists, but userDepartment does not, we cannot show the Ad.
        if (!userDepartment) {
            return false;
        }
        if (adDepartment.endsWith("*")) {
            const adDepartmentStartString = adDepartment.substring(
                0,
                adDepartment.length - 1
            );
            if (userDepartment.startsWith(adDepartmentStartString)) {
                return true;
            } else {
                return false;
            }
        } else {
            if (userDepartment === adDepartment) {
                return true;
            } else {
                return false;
            }
        }
    };

    var checkCategories = (adCategoryArray, userCategoryArray) => {
        // e.g. for projects:
        // adCategoryArray = allAdsWithLabels[i].adLabels.projects
        // userCategoryArray = userExpertises.projects
        // console.log("Check Categories: User", userCategoryArray);
        // console.log("Check Categories: Ad", adCategoryArray);
        let userCategoryArrayFitsAdCategoryArray = false;
        if (
            adCategoryArray !== "" &&
            adCategoryArray !== null &&
            adCategoryArray !== undefined
        ) {
            for (let j = 0; j < adCategoryArray.length; j++) {
                if (userCategoryArray.length !== 0) {
                    for (let k = 0; k < userCategoryArray.length; k++) {
                        if (
                            adCategoryArray[j].toLowerCase() ===
                            userCategoryArray[k].toLowerCase()
                        ) {
                            // allAdsPossible = allAdsPossible.concat(allAdsWithLabels[i]);
                            userCategoryArrayFitsAdCategoryArray = true;
                            // If one skill fits, we do not need to check the other user skills,
                            // and neither other Ad skills or projects, communities, etc.
                            // This Ad will be shown.
                        }
                        if (userCategoryArrayFitsAdCategoryArray) break;
                    }
                }
                if (userCategoryArrayFitsAdCategoryArray) break;
            }
        }
        return userCategoryArrayFitsAdCategoryArray;
    };

    const createArrayOfDataToBeShown = (userExpertises) => {
        // console.log("Entered createArrayOfDataToBeShown.");

        // This would probably break somewhere if userExpertises do not exist.
        if (userExpertises === null || userExpertises === "") {
            console.log(
                "USER EXPERTISES ARE EMPTY OR NULL. This is probably a mistake somewhere. Adding Atlantis as country."
            );
            userExpertises = {
                department: "",
                division: "",
                city: "",
                country: "Atlantis",
                skills: "",
                projects: "",
                tools: "",
                communities: "",
                products: "",
                organisations: "",
                email: ""
            };
        }

        // Remove Ads with invalid expiry Dates.

        allAds = allAds.filter(
            (item) =>
                item.adExpiryDate === "" ||
                item.adExpiryDate === null ||
                new Date(item.adExpiryDate) > currentDate
        );

        // Remove Ads with labels that do not fit to the user.
        // Elements can be accessed like:
        // allAds[i].adLabels.skills[j]
        // We would have departments, division, cities, countries,
        // skills, products, organisations, communities, projects, tools.
        // Notes about JavaScript functions:
        //   Find elements that exist in 2 arrays:
        //     array1.some(item => array2.includes(item))
        //   Clone array:
        //     const cloneArray1 = [...array1];

        // The Ads display algorithm:
        // For an Ad to be shown, the user must fullfill the condition:
        // "One of" means "Or logic".
        // (One of adLabels.departments) &&
        // (One of adLabels.divisions) &&
        // (One of adLabels.cities) &&
        // (One of adLabels.countries) &&
        // (One of adLabels.skills) ||
        // (One of adLabels.projects) ||
        // (One of adLabels.tools) ||
        // (One of adLabels.communities) ||
        // (One of adLabels.products) ||
        // (One of adLabels.organisations)
        // No value of any parameter means all are accepted,
        // e.g. empty cities means all cities are accepted.

        // allAdsWithoutLabels will be shown to all users.

        let allAdsWithLabels = [];
        allAdsWithLabels = allAds.filter(
            (item) => item.adLabels !== "" && item.adLabels !== null
        );
        let allAdsWithoutLabels = [];
        allAdsWithoutLabels = allAds.filter(
            (item) => item.adLabels === "" || item.adLabels === null
        );

        // console.log("Number of Ads without labels: " + allAdsWithoutLabels.length);
        // console.log("Number of Ads with labels: " + allAdsWithLabels.length);

        // Check which Ads fit to the user.

        // departments is a string in the format:
        // BAG-PH-RD-OIDT-OI-ST or
        // BAG-PH-RD-OIDT*
        // * means, whatever is after * is accepted as part of that department.

        // Check Ads one by one.
        for (let i = 0; i < allAdsWithLabels.length; i++) {
            // console.log("i: " + i);
            // console.log("Ad", allAdsWithLabels[i].adLabels);

            // department.
            let userDepartmentFitsAdDepartments = false;
            if (
                allAdsWithLabels[i].adLabels.departments !== "" &&
                allAdsWithLabels[i].adLabels.departments !== null &&
                allAdsWithLabels[i].adLabels.departments !== undefined
            ) {
                for (
                    let j = 0;
                    j < allAdsWithLabels[i].adLabels.departments.length;
                    j++
                ) {
                    // Check department.
                    // console.log(userExpertises.department);
                    if (
                        checkDepartment(
                            allAdsWithLabels[i].adLabels.departments[j],
                            userExpertises["department"]
                        )
                    ) {
                        userDepartmentFitsAdDepartments = true;
                        break;
                    }
                }
            } else {
                // Ad has no department label therefore user department fits no matter what.
                userDepartmentFitsAdDepartments = true;
            }
            // if department does not fit, this Ad cannot be used.
            // console.log("userDepartmentFitsAdDepartments: " + userDepartmentFitsAdDepartments);
            if (!userDepartmentFitsAdDepartments) continue;

            // division.
            // TODO +++ to simplify the code, maybe we could use reflexion for division, city, and country.
            // Unsure if it would all really work.
            let userDivisionFitsAdDivisions = false;
            if (
                allAdsWithLabels[i].adLabels.divisions !== "" &&
                allAdsWithLabels[i].adLabels.divisions !== null &&
                allAdsWithLabels[i].adLabels.divisions !== undefined
            ) {
                for (
                    let j = 0;
                    j < allAdsWithLabels[i].adLabels.divisions.length;
                    j++
                ) {
                    // console.log("userExpertises.division: " + userExpertises.division);
                    // console.log("allAdsWithLabels[i].adLabels.divisions[j]: " + allAdsWithLabels[i].adLabels.divisions[j]);
                    if (
                        allAdsWithLabels[i].adLabels.divisions[j] ===
                        userExpertises.division
                    ) {
                        userDivisionFitsAdDivisions = true;
                        break;
                    }
                }
            } else {
                // Ad has no division label therefore user division fits no matter what.
                userDivisionFitsAdDivisions = true;
            }
            // console.log("userDivisionFitsAdDivisions: " + userDivisionFitsAdDivisions);
            if (!userDivisionFitsAdDivisions) continue;

            // city.
            let userCityFitsAdCities = false;
            if (
                allAdsWithLabels[i].adLabels.cities !== "" &&
                allAdsWithLabels[i].adLabels.cities !== null &&
                allAdsWithLabels[i].adLabels.cities !== undefined
            ) {
                for (
                    let j = 0;
                    j < allAdsWithLabels[i].adLabels.cities.length;
                    j++
                ) {
                    if (
                        allAdsWithLabels[i].adLabels.cities[j] ===
                        userExpertises.city
                    ) {
                        userCityFitsAdCities = true;
                        break;
                    }
                }
            } else {
                // Ad has no city label therefore user city fits always.
                userCityFitsAdCities = true;
            }
            // console.log("userCityFitsAdCities: " + userCityFitsAdCities);
            if (!userCityFitsAdCities) continue;

            // country.
            let userCountryFitsAdCountries = false;
            if (
                allAdsWithLabels[i].adLabels.countries !== "" &&
                allAdsWithLabels[i].adLabels.countries !== null &&
                allAdsWithLabels[i].adLabels.countries !== undefined
            ) {
                for (
                    let j = 0;
                    j < allAdsWithLabels[i].adLabels.countries.length;
                    j++
                ) {
                    if (
                        allAdsWithLabels[i].adLabels.countries[j] ===
                        userExpertises.country
                    ) {
                        userCountryFitsAdCountries = true;
                        break;
                    }
                }
            } else {
                // Ad has no country label therefore user country fits always.
                userCountryFitsAdCountries = true;
            }
            // console.log("userCountryFitsAdCountries: " + userCountryFitsAdCountries);
            if (!userCountryFitsAdCountries) continue;

            // If we are here, this Ad fits with
            // department, division, city, country, of the user.
            // These are all "AND" parameters.
            // skills, projects, etc, need to fit only once.

            // We start with skills.
            // userExpertises.skills is an array of objects which each contain a skill and an expertise level (1, 2, 3).
            // Therefore the string we are looking for is not "userExpertises.skills[k]", it is "userExpertises.skills[k].skill".
            let userSkillsFitsAdSkills = false;
            if (
                allAdsWithLabels[i].adLabels.skills !== "" &&
                allAdsWithLabels[i].adLabels.skills !== null &&
                allAdsWithLabels[i].adLabels.skills !== undefined
            ) {
                // console.log("allAdsWithLabels[i].adLabels.skills", allAdsWithLabels[i].adLabels.skills);

                for (
                    let j = 0;
                    j < allAdsWithLabels[i].adLabels.skills.length;
                    j++
                ) {
                    if (userExpertises.skills.length !== 0) {
                        for (let k = 0; k < userExpertises.skills.length; k++) {
                            if (
                                allAdsWithLabels[i].adLabels.skills[
                                    j
                                ].toLowerCase() ===
                                userExpertises.skills[k].skill.toLowerCase()
                            ) {
                                allAdsPossible = allAdsPossible.concat(
                                    allAdsWithLabels[i]
                                );
                                userSkillsFitsAdSkills = true;

                                // If one skill fits, we do not need to check the other user skills,
                                // and neither other Ad skills or projects, communities, etc.
                                // This Ad will be shown.
                            }
                            if (userSkillsFitsAdSkills) break;
                        }
                    }
                    if (userSkillsFitsAdSkills) break;
                }
            }
            // console.log("userSkillsFitsAdSkills: " + userSkillsFitsAdSkills);
            if (userSkillsFitsAdSkills) continue;

            // Projects.
            if (
                checkCategories(
                    allAdsWithLabels[i].adLabels.projects,
                    userExpertises.projects
                )
            ) {
                // console.log("User Projects Fit Ad Projects: " + true);
                allAdsPossible = allAdsPossible.concat(allAdsWithLabels[i]);
                continue;
            }
            // console.log("User Projects Fit Ad Projects: " + false);

            // Communities.
            if (
                checkCategories(
                    allAdsWithLabels[i].adLabels.communities,
                    userExpertises.communities
                )
            ) {
                // console.log("User Communities Fit Ad Communities: " + true);
                allAdsPossible = allAdsPossible.concat(allAdsWithLabels[i]);
                continue;
            }
            // console.log("User Communities Fit Ad Communities: " + false);

            // Organisations.
            if (
                checkCategories(
                    allAdsWithLabels[i].adLabels.organisations,
                    userExpertises.organisations
                )
            ) {
                // console.log("User Organisations Fit Ad Organisations: " + true);
                allAdsPossible = allAdsPossible.concat(allAdsWithLabels[i]);
                continue;
            }
            // console.log("User Organisations Fit Ad Organisations: " + false);

            // Products.
            if (
                checkCategories(
                    allAdsWithLabels[i].adLabels.products,
                    userExpertises.products
                )
            ) {
                // console.log("User Products Fit Ad Products: " + true);
                allAdsPossible = allAdsPossible.concat(allAdsWithLabels[i]);
                continue;
            }
            // console.log("User Products Fit Ad Products: " + false);

            // Tools.
            if (
                checkCategories(
                    allAdsWithLabels[i].adLabels.tools,
                    userExpertises.tools
                )
            ) {
                // console.log("User Tools Fit Ad Tools: " + true);
                allAdsPossible = allAdsPossible.concat(allAdsWithLabels[i]);
                continue;
            }
            // console.log("User Tools Fit Ad Tools: " + false);

            // eMails.
            // user.email is a single string. Convert it to an array so we only need one algorithm for
            // checking.
            var userExpertisesEmailArray = [];
            userExpertisesEmailArray.push(userExpertises.email);
            // console.log(userExpertisesEmailArray);
            if (
                checkCategories(
                    allAdsWithLabels[i].adLabels.emails,
                    userExpertisesEmailArray
                )
            ) {
                // console.log("User email Fits Ad emails: " + true);
                allAdsPossible = allAdsPossible.concat(allAdsWithLabels[i]);
                continue;
            }
            // console.log("User email Fits Ad emails: " + false);
        }

        // Now concatenate allAdsWithoutLabels into allAdsPossible.
        // For now there are many more Ads without labels than Ads with labels.

        // console.log("Total number of allAdsPossible, with common labels only: " + allAdsPossible.length);
        // console.log("Total number of allAdsWithoutLabels: " + allAdsWithoutLabels.length);
        allAdsPossible.push(...allAdsWithoutLabels);

        // console.log("Total number of allAdsPossible: " + allAdsPossible.length);

        // console.log(allAdsPossible);
        var tempRandomAd =
            allAdsPossible[Math.floor(Math.random() * allAdsPossible.length)];
        setRandomAd(tempRandomAd);

        // TODO +++ Actually, it should check if Byron is running or not.
        // Or also, if the user is registered / logged in / has enough Byron skills, etc.
        // Check for punctuation. Words ending on " ", ".", "-".
        // We could add weight.
    };

    if (randomAd) {
        return (
            <InfoWrapper className="InfoWrapper">
                <DivWrap>
                    <div>
                        <div className="adWraper">
                            <a
                                href={randomAd.adAuthorUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="profileImage"
                                    src={randomAd.adAuthorImage}
                                    alt={randomAd.adTitle}
                                    width="150"
                                    height="150"
                                />
                            </a>
                            <div
                                onClick={clickEvent}
                                className="sectionContentWrap"
                            >
                                <img
                                    className="picture"
                                    src={randomAd.adPicturePath}
                                    alt={randomAd.adTitle}
                                    width="150"
                                    height="150"
                                />
                                <div className="textWrap">
                                    <h3 className="EMail">
                                        {randomAd.adTitle}
                                    </h3>
                                    <p className="text">{randomAd.adText}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </DivWrap>
            </InfoWrapper>
        );
    } else {
        return null;
    }
};

export default AdBanner;
