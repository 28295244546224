import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
// import { useEffect } from "react";
// import Dialog from "@material-ui/core/Dialog";
import { colors } from "../general/styles";
// For Debate Meetups.
// import { addMeetupToDB, updateMeetupInDB } from "../../hooks/socketOperations";
// import { matchUser } from "../../hooks/socketOperations";
// import { Redirect } from "react-router-dom";
import CountdownModal from "../lobby/countdownModal";
import { CONF } from "../../config/config";

const PopupComponent = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;

    .copyBtn {
        margin-bottom: 20px;

        svg {
            font-size: 30px;
        }
    }

    .inner {
        position: relative;
        width: 100%;
        //height: 100%;
        max-width: 700px;
        max-height: 90%;
        box-sizing: border-box;
        margin: auto;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        //align-items: center;
        background-color: #ffffff;
        font-size: 1.5rem;
        padding: 50px;
        overflow-y: auto;

        h3 {
            font-weight: 400;
            text-align: center;
        }

        h2 {
            font-weight: 500;
            border-bottom: 3px solid #d30f4b;
            text-align: center;
        }
        .answerBtnHolder {
            display: inline;
        }

        .answerBtnContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .answerBtn {
            margin: 15px;
            border-radius: 23px;
            background-color: white;
            color: #dc1448;
            font-size: 16px;
            font-weight: 700;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            padding: 12px 0;
            width: 224px;
            border: 1px solid #dc1448;
            opacity: 1;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s;

            &:hover {
                background-color: #dc1448;
                color: white;
            }
        }

        p {
            font-size: 1.1rem;
            font-weight: 500;
            margin: 10px;
            color: ${colors.darkGray};
        }

        .link {
            color: ${colors.pink};
            margin-bottom: 10px;
        }

        .icon {
            position: absolute;
            top: 15px;
            right: 15px;
            color: ${colors.darkGray};
        }

        .closeModalIcon {
            position: absolute;
            top: 15px;
            right: 15px;
            color: black;
        }
    }
`;

/*
const ModalWrap = styled(Dialog)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .MuiDialog-paperWidthSm {
    max-height: 300px;
  }

  .modalWrap {
    display: flex;
    flex-direction: column;
    min-width: 400px;

    @media only screen and (orientation: portrait) {
      min-width: 200px;
      overflow-y: auto;
    }
  }

  .newMeetupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    text-align: center;
    margin: 0;
  }

  .submit-button-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    margin-top: 24px;

    .saveUserInfo {
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;

      padding: 15px 71px;
      border-radius: 23px;
      background-color: #11394f;
      border: none;
    }
  }

  .input-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    margin-top: 24px;

    label {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4d4d4d;
    }

    input {
      padding: 0;
    }

    .input-field {
      border: none;
      box-sizing: border-box;
      margin-top: 16px;
      border-radius: 8px;
      background-color: #f4f2f2;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${colors.darkGray};
      padding-left: 15.5px;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 100%;
    }
  }

  input {
    padding: 0;
  }

  .MuiInputBase-root {
    margin-top: 5px;
    margin-bottom: 20px;
    width: 100%;
  }

`;
*/

export default function DebateOrFriendsMeetupModal(props) {
    const meetup = props.meetup;
    let debateOrFriendsMeetup = "";
    if (meetup.isDebateMeetup) {
        debateOrFriendsMeetup = "Debate";
        console.log("DebateModal");
    } else if (meetup.isFriendsMeetup) {
        debateOrFriendsMeetup = "Friends";
        console.log("FriendsModal");
    }

    const debateOrFriendsAnswersArray = meetup
        ? meetup.debateOrFriendsAnswers.split(",")
        : [];
    const [
        toggleDebateOrFriendsMeetupPopup,
        setToggleDebateOrFriendsMeetupPopup
    ] = useState(
        localStorage.getItem("temporaryPreferences") !== null ? false : true
    );

    async function saveUserDebateChoice() {
        let data = {
            userId: localStorage.getItem("token"),
            jwtToken: localStorage.getItem("jwtToken"),
            temporaryPreferences: localStorage.getItem("temporaryPreferences")
        };
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getUserInfo2`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        });
        setToggleDebateOrFriendsMeetupPopup(false);
    }

    const handleNoAnswerSelected = () => {
        window.location.href = `${window._env_.REACT_APP_BAYRONNECT_URL}/`;
    };

    return toggleDebateOrFriendsMeetupPopup ? (
        <PopupComponent>
            <div className="inner">
                <div className="newMeetupHeader">
                    <div className="exitModal" onClick={handleNoAnswerSelected}>
                        <CloseIcon className="closeModalIcon" />
                    </div>
                </div>
                <div>
                    <h2>
                        {debateOrFriendsMeetup} - {meetup.name}
                    </h2>
                    <h3>{meetup.debateOrFriendsQuestion}</h3>
                </div>
                <div className="answerBtnContainer">
                    {debateOrFriendsAnswersArray.map(
                        (debateOrFriendsAnswer, index) => {
                            return (
                                <div>
                                    <button
                                        className="answerBtn"
                                        value={debateOrFriendsAnswer}
                                        key={index}
                                        onClick={(e) => {
                                            localStorage.setItem(
                                                "temporaryPreferences",
                                                e.target.value
                                            );
                                            saveUserDebateChoice();
                                        }}
                                    >
                                        {debateOrFriendsAnswer}
                                    </button>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </PopupComponent>
    ) : (
        <CountdownModal
            isSurveyModal={props.isSurveyModal}
            socket={props.socket}
            meetup={meetup}
        />
    );
}
