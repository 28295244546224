import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Tooltip from "@material-ui/core/Tooltip";

const StyledButton = styled.button`
  border-radius: 23px;
  background-color: ${(props) => (props.hollow ? "#ffffff" : "#dc1448")};
  border-color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};

  padding: 12px 0;
  width: ${(props) => props.width};
  margin-left: ${(props) => (props.popup ? "30px" : "0px")};
  margin-right: ${(props) => (props.popup ? "30px" : "0px")};
  margin-top: ${(props) => (props.popup ? "30px" : "0px")};
  margin-bottom: 15px;
  border: 1px solid #dc1448;
  opacity: ${(props) => (props.disabled ? "0.3" : "1")}
  :hover {
    background-color: ${(props) => (props.hollow ? "#e9e9e9" : "#BE1442")};
    border-color: #BE1442;
  }
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint("tablet")`
    margin-bottom: 0;
  `}
`;

const NextButton = (props) => {
    return props.disabled === false ? (
        <Tooltip title={props.tooltipText} arrow>
            <StyledButton
                onClick={() => {
                    props.onClickEvent();
                }}
                disabled={props.disabled}
                width={props.width ? props.width : "224px"}
                hollow={props.hollow}
                className="enterLobbyButton"
                popup={props.popup}
            >
                {props.title ? props.title : "Quit"}
            </StyledButton>
        </Tooltip>
    ) : (
        <StyledButton
            onClick={() => {
                props.onClickEvent();
            }}
            disabled={props.disabled}
            width={props.width ? props.width : "224px"}
            hollow={props.hollow}
            className="enterLobbyButton"
            popup={props.popup}
        >
            {props.title ? props.title : "Quit"}
        </StyledButton>
    );
};

export default NextButton;
