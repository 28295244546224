import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import { colors } from "../components/general/styles";
import { CONF } from "../config/config";

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh !important;
    box-shadow: 0px 7px 23px 0px rgba(0, 0, 0, 0.15);
    //max-height: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: max-height 500ms ease-in-out;
    border-radius: 4px;
`;

const Container = styled.div`
    margin: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    font-size: 1.5rem;
    padding: 50px;

    h1 {
        font-size: 1.5rem;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 3px solid ${colors.red};
        margin-bottom: 30px;
    }
    p {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${colors.darkGray};
    }
    button {
        cursor: pointer;
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const Faq = (props) => {
    const classes = useStyles();

    return (
        <Wrapper style={{ minHeight: "100%", minWidth: "100%" }}>
            <Container>
                <h1>Frequently asked questions</h1>

                <div className={classes.root}>
                    {!CONF.IS_STANDALONE && (
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>
                                    Do we have a guide?
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    Yes. Please{" "}
                                    <a
                                        // href={require("../assets/ByronMeet_End_User_Guide.pdf")}    // this does not work on external (apache)
                                        href="../assets/ByronMeet_End_User_Guide.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        click here to get it.
                                    </a>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )}
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                I'm not being matched
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                It may help if you click the pause button so
                                that it turns into a play button. Then press the
                                play button again.
                                <br />
                                If this doesn't help click 'Go back' and reenter
                                the meetup. <br />
                                Also make sure that you don't have any
                                preferences set that prevent you from being
                                matched. To do that, go to the startpage and
                                click on "General Preferences" and check if any
                                of the tabs have a hard filter in place.
                                <br />
                                If you're still experiencing issues, contact{" "}
                                <a href="mailto:byron@bayer.com">
                                    byron@bayer.com
                                </a>{" "}
                                or type your question into the chat in the
                                lobby.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography className={classes.heading}>
                                I couldn't connect to my partner
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                The major cause for a failed connection is when
                                your partner did not click on "Ready" and thus
                                you'll be guided back to the lobby. <br />
                                Sometimes, however, due to slow internet it may
                                happen that when both partners accept to very
                                different times, e.g. one partner at the very
                                beginning of the countdown clock and one at the
                                very end of it, the connection cannot be
                                established. <br />
                                A general advice is to always click on the
                                "Ready" button as soon as possible. <br />
                                If the issue does persist, please contact{" "}
                                <a href="mailto:byron@bayer.com">
                                    byron@bayer.com
                                </a>{" "}
                                or ask in the chat of the lobby (visible to all
                                meetup members).
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4ia-header"
                        >
                            <Typography className={classes.heading}>
                                What is the play button for?
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                Next to the FAQ question mark you can see a
                                little pause/play button. If it shows a pause
                                button, it means that you are actively
                                participating in the meetup. If you click it, it
                                will put you on "pause", so that you won't be
                                matched again. If you click it again, you'll be
                                back to active status.
                                <br /> You can also click the pause button
                                during a videochat. It doesn't have an effect on
                                your current meeting.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                        >
                            <Typography className={classes.heading}>
                                My camera does not work
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                First and foremost, do you have any further
                                applications running that require access to your
                                camera, e.g. Zoom or Teams? If so, please close
                                these apps and reload ByronMeet. <br />
                                Additionally, you may click on the camera button
                                on the top right of the screen inside the red
                                bar. After a few seconds, you should be able to
                                see the contents from your camera. If that is
                                not the case, chose your respective camera
                                device from the dropdown. Depending on whether
                                you've granted ByronMeet permission to use the
                                camera or not, you'll be asked to grant
                                permission via a small browser popup at the top
                                of the page. In this case, please check
                                "Remember permission" so that you don't have to
                                do this again and click "OK". Finally click
                                "Confirm" to confirm your camera and audio
                                choice.
                                <br />
                                If the issue does persist, please contact{" "}
                                <a href="mailto:byron@bayer.com">
                                    byron@bayer.com
                                </a>{" "}
                                or ask in the chat of the lobby (visible to all
                                meetup members).
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                        >
                            <Typography className={classes.heading}>
                                The Login button does not work
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                Use an 'incognito' or 'private' window in your
                                browser and now go again to the{" "}
                                <a href={`${window.location.origin}`}>Home</a>{" "}
                                page.
                                <br />
                                If the issue does persist, please contact{" "}
                                <a href="mailto:byron@bayer.com">
                                    byron@bayer.com
                                </a>
                                .
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </Container>
        </Wrapper>
    );
};

export default Faq;
