import React from "react";
import styled from "styled-components";
import { CONF } from "../config/config";

const StyledButton = styled.button`
    border-radius: 23px;
    width: 224px;
    background-color: ${(props) => (props.hollow ? "#ffffff" : "#dc1448")};
    border-color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    padding: 12px 0;
    display: flex;
    justify-content: center;

    :hover {
        background-color: ${(props) => (props.hollow ? "#e9e9e9" : "#BE1442")};
        border-color: #be1442;
    }
`;

const Error = () => {
    return (
        <div
            className="columns is-fullheight error-message-wrap"
            style={{ minHeight: "100%" }}
        >
            <p className="error-message">
                Oops, something went wrong, please click on a link below to go
                to homepage and try again.
            </p>
            <StyledButton
                className={"laterNews"}
                onClick={() => {
                    if (CONF.OPS_MODE === "internal") {
                        window.location.href = "/bayronnect";
                    } else {
                        window.location.href = "/";
                    }
                }}
                width="180px"
                hollow={true}
            >
                Go to homepage
            </StyledButton>
        </div>
    );
};

export default Error;
