import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { sendTopic } from "../../hooks/socketOperations";
import { colors } from "../general/styles";

const StyledButton = styled.button`
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: ${colors.red};
    color: white;
    z-index: 1500;
    border: none;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    max-width: 40%;

    @media only screen and (max-width: 1024px) {
        max-width: 100%;
        position: initial;
        margin: 40px 0 0 0;
    }

    @media only screen and (max-width: 889px) {
        transform: initial;
    }
`;

const TopicDisplay = (props) => {
    const [topic, setTopic] = useState("click to switch topics");
    // Because of the way the algorithm to display topics is built,
    // see further below:
    // handleTopicChange(i + 1 > topics.length - 1 ? 0 : i + 1)
    // if the initial state of i would be 0, the first topic, topics[0], would not be
    // the first to be displayed. Therefore we start with i = -1.
    const [i, setI] = useState(-1);

    const topics =
        props.topics !== null
            ? props.topics
            : [
                  "true north",
                  "pharma future",
                  "formulation technologies",
                  "laser technologies",
                  "CRISPR",
                  "CAR-T therapies",
                  "small molecules"
              ];

    // shuffle the array so people get random order of topics

    useEffect(() => {
        //todo uncomment when implemented
        props.socket.on("receiveTopic", (msg) => {
            if (msg.id !== i) {
                handleTopicChange(msg.id, false);
            }
        });
    });

    const handleTopicChange = (idx, emit = true) => {
        if (emit === true) {
            sendTopic(props.socket, idx, props.socketRoomId);
        }
        setTopic(topics[idx]);
        setI(idx);
    };

    return (
        topics.length > 0 && (
            <StyledButton
                onClick={() =>
                    handleTopicChange(i + 1 > topics.length - 1 ? 0 : i + 1)
                }
                className={"topicsBtn"}
            >
                {topic}
            </StyledButton>
        )
    );
};

export default TopicDisplay;
