import { InfoTooltip } from "../infoTooltip";
import React from "react";

export const CalendarInput = (props) => {
    return (
        <div className="input-wrap">
            <label>
                Drag and drop an Outlook invite:{" "}
                <InfoTooltip tooltipText="Drag and drop your .ics file to create an event" />
            </label>
            <input
                type="file"
                onChange={props.onChange}
                className="photo-input"
            />
        </div>
    );
};
