/** @format */

import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import * as StackBlur from "stackblur-canvas";
import IconButton from "@material-ui/core/IconButton";
import TooltipMui from "@material-ui/core/Tooltip";
import blurOnIcon from "../../assets/icons/video/blurOn.png";
import blurOffIcon from "../../assets/icons/video/blurOff.png";
import backgroundOnIcon from "../../assets/icons/video/backgroundOn.png";
import backgroundOffIcon from "../../assets/icons/video/backgroundOff.png";
import { CONF } from "../../config/config";

const StyledCanvasContainer = styled.div`
    // position: absolute;
    // top: 0;
    // bottom: 0;
    display: flex;
    //position: relative;
    flex-grow: 1;
    //height: 100%;
    @media screen and (max-width: 768px) {
        position: relative;
    }

    .filter-active {
        background-color: #66b512 !important;
    }

    &.isSmall {
        height: 300px;
        width: 400px;
        .button-container {
            max-width: calc(100% - 75px) !important;
        }
    }

    .output-canvas {
        //position: absolute;
        //left: 999999999px;
        width: 100%;

        &.dontMirror {
            transform: scale(-1, 1);
        }
    }

    .buttons {
        display: flex;
    }

    .output-video {
        z-index: 2;
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .button-container {
        max-width: calc(100% - 300px);
        min-width: 200px;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
        display: flex;
        z-index: 999;

        .buttons {
            margin: 10px 10px;
        }

        .button {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            margin: 0 5px;
            opacity: 0.8;
            cursor: pointer;
            transition: all 0.1s linear;

            &:hover {
                opacity: 1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .backgrounds {
            display: flex;
            height: 60px;
            width: 100%;
            overflow-x: scroll;
            margin-top: 5px;

            img {
                width: auto;
                max-height: 60px;
                cursor: pointer;
            }
        }
    }

    .output-canvas {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }

    &.small-container {
        .output-canvas {
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }

        .output-video {
            height: 100%;
            max-width: 100%;
            position: initial;
            //max-width: 400px;
        }
    }
`;
const VideoCanvas = (props) => {
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const requestRef = useRef(null);

    const [currentFilter, setCurrentFilter] = useState({
        activeEffect: localStorage.getItem("activeEffect") || "normal",
        backgroundImg: localStorage.getItem("backgroundImg"),
        init: true
    });

    let img;

    // console.log("%c videoCanvas render", "background: white; color: blue;");

    const [showBackgrounds, setShowBackgrounds] = useState(
        currentFilter.activeEffect === "background"
    );
    const [virtualBackgrounds, setVirtualBackgrounds] = useState([]);
    const [selfieSegmentation, setSelfieSegmentation] = useState(false);

    const videoId = props.isSmall ? "popup_webcam" : "webcam_video";

    let webcam_video;

    useEffect(() => {
        if (props.stream) {
            webcam_video = document.createElement("video");
            webcam_video.srcObject = props.stream;
            webcam_video.setAttribute("id", videoId);
            webcam_video.muted = true;
            webcam_video.style.display = "none";
            document.body.appendChild(webcam_video);

            webcam_video.addEventListener(
                "playing",
                function () {
                    if (!selfieSegmentation) {
                        setSelfieSegmentation(
                            new SelfieSegmentation({
                                locateFile: (file) =>
                                    `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`
                            })
                        );
                    } else {
                        requestFrames();
                    }
                },
                true
            );

            var userAgent = window.navigator.userAgent;

            if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
                webcam_video.autoplay = true;
                webcam_video.playsInline = true;
            }
            webcam_video.play();
        }

        return () => {
            cancelAnimationFrame(requestRef.current);
            if (document.getElementById(videoId)) {
                document
                    .getElementById(videoId)
                    .srcObject.getTracks()
                    .forEach(function (track) {
                        track.stop();
                    });
                document.getElementById(videoId).outerHTML = "";
            }
        };
    }, [props.stream]);

    async function requestFrames() {
        if (currentFilter.activeEffect === "background") {
            img = new Image();
            img.src = currentFilter.backgroundImg;
            img.crossOrigin = "anonymous";
        }

        selfieSegmentation.onResults(onResults);
        contextRef.current = canvasRef.current.getContext("2d");

        canvasRef.current.width = props.stream
            .getVideoTracks()[0]
            .getSettings().width;
        canvasRef.current.height = props.stream
            .getVideoTracks()[0]
            .getSettings().height;

        await selfieSegmentation.send({ image: webcam_video });
        requestRef.current = window.requestAnimationFrame(requestFrames);
    }

    useEffect(() => {
        if (
            props.setTempStream &&
            (props.currentPage === "match" || "conference")
        ) {
            let finalStream = canvasRef.current.captureStream();
            props.stream.getTracks().forEach((track) => {
                if (track.kind == "audio") {
                    finalStream.addTrack(track);
                }
            });
            props.setTempStream(finalStream);
        }
        if (!currentFilter.init) {
            if (!webcam_video) {
                webcam_video = document.getElementById(videoId);
            }
            requestFrames();
        }

        window.localStorage.setItem("activeEffect", currentFilter.activeEffect);

        return () => {
            cancelAnimationFrame(requestRef.current);
        };
    }, [currentFilter]);

    useEffect(() => {
        if (
            props.setTempStream &&
            (props.currentPage === "match" || "conference")
        ) {
            let finalStream = canvasRef.current.captureStream();

            props.stream.getTracks().forEach((track) => {
                if (track.kind == "audio") {
                    finalStream.addTrack(track);
                }
            });
            props.setTempStream(finalStream);
        }
    }, [props.currentPage, props.stream]);

    useEffect(() => {
        function importAll(r) {
            let images = [];
            r.keys().map((item, index) => {
                if (CONF.OPS_MODE === "internal") {
                    images.push(
                        "/bayronnect/virtual-backgrounds/" +
                            item.replace("./", "")
                    );
                } else {
                    images.push(
                        "/virtual-backgrounds/" + item.replace("./", "")
                    );
                }
                return images;
            });
            return images;
        }

        let backgrounds;

        backgrounds = importAll(
            require.context(
                "../../../public/virtual-backgrounds",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        setVirtualBackgrounds(backgrounds);
        return () => {
            cancelAnimationFrame(requestRef.current);
            if (selfieSegmentation) {
                selfieSegmentation.close();
            }
            let script = document.querySelector(
                'script[src="https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/selfie_segmentation_solution_simd_wasm_bin.js"]'
            );
            if (script) {
                if (script.length > 1) {
                    script[1].remove();
                } else {
                    script.remove();
                }
            }
        };
    }, []);

    useEffect(() => {
        if (selfieSegmentation) {
            webcam_video = document.getElementById(videoId);
            initModel();
            requestFrames();
        }
    }, [selfieSegmentation]);

    //Turn off virtual backgrounds if you are sharing screen
    useEffect(() => {
        if (props.sharingScreen) {
            setCurrentFilter({
                activeEffect: "normal"
            });
        } else {
            setCurrentFilter({
                ...currentFilter,
                activeEffect: localStorage.getItem("activeEffect"),
                init: true
            });
        }
    }, [props.sharingScreen]);

    const initModel = () => {
        if (props.sharingScreen) {
            selfieSegmentation.setOptions({
                modelSelection: 1,
                selfieMode: false
            });
        } else {
            selfieSegmentation.setOptions({
                modelSelection: 1,
                selfieMode: true
            });
        }
    };

    function onResults(results) {
        contextRef.current.save();
        contextRef.current.clearRect(
            0,
            0,
            props.stream.getVideoTracks()[0].getSettings().width,
            props.stream.getVideoTracks()[0].getSettings().height
        );

        if (props.sharingScreen) {
            contextRef.current.drawImage(
                results.image,
                0,
                0,
                props.stream.getVideoTracks()[0].getSettings().width,
                props.stream.getVideoTracks()[0].getSettings().height
            );
        } else if (currentFilter.activeEffect === "background") {
            if (img) {
                //dont need this part for BLUR
                contextRef.current.drawImage(
                    results.segmentationMask,
                    0,
                    0,
                    props.stream.getVideoTracks()[0].getSettings().width,
                    props.stream.getVideoTracks()[0].getSettings().height
                );

                ////////BACKGROUND FILTER
                contextRef.current.globalCompositeOperation = "source-in";
                contextRef.current.filter = "none";
                contextRef.current.drawImage(
                    results.image,
                    0,
                    0,
                    props.stream.getVideoTracks()[0].getSettings().width,
                    props.stream.getVideoTracks()[0].getSettings().height
                );

                contextRef.current.globalCompositeOperation =
                    "destination-atop";
                contextRef.current.filter = "none";
                contextRef.current.drawImage(
                    img,
                    0,
                    0,
                    props.stream.getVideoTracks()[0].getSettings().width,
                    props.stream.getVideoTracks()[0].getSettings().height
                );

                ///////END BACKGRUND FILTER
            }
        } else if (currentFilter.activeEffect === "blur") {
            //////BLUR
            contextRef.current.globalCompositeOperation = "destination-over";
            contextRef.current.filter = "none";
            contextRef.current.drawImage(
                results.image,
                0,
                0,
                props.stream.getVideoTracks()[0].getSettings().width,
                props.stream.getVideoTracks()[0].getSettings().height
            );

            contextRef.current.globalCompositeOperation = "destination-out";
            contextRef.current.drawImage(
                results.segmentationMask,
                0,
                0,
                props.stream.getVideoTracks()[0].getSettings().width,
                props.stream.getVideoTracks()[0].getSettings().height
            );

            try {
                //blur the canvas
                StackBlur.canvasRGB(
                    canvasRef.current,
                    0,
                    0,
                    props.stream.getVideoTracks()[0].getSettings().width,
                    props.stream.getVideoTracks()[0].getSettings().height,
                    25
                );
            } catch (e) {
                console.log(e);
            }

            //add the image over missing pixels
            contextRef.current.globalCompositeOperation = "destination-over";
            contextRef.current.drawImage(
                results.image,
                0,
                0,
                props.stream.getVideoTracks()[0].getSettings().width,
                props.stream.getVideoTracks()[0].getSettings().height
            );
            ////////END BLUR
        } else if (
            currentFilter.activeEffect === "normal" ||
            !currentFilter.activeEffect
        ) {
            contextRef.current.globalCompositeOperation = "destination-over";

            contextRef.current.drawImage(
                results.image,
                0,
                0,
                props.stream.getVideoTracks()[0].getSettings().width,
                props.stream.getVideoTracks()[0].getSettings().height
            );
        }

        contextRef.current.restore();
    }

    return (
        <StyledCanvasContainer
            className={`small-container ${props.isSmall && "isSmall"}`}
        >
            {!props.sharingScreen && (
                <div className="button-container">
                    <div className="buttons">
                        <TooltipMui title="Blur" placement="right-start" arrow>
                            <div
                                className="button"
                                aria-label="blur"
                                onClick={() => {
                                    setShowBackgrounds(false);
                                    setCurrentFilter((prevFilter) => ({
                                        ...prevFilter,
                                        activeEffect:
                                            prevFilter.activeEffect !== "blur"
                                                ? "blur"
                                                : "normal",
                                        init: false
                                    }));
                                }}
                            >
                                <img
                                    src={
                                        currentFilter.activeEffect === "blur"
                                            ? blurOffIcon
                                            : blurOnIcon
                                    }
                                    alt={"toggle background blur"}
                                />
                            </div>
                        </TooltipMui>
                        <TooltipMui
                            title="Virtual background"
                            placement="right-start"
                            arrow
                        >
                            <div
                                className="button"
                                aria-label="backgrounds"
                                onClick={() => {
                                    if (
                                        currentFilter.activeEffect ===
                                        "background"
                                    ) {
                                        setShowBackgrounds(false);
                                        setCurrentFilter((prevFilter) => ({
                                            ...prevFilter,
                                            activeEffect: "normal",
                                            init: false
                                        }));
                                    } else {
                                        setShowBackgrounds(true);
                                    }
                                }}
                            >
                                <img
                                    src={
                                        currentFilter.activeEffect ===
                                        "background"
                                            ? backgroundOffIcon
                                            : backgroundOnIcon
                                    }
                                    alt={"toggle background blur"}
                                />
                            </div>
                        </TooltipMui>
                    </div>
                    {showBackgrounds && (
                        <div className="backgrounds">
                            {virtualBackgrounds.map(
                                (virtualBackground, index) => (
                                    <img
                                        key={index}
                                        onClick={() => {
                                            setCurrentFilter((prevFilter) => ({
                                                ...prevFilter,
                                                activeEffect: "background",
                                                backgroundImg:
                                                    virtualBackground,
                                                init: false
                                            }));
                                            window.localStorage.setItem(
                                                "backgroundImg",
                                                virtualBackground
                                            );
                                        }}
                                        src={virtualBackground}
                                        alt=""
                                        x
                                    />
                                )
                            )}
                        </div>
                    )}
                </div>
            )}
            <canvas
                className={`output-canvas ${
                    props.sharingScreen && "dontMirror"
                }`}
                ref={canvasRef}
                // height="300"
                // width="400"
            />
        </StyledCanvasContainer>
    );
};

export default VideoCanvas;
