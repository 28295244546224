import React, { useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown-now";
import breakpoint from "styled-components-breakpoint";
import { colors } from "./styles";

// Timer, you see on commingSoon-Page

const CountdownWrapper = styled.div`
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background-color: ${colors.red};
    // margin-left: auto;
    // margin-right: auto;
    // box-shadow: 2px 2px 11px rgb(10, 10, 10);
    //
    // ${breakpoint("desktop")`
  //   width: 200px;
  //   height: 80px;
  //   border-radius: 5%;
  //   `}
    //
    // .countdown {
    //   color: white;
    //   align-self: center;
    // }
`;
const CountHolder = styled.div`
    display: flex;
    background-color: ${colors.red};
    border-radius: 20px;
    padding: 20px 40px;
`;

const CountBox = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
`;
const CountText = styled.div`
    font-size: 1rem;
    margin-top: 10px;
`;

const CountCircle = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    margin: 0 5px;
    color: white;
    font-weight: 500 !important;
    font-size: 2.5rem;
`;

const JourneyTimer = (props) => {
    const [countdown, setCountdown] = useState(false);
    const [complete, setComplete] = useState(false);
    const time = props.time;

    const renderer = ({ days, hours, minutes, seconds }) => {
        return complete ? (
            "You can now participate!"
        ) : (
            <CountHolder>
                <CountBox>
                    <CountCircle>
                        <div>{days}</div>
                    </CountCircle>
                    <CountText>DAY</CountText>
                </CountBox>
                <CountBox>
                    <CountCircle>
                        <div>{hours}</div>
                    </CountCircle>
                    <CountText>HR</CountText>
                </CountBox>
                <CountBox>
                    <CountCircle>
                        <div>{minutes}</div>
                    </CountCircle>
                    <CountText>MIN</CountText>
                </CountBox>
                <CountBox>
                    <CountCircle>
                        <div>{seconds}</div>
                    </CountCircle>
                    <CountText>SEC</CountText>
                </CountBox>
            </CountHolder>
        );
    };

    const handleComplete = (e) => {

        setComplete(true);
    };

    const handleTimerStart = (e) => {
        setComplete(false);
        countdown.getApi().start();
    };

    return (
        <CountdownWrapper>
            <div className="countdown">
                <Countdown
                    date={new Date(time)}
                    autoStart={true}
                    ref={setCountdown}
                    renderer={renderer}
                    onComplete={handleComplete}
                    onTick={handleTimerStart}
                />
            </div>
        </CountdownWrapper>
    );
};

export default JourneyTimer;
