import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NextButton from "../general/nextButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { colors } from "../general/styles";
import loaderImg from "../../assets/images/loading.svg";
import Peer from "peerjs";
import { CONF } from "../../config/config";

const StyledMeetupButtons = styled.div`
    display: flex;
    margin-top: 30px;

    button {
        margin: 0 15px;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const StyledMain = styled.main`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        height: auto !important;
        min-height: auto !important;
    }

    .conference-text {
        color: white;
        font-size: 20px;
        margin: auto;
    }

    .accordion-container {
        width: 100%;
    }

    video {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }

    .remote-stream {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        position: relative;
        background-color: ${colors.grey};
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
        transition: all 0.1s linear;
        color: white;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;

        &__video {
            width: 100%;
            object-fit: unset;
            aspect-ratio: 4 / 3;
        }

        &__info {
            width: 100%;
            height: 50px;
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: 0;
        }

        &--no-video {
            padding: 20px 30px;

            .remote-stream__video {
                display: none;
            }
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                max-height: 50px;

                img {
                    height: 21px;
                    width: auto;
                    object-fit: contain;
                    margin: 0 16px 0 -9px;
                }
            }
        }

        &--loading {
            width: 100%;
            aspect-ratio: 4 / 3;
            padding: 20px 40px;

            .remote-stream__video {
                object-fit: none;
            }
            div {
                display: none;
            }
        }

        &--streaming {
            width: 100%;
            aspect-ratio: 4 / 3;

            .remote-stream__video {
                display: unset;
            }
            div {
                display: none;
            }
        }
    }

    .enterLobbyButton {
        margin: 15px auto 15px auto;
    }

    .conf {
        display: flex;
        max-height: 600px;
        overflow-y: auto;
        flex-wrap: wrap;
        justify-content: center;
        background: white;
        border-radius: 8px;
    }

    .conf__participant {
        display: flex;
        background: white;
        padding: 10px 14px 10px 12px;
        border-radius: 8px;
        align-items: center;
        margin: 5px;
        border: 2px solid #d30f4b;

        .user {
            margin-left: 10px;
            &__name {
            }
            &__email {
                opacity: 0.6;
                font-size: 14px;
                text-transform: lowercase;
            }
        }
    }
`;

/*

  Think of this as multiple Calls between Host and Recipients.
  We don't need a mesh,
  peer topology should look like this.

          (*) Host
         / | \
        /  |  \
       /   |   \
     (*)  (*)  (*) Recipients

  Multiple Hosts *should* work if we display the streams correctly.

*/

const Conference = ({ localStream, meetup, socket }) => {
    const myId = localStorage.getItem("userId");
    const [isHost] = useState(myId === meetup.creator);
    const [participants, setParticipants] = useState([]);
    const [loadingLocalStream, setLoadingLocalStream] = useState(true);
    const [loadingRemoteStream, setLoadingRemoteStream] = useState(true);
    const [hostConnected, setHostConnected] = useState(true);

    const peer = useRef();
    const remoteStream = useRef();

    const config = {
        iceServers: [
            { urls: "stun:turnserver.bayron.int.bayer.com:443" },
            {
                urls: [
                    "turns:turnserver.bayron.int.bayer.com:443?transport=tcp",
                    "turns:turnserver.bayron.int.bayer.com:5349"
                ],
                credential: "lRlP32eM",
                username: "yeysus"
            }
        ]
    };

    const initConnection = async () => {
        await connectPeerServer();
        connectConferenceSocket();
        addSocketEvents();
        addPeerEvents();
    };

    const connectPeerServer = () => {
        peer.current = new Peer(myId, {
            path: "/peerjs",
            host: "/",
            port: CONF.REACT_APP_NODE_ENV === "development" ? 9998 : 443,
            secure: CONF.REACT_APP_NODE_ENV === "development" ? false : true,
            config: config
        });

        return new Promise((resolve, reject) => {
            peer.current.on("open", () => {
                resolve();
            });
            peer.current.on("error", (e) => {
                console.log(e);
                reject();
            });
        });
    };

    const connectConferenceSocket = () => {
        socket.emit("/conference/connect", {
            id: myId,
            meetupId: meetup.id,
            isHost: isHost
        });
    };

    const addSocketEvents = () => {
        if (isHost) {
            socket.on("/conference/call-users", ({ users }) => {
                console.log("call-users", users);
                setParticipants((currentParticipants) => [
                    ...currentParticipants,
                    ...users
                ]);
                users.forEach((user) => {
                    callUser(user.id);
                });
            });
            socket.on("/conference/new-user", ({ user }) => {
                if (user.id !== myId) {
                    setParticipants((currentParticipants) => [
                        ...currentParticipants,
                        user
                    ]);
                    callUser(user.id);
                }
            });
        }
    };

    const addPeerEvents = () => {
        peer.current.on("open", function (name) {
            return;
        });
        peer.current.on("connection", function (conn) {
            setHostConnected(false);
        });
        peer.current.on("call", function (call) {
            call.answer(localStream);
            call.on("stream", (stream) => {
                if (!isHost) remoteStream.current.srcObject = stream;
            });
        });
    };

    const callUser = (id) => {
        const conn = peer.current.connect(id);
        const call = peer.current.call(id, localStream);
    };

    useEffect(() => {
        !loadingLocalStream && initConnection();
    }, [loadingLocalStream]);

    useEffect(() => {
        localStream && setLoadingLocalStream(false);
    }, [localStream]);

    const cleanup = () => {
        isHost
            ? socket.emit("/conference/end", {
                  meetupId: meetup.id
              })
            : socket.emit("/conference/disconnect", {
                  meetupId: meetup.id
              });

        socket.off("/conference/call-users");
        socket.off("/conference/new-user");

        peer.current.removeAllListeners();
        peer.current.destroy();

        window.removeEventListener("beforeunload", cleanup);
    };

    useEffect(() => {
        window.addEventListener("beforeunload", cleanup);

        return () => cleanup();
    }, []);

    return (
        <StyledMain>
            {!isHost && (
                <div
                    className={`remote-stream ${
                        hostConnected
                            ? "remote-stream--no-video"
                            : loadingRemoteStream
                            ? "remote-stream--loading"
                            : "remote-stream--streaming"
                    }`}
                >
                    <video
                        ref={remoteStream}
                        className={`remote-stream__video`}
                        playsInline
                        autoPlay
                        poster={loaderImg}
                        onWaiting={() => setLoadingRemoteStream(true)}
                        onPlay={() => setLoadingRemoteStream(false)}
                    />
                    <div>
                        <img src={loaderImg} />
                        Waiting for host
                    </div>
                </div>
            )}

            {participants.length > 0 && (
                <div className="conf">
                    {participants.length < 2 ? (
                        participants.map(
                            (participant, index) =>
                                participant.id && (
                                    <div
                                        key={index}
                                        className="conf__participant"
                                    >
                                        <AccountCircleIcon />
                                        <div className="user">
                                            <div className="user__name">
                                                {participant.name}
                                            </div>
                                            <div className="user__email">
                                                {participant.email}
                                            </div>
                                        </div>
                                    </div>
                                )
                        )
                    ) : (
                        <div className="accordion-container">
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        There are currently{" "}
                                        {participants.length} people in this
                                        conference
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {participants.map(
                                        (participant, index) =>
                                            participant.id && (
                                                <div
                                                    key={index}
                                                    className="conf__participant"
                                                >
                                                    <AccountCircleIcon />
                                                    <div className="user">
                                                        <div className="user__name">
                                                            {participant.name}
                                                        </div>
                                                        <div className="user__email">
                                                            {participant.email}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )}
                </div>
            )}

            {isHost && (
                <StyledMeetupButtons>
                    <NextButton
                        className="next-button"
                        title="End conference"
                        onClickEvent={() => {
                            socket.emit("/conference/end", {
                                meetupId: meetup.id
                            });
                            // socket.emit("pNumber", roomId);
                        }}
                    />
                </StyledMeetupButtons>
            )}
        </StyledMain>
    );
};

export default withRouter(Conference);
