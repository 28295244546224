import React from "react";
// import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { compress } from "micro-graphql-react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { CONF } from "../../config/config";

import {
    AuthClient,
    getUserId,
    getUserName,
    login as authLogin
    // logout as authLogout
} from "./auth-client";

// import { UserAgentApplication } from "msal";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    paper: {
        backgroundColor: "rgba(0,0,0,1)"
    }
});

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
        margin: 5px;
    }
    p {
        margin: 20px;
    }
    .data-privacy {
        max-height: 300px;
        overflow: hidden;
        overflow-y: scroll;
        border-color: black;
        border-width: thin;
        border-style: solid;
    }
    .agree-or-not {
        display: flex;
        flex-direction: row;
        align-items: space-between;
        button {
            margin: 20px;
        }
    }
`;

const StyledButton = styled.button`
    border-radius: 23px;
    width: 224px;
    background-color: ${(props) => (props.hollow ? "#ffffff" : "#dc1448")};
    border-color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    padding: 12px 0;
    display: flex;
    justify-content: center;

    :hover {
        background-color: ${(props) => (props.hollow ? "#e9e9e9" : "#BE1442")};
        border-color: #be1442;
    }
`;

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

function LoginModal(props) {
    const open = true;
    const [consent, setConsent] = React.useState(false);

    const [, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    async function login() {
        const devJWToken =
            "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6ImhmaDhtbzY2LWNhZzAtMm5sYy1oOWxkLWcwMjIxM2NoMSJ9.nMz4E1Ta02lB_rgqkIjrkJxa_dve0zfwhbL7MxT3j1Q";

        if (CONF.REACT_APP_NODE_ENV === "development") {
            console.debug("DEV ENV");
            localStorage.setItem("jwtToken", devJWToken);
            return true;
        } else {
            await authLogin();
            const username = getUserName();
            console.debug("Username: ", username);

            if (username) {
                const userInfo = await checkUser();
                console.debug("AZURE ID: ", userInfo);

                if (userInfo.status) {
                    setConsent(true);
                } else if (userInfo) {
                    const userId = await getUserId();

                    localStorage.setItem(
                        "jwtToken",
                        userInfo.data.init.jwtToken
                    );
                    localStorage.setItem("bayronToken", userInfo.data.init.id);
                    localStorage.setItem("token", userId);
                    localStorage.setItem("logged-in", true);
                    localStorage.setItem("username", username);

                    if (localStorage.getItem("redirect")) {
                        const meetupName = localStorage.getItem("redirect");
                        localStorage.removeItem("redirect");
                        props.history.push(`/${meetupName}`);
                    } else {
                        window.location.reload();
                    }
                } else {
                    setError(true);
                    return false;
                }
            } else {
                setError(true);
            }
        }
    }

    const checkUser = async () => {
        const client = new AuthClient({
            endpoint: "/graphql", //http://127.0.0.1:3000
            noCaching: true,
            fetchOptions: {
                headers: {
                    "Content-Type": "application/json"
                    // 'x-apollo-operation-name': 'init',
                    // 'Apollo-Require-Preflight': 'true'
                }
            }
        });

        try {
            const result = await client.runQuery(compress`query {me { id }}`);

            if (result.errors) {
                console.debug("User doesnt exist in bayron");
                return { status: "User not exists" };
            }
        } catch (e) {
            console.debug("error on checkUserQuery: ", e);
            return { status: "User not exists" };
        }

        try {
            const userId = await getUserId();

            if (!userId.status) {
                const jwt = await client.runQuery(
                    compress`query init($id: String!){
            init(input: $id){
              id
              jwtToken
            }
          }`,
                    { id: userId }
                );



                return jwt;
            } else {
                console.debug("ERROR ON getuser ID or query init: ", error);
                return { status: "User not exists" };
            }
        } catch (error) {
            console.debug("ERROR ON INIT: ", error);
            return { status: "ERROR ON INIT" };
        }
    };

    const addUser = async () => {
        const client = new AuthClient({ endpoint: "/graphql" }, true);

        let result;
        try {
            result = await client.runMutation(
                compress`mutation {
          createProfile(input: {}) {
            id
          }
        }`,
                { user: {} }
            );
        } catch (e) {
            console.debug("MUTATION ERROR: ", e);
            setError(true);
        }
        try {
            console.debug("BAYRON ID (mutation): ", result);

            if (result.createProfile.id) {
                const userId = await getUserId();

                const userInfo = await checkUser();

                if (userInfo) {
                    const username = getUserName();

                    localStorage.setItem(
                        "jwtToken",
                        userInfo.data.init.jwtToken
                    );
                    localStorage.setItem("bayronToken", userInfo.data.init.id);
                    localStorage.setItem("token", userId);
                    localStorage.setItem("logged-in", true);
                    localStorage.setItem("username", username);

                    if (localStorage.getItem("redirect")) {
                        const meetupName = localStorage.getItem("redirect");
                        localStorage.removeItem("redirect");
                        props.history.push(`/${meetupName}`);
                    } else {
                        window.location.reload();
                    }

                    return true;
                }

                setError(true);
                return false;
            } else {
                setConsent(false);
                setError(true);
                return false;
            }
        } catch (e) {
            console.debug("Create user error: ", e);
            return { status: "couldnt add user" };
        }
    };

    return (
        <div>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={open}
                fullScreen
            >
                <Dialog aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title">
                        Byron Login
                    </DialogTitle>
                    <DialogContent dividers>
                        <ModalBody>
                            {!consent && !error && (
                                <>
                                    <Typography gutterBottom>
                                        Click the button below to login or
                                        register.
                                    </Typography>
                                    <StyledButton
                                        onClick={async () => {
                                            await login();
                                        }}
                                        width="224px"
                                        hollow={false}
                                    >
                                        Login
                                    </StyledButton>
                                </>
                            )}
                            {consent && !error && (
                                <>
                                    <Typography gutterBottom>
                                        Please have a look at our data privacy
                                        statement. <br /> Please agree to our
                                        terms in order to use Byron and
                                        ByronMeet by clicking 'Agree' to
                                        continue. If you disagree we'll send you
                                        back to Byron's homepage.
                                    </Typography>
                                    <div className="data-privacy">
                                        <Typography className>
                                            <h2>PRIVACY STATEMENT</h2>
                                            <p>
                                                This website (hereinafter the{" "}
                                                <em>"Website"</em> is provided
                                                by Bayer AG, 51368 Leverkusen
                                                (hereinafter <em>"us"</em> or{" "}
                                                <em>"we"</em>). For further
                                                information regarding the
                                                provider of the Website, please
                                                refer to our{" "}
                                                <a href="https://bayron.intranet.cnb/imprint">
                                                    imprint
                                                </a>
                                                .
                                            </p>
                                            <h2>
                                                A. Handling of personal data
                                            </h2>
                                            <p>
                                                In the following we wish to
                                                provide you with information on
                                                how we handle your personal data
                                                when you use our Website. Unless
                                                otherwise indicated in the
                                                following chapters, the legal
                                                basis for the handling of your
                                                personal data results from the
                                                fact that such handling is
                                                required to make available the
                                                functionalities of the Website
                                                requested by you (Art. 6(1)(b)
                                                General Data Protection
                                                Regulation).
                                            </p>
                                            <h2>I. Using our Website</h2>
                                            <h3>1. Accessing our Website</h3>
                                            <p>
                                                When you call up our Website,
                                                your browser will transfer
                                                certain information to our web
                                                server. This is done for
                                                technical reasons and required
                                                to make available to you the
                                                requested information. To
                                                facilitate your access to the
                                                Website, the following
                                                information are collected,
                                                briefly stored and used:{" "}
                                            </p>
                                            <ul>
                                                <li>IP address</li>
                                                <li>date and time of access</li>
                                                <li>
                                                    time zone difference to
                                                    Greenwich Mean Time (GMT)
                                                </li>
                                                <li>
                                                    content of request (specific
                                                    site)
                                                </li>
                                                <li>
                                                    status of access/HTTP status
                                                    code
                                                </li>
                                                <li>
                                                    transferred volume of data
                                                </li>
                                                <li>
                                                    website requesting access
                                                </li>
                                                <li>
                                                    browser, language settings,
                                                    version of browser software
                                                    operating system and surface
                                                </li>
                                            </ul>
                                            <p>
                                                Moreover, to protect our
                                                legitimate interests, we will
                                                store such information for a
                                                limited period of time in order
                                                to be able to initiate a
                                                tracking of personal data in the
                                                event of actual or attempted
                                                unauthorized access to our
                                                servers (Art. 6(1)(f) General
                                                Data Protection Regulation).
                                            </p>
                                            <h3>2. Registration and login</h3>
                                            <p>
                                                In order to be considered in our
                                                Byron expert search, you need to
                                                first register an account with
                                                us, where you are able to
                                                determine your personal login
                                                credentials that you need to be
                                                able to log into your personal
                                                account. For this registration
                                                and subsequent login procedure,
                                                we collect the following
                                                information about you which are
                                                directly derived from the Bayer
                                                Active Directory:
                                            </p>
                                            <ul>
                                                <li>Name and surname</li>
                                                <li>
                                                    Contact data (email address,
                                                    CWID)
                                                </li>
                                                <li>City</li>
                                                <li>Position and Department</li>
                                            </ul>
                                            {/* <p>
                        Furthermore, during said register process, we will ask
                        for the following meta data which can be edited later by
                        you in your profile:{" "}
                      </p>
                      <ul>
                        <li>Current position at Bayer</li>
                        <li>City and country of workplace</li>
                      </ul> */}
                                            <p>
                                                We process this personal data in
                                                order to provide you with an
                                                access to the services of our
                                                Website. They are deleted in
                                                case you deactivate your user
                                                account.
                                            </p>
                                            <p>
                                                Due to technical reason login to
                                                the website via "go/bayron" is
                                                done via so called single sign
                                                on (SSO) technology. For this
                                                purpose and to set your user
                                                profile certain personal data
                                                are automatically being
                                                transferred to your Byron user
                                                profile, irrespective of your
                                                actual login. That is your name,
                                                surname, your e-mail address,
                                                your job title and your office
                                                location. We will additionally
                                                receive your ID which is not
                                                displayed in your profile.
                                            </p>
                                            <p>
                                                You can edit all of the info in
                                                your profile. Additionally you
                                                can provide your workplace
                                                location (city and country) as
                                                well as a profile and profile
                                                header picture and the number of
                                                your mobile phone.
                                            </p>
                                            <h3>3. Setting of cookies</h3>
                                            <h4>a) What are cookies?</h4>
                                            <p>
                                                This Website uses so-called
                                                “cookies”. Cookies are small
                                                text files that are stored in
                                                the memory of your terminal via
                                                your browser. They store certain
                                                information (e.g. your preferred
                                                language or site settings) which
                                                your browser may (depending on
                                                the lifespan of the cookie)
                                                retransmit to us upon your next
                                                visit to our Website.
                                            </p>
                                            <h4>b) What cookies do we use?</h4>
                                            <p>
                                                We differentiate between two
                                                categories of cookies: (1)
                                                functional cookies, without
                                                which the functionality of our
                                                Website would be reduced, and
                                                (2) optional cookies used for
                                                e.g. website analysis and
                                                marketing purposes. Byron uses
                                                no optional cookies. However, we
                                                make use of functional cookies.{" "}
                                            </p>
                                            <h3>
                                                4. Use of public profile info
                                            </h3>
                                            <p>
                                                Only you can edit your profile
                                                information including your
                                                “skill”, "community", "product",
                                                "organisation" and "tool" tags.
                                                Everyone at Bayer will be able
                                                to see your profile including
                                                your name, location, job
                                                position, profile picture,
                                                profile header image and tags,
                                                summarized as “public profile
                                                info”. We are keeping the
                                                possibility open that we might
                                                use the public profile info
                                                including your skill tags to
                                                further enhance our Byron
                                                matching algorithm. Your skill
                                                tags are crucial for matching
                                                and ranking your profile when a
                                                user uses Byron’s search engine
                                                and poses a query.
                                            </p>
                                            <p>
                                                In case we decide to discontinue
                                                Byron and its services, we will
                                                automatically delete your
                                                profile and user data.
                                                Furthermore are we keeping the
                                                possibility open to delete a
                                                registered user’s profile and
                                                all of the data attached to it
                                                for any reason given.
                                            </p>
                                            <p>
                                                All public profile information
                                                is furthermore used in the
                                                context of the video chat
                                                extension of Byron, "ByronMeet",
                                                to display characteristics of a
                                                user inside a chat and to
                                                enhance the filtering mechanisms
                                                of our ByronMeet matching
                                                algorithm.
                                            </p>
                                            <h2>
                                                II. Transfer of personal data
                                                for commissioned processing
                                            </h2>
                                            <p>
                                                For the processing of your
                                                personal data we will to some
                                                extent use specialized service
                                                contractors. Such service
                                                contractors are carefully
                                                selected and regularly monitored
                                                by us. Based on respective data
                                                processor agreements, they will
                                                only process personal data upon
                                                our instruction and strictly in
                                                accordance with our directives.
                                            </p>
                                            <h2>
                                                B. Information regarding your
                                                rights
                                            </h2>
                                            <p>
                                                The following rights are in
                                                general available to you
                                                according to applicable data
                                                privacy laws:
                                            </p>
                                            <ul>
                                                <li>
                                                    Right of information about
                                                    your personal data stored by
                                                    us;{" "}
                                                </li>
                                                <li>
                                                    Right to request the
                                                    correction, deletion or
                                                    restricted processing of
                                                    your personal data;{" "}
                                                </li>
                                                <li>
                                                    Right to object to a
                                                    processing for reasons of
                                                    our own legitimate interest,
                                                    public interest, or
                                                    profiling , unless we are
                                                    able to proof that
                                                    compelling, warranted
                                                    reasons superseding your
                                                    interests, rights and
                                                    freedom exist, or that such
                                                    processing is done for
                                                    purposes of the assertion,
                                                    exercise or defense of legal
                                                    claims;
                                                </li>
                                                <li>
                                                    Right to data portability;
                                                </li>
                                                <li>
                                                    Right to file a complaint
                                                    with a data protection
                                                    authority;
                                                </li>
                                                <li>
                                                    You may at any time with
                                                    future effect withdraw your
                                                    consent to the processing of
                                                    your personal data. For
                                                    further information please
                                                    refer to the chapters above
                                                    describing the processing of
                                                    personal data based on your
                                                    consent.
                                                </li>
                                            </ul>
                                            <p>
                                                If you wish to exercise your
                                                rights, please address your
                                                request to the contact indicated
                                                below. (&#x2192; C.).
                                            </p>
                                            <h2>C. Contact</h2>
                                            <p>
                                                For any questions you may have
                                                with respect to data privacy,
                                                please write an e-mail to
                                                byron@bayer.com or contact our
                                                company data protection officer
                                                at the following address:{" "}
                                            </p>
                                            <p>
                                                Group Data Protection Officer
                                                Bayer AG 51368 Leverkusen
                                            </p>
                                            <h2>
                                                D. Amendment of Privacy
                                                Statement
                                            </h2>
                                            <p>
                                                We may update our Privacy
                                                Statement from time to time.
                                                Updates of our Privacy Statement
                                                will be published on our
                                                Website. Any amendments become
                                                effective upon publication on
                                                our Website. We therefore
                                                recommend that you regularly
                                                visit the site to keep yourself
                                                informed on possible updates.{" "}
                                            </p>
                                            <br />
                                            <p id="last-updated">
                                                Last updated: July 13th, 2020
                                            </p>
                                        </Typography>
                                    </div>
                                    <div className="agree-or-not">
                                        <StyledButton
                                            onClick={() => {
                                                //TODO - register user in bayron
                                                console.debug(
                                                    "ENTERING ADD USER"
                                                );
                                                addUser();
                                            }}
                                            width="224px"
                                            hollow={false}
                                        >
                                            Agree
                                        </StyledButton>
                                        <StyledButton
                                            onClick={() =>
                                                (window.location.href =
                                                    "https://bayron.intranet.cnb")
                                            }
                                            width="224px"
                                            hollow={true}
                                        >
                                            Disagree
                                        </StyledButton>
                                    </div>
                                </>
                            )}
                            {error && (
                                <>
                                    <Typography gutterBottom>
                                        Oh no! Something went horribly wrong.
                                        Here's what you can do:
                                        <br />
                                        1. Click the button below and try again.
                                    </Typography>
                                    <br />
                                    <br />
                                    <StyledButton
                                        onClick={() => {
                                            localStorage.clear();
                                            window.location.reload();
                                        }}
                                        width="224px"
                                        hollow={true}
                                    >
                                        Try Again
                                    </StyledButton>
                                    <br />
                                    <br />
                                    <Typography gutterBottom>
                                        2. If it fails again, please contact{" "}
                                        <a href="mailto:byron@bayer.com?subject=ByronMeet%20Error">
                                            byron@bayer.com
                                        </a>{" "}
                                        by clicking the button below so we can
                                        get in touch with you as quick as
                                        possible to fix your issue.
                                    </Typography>
                                    <br />
                                    <br />
                                    <a href="mailto:byron@bayer.com?subject=ByronMeet%20Error">
                                        <StyledButton
                                            width="224px"
                                            hollow={true}
                                        >
                                            Send Email
                                        </StyledButton>
                                    </a>
                                </>
                            )}
                        </ModalBody>
                    </DialogContent>
                    {/* <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              SUBMIT
            </Button>
          </DialogActions> */}
                </Dialog>
            </Dialog>
        </div>
    );
}

export default LoginModal;
