import HelpIcon from "@material-ui/icons/Help";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import Klick from "../../components/conversationalAI/klick.jsx";
import Klickbot from "../../components/conversationalAI/klickbot.jsx";
import Similar from "../../components/conversationalAI/similar.jsx";
import { CONF } from "../../config/config";
// import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

// Page for waiting for matching partner, corresponding components in ../components/lobby

// Jesus TODO +++ Alen. Change some colors and message display options.

const MessageP = styled.div`
    color: ${(props) => (props.busy ? "red" : "white")};
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #fffdfb;

    &.find-match {
    }

    .userNumber {
        display: inline;
    }
    @keyframes blink {
        /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
        0% {
            opacity: 0.2;
        }
        /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
        20% {
            opacity: 1;
        }
        /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
        100% {
            opacity: 0.2;
        }
    }
    span {
        /**
     * Use the blink animation, which is defined above
     */
        animation-name: blink;
        /**
     * The animation should take 1.4 seconds
     */
        animation-duration: 1.4s;
        /**
     * It will repeat itself forever
     */
        animation-iteration-count: infinite;
        /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
        animation-fill-mode: both;
    }
    span:nth-child(2) {
        /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
        animation-delay: 0.2s;
    }
    span:nth-child(3) {
        /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
        animation-delay: 0.4s;
    }
`;

const StyledTooltip = styled.div`
    display: inline;
    position: relative;

    .help-icon {
        position: absolute;
        font-size: ${(props) => props.size};
        margin: 4px;
        color: ${(props) => props.color};
    }
`;

const Wrapper = styled.div`
  display: flex;
  margin: 5px 5px;

  &.drawIn {
    position: absolute;
    top: 0;
  }

  &.clipboard-wrap {
    cursor: pointer;
    flex-wrap: wrap;
    min-height: 100px;
    justify-content: flex-start;
  }
  .message-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &.klick-wrap {
    flex-wrap: wrap;
    font-size: 30px;
    text-align: center;
    color: #f9d71c;
    margin 60px 0 40px;
  }

  &.klick-column {
    flex: 50%;
  }

  .alert-box {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-top: 0.5em;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    // display: none;
    opacity: 0;
  }
  .alert-hidden {
    opacity: 0;
    transition: all 250ms linear 1s;
  }
  .alert-shown {
    opacity: 1;
  }

  svg {
    color: white;
    margin-left: 0.2em;
  }

  .back-button {
    position: absolute;
    bottom: 20%;
  }
`;

const WrapperPeople = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
`;

const InfoWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    &.bonus-wrap {
        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }
    }
    .klick-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        //min-width: 150px;
        cursor: pointer;
        max-width: calc(100% / 3);
        width: 100%;

        @media screen and (max-width: 550px) {
            margin: 0 10px;
        }

        @media screen and (max-width: 780px) {
            margin: 0 30px;
        }
        &.last-column {
            @media screen and (max-width: 630px) {
                margin-top: 30px;
            }
        }

        p {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.6px;
            text-align: center;
            color: #fffdfb;
            max-width: 150px;

            &.invite-text {
                max-width: 100px;
            }
        }
    }
`;

const UsersInLobby = (props) => {
    const socket = props.socket;
    // eslint-disable-next-line no-unused-vars
    const [users, setUsers] = useState(props.usersInLobby.users);
    const [userCount, setUserCount] = useState(props.usersInLobby.usersInLobby);
    const [usersInMatch, setUsersInMatch] = useState(
        props.usersInLobby.usersInMatch
    );
    // const [isShowingAlert, setIsShowingAlert] = useState(false);

    const [showConversationalAi, setShowConversationalAi] = useState(false);
    const [showConversationalAiChatbot, setShowConversationalAiChatbot] =
        useState(false);
    const [showSimilar, setShowSimilar] = useState(false);
    const path = CONF.OPS_MODE === "internal" ? "/bayronnect" : "";

    useEffect(() => {
        // getUsersInLobby();

        if (socket) {
            socket.on("updateUsersInMeetup", (msg) => {
                console.log("SINGAL updateUsersInMeetup: ", msg);
                if (msg.usersInLobby) {
                    setUserCount(msg.usersInLobby);
                    setUsersInMatch(msg.usersInMatch);
                    setUsers(msg.users);
                }
            });
        }
        return function cleanup() {
            if (socket) {
                socket
                    .removeListener("updateUsersInMeetup")
                    .on("updateUsersInMeetup", () =>
                        console.log(
                            "removing updateUsersInMeetup socket listener"
                        )
                    );
            }
        };
    }, [socket]);

    //Initial checking for user count (when user enters the lobby)
    //TODO: can be counted before (when getting meetup information during entry into lobby)
    const getUsersInLobby = async () => {
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getUsersInLobby`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                meetupId: props.meetupId
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setUsers(data.users);
                setUserCount(data.usersInLobby);
                setUsersInMatch(data.usersInMatch);
            });
    };

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(
            window.location.href.replace("/lobby", "")
        );
        // setIsShowingAlert(true);
        alert("Link copied to clipboard");
        // setTimeout(() => {
        //   setIsShowingAlert(false);
        // }, 1000);
    };

    const userCountWrap = {
        display: "flex",
        flexDirection: "column",
        marginTop: "20px"
    };
    const lobbyUserWrap = {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center"
    };

    const handleStyleButton = (condition) => {
        let leftPosition;
        let topPosition;
        if (condition > 1) {
            leftPosition = 2 + condition * 25;
        }
        if (condition / 10 > 0) {
            if (condition % 10 === 1) {
                leftPosition = 0;
            } else {
                leftPosition = 2 + (condition / 10 + 1) * 25;
            }
            topPosition = (parseInt(condition / 10) + 1) * 60;
        }
        return {
            left: leftPosition,
            top: topPosition
        };
    };

    return (
        <div style={userCountWrap}>
            <MessageP className="find-match">
                Finding your match
                <span>.</span>
                <span>.</span>
                <span>.</span>
                <StyledTooltip color={"white"} size={"16px"}>
                    <HelpIcon
                        className="help-icon"
                        data-tip={`Don't be too disappointed if you're not being matched immediately. It can take a couple of seconds or up to a minute for new users to become available.`}
                        data-place={"right"}
                    />
                </StyledTooltip>
            </MessageP>
            <ReactTooltip effect="solid" data-multiline />
            <div style={lobbyUserWrap}>
                <Wrapper className={"userCount"}>
                    <MessageP>
                        There is currently {userCount}{" "}
                        {`${userCount === 1 ? "user" : "users"}`} in lobby
                    </MessageP>
                    <StyledTooltip color={"white"} size={"16px"}>
                        <HelpIcon
                            className="help-icon"
                            data-tip={`This number represents people that are currently in the lobby of meetup and are available for matching. This includes people that you already matched with in this meetup.`}
                            data-place={"right"}
                        />
                    </StyledTooltip>
                </Wrapper>
                {/* TODO show users images in bubble in internal version */}
                {/* <WrapperPeople>
          {users.map((user, i) => {
            if(!user.busy) {
              return (
                <Wrapper key={i} className={i > 1 ? "profilePic drawIn" : "profilePic"}>
                  <ProfilePictureLink
                    isLobbyImage={true}
                    key={i}
                    username={user.username}
                    size="60px"
                    busy={user.busy}
                  />
                </Wrapper>
              );
            }
          })}
        </WrapperPeople> */}
                {usersInMatch === 0 && (
                    <Wrapper className={"userCount"}>
                        <MessageP>
                            There are currently no users in match
                        </MessageP>
                        <StyledTooltip color={"white"} size={"16px"}>
                            <HelpIcon
                                className="help-icon"
                                data-tip={`This number represents people that are currently in matching process or they are in match with each other.`}
                                data-place={"right"}
                            />
                        </StyledTooltip>
                    </Wrapper>
                )}
                {usersInMatch === 1 && (
                    <Wrapper className={"userCount"}>
                        <MessageP>
                            There is currently {usersInMatch} user in matching
                            process
                        </MessageP>
                        <StyledTooltip color={"white"} size={"16px"}>
                            <HelpIcon
                                className="help-icon"
                                data-tip={`This number represents people that are currently in matching process or they are in match with each other.`}
                                data-place={"right"}
                            />
                        </StyledTooltip>
                    </Wrapper>
                )}
                {usersInMatch > 1 && (
                    <Wrapper className={"userCount"}>
                        <MessageP>
                            There are currently {usersInMatch} user in matching
                            process
                        </MessageP>
                        <StyledTooltip color={"white"} size={"16px"}>
                            <HelpIcon
                                className="help-icon"
                                data-tip={`This number represents people that are currently in matching process or they are in match with each other.`}
                                data-place={"right"}
                            />
                        </StyledTooltip>
                    </Wrapper>
                )}
                {/* TODO show users images in bubble in internal version */}
                {/* <WrapperPeople>
          {users.map((user, i) => {
            if(user.busy) {
              return (
                <Wrapper key={i} className={i > 1 ? "profilePic drawIn" : "profilePic"} style={handleStyleButton(i)}>
                  <ProfilePictureLink
                    isLobbyImage={true}
                    key={i}
                    username={user.username}
                    size="60px"
                    busy={user.busy}
                  />
                </Wrapper>
              );
            }
          })}
        </WrapperPeople> */}
                <Wrapper className="klick-wrap">
                    <MessageP>While you are waiting for a match:</MessageP>
                </Wrapper>
                <InfoWrap className="bonus-wrap">
                    <div
                        className="klick-column"
                        onClick={() => setShowConversationalAi(true)}
                    >
                        <img src={path + "/bulb.png"} alt="Bulb" />
                        <p>Learn something new</p>
                    </div>
                    {!CONF.IS_STANDALONE && (
                        <div
                            className="klick-column"
                            onClick={() => setShowSimilar(true)}
                        >
                            <img src={path + "/similar.png"} alt="Similar" />
                            <p>Find similar personalities</p>
                        </div>
                    )}
                    <div
                        className="klick-column"
                        onClick={() => copyToClipBoard()}
                    >
                        <img src={path + "/invite.png"} alt="Invite" />
                        <p className="invite-text">Invite friends</p>
                    </div>
                    {"chatbot is not working yet" === "" && (
                        <div
                            className="klick-column  last-column"
                            onClick={() => setShowConversationalAiChatbot(true)}
                        >
                            <img src={path + "/chatbot.png"} alt="Chatbot" />
                            <p>Interact with chatbot</p>
                        </div>
                    )}
                </InfoWrap>
                {/* <div className={`alert-box success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}>Link copied to clipboard.</div> */}
            </div>
            {showConversationalAi ? (
                <Klick
                    showConversationalAi={showConversationalAi}
                    setShowConversationalAi={setShowConversationalAi}
                />
            ) : (
                ""
            )}
            {showSimilar ? (
                <Similar
                    showSimilar={showSimilar}
                    setShowSimilar={setShowSimilar}
                />
            ) : (
                ""
            )}
            {showConversationalAiChatbot ? (
                <Klickbot
                    showConversationalAiChatbot={showConversationalAiChatbot}
                    setShowConversationalAiChatbot={
                        setShowConversationalAiChatbot
                    }
                />
            ) : (
                ""
            )}
        </div>
    );
};

export default withRouter(UsersInLobby);
