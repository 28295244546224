// Hook to communicate through the socket, managing one session

// export const submitPreferences = (socket, preferences) => {
//   socket.emit('preferences', { preferences });
// }

import { CONF } from "../config/config";

export const addMeetupToDB = async (meetup, socket) => {

    console.debug('SOCKET: ', socket);

    let meetupData = new FormData();
    meetupData.append('jwtToken', localStorage.getItem('jwtToken'));
    meetupData.append('live', meetup.live);

    for (var key in meetup.meetup) {
        meetupData.append(key, meetup.meetup[key]);
    }

    // for(var pair of meetupData.entries()) {
    //   console.log(pair[0]+', '+pair[1]);
    // }

    let response = await fetch(
        `${CONF.BAYRONNECT_API_URL}/api/createMeetup`,
        {
            method: "POST",
            body: meetupData,
            headers: {
                'Accept': 'application/json',
                // 'Content-Type': 'multipart/form-data; boundary="another cool boundary"'
            },
        }
    );
    response = await response.json();
    if (response.status) {
        if (response.status === 'error creating meetup') {
            alert('ERROR IN MEETUP CREATION PLEASE TRY AGAIN LATER');
        } else {
            alert(response.status);
            localStorage.removeItem("jwtToken");
            window.location.reload();
        }
    }
    checkCurrentUsersInMeetup(localStorage.getItem('username'), meetup.meetup.creator, socket);
    return response;
};

export const checkCurrentUsersInMeetup = async (email, meetupCreator, socket) => {
    console.log({ email: email, meetupCreator: meetupCreator });
    socket.emit('checkUpdateMeetupCount', { email: email, meetupCreator: meetupCreator });
}

export const updateMeetupInDB = async (meetup, socket) => {
    console.log('MEEEYUP:', meetup);
    let meetupData = new FormData();
    meetupData.append('jwtToken', localStorage.getItem('jwtToken'));
    meetupData.append('live', meetup.live);

    for (var key in meetup.meetup) {
        meetupData.append(key, meetup.meetup[key]);
    }

    let response = await fetch(
        `${CONF.BAYRONNECT_API_URL}/api/updateMeetup`,
        {
            method: "POST",
            body: meetupData,
            headers: {
                'Accept': 'application/json',
            },
        }
    );
    response = await response.json();

    if (response.status) {
        alert(response.status);
        if (response.status !== 'error on meetup update' && response.status !== 'error on getting meetups') {
            localStorage.removeItem('jwtToken');
            window.location.reload();
        }
    }
    checkCurrentUsersInMeetup(localStorage.getItem('username'), meetup.meetup.creator, socket);
    return response;
};

export const deleteMeetup = async (id, socket) => {
    let response = await fetch(
        `${CONF.BAYRONNECT_API_URL}/api/deleteMeetup`,
        {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                id: id,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    response = await response.json();

    if (response.status) {
        alert(response.status);
        if (response.status !== 'error deleting meetup') {
            localStorage.removeItem('jwtToken');
            window.location.reload();
        }

    }

    checkCurrentUsersInMeetup(localStorage.getItem('username'), '', socket);

    return response;
};

export const chooseMeetupOnDB = async (meetup, user, socketId) => {

    let response = await fetch(
        `${CONF.BAYRONNECT_API_URL}/api/chooseMeetupOnDB`,
        {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                meetup,
                socketId: socketId
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    response = await response.json();

    if (response.status) {
        alert(response.status);
        localStorage.removeItem('jwtToken');
        window.location.reload();
    } else if (response.message) {
        alert(response.message);
        window.location.reload();
    }

    return response;
};

export const sendLanguageToDB = async (languages) => {
    let response = await fetch(
        `${CONF.BAYRONNECT_API_URL}/api/setUserLanguages`,
        {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                languages: languages,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    response = await response.json();

    if (response.status) {
        if (response.status === 'couldnt save languages') {
            alert(response.status);
        } else {
            alert(response.status);
            localStorage.removeItem('jwtToken');
            window.location.reload();
        }
    }
    return response;
};

export const sendUserPreferencesToDB = async (userPreferences) => {
    let response = await fetch(
        `${CONF.BAYRONNECT_API_URL}/api/setUserPreferences`,
        {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                userPreferences: userPreferences,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    response = await response.json();
    if (response.status) {
        if (response.status === 'couldnt save languages') {
            alert(response.status);
        } else {
            alert(response.status);
            localStorage.removeItem('jwtToken');
            window.location.reload();
        }
    }
    return response;
};

export const matchUser = (socket, jwtToken) => {
    socket.emit("matchUser", { jwtToken: jwtToken });
};

/*export const sendMessage = (socket, message) => {
    socket.emit('info', { message })
}*/

export const doEmitDisconnected = (socket, auth) => {
    socket.emit("disconnected", { auth });
};

export const doUnregister = (socket) => {
    socket.emit("unregister", { jwtToken: localStorage.getItem("jwtToken") });
};
export const doLobbyUnregister = (socket) => {
    socket.emit("lobbyUnregister", {
        jwtToken: localStorage.getItem("jwtToken"),
    });
};
export const exitLobby = (socket, users) => {
    socket.emit("exitLobby", {
        jwtToken: localStorage.getItem("jwtToken"),
        numberOfPeople: users,
    });
};

export const sendTopic = (socket, topicID, socketRoomId) => {
    socket.emit("sendTopic", { id: topicID, socketRoomId });
};

export const displayTopics = (socket, isTopics, socketRoomId) => {
    socket.emit("displayTopics", { isTopics: isTopics, socketRoomId });
};
