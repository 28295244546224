/** @format */

import React from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import styled from "styled-components";

const FooterWrapper = styled.div`
    // position: absolute;
    // bottom: 10px;
    // left: 0;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: 15px;
    min-width: 275px;

    p {
        display: inline;
        margin: 0px 0px 0px 10px;
        color: #ffffff;
    }
`;

const Footer = (props) => {
    return (
        <FooterWrapper className={props.className}>
            <p>
                Created with{" "}
                <FavoriteIcon
                    style={{
                        fill: "#d31548",
                        fontSize: 20,
                        position: "relative",
                        top: 3
                    }}
                />{" "}
                by Bayer Pharma {"IT"}
            </p>
        </FooterWrapper>
    );
};

export default Footer;
