// Show similar personalities to the user based on the user skills.
// Used the component klick as start for this.

// Wikipedia REST API:
// https://stackoverflow.com/questions/8555320/is-there-a-wikipedia-api-just-for-retrieve-content-summary

// Access Data from an External API into a React Component:
// https://www.pluralsight.com/guides/access-data-from-an-external-api-into-a-react-component

// Reading and writing json files with node:
// https://stackabuse.com/reading-and-writing-json-files-with-node-js/

// Selecting random json object:
// https://stackoverflow.com/questions/30061969/select-random-object-from-json

// Creating sort of a constructor function for functional React. Essentially meaning,
// Code that runs before anything else in the life-cycle of this component, and
// Code that runs once, and only once, for the entire life-cycle of this component.
// From
// https://dev.to/bytebodger/constructors-in-functional-components-with-hooks-280m

// How to use componentWillMount and componentWillUnmount in Functional React.
// https://dev.to/robmarshall/how-to-use-componentwillunmount-with-functional-components-in-react-2a5g

import React, { useState, useRef } from "react";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";

import { CONF } from "../../config/config";

// import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import "./klick.css";
import { Typography } from "@material-ui/core";
// import { te } from "date-fns/locale";

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const Similar = (props) => {
    // This array holds the user expertise: skills, communities, organisations, products.
    // TODO +++ Projects is missing.
    // It is needed only once.
    const userInfo = useRef();

    const userExpertises = useRef();
    // This is the array holding the objects that will be shown to the user.
    // const [jsonData, setJsonData] = React.useState([]);
    const jsonData = useRef();

    // let userId = localStorage.getItem("token");
    /* if(!userId) {
    userId = "testId";
  } */
    // console.log("Similar component. userId: " + userId);

    var currentDate = new Date();

    // TODO +++ all of this should be moved to a database approach.

    const rightChars = "uiopjklnm";
    const leftChars = "qwerasdfyxcv";

    // temporaryKey will be the currently selected key of the objectKeys array.
    var temporaryKey;
    // ran_key will be the currently selected object of the objectKeys array.
    var ran_key;
    // selectedKeys is an Array holding all keys that were selected, in the order in which they were selected.
    // selectedKey is the position in the selectedKeys array where we are currently.
    const [navigation, setNavigation] = useState({
        selectedItem: "",
        selectedKeys: [],
        selectedKey: ""
    });

    let contentDirectory = "./files/";

    // var leftPressed = false;
    // var rightPressed = false;
    // var myNotes = "My Notes " + new Date();

    const [open, setOpen] = useState(true);

    // console.log("component jsonData: ");
    // console.log(jsonData.current);

    // objectKeys is an array holding the keys of the data that can be shown.
    // var objectKeys = Object.keys(jsonData);

    //const [objectKeys, setObjectKeys] = useState(Object.keys(jsonData));
    const objectKeys = useRef();

    // console.log("component objectKeys: ");
    // console.log(objectKeys.current);

    // Select a new object (ran_key) to be displayed.
    var getNewItem = (temporarySelectedKeys) => {
        // console.log("getNewItem objectKeys: ");
        // console.log(objectKeys);
        // console.log("getNewItem jsonData: ");
        // console.log(jsonData);
        // console.log("objectKeys.length: " + objectKeys.current.length);

        if (temporarySelectedKeys.length < objectKeys.current.length) {
            // The user has not seen all data.
            do {
                temporaryKey = Math.floor(
                    Math.random() * objectKeys.current.length
                );
                ran_key = objectKeys.current[temporaryKey];
            } while (temporarySelectedKeys.indexOf(temporaryKey) > -1); // Repeat until we find an item the user has not seen yet.
        } else {
            // The user has seen all data. Just find a random item.
            temporaryKey = Math.floor(
                Math.random() * objectKeys.current.length
            );
            ran_key = objectKeys.current[temporaryKey];
        }

        // console.log("getNewItem ran_key: ");
        // console.log(ran_key);
        // console.log("getNewItem temporaryKey: " + temporaryKey);
    };

    var moveToNextItem = () => {
        let temporarySelectedItem;
        let temporarySelectedKey = -1;
        let temporarySelectedKeys = [];
        if (navigation.selectedKey === "") {
            temporarySelectedKey = -1;
            // console.log("navigation.selectedKey: " + navigation.selectedKey);
        } else {
            temporarySelectedKey = navigation.selectedKey;
        }
        // console.log("navigation.selectedKey start: " + navigation.selectedKey);
        // console.log("temporarySelectedKey start: " + temporarySelectedKey);
        if (navigation.selectedKeys.length > 0) {
            temporarySelectedKeys = navigation.selectedKeys;
        }

        // Avoid showing elements twice on the same session by checking if temporaryKey is already on
        // selectedKeys. However, at some point the user will have seen all elements.
        // The length of selectedKeys will be the same as objectKeys.
        // At that moment we stop using the check if temporaryKey is already on selectedKeys.
        // Elements may be shown multiple times if the user keeps clicking.
        // selectedKeys will hold the sequence in which elements were shown.

        // if selectedKey is at the end of the selectedKeys array it means, we need a new temporaryKey.
        // If not, we have been going back and forth and we move to the next element of selectedItems.
        if (temporarySelectedKey === temporarySelectedKeys.length - 1) {
            getNewItem(temporarySelectedKeys);
            temporarySelectedKeys = temporarySelectedKeys.concat(temporaryKey);
            temporarySelectedKey = temporarySelectedKey + 1;
        } else {
            // We are not at the end of the selectedKeys array.
            temporarySelectedKey = temporarySelectedKey + 1;
            ran_key = temporarySelectedKeys[temporarySelectedKey];
        }
        temporarySelectedItem = jsonData.current[ran_key];

        // console.log("moveToNextItem ran_key: " + ran_key);

        setNavigation({
            selectedItem: temporarySelectedItem,
            selectedKeys: temporarySelectedKeys,
            selectedKey: temporarySelectedKey
        });

        // From https://javascript.plainenglish.io/how-to-add-to-an-array-in-react-state-3d08ddb2e1dc,
        // .push does not work because .push returns the length of the array after modification,
        // not the array itself.
        // .concat neither because if will add the digits of a number as single digits to the array,
        // e.g. of 156 would add 3 new elements at the end, 1, 5, and 6.
    };

    var moveToPreviousItem = () => {
        let temporarySelectedKey = navigation.selectedKey;
        let temporarySelectedItem = navigation.selectedItem;
        let temporarySelectedKeys = navigation.selectedKeys;
        // console.log("moveToPreviousItem start temporarySelectedKey: " + temporarySelectedKey);
        // console.log("moveToPreviousItem start selectedKeys.length: " + temporarySelectedKeys.length);
        if (temporarySelectedKey === 0) {
            // Add new item at the beginning of the array.
            getNewItem(temporarySelectedKeys);
            temporarySelectedKeys.unshift(temporaryKey);
            // temporarySelectedKey stays as 0.
        } else {
            temporarySelectedKey = temporarySelectedKey - 1;
            // console.log("moveToPreviousItem mid temporarySelectedKey: " + temporarySelectedKey);
        }
        // console.log("moveToPreviousItem end temporarySelectedKey: " + temporarySelectedKey);
        // console.log("moveToPreviousItem end temporarySelectedKeys[temporarySelectedKey]: " + temporarySelectedKeys[temporarySelectedKey]);
        ran_key =
            objectKeys.current[temporarySelectedKeys[temporarySelectedKey]];
        temporarySelectedItem = jsonData.current[ran_key];

        setNavigation({
            selectedItem: temporarySelectedItem,
            selectedKeys: temporarySelectedKeys,
            selectedKey: temporarySelectedKey
        });
    };

    var createArrayOfDataToBeShown = () => {
        let jsonDataOriginal = require("./data.json");
        // Let through all elements with expiryDate == '' or
        // expiryDate > currentDate.
        jsonDataOriginal = jsonDataOriginal.filter(
            (item) =>
                (item.expiry_date === "") |
                (item.expiry_date === null) |
                (new Date(item.expiry_date) > currentDate)
        );
        //        if (process.env.REACT_APP_OPS_MODE === "external") {
        if (CONF.OPS_MODE === "external") {
            jsonDataOriginal = jsonDataOriginal.filter(
                (item) => item.labels === "personality"
            );
        }

        // console.log("userExpertises: ");
        // console.log(userExpertises);

        // Remove all elements where the user has no similarity with the person.
        // This is for testing only.
        // TODO +++ It must work with lowercase.

        // If using Byron as well, userInfo contains the skills, communities, etc.
        // TODO +++ Actually, it should check if Byron is running or not.
        // Or also, if the user is registered / logged in / has enough Byron skills, etc.

        // Check for empty expertises.
        // Check for punctuation. Words ending on " ", ".", "-".
        // Check for slides that match 2 or more words.

        let tempJsonData = [];
        // Check for each skill if it is in the description of the personality.
        for (var i = 0; i < jsonDataOriginal.length; i++) {
            // console.log(jsonDataRow);
            for (const lowerCaseUserExpertise of userExpertises.current) {
                // console.log(userSkill);
                let testDescription = jsonDataOriginal[i].description;
                // Some data may not have a description.
                if (
                    testDescription &&
                    testDescription
                        .toLowerCase()
                        .includes(lowerCaseUserExpertise)
                ) {
                    // console.log(lowerCaseUserExpertise + " is in " + jsonDataOriginal[i].description);
                    tempJsonData = tempJsonData.concat(jsonDataOriginal[i]);
                }
            }
        }

        jsonData.current = tempJsonData;
        objectKeys.current = Object.keys(tempJsonData);

        moveToNextItem();
    };

    useEffect(() => {
        // console.log("Similar component. useEffect.");

        if (process.env.REACT_APP_BAYRONNECT_URL !== "http://127.0.0.1:3000") {
            getUserSkills();
        } else {
            // Use some default values for testing in our own development laptops. Empty also works.
            userExpertises.current = ["croatia", "cuba"];
            // Test.
            for (const singleSkill of userExpertises.current) {
                const singleSkillArray = singleSkill.split(" ");
                if (singleSkillArray.length > 1) {
                    userExpertises.current =
                        userExpertises.current.concat(singleSkillArray);
                }
            }

            createArrayOfDataToBeShown();
        }

        return function cleanup() {
            // Anything in here is fired on component unmount.
            // Same as above for removeEventListener.
            document.removeEventListener("keydown", handleKeyDown, false);
        };
    }, [jsonData]);

    /*
  const handleClickOpen = () => {
    setOpen(true);
  };
  */

    const handleClose = () => {
        setOpen(false);
        if (props.setShowSimilar) {
            props.setShowSimilar(false);
        }
    };

    var handleKeyDown = (e) => {
        // We will respond only to certain keys.
        // Space and left characters: Move to the next item.
        // Right characters: Take note of what was shown, and move then.

        // See https://devstephen.medium.com/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a
        // for a discussion about using key vs keyCode.

        if (e.key === " " || leftChars.indexOf(e.key) > -1) {
            moveToNextItem();
        } else if (rightChars.indexOf(e.key) > -1) {
            // Take note of what was shown.
            // alert ("handleKeyDown old selected Item: " + selectedItem.name + " " + jsonData[ran_key].name);

            // myNotes = myNotes + "\n" + jsonData[ran_key].name + ", " + jsonData[ran_key].url;

            // console.log (myNotes);
            // Now move to the next item.
            moveToNextItem();
            // alert ("handleKeyDown new selected Item: " + selectedItem.name + " " + jsonData[ran_key].name);
            // keyCode 39:
        } else if (e.key === "ArrowDown" || e.key === "ArrowRight") {
            moveToNextItem();
        } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
            moveToPreviousItem();
        } else if (e.key === "Escape") {
            handleClose();
        } else if (e.key === "Enter") {
            moveToNextItem();
        }
        // leftPressed = false;
        // rightPressed = false;
    };

    // Taken from skillsOverlay.jsx.
    const getUserSkills = async () => {
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getBayronTags`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken")
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                // setUserInfo(data);

                userInfo.current = data;

                var tempUserExpertises = [];
                // There is probably a more efficient way to do this below but it works so leave it for now.
                // TODO +++: Different expertises may repeat the word, e.g. there could be a skill scientist and a
                // community called scientist as well.

                for (const skillsElements of userInfo.current.skills) {
                    tempUserExpertises = tempUserExpertises.concat(
                        skillsElements.skill
                    );
                    // Skills maybe be a composed term, like "Molecular Toxicology".
                    const singleSkillSpaceArray =
                        skillsElements.skill.split(" ");
                    if (singleSkillSpaceArray.length > 1) {
                        // Eliminate short words.
                        for (const singleSkillArrayWord of singleSkillSpaceArray) {
                            if (singleSkillArrayWord.length > 3) {
                                tempUserExpertises =
                                    tempUserExpertises.concat(
                                        singleSkillArrayWord
                                    );
                            }
                        }
                    }
                    // Make the same for .split ("-").
                    const singleSkillMinusArray =
                        skillsElements.skill.split("-");
                    if (singleSkillMinusArray.length > 1) {
                        // Eliminate short words.
                        for (const singleSkillArrayWord of singleSkillMinusArray) {
                            if (singleSkillArrayWord.length > 3) {
                                tempUserExpertises =
                                    tempUserExpertises.concat(
                                        singleSkillArrayWord
                                    );
                            }
                        }
                    }
                }
                for (const communityElements of userInfo.current.communities) {
                    tempUserExpertises = tempUserExpertises.concat(
                        communityElements.community
                    );
                }
                for (const productsElements of userInfo.current.products) {
                    tempUserExpertises = tempUserExpertises.concat(
                        productsElements.product
                    );
                }
                for (const organisationsElements of userInfo.current
                    .organisations) {
                    tempUserExpertises = tempUserExpertises.concat(
                        organisationsElements.organisation
                    );
                }
                const lowerCaseUserExpertises = tempUserExpertises.map(
                    (tempUserExpertises) => tempUserExpertises.toLowerCase()
                );
                /* setUserExpertises ({
        userExpertises: lowerCaseUserExpertises
      }); */
                userExpertises.current = lowerCaseUserExpertises;
                // console.log("In await fetch getUserSkills userExpertises:");
                // console.log(userExpertises);

                createArrayOfDataToBeShown();
            });
    };

    return (
        <div>
            {navigation.selectedItem && navigation.selectedItem.content ? (
                <Dialog
                    onClose={handleClose}
                    onKeyDown={handleKeyDown}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth={true}
                    maxWidth="lg"
                    maxHeight="md"
                >
                    <img
                        style={{ width: "100%", height: "auto" }}
                        className="info-img-content"
                        src={require("" +
                            contentDirectory +
                            navigation.selectedItem.content)}
                        alt={navigation.selectedItem.name}
                        onClick={moveToNextItem}
                    />
                </Dialog>
            ) : (
                <Dialog
                    onClose={handleClose}
                    onKeyDown={handleKeyDown}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    /* fullWidth={true}
          maxWidth="false" */
                    width="200px"
                    maxHeight="sm"
                    textAlign="center"
                >
                    <DialogContent>
                        <Typography>
                            To find similar personalities to You, <br />
                            you need to have enough <br />
                            Skills, Products, Communities or Organisations{" "}
                            <br />
                            in your profile.
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
            {/*
        <TemporaryMessage
          showTemporaryMessage={true}
          setShowTemporaryMessage={true}
          onClose={handleTemporaryMessageClose}
          messageText="You need to have enough Skills, Products, Communities or Organisations in your profile to be able to find similar personalities.">
      </TemporaryMessage> */}
            {/* This was for a different format. On hold now.
          <div>
            <h2 className="info-title">{selectedItem.name}</h2>
            <img className="info-img" src={selectedItem.image} alt={selectedItem.name}/>
            <h5 className="info-item">{selectedItem.shortDescription}</h5>
          </div>
        */}
            {/*
        I had this code but it was not working properly.
        <div className="modalWrap" >
          <div className="newMeetupHeader">
            <div className="exitModal" onClick={closeModal}>
              <CloseIcon className="closeModalIcon" />
            </div>
          </div>
          {selectedItem.content ?
          <img style={{ width: 'auto', height: '90%' }} className="info-img-content" src={require("" + contentDirectory + selectedItem.content)} alt={selectedItem.name}/>
          :
          <div>
            <h2 className="info-title">{selectedItem.name}</h2>
            <img className="info-img" src={selectedItem.image} alt={selectedItem.name}/>
            <h5 className="info-item">{selectedItem.shortDescription}</h5>
          </div>
          }
        </div>
        */}
            {/*
        // This is not updating for some reason.
        // Anyway we should change the way notes are displayed.
        <TextareaAutosize className="my-notes" readOnly value={myNotes} />
        */}
        </div>
    );
};

export default withRouter(Similar);
