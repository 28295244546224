/** @format */

import React from "react";
import styled from "styled-components";

const ProfilePictureWrapper = styled.div`
    margin-right: 15px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 991px) {
        margin-top: 15px;
        margin-bottom: 15px;
        padding-left: 0px;
        padding-right: 0px;
    }

    a {
        display: flex;
        align-items: center;
        width: ${(props) => (props.isLobbyImage ? "auto" : "120px")};
        text-decoration: none;
        justify-content: center;
    }

    p {
        display: inline;
        margin: 0px 0px 0px 10px;
        color: #ffffff;
    }
`;

export const StyledDiv = styled.div`
    width: ${({ size = "40px" }) => size};
    padding-bottom: ${({ size = "40px" }) => size};
    position: relative;

    .profilepicture-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: ${(props) =>
            props.busy && props.busy !== undefined
                ? "2px solid red"
                : "2px solid " + props.borderColor};
        overflow: hidden;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }
    .profilepicture {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
    }
`;

export const StyledProfileImage = styled.div`
    display: flex;
    align-items: center;
    transform: scale(1);
`;

const ProfilePictureLink = (props) => {
    // console.log(props.username)
    // console.log(props.isHomepage);
    let borderColor;
    if (props.isHomepage) {
        borderColor = "#FFFFFF";
    } else {
        borderColor = "#65B514";
    }
    const bayronUrl = window._env_.REACT_APP_BAYRON_URL
        ? window._env_.REACT_APP_BAYRON_URL
        : "https://127.0.0.1";
    // const bayronUrl = "https://staging-bayron.intranet.cnb" // window._env_.REACT_APP_BAYRON_URL ? window._env_.REACT_APP_BAYRON_URL : 'https://127.0.0.1/bayronnect'
    const imageUrl = `${bayronUrl}/images`; // window._env_.REACT_APP_IMAGES_URL ? window._env_.REACT_APP_IMAGES_URL : 'http://172.18.0.5:3000/images'
    return (
        <ProfilePictureWrapper
            homePageHeader={props.homePageHeader}
            isLobbyImage={props.isLobbyImage}
        >
            {/* <a
        href={
          props.isLobbyImage
            ? `${bayronUrl}/profile/${props.username}`
            : `${bayronUrl}/me`
        }
        target="_blank"
      > */}
            <StyledProfileImage>
                <StyledDiv
                    size={props.size}
                    busy={props.busy}
                    borderColor={borderColor}
                >
                    <div className="profilepicture-wrapper">
                        {/* <div className="profilepicture" style={{
                backgroundImage: `url(${imageUrl}/${props.username}/profile)`
              }}></div> */}
                        <img
                            className="profilepicture"
                            src={`${imageUrl}/${props.username}/profile`}
                            alt={props.username}
                        />
                        {/* <img className="profilepicture" src='https://upload.wikimedia.org/wikipedia/en/3/35/Supermanflying.png' alt="example" /> */}
                    </div>
                </StyledDiv>
            </StyledProfileImage>
            {!props.isLobbyImage && <p>My profile</p>}
            {/* </a> */}
        </ProfilePictureWrapper>
    );
};

export default ProfilePictureLink;
