import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import Event from "../components/events/event";
import { CONF } from "../config/config";

const EventPage = (props) => {
    const [meetup, setMeetup] = useState(false);
    let { meetupId } = useParams();

    useEffect(() => {
        if (!props.location.state || !props.location.state.meetup) {
            fetch(`${CONF.BAYRONNECT_API_URL}/api/getMeetupStatus`, {
                method: "POST",
                body: JSON.stringify({
                    jwtToken: localStorage.getItem("jwtToken"),
                    meetupId: meetupId
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    setMeetup(json.meetup);
                });
        } else {
            setMeetup(props.location.state.meetup);
        }
    }, []);

    const pageStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexGrow: "1"
    };

    return (
        <React.Fragment>
            <div style={pageStyle}>
                <Event history={props.history} meetup={meetup} />
            </div>
        </React.Fragment>
    );
};

export default withRouter(EventPage);
