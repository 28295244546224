/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { IOSSwitch } from "../general/IOSwitch";
import { CONF } from "../../config/config";

const Wrapper = styled.div`
    height: auto;
    width: 100%;
    box-sizing: border-box;
    border: none;
    display: flex;
    flex-direction: column;

    .labelWrap {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    .hoverWrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .showInfo {
        position: absolute;
        right: 0;
        top: -15px;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 10px;
        border-radius: 8px;
        p {
            margin: 0;
        }
    }
    .firstElement {
        display: ${(props) => (props.showInfoFirstElement ? "block" : "none")};
    }
    .secondElement {
        display: ${(props) => (props.showInfoSecondElement ? "block" : "none")};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #11394f !important;
    }
`;
const AutocompleteComp = styled(Autocomplete)`
    .MuiInputBase-root {
        border: none;
        :focus {
            border: none;
        }
        :hover {
            border: none !important;
        }
    }
    input:focus {
        border: none;
    }
    .MuiAutocomplete-tag {
        border-radius: 3px;
        background-color: #d8e7ef;
        padding-left: 8.5px;
        padding-top: 9px;
        padding-bottom: 9px;

        span {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #11394f;
        }
    }
`;
const TextFieldComp = styled(TextField)`
    color: red;
    border-color: white;

    > div {
        border-radius: 8px;
        background-color: #f4f2f2;
    }
`;

const OrganisationsPreferences = (props) => {
    const [allOrganisations, setAllOrganisations] = useState([]);
    const [, setUpdateComp] = useState([]);
    const [showInfoFirstElement, setShowInfoFirstElement] = useState(false);
    const [showInfoSecondElement, setShowInfoSecondElement] = useState(false);

    const handleToggle = (ismeetOrganisations) => (e) => {
        let newValue = props.userOrganisationsHardFilters;

        if (ismeetOrganisations) {
            newValue.meet_organisations = e.target.checked;
            setUpdateComp([]);
            props.setUserOrganisationsHardFilters(newValue);
        } else {
            newValue.nomeet_organisations = e.target.checked;
            setUpdateComp([]);
            props.setUserOrganisationsHardFilters(newValue);
        }
    };

    const getOrganisationSuggestions = async (value) => {
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getBayronSuggestions`, {
            method: "POST",
            body: JSON.stringify({
                jwtToken: localStorage.getItem("jwtToken"),
                letters: value,
                prefType: "organisations"
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const filteredData = data.filter(
                    (t) =>
                        !props.userOrganisationsFilters.meet_organisations.includes(
                            t
                        ) &&
                        !props.userOrganisationsFilters.nomeet_organisations.includes(
                            t
                        )
                );
                setAllOrganisations(filteredData);
            });
    };
    const handleHoverFirstElement = () => {
        setShowInfoFirstElement((showInfo) => !showInfo);
    };

    const handleHoverSecondElement = () => {
        setShowInfoSecondElement((showInfo) => !showInfo);
    };

    return (
        <Wrapper
            showInfoFirstElement={showInfoFirstElement}
            showInfoSecondElement={showInfoSecondElement}
        >
            <div className="labelWrap">
                <div className="hoverWrap">
                    <div className="showInfo firstElement">
                        <p>Use as hard filter?</p>
                    </div>
                    <label className="inputLabel">
                        Organisations you prefer to meet up with:
                    </label>
                    <IOSSwitch
                        disabled={
                            props.userOrganisationsHardFilters
                                .nomeet_organisations
                        }
                        checked={
                            props.userOrganisationsHardFilters
                                .meet_organisations
                        }
                        onChange={handleToggle(true)}
                        name="communitiesHardFilter"
                        inputProps={{
                            "aria-labelledby": "switch-list-label-topics"
                        }}
                        onMouseEnter={handleHoverFirstElement}
                        onMouseLeave={handleHoverFirstElement}
                    />
                </div>
            </div>
            <AutocompleteComp
                multiple
                options={allOrganisations}
                value={props.userOrganisationsFilters.meet_organisations}
                defaultValue={props.userOrganisationsFilters.meet_organisations}
                onInputChange={(event, value) => {
                    console.log(value);
                    getOrganisationSuggestions(value);
                }}
                onChange={(event, value) => {
                    let newValue = props.userOrganisationsFilters;
                    newValue.meet_organisations = value;
                    props.setUserOrganisationsFilters(newValue);
                    setUpdateComp([]);
                }}
                renderInput={(params) => (
                    <TextFieldComp
                        {...params}
                        className=""
                        margin="normal"
                        variant="outlined"
                    />
                )}
            />
            <div className="labelWrap">
                <div className="hoverWrap">
                    <div className="showInfo secondElement">
                        <p>Use as hard filter?</p>
                    </div>
                    <label className="inputLabel">
                        Organisations you would rather avoid to meet:
                    </label>
                    <IOSSwitch
                        disabled={
                            props.userOrganisationsHardFilters
                                .meet_organisations
                        }
                        checked={
                            props.userOrganisationsHardFilters
                                .nomeet_organisations
                        }
                        onChange={handleToggle(false)}
                        name="communitiesHardFilter"
                        inputProps={{
                            "aria-labelledby": "switch-list-label-topics"
                        }}
                        onMouseEnter={handleHoverSecondElement}
                        onMouseLeave={handleHoverSecondElement}
                    />
                </div>
            </div>
            <AutocompleteComp
                multiple
                disabled={props.userOrganisationsHardFilters.meet_organisations}
                options={allOrganisations}
                defaultValue={
                    props.userOrganisationsHardFilters.nomeet_organisations
                }
                value={props.userOrganisationsFilters.nomeet_organisations}
                onInputChange={(event, value) => {
                    console.log(value);
                    getOrganisationSuggestions(value);
                }}
                onChange={(event, value) => {
                    let exists = false;
                    let removeItem;
                    value.forEach((item) => {
                        if (
                            props.userOrganisationsFilters.meet_organisations.includes(
                                item
                            )
                        ) {
                            exists = true;
                            removeItem = item;
                        }
                    });
                    if (exists) {
                        alert("First remove community from previous input");
                        value = value.filter((obj) => obj !== removeItem);
                        setUpdateComp([]);
                        let newValue = props.userOrganisationsFilters;
                        newValue.nomeet_organisations = value;
                        props.setUserOrganisationsFilters(newValue);
                    } else {
                        setUpdateComp([]);
                        let newValue = props.userOrganisationsFilters;
                        newValue.nomeet_organisations = value;
                        props.setUserOrganisationsFilters(newValue);
                    }
                }}
                renderInput={(params) => (
                    <TextFieldComp
                        {...params}
                        className=""
                        margin="normal"
                        variant="outlined"
                    />
                )}
            />
        </Wrapper>
    );
};

export default OrganisationsPreferences;
