import React, { useState, useCallback, useEffect, useRef } from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import styled from "styled-components";

import * as Excel from "exceljs/dist/exceljs"; // from "exceljs"; breaks build
import * as FileSaver from "file-saver";
import { CONF } from "../../config/config";

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        max-width: 90vw;
        width: 100%;
    }
    .MuiTablePagination-root {
        color: white;
    }
`;

function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
                setShowFullCell(false);
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: "center",
                lineHeight: "24px",
                width: 1,
                height: 1,
                position: "relative",
                display: "flex"
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: "block",
                    position: "absolute",
                    top: 0
                }}
            />
            <Box
                ref={cellValue}
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}
            >
                {value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});

function renderCellExpand(params) {
    return (
        <GridCellExpand
            value={params.value || ""}
            width={params.colDef.computedWidth}
        />
    );
}

const columns = [
    { field: "id", headerName: "ID", width: 90, renderCell: renderCellExpand },
    {
        field: "userEmail",
        headerName: "User email",
        width: 230,
        renderCell: renderCellExpand
    },
    {
        field: "partnerEmail",
        headerName: "Partner email",
        width: 230,
        renderCell: renderCellExpand
    },
    {
        field: "surveyRating",
        headerName: "Survey rating",
        width: 130,
        renderCell: renderCellExpand
    },
    {
        field: "surveyDescription",
        headerName: "Survey Description",
        width: 1200,
        renderCell: renderCellExpand
    }
];

const columnsExcel = [
    {
        key: "userEmail",
        header: "User email"
    },
    {
        key: "partnerEmail",
        header: "Partner email"
    },
    {
        key: "surveyRating",
        header: "Survey rating"
    },
    {
        key: "surveyDescription",
        header: "Survey Description"
    }
];

export default (props) => {
    console.log(props);
    const [meetupRatings, setMeetupRatings] = useState();

    useEffect(() => {
        console.log("meetupRatings ", meetupRatings);
        if (props.activeMeetup) {
            fetch(`${CONF.BAYRONNECT_API_URL}/api/getMeetupRatings`, {
                method: "POST",
                body: JSON.stringify({
                    meetupId: props.activeMeetup.meetupId
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    setMeetupRatings(data);
                });
        }
    }, [props]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <ExcelExportButton onClick={saveExcel}>
                    Export to Excel
                </ExcelExportButton>
            </GridToolbarContainer>
        );
    }
    const ExcelExportButton = styled.div`
        background-color: #dc1448;
        padding: 10px;
        color: white;
        border-radius: 5%;
        cursor: pointer;
    `;

    function saveExcel() {
        // Create workbook & add worksheet
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("User Feedback");

        // add column headers
        worksheet.columns = columnsExcel;

        // // Add row using key mapping to columns
        worksheet.addRows(meetupRatings);

        // save workbook to disk
        workbook.xlsx
            .writeBuffer()
            .then((buffer) =>
                FileSaver.saveAs(
                    new Blob([buffer]),
                    `${props.activeMeetup.meetupName}_feedback.xlsx`
                )
            )
            .catch((err) => console.log("Error writing excel export", err));
    }

    return (
        <>
            {meetupRatings && (
                <div>
                    <StyledDialog
                        open={props.ratingsModal}
                        onClose={props.closeRatingsModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogActions>
                            <IconButton
                                aria-label="close"
                                onClick={props.closeRatingsModal}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogActions>
                        <DialogTitle id="alert-dialog-title">
                            {" "}
                            <p
                                style={{
                                    paddingLeft: "40px",
                                    marginTop: "0",
                                    fontWeight: "bold"
                                }}
                            >
                                Meetup:{" "}
                                {props.activeMeetup &&
                                    props.activeMeetup.meetupName}
                            </p>
                        </DialogTitle>
                        <DialogContent>
                            <div
                                style={{ paddingBottom: "60px", width: "100%" }}
                            >
                                <DataGrid
                                    autoHeight={true}
                                    rows={meetupRatings}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    components={{ Toolbar: CustomToolbar }}
                                />
                            </div>
                        </DialogContent>
                    </StyledDialog>
                </div>
            )}
        </>
    );
};
