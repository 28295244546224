import React, { useEffect } from "react";
import styled from "styled-components";
import { Redirect, withRouter } from "react-router-dom";
import { dashboardUser } from "../../pages/statisticPage";

const Container = styled.div`
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
`;

const Chatbot = (props) => {
    const username = localStorage.getItem("username");
    const isAdmin = username
        ? dashboardUser.includes(username.split("@")[0])
        : false;

    useEffect(() => {
        if (!isAdmin) {
            props.history.push(`/`);
        }
    });

    return (
        <Container>
            <iframe
                src="https://cocohub.ai/video?channelId=02-WyJjaGFubmVscyIsNjUyNDJd&sceneId=BGR_Living_Room"
                width="600"
                height="600"
                allow="camera;microphone"
            />
        </Container>
    );
};

export default withRouter(Chatbot);
