import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { colors } from "../general/styles";
import { CONF } from "../../config/config";

const ModalWrap = styled(Dialog)`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .MuiDialog-paperWidthSm {
        max-height: 300px;
    }

    .modalWrap {
        display: flex;
        flex-direction: column;
        min-width: 400px;

        @media only screen and (orientation: portrait) {
            min-width: 200px;
            overflow-y: auto;
        }
    }
    .newMeetupHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    h3 {
        text-align: center;
        margin: 0;
    }
    .submit-button-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: center;
        margin-top: 24px;

        .saveUserInfo {
            margin: 0 auto;
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;

            padding: 15px 71px;
            border-radius: 23px;
            background-color: #11394f;
            border: none;
        }
    }
    .input-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: flex-start;
        margin-top: 24px;

        label {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4d4d4d;
        }

        input {
            padding: 0;
        }
        .input-field {
            border: none;
            box-sizing: border-box;
            margin-top: 16px;
            border-radius: 8px;
            background-color: #f4f2f2;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${colors.darkGray};
            padding-left: 15.5px;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 100%;
        }
    }

    input {
        padding: 0;
    }

    .MuiInputBase-root {
        margin-top: 5px;
        margin-bottom: 20px;
        width: 100%;
    }
`;

export default function UserProfileModal(props) {
    const open = props.showUserProfile;
    // const [username, setUsername] = useState('');
    const [city, setCity] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    //TODO PETAR ADD NEW ENDPOINT AND GET USER CITY & USERNAME
    async function fetchData(change = false) {
        let data = {
            jwtToken: localStorage.getItem("jwtToken")
        };
        if (change) {
            data.city = city;
        }
        await fetch(`${CONF.BAYRONNECT_API_URL}/api/getUserInfo2`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log("RESPONSE DATA", data);
                // setUsername(data.email);
                setCity(data.city);
                if (change) {
                    handleClose();
                }
            });
    }

    const handleClose = () => {
        props.setShowUserProfile(false);
    };

    const saveUserInfo = () => {
        fetchData(true);
    };

    return (
        <ModalWrap
            className="userProfileModal"
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{
                width: "100%",
                maxWidth: "100vw",
                maxHeight: "100%",
                position: "fixed",
                top: "50%",
                left: "0",
                transform: "translate(0, -50%)",
                overflowY: "auto"
            }}
        >
            <div className="modalWrap">
                <div className="newMeetupHeader">
                    <h3>Profile</h3>
                    <div className="exitModal" onClick={handleClose}>
                        <CloseIcon className="closeModalIcon" />
                    </div>
                </div>
                {/* <div className="input-wrap">
          <label>Username</label>
          <input className="input-field" type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)}/>
        </div> */}
                <div className="input-wrap">
                    <label>City</label>
                    <input
                        className="input-field"
                        type="text"
                        id="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <div className="submit-button-wrap">
                    <button className="saveUserInfo" onClick={saveUserInfo}>
                        Update
                    </button>
                </div>
            </div>
        </ModalWrap>
    );
}
