import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CONF } from "../../config/config";

const EnabledFiltersContainer = styled.div`
    position: fixed;
    top: 82px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    color: white;
    width: 100%;
    z-index: 5;

    @media screen and (max-width: 889px) {
        top: 60px;
    }

    .MuiExpansionPanel-root {
        border-radius: 0 !important;
    }
`;

const StyledEnabledFilters = styled.div`
    .filters-title {
        font-weight: bold;
        font-size: 18px;
        margin: 0 0 10px 0;
    }
    .filters-content {
        display: flex;
        flex-wrap: wrap;
    }
    .filters-item {
        margin: 5px 3px;
        padding: 0 7px 0 0;
        border-right: 2px solid black;
        &:last-of-type {
            border-right: none;
        }
    }
    .MuiDivider-root {
        background-color: white;
        margin: 0 10px;
    }
`;

const EnabledFilters = (props) => {
    const [filters, setFilters] = useState({
        allCities: [],
        filters: {
            city: {
                meet_city: [],
                nomeet_city: []
            },
            communities: {
                meet_communities: [],
                nomeet_communities: []
            },
            departments: {
                meet_departments: [],
                nomeet_departments: []
            },
            divisions: {
                meet_divisions: [],
                nomeet_divisions: []
            },
            organisations: {
                meet_organisations: [],
                nomeet_organisations: []
            },
            products: {
                meet_products: [],
                nomeet_products: []
            },
            skills: {
                meet_skills: [],
                nomeet_skills: []
            }
        },
        hardFilters: {}
    });
    useEffect(() => {
        async function fetchData() {
            await fetch(`${CONF.BAYRONNECT_API_URL}/api/getUserPreferences`, {
                method: "POST",
                body: JSON.stringify({
                    jwtToken: localStorage.getItem("jwtToken")
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setFilters(data);
                    // console.log("RESPONSE DATA", data);
                });
        }
        fetchData();
    }, []);

    return (
        <EnabledFiltersContainer>
            {filters?.filters?.city?.meet_city?.length ||
            filters?.filters?.city?.nomeet_city?.length ||
            filters?.filters?.communities?.meet_communities?.length ||
            filters?.filters?.communities?.nomeet_communities?.length ||
            filters?.filters?.departments?.meet_departments?.length ||
            filters?.filters?.departments?.nomeet_departments?.length ||
            filters?.filters?.divisions?.meet_divisions?.length ||
            filters?.filters?.divisions?.nomeet_divisions?.length ||
            filters?.filters?.organisations?.meet_organisations?.length ||
            filters?.filters?.organisations?.nomeet_organisations?.length ||
            filters?.filters?.products?.meet_products?.length ||
            filters?.filters?.products?.nomeet_products?.length ||
            filters?.filters?.skills?.meet_skills?.length ||
            filters?.filters?.skills?.nomeet_skills?.length ? (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Enabled filters:</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StyledEnabledFilters>
                            <div className={"filters-content"}>
                                {filters.filters.city.meet_city.length ? (
                                    <div className={"filters-item"}>
                                        <b>City:</b>{" "}
                                        {filters.filters.city.meet_city.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.city.nomeet_city.length ? (
                                    <div className={"filters-item"}>
                                        <b>No city:</b>{" "}
                                        {filters.filters.city.nomeet_city.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.communities.meet_communities
                                    .length ? (
                                    <div className={"filters-item"}>
                                        <b>Communities:</b>{" "}
                                        {filters.filters.communities.meet_communities.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.communities.nomeet_communities
                                    .length ? (
                                    <div className={"filters-item"}>
                                        <b>No communities:</b>
                                        {filters.filters.communities.nomeet_communities.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.departments.meet_departments
                                    .length ? (
                                    <div className={"filters-item"}>
                                        <b>Departments:</b>{" "}
                                        {filters.filters.departments.meet_departments.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}
                                {filters.filters.departments.nomeet_departments
                                    .length ? (
                                    <div className={"filters-item"}>
                                        <b>No Departments:</b>
                                        {filters.filters.departments.nomeet_departments.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.divisions.meet_divisions
                                    .length ? (
                                    <div className={"filters-item"}>
                                        <b>Divisions:</b>{" "}
                                        {filters.filters.divisions.meet_divisions.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}
                                {filters.filters.divisions.nomeet_divisions
                                    .length ? (
                                    <div className={"filters-item"}>
                                        <b>No divisions:</b>{" "}
                                        {filters.filters.divisions.nomeet_divisions.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.organisations
                                    .meet_organisations.length ? (
                                    <div className={"filters-item"}>
                                        <b>Organisations:</b>{" "}
                                        {filters.filters.organisations.meet_organisations.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}
                                {filters.filters.organisations
                                    .nomeet_organisations.length ? (
                                    <div className={"filters-item"}>
                                        <b>No Organisations:</b>
                                        {filters.filters.organisations.nomeet_organisations.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.products.meet_products
                                    .length ? (
                                    <div className={"filters-item"}>
                                        <b>Products:</b>{" "}
                                        {filters.filters.products.meet_products.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}
                                {filters.filters.products.nomeet_products
                                    .length ? (
                                    <div className={"filters-item"}>
                                        <b>No products:</b>{" "}
                                        {filters.filters.products.nomeet_products.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.skills.meet_skills.length ? (
                                    <div className={"filters-item"}>
                                        <b>Skills:</b>{" "}
                                        {filters.filters.skills.meet_skills.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}

                                {filters.filters.skills.nomeet_skills.length ? (
                                    <div className={"filters-item"}>
                                        <b>No skills:</b>{" "}
                                        {filters.filters.skills.nomeet_skills.join(
                                            ", "
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </StyledEnabledFilters>
                    </AccordionDetails>
                </Accordion>
            ) : null}
        </EnabledFiltersContainer>
    );
};

export default EnabledFilters;
