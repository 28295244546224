import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { CONF } from "../../config/config";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const StyledButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
        margin: 20px;
    }
`;

const Whale = styled.div`
    display: flex;
    justify-content: center;
    margin: 15px 20px 20px 20px;
`;

const ModalText = styled.span`
    text-align: center;
    width: 100%;
    display: block;
`;

const StyledButton = styled.button`
    border-radius: 23px;
    width: 224px;
    background-color: ${(props) => (props.hollow ? "#ffffff" : "#dc1448")};
    border-color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props) => (props.hollow ? "#dc1448" : "#ffffff")};
    padding: 12px 0;
    display: flex;
    justify-content: center;

    :hover {
        background-color: ${(props) => (props.hollow ? "#e9e9e9" : "#BE1442")};
        border-color: #be1442;
    }
`;

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

export default function NewsModal(props) {
    const [open, setOpen] = React.useState(true);

    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
    const handleClose = () => {
        if (props.news) {
            localStorage.setItem("news", "false");
        }
        if (props.link) {
            props.setModal(false);
        }
        setOpen(false);
    };

    return (
        <div>
            {/*<Button variant="outlined" color="primary" onClick={handleClickOpen}>*/}
            {/*    Open dialog*/}
            {/*</Button>*/}
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                {props.news && (
                    <>
                        <DialogTitle
                            id="customized-dialog-title"
                            onClose={handleClose}
                        >
                            Byron Updates
                        </DialogTitle>
                        <DialogContent dividers>
                            <Whale>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="41"
                                    viewBox="0 0 40 41"
                                >
                                    <g fill="none" fillRule="evenodd">
                                        <path
                                            fill="#D30F4B"
                                            d="M20 .5C8.954.5 0 9.454 0 20.5s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20"
                                        />
                                        <path
                                            fill="#FFF"
                                            d="M31.034 12.046c0-1.045-.847-1.787-1.892-1.787-2.026 0-3.281 2.355-2.6 6.328.66-1.979 1.767-2.543 2.6-2.543.896 0 1.892-.567 1.892-1.998M22.258 15.211c0-.638.517-1.091 1.155-1.091 1.238 0 2.005 1.438 1.587 3.865-.402-1.21-1.079-1.553-1.587-1.553-.547 0-1.155-.347-1.155-1.22M25.507 19.012c-7.253 0-8.491 7.048-13.523 4.738-1.067-.49-1.57-1.09-1.83-1.627a2.319 2.319 0 0 1-.265-.955c-.05-.971 1.537-3.882.937-5.467-.605-1.597-2.773 2.053-3.715 2.557-.941.503-5.3-.068-4.418 1.395.876 1.452 4.187 1.86 4.877 2.643.114.13.241.368.383.674 1.564 2.926 3.37 8.057 11.588 8.188-.114.137-.228.276-.328.391-.203.235-.362.42-.45.57a.438.438 0 0 0 .002.435c.072.123.234.271.602.271h.006c.67-.016 2.234-.408 3.578-1.396l.276-.202c1.67-.003 3.83-.087 6.583-.721 1.561-.36 2.834-1.09 2.984-1.748 1.878-8.26-2.245-9.745-7.287-9.745zm1.857 8.142a1.335 1.335 0 0 1-1.333-1.333c0-.735.598-1.334 1.333-1.334.734 0 1.333.599 1.333 1.334 0 .734-.599 1.333-1.333 1.333z"
                                        />
                                    </g>
                                </svg>
                            </Whale>
                            <Typography gutterBottom>
                                <strong>
                                    Want to be up-to-date with all the new
                                    features we add to Byron and ByronMeet?
                                </strong>
                                <br />
                                If you click „Yes”, we’ll send you our feature
                                updates once a month. You can also participate
                                in our feature calls every two months. If you
                                change your mind, you can opt-out whenever you
                                want!
                            </Typography>
                            <StyledButtons>
                                <StyledButton
                                    className={"acceptNews"}
                                    onClick={async () => {
                                        fetch(
                                            `${CONF.BAYRONNECT_API_URL}/api/receiveNewsletter`,
                                            {
                                                method: "POST",
                                                body: JSON.stringify({
                                                    jwt: localStorage.getItem(
                                                        "jwtToken"
                                                    ),
                                                    choice: true
                                                }),
                                                headers: {
                                                    "Content-Type":
                                                        "application/json"
                                                }
                                            }
                                        ).then((response) => {
                                            console.log("response", response);
                                            return response.json();
                                        });
                                        handleClose();
                                    }}
                                    width="180px"
                                    hollow={false}
                                >
                                    Yes
                                </StyledButton>
                                <StyledButton
                                    className={"laterNews"}
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    width="180px"
                                    hollow={true}
                                >
                                    Maybe Later
                                </StyledButton>
                            </StyledButtons>
                        </DialogContent>
                    </>
                )}
                {props.link && (
                    <>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                https://bayron.intranet.cnb/bayronnect/
                                {encodeURIComponent(props.link)}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                autoFocus
                                onClick={handleClose}
                                color="primary"
                            >
                                COOL
                            </Button>
                        </DialogActions>
                    </>
                )}

                {props.alert && (
                    <>
                        <DialogTitle id="customized-dialog-title">
                            Update!
                        </DialogTitle>
                        <DialogContent dividers>
                            <Whale>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="41"
                                    viewBox="0 0 40 41"
                                >
                                    <g fill="none" fillRule="evenodd">
                                        <path
                                            fill="#D30F4B"
                                            d="M20 .5C8.954.5 0 9.454 0 20.5s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20"
                                        />
                                        <path
                                            fill="#FFF"
                                            d="M31.034 12.046c0-1.045-.847-1.787-1.892-1.787-2.026 0-3.281 2.355-2.6 6.328.66-1.979 1.767-2.543 2.6-2.543.896 0 1.892-.567 1.892-1.998M22.258 15.211c0-.638.517-1.091 1.155-1.091 1.238 0 2.005 1.438 1.587 3.865-.402-1.21-1.079-1.553-1.587-1.553-.547 0-1.155-.347-1.155-1.22M25.507 19.012c-7.253 0-8.491 7.048-13.523 4.738-1.067-.49-1.57-1.09-1.83-1.627a2.319 2.319 0 0 1-.265-.955c-.05-.971 1.537-3.882.937-5.467-.605-1.597-2.773 2.053-3.715 2.557-.941.503-5.3-.068-4.418 1.395.876 1.452 4.187 1.86 4.877 2.643.114.13.241.368.383.674 1.564 2.926 3.37 8.057 11.588 8.188-.114.137-.228.276-.328.391-.203.235-.362.42-.45.57a.438.438 0 0 0 .002.435c.072.123.234.271.602.271h.006c.67-.016 2.234-.408 3.578-1.396l.276-.202c1.67-.003 3.83-.087 6.583-.721 1.561-.36 2.834-1.09 2.984-1.748 1.878-8.26-2.245-9.745-7.287-9.745zm1.857 8.142a1.335 1.335 0 0 1-1.333-1.333c0-.735.598-1.334 1.333-1.334.734 0 1.333.599 1.333 1.334 0 .734-.599 1.333-1.333 1.333z"
                                        />
                                    </g>
                                </svg>
                            </Whale>
                            <Typography gutterBottom>
                                <ModalText
                                    dangerouslySetInnerHTML={{
                                        __html: props.content
                                    }}
                                />
                            </Typography>
                            <StyledButtons></StyledButtons>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </div>
    );
}
