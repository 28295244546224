// Wikipedia REST API:
// https://stackoverflow.com/questions/8555320/is-there-a-wikipedia-api-just-for-retrieve-content-summary

// Access Data from an External API into a React Component:
// https://www.pluralsight.com/guides/access-data-from-an-external-api-into-a-react-component

// Reading and writing json files with node:
// https://stackabuse.com/reading-and-writing-json-files-with-node-js/

// Selecting random json object:
// https://stackoverflow.com/questions/30061969/select-random-object-from-json

// Creating sort of a constructor function for functional React. Essentially meaning,
// Code that runs before anything else in the life-cycle of this component, and
// Code that runs once, and only once, for the entire life-cycle of this component.
// From
// https://dev.to/bytebodger/constructors-in-functional-components-with-hooks-280m

// How to use componentWillMount and componentWillUnmount in Functional React.
// https://dev.to/robmarshall/how-to-use-componentwillunmount-with-functional-components-in-react-2a5g

import React, { useState } from "react";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { CONF } from "../../config/config";
import "./klick.css";
// import { te } from "date-fns/locale";

const Klick = (props) => {
    // const [counter, setCounter] = useState(0);
    // const [constructorHasRun, setConstructorHasRun] = useState(false);

    //const [myNotes, setMyNotes] = useState("My Notes");
    // const [selectedItem, setSelectedItem] = useState(true);

    var currentDate = new Date();

    // require understands .json data and loads it properly if the file has a .json ending.
    let jsonData = require("./data.json");
    // Let through all elements with expiryDate == '' or
    // expiryDate > currentDate.
    jsonData = jsonData.filter(
        (item) =>
            (item.expiry_date === "") |
            (item.expiry_date === null) |
            (new Date(item.expiry_date) > currentDate)
    );
    if (CONF.OPS_MODE === "external") {
        jsonData = jsonData.filter(
            (item) => item.labels === "personality" || item.labels === "book"
        );
    }

    // objectKeys is an array holding the keys of the data that can be shown.
    var objectKeys = Object.keys(jsonData);

    // temporaryKey will be the currently selected key of the objectKeys array.
    var temporaryKey;
    // ran_key will be the currently selected object of the objectKeys array.
    var ran_key;
    // selectedKeys is an Array holding all keys that were selected, in the order in which they were selected.
    // selectedKey is the position in the selectedKeys array where we are currently.
    const [navigation, setNavigation] = useState({
        selectedItem: "",
        selectedKeys: [],
        selectedKey: ""
    });

    // console.log("Klick is called.");

    // TODO +++ all of this should be moved to a database approach.
    // Then this code would run on bayronnect-server.
    // To prevent that Webpack adds an MD5 hash to the end of the file name during building when
    // copying the image files to static/media/, one should change the configuration
    // of WebPack as follows, and rebuild the service or the docker container.
    // In the file
    // bayronnect/node_modules/react-scripts/config/webpack.config.js
    // remove .[hash:8] in static/media/[name].[hash:8].[ext]
    // This is the part of the config file:
    /*
  {
    test: [/\.bmp$/, /\.gif$/, /\.jpe?g$/, /\.png$/],
    loader: require.resolve('url-loader'),
    options: {
      limit: 10000,
      name: 'static/media/[name].[ext]',
    },
  },
  /*
  */
    let contentDirectory = "./files/";

    // We will do the "Take notes" functionality at another time.
    // var leftPressed = false;
    // var rightPressed = false;
    // var myNotes = "My Notes " + new Date();

    const rightChars = "uiopjklnm";
    const leftChars = "qwerasdfyxcv";

    const [open, setOpen] = useState(true);
    // let timeOut;

    // Select a new object (ran_key) to be displayed.
    var getNewItem = (temporarySelectedKeys) => {
        if (temporarySelectedKeys.length < objectKeys.length) {
            // The user has not seen all data.
            do {
                temporaryKey = Math.floor(Math.random() * objectKeys.length);
                ran_key = objectKeys[temporaryKey];
            } while (temporarySelectedKeys.indexOf(temporaryKey) > -1); // Repeat until we find an item the user has not seen yet.
        } else {
            // The user has seen all data. Just find a random item.
            temporaryKey = Math.floor(Math.random() * objectKeys.length);
            ran_key = objectKeys[temporaryKey];
        }
        // console.log("getNewItem temporaryKey: " + temporaryKey);
    };

    var moveToNextItem = () => {
        let temporarySelectedItem;
        let temporarySelectedKey = -1;
        let temporarySelectedKeys = [];
        if (navigation.selectedKey === "") {
            temporarySelectedKey = -1;
            // console.log("navigation.selectedKey: " + navigation.selectedKey);
        } else {
            temporarySelectedKey = navigation.selectedKey;
        }
        // console.log("navigation.selectedKey start: " + navigation.selectedKey);
        // console.log("temporarySelectedKey start: " + temporarySelectedKey);
        if (navigation.selectedKeys.length > 0) {
            temporarySelectedKeys = navigation.selectedKeys;
        }

        // Avoid showing elements twice on the same session by checking if temporaryKey is already on
        // selectedKeys. However, at some point the user will have seen all elements.
        // The length of selectedKeys will be the same as objectKeys.
        // At that moment we stop using the check if temporaryKey is already on selectedKeys.
        // Elements may be shown multiple times if the user keeps clicking.
        // selectedKeys will hold the sequence in which elements were shown.

        // if selectedKey is at the end of the selectedKeys array it means, we need a new temporaryKey.
        // If not, we have been going back and forth and we move to the next element of selectedItems.
        if (temporarySelectedKey === temporarySelectedKeys.length - 1) {
            getNewItem(temporarySelectedKeys);
            // console.log(ran_key);
            temporarySelectedKeys = temporarySelectedKeys.concat(temporaryKey);
            temporarySelectedKey = temporarySelectedKey + 1;
            //setSelectedKeys(selectedKeys => [...selectedKeys, temporaryKey]);

            // console.log("moveToNextItem mid selectedKeys.length: " + temporarySelectedKeys.length);
            // console.log("moveToNextItem mid pre selectedKey: " + temporarySelectedKey);
        } else {
            // We are not at the end of the selectedKeys array.
            temporarySelectedKey = temporarySelectedKey + 1;
            //temporarySelectedKeys = temporarySelectedKeys.concat(temporaryKey);
            ran_key = temporarySelectedKeys[temporarySelectedKey];
            //setSelectedItem(jsonData[ran_key]);
        }
        //setSelectedKey(selectedKey => selectedKey + 1);
        //setSelectedItem(selectedItem => jsonData[ran_key]);
        temporarySelectedItem = jsonData[ran_key];
        // console.log("ran_key");
        // console.log(ran_key);
        // console.log(jsonData[ran_key]);
        // console.log(objectKeys[ran_key]);

        setNavigation({
            selectedItem: temporarySelectedItem,
            selectedKeys: temporarySelectedKeys,
            selectedKey: temporarySelectedKey
        });

        // console.log("navigationSelectedItem.name: " + temporarySelectedItem.name);

        // From https://javascript.plainenglish.io/how-to-add-to-an-array-in-react-state-3d08ddb2e1dc,
        // .push does not work because .push returns the length of the array after modification,
        // not the array itself.
        // .concat neither because if will add the digits of a number as single digits to the array,
        // e.g. of 156 would add 3 new elements at the end, 1, 5, and 6.

        // console.log("moveToNextItem end selectedKey: " + temporarySelectedKey);
        // console.log("moveToNextItem end selectedKeys.length: " + temporarySelectedKeys.length);
    };

    var moveToPreviousItem = () => {
        let temporarySelectedKey = navigation.selectedKey;
        let temporarySelectedItem = navigation.selectedItem;
        let temporarySelectedKeys = navigation.selectedKeys;
        // console.log("moveToPreviousItem start temporarySelectedKey: " + temporarySelectedKey);
        // console.log("moveToPreviousItem start selectedKeys.length: " + temporarySelectedKeys.length);
        if (temporarySelectedKey === 0) {
            // Add new item at the beginning of the array.
            getNewItem(temporarySelectedKeys);
            temporarySelectedKeys.unshift(temporaryKey);
            // temporarySelectedKey stays as 0.
        } else {
            temporarySelectedKey = temporarySelectedKey - 1;
            // console.log("moveToPreviousItem mid temporarySelectedKey: " + temporarySelectedKey);
        }
        // console.log("moveToPreviousItem end temporarySelectedKey: " + temporarySelectedKey);
        // console.log("moveToPreviousItem end temporarySelectedKeys[temporarySelectedKey]: " + temporarySelectedKeys[temporarySelectedKey]);
        ran_key = objectKeys[temporarySelectedKeys[temporarySelectedKey]];
        temporarySelectedItem = jsonData[ran_key];

        setNavigation({
            selectedItem: temporarySelectedItem,
            selectedKeys: temporarySelectedKeys,
            selectedKey: temporarySelectedKey
        });
    };

    useEffect(() => {
        // Anything in here is fired on component mount.
        /*
    timeOut = setTimeout(function(){
      window.location.href = currentPath;
    }, 20000);
    console.log(timeOut);
    */
        // Jesus: Too many issues with document.addEventListener in useEffect.
        // The state props changed to initial state when calling first moveToNextItem
        // to moveToPreviousItem.
        // Leaving it here as reference only.
        // document.addEventListener ("keydown", handleKeyDown, false);
        moveToNextItem();
        // console.log("klick useEffect");
        // alert ("useEffect");
        return function cleanup() {
            // Anything in here is fired on component unmount.
            // Same as above for removeEventListener.
            document.removeEventListener("keydown", handleKeyDown, false);
            // clearTimeout(timeOut);
        };
    }, []);

    /*
  const handleClickOpen = () => {
    setOpen(true);
  };
  */

    const handleClose = () => {
        setOpen(false);
        if (props.setShowConversationalAi) {
            props.setShowConversationalAi(false);
        }
        // props.resetIdleTimer();
        //clearTimeout(timeOut);
    };

    var handleKeyDown = (e) => {
        // We will respond only to certain keys.
        // Space and left characters: Move to the next item.
        // Right characters: Take note of what was shown, and move then.

        // See https://devstephen.medium.com/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a
        // for a discussion about using key vs keyCode.

        if (e.key === " " || leftChars.indexOf(e.key) > -1) {
            moveToNextItem();
        } else if (rightChars.indexOf(e.key) > -1) {
            // Take note of what was shown.
            // alert ("handleKeyDown old selected Item: " + selectedItem.name + " " + jsonData[ran_key].name);

            // myNotes = myNotes + "\n" + jsonData[ran_key].name + ", " + jsonData[ran_key].url;

            // console.log (myNotes);
            // Now move to the next item.
            moveToNextItem();
            // alert ("handleKeyDown new selected Item: " + selectedItem.name + " " + jsonData[ran_key].name);
            // keyCode 39:
        } else if (e.key === "ArrowDown" || e.key === "ArrowRight") {
            moveToNextItem();
        } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
            moveToPreviousItem();
        } else if (e.key === "Escape") {
            handleClose();
        } else if (e.key === "Enter") {
            moveToNextItem();
        }
        // leftPressed = false;
        // rightPressed = false;
    };

    const closeModal = async () => {
        if (props.setShowConversationalAi) {
            props.setShowConversationalAi(false);
        }
    };

    /*
  const constructor = () => {
    if (constructorHasRun) return;
    // A state variable could be the equivalent of a constructor however it misses
    // running some code only once.

    // rightPressed = false;
    // handleKeyDown = handleKeyDown.bind ();

    // console.log("Klick inline constructor just ran.");
    setConstructorHasRun(true);
  };
  */

    // constructor();

    return (
        <div>
            <Dialog
                onClose={handleClose}
                onKeyDown={handleKeyDown}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth="lg"
                // maxHeight="lg"
            >
                <div
                    className="newMeetupHeader"
                    style={{ justifyContent: "end" }}
                >
                    <div className="exitModal" onClick={closeModal}>
                        <CloseIcon className="closeModalIcon" />
                    </div>
                </div>
                {
                    navigation.selectedItem.content ? (
                        <img
                            style={{ width: "100%", height: "auto" }}
                            className="info-img-content"
                            src={
                                require("" +
                                    contentDirectory +
                                    navigation.selectedItem.content).default
                            }
                            alt={navigation.selectedItem.name}
                            onClick={moveToNextItem}
                        />
                    ) : (
                        ""
                    )
                    /* This was for a different format. On hold now.
          <div>
            <h2 className="info-title">{selectedItem.name}</h2>
            <img className="info-img" src={selectedItem.image} alt={selectedItem.name}/>
            <h5 className="info-item">{selectedItem.shortDescription}</h5>
          </div>
          */
                }
                {/*
        I had this code but it was not working properly.
        <div className="modalWrap" >
          <div className="newMeetupHeader">
            <div className="exitModal" onClick={closeModal}>
              <CloseIcon className="closeModalIcon" />
            </div>
          </div>
          {selectedItem.content ?
          <img style={{ width: 'auto', height: '90%' }} className="info-img-content" src={require("" + contentDirectory + selectedItem.content)} alt={selectedItem.name}/>
          :
          <div>
            <h2 className="info-title">{selectedItem.name}</h2>
            <img className="info-img" src={selectedItem.image} alt={selectedItem.name}/>
            <h5 className="info-item">{selectedItem.shortDescription}</h5>
          </div>
          }
        </div>
        */}
                {/*
        // This is not updating for some reason.
        // Anyway we should change the way notes are displayed.
        <TextareaAutosize className="my-notes" readOnly value={myNotes} />
        */}
            </Dialog>
        </div>
    );
};

export default withRouter(Klick);
