import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const InternalityDropdown = (props) => {
    const [value, setValue] = useState(props.internality);
    return (
        <Select
            id="internality"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                props.setInternality(e.target.value);
            }}
            className="chatDurationSelect"
        >
            <MenuItem value={"Internal"}>Internal</MenuItem>
            <MenuItem value={"External"}>External</MenuItem>
        </Select>
    );
};
