import { InfoTooltip } from "../infoTooltip";
import React from "react";

export const TextInput = (props) => {
    return (
        <div className="input-wrap">
            <label>
                {props.label}{" "}
                {props.tooltipText && (
                    <InfoTooltip tooltipText={props.tooltipText} />
                )}
            </label>
            <input
                className="meetupInput"
                type="text"
                id={props.inputId}
                placeholder={props.placeholder}
                value={props.value}
                // disabled={props.meetup && action === "update" ? "" : ""}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </div>
    );
};
