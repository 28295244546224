/** @format */

import React from "react";
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const Wrapper = styled.div`
    height: auto;
    width: 100%;
    box-sizing: border-box;
    border: none;
    display: flex;
    flex-direction: column;
    padding-top: 43.5px;
`;
const AutocompleteComp = styled(Autocomplete)`
    .MuiInputBase-root {
        border: none;
        :focus {
            border: none;
        }
        :hover {
            border: none !important;
        }
    }
    input:focus {
        border: none;
    }
    .MuiAutocomplete-tag {
        border-radius: 3px;
        background-color: #d8e7ef;
        padding-left: 8.5px;
        padding-top: 9px;
        padding-bottom: 9px;

        span {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #11394f;
        }
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #11394f !important;
    }
`;
const TextFieldComp = styled(TextField)`
    color: red;
    border-color: white;

    > div {
        border-radius: 8px;
        background-color: #f4f2f2;
    }
`;

const Languages = (props) => {
    const languages = [
        "english",
        "german",
        "norwegian",
        "mandarin",
        "japanese",
        "spanish",
        "turkish",
        "portuguese",
        "swedish",
        "french"
    ];
    return (
        <Wrapper>
            <label>Languages you speak:</label>
            <AutocompleteComp
                multiple
                options={languages}
                defaultValue={props.userLanguages}
                onChange={(event, value) => props.setLanguages(value)}
                renderInput={(params) => (
                    <TextFieldComp
                        {...params}
                        className=""
                        margin="normal"
                        variant="outlined"
                    />
                )}
                className={"prefLanguages"}
            />
        </Wrapper>
    );
};

export default Languages;
