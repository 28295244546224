const ics = require("ics");
export const createEvent = meetup => {
    const startDate = new Date(meetup.startDate);
    const endDate = new Date(meetup.endDate);
    const duration = (endDate - startDate) / 60000;
    const baseUrl = window._env_.REACT_APP_BAYRONNECT_URL;

    console.log(meetup);
    var inviteText = "";
    var title = "";
    var location = "";
    let alarms = [];
    alarms.push({
        action: 'audio',
        description: 'Reminder',
        trigger: { hours: 0, minutes: 15, before: true },
        repeat: 1,
        attachType: 'VALUE=URI',
        attach: 'Glass'
    })
    if (!meetup.type || meetup.type == "bayronnect") {
        title = `${meetup.name} ByronMeet meetup`;
        location = `${baseUrl}`;
        inviteText = `Welcome!
      You are invited to a ByronMeet Speed Networking Session! Here is how it works:
      0. Use your favorite browser (which must be Chrome/new Edge/Safari). If you were told by your organizer to use VPN if you are not in your office, please make sure your corporate VPN is turned on.
      1. Go to ${baseUrl}
      2. If you're already logged in, move to step 5. If not, you'll see a button saying "Login". Please click the button.
      3. A Microsoft popup will appear. Please login using your Microsoft credentials.
      4. If you're entirely new to Byron and ByronMeet, you'll be shown a data privacy consent. Please click the "Agree" button if you agree to it and welcome to ByronMeet!
      5. Once the page has fully loaded (may take some seconds), either you are already in the ByronMeet events page, or you need to click on "ByronMeet".
      6. Now click 'Enter' on the event tile that says, ${escape(meetup.name)}.
      7. You'll see the camera confirmation screen. If you are okay with the camera settings, click "Confirm", otherwise change it respectively and then confirm. Notice that there are no virtual backgrounds, so be careful what is in your background!
      8. You are now being matched with a random person! Enjoy the conversations and DON'T FORGET to hit READY to match the next person!
      Thanks for using ByronMeet and if you have any feedback, don't hesitate to contact us via byron@bayer.com!
      P.S.: Don't forget to thank your organizer for setting up the event :)

      In case you are a Byron expert, the direct link to your meetup is:
      ${baseUrl}/${meetup.id}
      If this is a secret or group meetup, only the colleagues added to the meetup will be able to see or enter into the meetup.

      `;
    } else {
        title = `${meetup.name} event`;
        if (meetup.type === "online") {
            location = meetup.website ? meetup.website : "";
        } else {
            if (meetup.address) {
                location += meetup.address;
            }
            // if (meetup.city && meetup.city !== 'null') {
            //   location += meetup.address ? ', ' + meetup.city : meetup.city;
            // }
            // if (meetup.country && meetup.country !== 'null') {
            //   location += meetup.city ? ', ' + meetup.country : meetup.country;
            // }
            if (meetup.type === "hybrid") {
                location += location ? " / " + meetup.website : meetup.website;
            }
        }

        function trim1(str) {
            return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
        }
        let keywordsFormatted;
        if (Array.isArray(meetup.meetupAll.keywords)) {
            let keywordsString = meetup.meetupAll.keywords.join(",")
            keywordsFormatted = trim1(keywordsString).replace(/,/g, ", ");
        } else {
            keywordsFormatted = trim1(meetup.meetupAll.keywords).replace(/,/g, ", ");
        }



        inviteText = `
${meetup.description && meetup.description}

${(meetup.meetupAll.keywords && meetup.meetupAll.keywords.length > 0) ? 'Keywords: ' + keywordsFormatted : ''}

${meetup.meetupAll.language && 'Language: ' + meetup.meetupAll.language}

${meetup.meetupAll.website && 'Web: ' + meetup.meetupAll.website}

${meetup.meetupAll.registration ? "Registration required: Yes" + (meetup.meetupAll.registrationLink && ', ' + meetup.meetupAll.registrationLink) : "Registration required: No"}

${meetup.meetupAll.cost || meetup.meetupAll.cost === '' ? (meetup.meetupAll.cost === "0" || meetup.meetupAll.cost === '' ? "Cost: Free" : "Cost: " + meetup.meetupAll.cost) : ''}

${meetup.meetupAll.internality && meetup.meetupAll.internality + ' event'}
`.replace(/\n\s*\n/g, '\n\n');

        inviteText = replaceAll(inviteText, '#', '%23');

    }

    return ics.createEvent(
        {
            title: escape(title),
            description: inviteText,
            busyStatus: "BUSY",
            location: `${location}`,
            start: [
                startDate.getFullYear(),
                startDate.getMonth() + 1,
                startDate.getDate(),
                startDate.getHours(),
                startDate.getMinutes()
            ],
            end: [
                endDate.getFullYear(),
                endDate.getMonth() + 1,
                endDate.getDate(),
                endDate.getHours(),
                endDate.getMinutes()
            ],
            // duration: { minutes: duration },
            attendees: meetup.attendees,
            alarms: alarms
        },
        (error, value) => {
            if (error) {
                console.log(error);
            }
            return value;
        }
    );
};

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}
