// Wikipedia REST API:
// https://stackoverflow.com/questions/8555320/is-there-a-wikipedia-api-just-for-retrieve-content-summary

// Access Data from an External API into a React Component:
// https://www.pluralsight.com/guides/access-data-from-an-external-api-into-a-react-component

// Reading and writing json files with node:
// https://stackabuse.com/reading-and-writing-json-files-with-node-js/

// Selecting random json object:
// https://stackoverflow.com/questions/30061969/select-random-object-from-json

// Creating sort of a constructor function for functional React. Essentially meaning,
// Code that runs before anything else in the life-cycle of this component, and
// Code that runs once, and only once, for the entire life-cycle of this component.
// From
// https://dev.to/bytebodger/constructors-in-functional-components-with-hooks-280m

// How to use componentWillMount and componentWillUnmount in Functional React.
// https://dev.to/robmarshall/how-to-use-componentwillunmount-with-functional-components-in-react-2a5g

import React, { useState } from "react";
import "./klickbot.css";

const Klickbot = ({ showConversationalAiChatbot, setShowConversationalAiChatbot }) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendMessage = async () => {
    const userMessage = inputValue.trim();
    if (!userMessage) return;
    const newMessages = [...messages, { text: userMessage, sender: "user" }];
    setMessages(newMessages);
    setInputValue("");

    const botResponse = await sendMessageToChatGPT(newMessages);
    setMessages([...newMessages, { text: botResponse, sender: "bot" }]);
  };

   const handleClose = () => {
    setShowConversationalAiChatbot(false);
  };

  // if (!isOpen) return null; // Return null if the chatbot is closed

  return (
    showConversationalAiChatbot && (
      <div className="chat-container">
        <div className="chat-header">
          <span>Chatbot</span>
          <button className="close-button" onClick={handleClose}>X</button>
        </div>
        <div className="message-container">
          {messages.map((message, index) => (
            <div key={index} className={`${message.sender}-message`}>
              {message.text}
            </div>
          ))}
        </div>
        <div className="input-container">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={(event) =>
              event.key === "Enter" && handleSendMessage()
            }
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    )
  );

};

export default Klickbot;

async function sendMessageToChatGPT(conversation) { // Change parameter to conversation
    const apiURL = "https://chat.int.bayer.com/api/v1/chat/completions";
    const apiKey = process.env.REACT_APP_API_KEY; // Replace with your actual API key
    console.debug(`Sending message to API: ${apiURL}`);

    // Format the messages for the API
    const formattedMessages = conversation.map((msg) => ({
        role: msg.sender === "user" ? "user" : "assistant",
        content: msg.text,
    }));

    try {
        const response = await fetch(apiURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${apiKey}`
            },
            body: JSON.stringify({
                messages: [
                    {
                        role: "system",
                        content: "You are ByronAI, a large language model trained by Bayer. Follow the user's instructions carefully. Respond using markdown."
                    },
                    ...formattedMessages
                ],
                model: "gpt-4-turbo",
                temperature: 0.7,
                max_tokens: 150,
                stream: false, // Set stream to false
                response_format: {
                    type: "text"
                }
            })
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.debug('API response:', data);

        // Access the content of the response correctly
        return data.choices[0].message.content.trim();
    } catch (error) {
        console.error("Failed to fetch from OpenAI:", error);
        return "I'm sorry, I couldn't fetch the response. Please try again later.";
    }
}
